// storyofmylifeSchema.js
import { z } from 'zod';

const qualificationsSchema = z.object({
    qualifications: z.object({
        awards: z.array(z.object({
            name: z.string().min(3, 'Must be at least 3 characters long'),
            year: z.preprocess((arg) => {
              if (arg instanceof Date) {
                return arg;
              }
              if (typeof arg === 'string') {
                const date = new Date(arg);
                if (!isNaN(date.getTime())) {
                  return date;
                }
              }
              return undefined;
            }, z.date().optional()),
        })).optional(),
        education: z.array(z.object({
            institution: z.string().min(2,"Must not be empty"),
            degree: z.string().min(2,"Must not be empty"),
            privacy: z.boolean().optional(),
            fromDate: z.preprocess((arg) => {
              if (arg instanceof Date) {
                return arg;
              }
              if (typeof arg === 'string') {
                const date = new Date(arg);
                if (!isNaN(date.getTime())) {
                  return date;
                }
              }
              return undefined;
            }, z.date().optional()),
            toDate: z.preprocess((arg) => {
              if (arg instanceof Date) {
                return arg;
              }
              if (typeof arg === 'string') {
                const date = new Date(arg);
                if (!isNaN(date.getTime())) {
                  return date;
                }
              }
              return undefined;
            }, z.date().optional()),
        })).optional(),
        workExperience: z.array(z.object({
            company: z.string().min(3, 'Must be at least 3 characters long'),
            position: z.string().min(3, 'Must be at least 3 characters long'),
            privacy: z.boolean().optional(),
            fromDate: z.preprocess((arg) => {
              if (arg instanceof Date) {
                return arg;
              }
              if (typeof arg === 'string') {
                const date = new Date(arg);
                if (!isNaN(date.getTime())) {
                  return date;
                }
              }
              return undefined;
            }, z.date().optional()),
            toDate: z.preprocess((arg) => {
              if (arg instanceof Date) {
                return arg;
              }
              if (typeof arg === 'string') {
                const date = new Date(arg);
                if (!isNaN(date.getTime())) {
                  return date;
                }
              }
              return undefined;
            }, z.date().optional()),
        })).optional(),
    }).optional()
});

export default qualificationsSchema;
