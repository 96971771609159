"use client";
import JWIcon from '../../assets/JW_icon.png'
import ProfileViewLogger from "./ProfileViewLogger"; // Ensure correct path
import MovieIcon from '@mui/icons-material/Movie';
import TvIcon from '@mui/icons-material/Tv';
import DescriptionIcon from '@mui/icons-material/Description';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import BookIcon from '@mui/icons-material/Book';
import TheatersIcon from '@mui/icons-material/Theaters'; // Assuming for DOCUMENTARY
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'; // Assuming for OTHERS
import WorkIcon from '@mui/icons-material/Work';
import PersonIcon from '@mui/icons-material/Person';
import React, { useEffect, useState, useRef } from "react";
import { useAxios } from "../../contexts/AxiosContext";
import { useTheme } from "../../contexts/ThemeContext";
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box, IconButton, Button, Typography, Tooltip, Link,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Card, CardContent
} from "@mui/material";
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import FavoriteIcon from '@mui/icons-material/Favorite';
import HomeIcon from '@mui/icons-material/Home';
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { FaLinkedin, FaImdb } from "react-icons/fa";
import { SiMaildotru } from "react-icons/si";

import LanguagesCard from "../ProfileComponents/LanguagesCard";
import SeriesCarouselCard from "../ProfileComponents/SeriesCarouselCard";
import DocCarouselCard from "../ProfileComponents/DocCarouselCard";
import PeopleAndOthersCard from "../ProfileComponents/PeopleAndOthersCard";
import MovieGrid from "../ProfileComponents/MovieCarouselCard";
import GameGrid from '../ProfileComponents/GamesCarouselCard';
import BookGrid from '../ProfileComponents/BookCarouselCard';
import ReachOutModal from '../modals/ReachOutModal';
import MetaPreview from "../FormAddons/MetaPreview";
import { useMediaQuery } from '@mui/material';
import logger from "../../logger";
import PVDark from '../../assets/PVDark.jpg';
import PVLight from '../../assets/PVLight.jpg';
import { useLogin } from "../../contexts/LoginContext";

const categoryIcons = {
    'MOVIES': <MovieIcon />,
    'TV SHOWS': <TvIcon />,
    'DOCUMENTARY': <TheatersIcon />,
    'GAMES': <SportsEsportsIcon />,
    'BOOKS': <BookIcon />,
    'OTHERS': <MoreHorizIcon />
};

const PublicView = () => {
    const { pubProfileLink } = useParams();
    const navigate = useNavigate();
    const api = useAxios();
    const { theme, toggleTheme } = useTheme();
    const [profile, setProfile] = useState(null);
    const [media, setMedia] = useState({ movie: [], series: [], documentary: [], director: [], game: [], book: [], writer: [] });
    const [loading, setLoading] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedPoster, setSelectedPoster] = useState(null); // State for selected poster
    const [selectedWorkDetails, setSelectedWorkDetails] = useState(null); // State for selected work details
    const [hideLeftColumn, setHideLeftColumn] = useState(false); // State to hide the left column
    const [showFavorites, setShowFavorites] = useState(false); // State to show favorites
    const [selectedCategory, setSelectedCategory] = useState(null); // State to track selected category
    const [showExperience, setShowExperience] = useState(false); // State to show experience
    const [selectedExperience, setSelectedExperience] = useState(null); // State to track selected experience
    const isXsScreen = useMediaQuery('(max-width:600px)'); // Adjust '600px' based on your theme's xs breakpoint
    const [showSmallContact, setShowSmallContact] = useState(false); // State to show experience
    const [selectedExperienceUrl, setSelectedExperienceUrl] = useState(null);
    const [expandProfile, setExpandProfile] = useState(false); // State to expand profile picture view
    const bgColor = theme.palette.mode === "dark" ? "black" : "white";
    const textColor = theme.palette.text.primary;
    const pvImage = theme.palette.mode === "light" ? PVDark : PVLight;
    const { isLoggedIn, userId } = useLogin();
    const [showLeftColumn, setShowLeftColumn] = useState(false);
    const [hideMidAndRightColumns, setHideMidAndRightColumns] = useState(false); // State to hide mid and right columns
    const [publicOrder, setPublicOrder] = useState([]);
    const [selectedButton, setSelectedButton] = useState('ownWorks');

    useEffect(() => {
        setShowSmallContact(isXsScreen); // Set state based on screen size
    }, [isXsScreen]);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await api.get(`/public/profile/${pubProfileLink}`);
                if (response.data) {
                    setProfile(response.data);
                    console.log(response.data);
                    logger(`Profile data fetched successfully: ${JSON.stringify(response.data)}`, 'debug', ['env:production', 'issue:profile-fetch', 'component:PublicView'], 'soozh-beta-frontend');
                    const order = response.data.userPreference?.publicOrder || ['ownWorks', 'workedIn', 'favourites'];
                    setPublicOrder(order);
                    // Set flags based on the default view
                    switch (order[0]) {
                        case 'favourites':
                            setShowFavorites(true);
                            setShowExperience(false);
                            setSelectedCategory('MOVIES'); // Set default category
                            break;
                        case 'ownWorks':
                            setShowFavorites(false);
                            setShowExperience(false);
                            if (response.data.ownWorks && response.data.ownWorks.portfolio.length > 0) {
                                setSelectedPoster(response.data.ownWorks.portfolio[0].poster);
                                setSelectedWorkDetails(response.data.ownWorks.portfolio[0]);
                            }
                            break;
                        case 'workedIn':
                            setShowFavorites(false);
                            setShowExperience(true);
                            if (response.data.workedIn?.credited?.length > 0) {
                                setSelectedExperience(response.data.workedIn.credited[0]);
                                setSelectedExperienceUrl(response.data.workedIn.credited[0].proof_link);
                            }
                            break;
                        default:
                            setShowFavorites(false);
                            setShowExperience(false);
                            break;
                    }
                    // Set the first item in the ownWorks array as the selected work by default
                    if (response.data.ownWorks && response.data.ownWorks.portfolio.length > 0) {
                        setSelectedPoster(response.data.ownWorks.portfolio[0].poster);
                        setSelectedWorkDetails(response.data.ownWorks.portfolio[0]);
                    }
                } else {
                    logger('Profile data is null, navigating to edit profile', 'warn', ['env:production', 'issue:profile-fetch', 'component:PublicView'], 'soozh-beta-frontend');
                }
            } catch (error) {
                logger(`Error fetching profile: ${error.message}`, 'error', ['env:production', 'issue:profile-fetch', 'component:PublicView'], 'soozh-beta-frontend');
                console.error('Error fetching profile:', error);
            }
            try {
                const response = await api.get(`/public/media_v2/${pubProfileLink}`);
                if (response.data) {
                    setMedia(response.data || {});
                }
            } catch (err) {
                console.log(err.message);
            }
            setLoading(false);
        };

        setLoading(true);
        fetchProfile();

    }, [api, logger, navigate]);

    const handlePersonIconClick = () => {
        setShowLeftColumn(true);
        setHideMidAndRightColumns(true); // Hide mid and right columns when left column is shown
        setExpandProfile(true); // Expand profile when the left column is shown
    };

    const handleRowClick = (work) => {
        setExpandProfile(false); // Collapse profile expansion
        setSelectedPoster(work.poster);
        setSelectedWorkDetails(work);
        setSelectedExperienceUrl(null); // Reset experience URL when a work is selected
    };

    const handleExperienceRowClick = (experience) => {
        setExpandProfile(false); // Collapse profile expansion
        setShowSmallContact(false);
        setSelectedExperience(experience);
        setSelectedExperienceUrl(experience.proof_link); // Set experience URL for metadata fetching
    };

    const handleFavoritesClick = () => {
        setExpandProfile(false); // Collapse profile expansion
        setShowLeftColumn(false); // Hide left column
        setHideMidAndRightColumns(false); // Show mid and right columns
        setShowFavorites(true);
        setShowExperience(false);
        setSelectedCategory('MOVIES'); // Set default category to "MOVIES"
    };

    const handleExperienceClick = () => {
        setExpandProfile(false); // Collapse profile expansion
        setShowLeftColumn(false); // Hide left column
        setHideMidAndRightColumns(false); // Show mid and right columns
        setShowFavorites(false);
        setShowExperience(true);
        if (profile?.workedIn?.credited && profile.workedIn.credited.length > 0) {
            setSelectedExperience(profile.workedIn.credited[0]);
            setSelectedExperienceUrl(profile.workedIn.credited[0].proof_link); // Set experience URL for metadata fetching
        }
    };

    const handlecontactCardClick = () => {
        setExpandProfile(false); // Collapse profile expansion
        setHideLeftColumn(false);
        setShowFavorites(false);
        setShowExperience(false);
        setShowSmallContact(true);
    };

    const handleHomeClick = () => {
        setExpandProfile(false); // Collapse profile expansion
        setShowLeftColumn(false); // Hide left column
        setHideMidAndRightColumns(false); // Show mid and right columns
        setShowFavorites(false);
        setShowExperience(false);
    };

    const handleCategoryClick = (category) => {
        setExpandProfile(false); // Collapse profile expansion
        setShowSmallContact(false);
        setSelectedCategory(category);
    };

    const handleProfilePicClick = () => {
        setExpandProfile(!expandProfile);
    };

    const renderButtons = () => {
        return publicOrder.map((item, index) => {
            const isSelected = selectedButton === item;
            const glowEffect = isSelected
                ? {
                    boxShadow: '0px 0px 10px 2px rgba(255, 255, 255, 0.8)', // Glowing effect
                    borderColor: 'rgba(255, 255, 255, 0.8)',
                }
                : {};

            const handleClick = () => {
                setSelectedButton(item);
                switch (item) {
                    case 'favourites':
                        handleFavoritesClick();
                        break;
                    case 'ownWorks':
                        handleHomeClick();
                        break;
                    case 'workedIn':
                        handleExperienceClick();
                        break;
                    default:
                        break;
                }
            };

            return (
                <Button
                    key={index}
                    variant="outlined"
                    onClick={handleClick}
                    sx={{
                        color: textColor,
                        width: '20%',
                        borderRadius: "16px",
                        fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" },
                        backgroundColor: bgColor,
                        '&:hover': {
                            backgroundColor: theme.palette.background.default,
                        },
                        transition: 'all 0.5s ease',
                        ...glowEffect, // Apply the glowing effect conditionally
                    }}
                >
                    {item === 'favourites' ? 'Favorites' : item === 'ownWorks' ? 'Stories' : 'Experience'}
                </Button>
            );
        });
    };


    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <ProfileViewLogger pubProfileLink={pubProfileLink} >
            <div
                className={`relative w-full h-screen grid grid-cols-1 md:grid-cols-4 gap-4 p-4 ${hideLeftColumn ? 'hide-left' : ''}`}
                style={{ backgroundColor: bgColor }}
            >
                {/* User Content Box */}
                <Box
                    sx={{
                        gridColumn: { xs: "1 / span 4", md: expandProfile ? "1 / span 4" : "1 / span 4", lg: expandProfile ? "1 / span 4" : "1 / span 4" },
                        height: "calc(100vh - 32px)", // Full height minus the top margin
                        borderRadius: "20px",
                        padding: { xs: "8px", md: "16px" }, // Dynamic padding
                        overflow: "hidden",
                        position: 'relative', // Ensure the background image is positioned correctly
                        zIndex: 1, // Place it above the background image
                        display: 'flex',
                        flexDirection: 'column', // Stack children vertically
                        justifyContent: 'flex-start',
                        transition: 'all 0.5s ease', // Smooth transition for all elements
                    }}
                >
                    {/* Top Section */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '16px',
                            height: '7.5%',
                            transition: 'all 0.5s ease',
                        }}
                    >
                        {/* Top UC Box 1 */}
                        <Box
                            sx={{
                                width: '30%', // Adjust the width as needed
                                borderRadius: "16px",
                                marginRight: '16px',
                                backgroundColor: 'transparent',
                                transition: 'all 0.5s ease',
                            }}
                        >
                            {<a href="https://justwrite.soozh.app/" target="_blank" rel="noopener noreferrer">
                                <img
                                    src={JWIcon}
                                    alt="JW Icon"
                                    style={{
                                        width: isXsScreen ? '30px' : '48px',  // Adjust size as per your need
                                        fontWeight: 'bold',
                                        color: textColor,
                                        display: 'flex',
                                        opacity: '90%',
                                        transition: 'all 0.5s ease',
                                        marginTop: isXsScreen ? '5px' : 'inherit'
                                    }}
                                />
                            </a>
                            }

                        </Box>

                        {/* Top UC Box 3 */}
                        <Box
                            sx={{
                                justifyContent: "space-between", // Ensures even spacing between icons
                                width: '70%', // Adjust the width as needed
                                borderRadius: "16px",
                                backgroundColor: 'transparent',
                                display: 'flex',
                                alignItems: 'center',
                                transition: 'all 0.5s ease',
                            }}
                        >
                            {/* Buttons for larger screens */}
                            {renderButtons()}
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setExpandProfile(false); // Collapse profile expansion
                                    setModalIsOpen(true);
                                }}
                                sx={{
                                    display: { xs: 'none', md: 'inline-flex' },
                                    color: textColor,
                                    width: '20%',
                                    borderRadius: "16px",
                                    fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" },
                                    backgroundColor: bgColor,
                                    '&:hover': {
                                        backgroundColor: theme.palette.background.default,
                                    },
                                    transition: 'all 0.5s ease',
                                }}
                            >
                                Connect
                            </Button>
                            {/* Icons for xs screens */}
                            {isLoggedIn && <IconButton onClick={() => navigate("/")} sx={{ color: textColor }}>
                                <HomeIcon />
                            </IconButton>}
                            <IconButton onClick={handlePersonIconClick} sx={{ color: textColor }}>
                                <PersonIcon />
                            </IconButton>
                            <IconButton
                                onClick={handlecontactCardClick}
                                sx={{ display: { xs: 'inline-flex', md: 'none' }, color: textColor }}
                            >
                                <PersonIcon />
                            </IconButton>
                            <IconButton
                                onClick={handleHomeClick}
                                sx={{ display: { xs: 'inline-flex', md: 'none' }, color: textColor }}
                            >
                                <DescriptionIcon />
                            </IconButton>
                            <IconButton
                                onClick={handleExperienceClick}
                                sx={{ display: { xs: 'inline-flex', md: 'none' }, color: textColor }}
                            >
                                <WorkIcon />
                            </IconButton>
                            <IconButton
                                onClick={handleFavoritesClick}
                                sx={{ display: { xs: 'inline-flex', md: 'none' }, color: textColor }}
                            >
                                <FavoriteIcon />
                            </IconButton>
                            <IconButton
                                onClick={toggleTheme}
                                sx={{
                                    color: textColor,
                                    fontSize: { xs: "20px", sm: "24px", md: "28px", lg: "32px" },
                                    transition: 'all 0.5s ease',
                                }}
                            >
                                {theme.palette.mode === "dark" ? (
                                    <Brightness7Icon />
                                ) : (
                                    <Brightness4Icon />
                                )}
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    setExpandProfile(false); // Collapse profile expansion
                                    setModalIsOpen(true);
                                }}
                                sx={{ display: { xs: 'inline-flex', md: 'none' }, color: textColor }}
                            >
                                <ConnectWithoutContactIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    {/* Middle Section */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' }, // Align children vertically on xs screens and horizontally on md+ screens
                            justifyContent: 'space-between',
                            marginBottom: '16px',
                            height: '100%',
                            transition: 'all 0.5s ease',
                        }}
                    >
                        {/* Left Column */}
                        {showLeftColumn && (
                            <Box
                                sx={{
                                    display: { xs: showSmallContact ? 'flex' : 'none', md: 'flex' },
                                    width: { xs: '100%', md: expandProfile ? '100%' : '30%' },
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    marginBottom: '16px',
                                    marginRight: { xs: '0', md: expandProfile ? '0' : '16px' },
                                    height: { xs: '90vh', md: '92%' }, // You might want to adjust this for xs
                                    minHeight: { xs: '250px', md: 'auto' }, // Ensuring a minimum height
                                    overflowX: { xs: 'scroll', md: 'hidden' },
                                    transition: 'all 0.5s ease',
                                    gap: { xs: '16px', md: '0' }, // Gap between cards only in xs
                                }}
                                onClick={handleProfilePicClick}
                            >
                                {/* Mid Shape UC Box 1 */}
                                <Box
                                    sx={{
                                        flex: { xs: '1 0 auto', md: '1' },
                                        width: '100%', // 80vw could be too wide if there are two cards; consider adjusting
                                        minHeight: '250px', // Ensure cards don't become too short
                                        borderRadius: '16px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        position: 'relative',
                                        backgroundColor: 'transparent',
                                        justifyContent: 'center', // Center horizontally
                                        overflow: 'hidden', // Ensure the image doesn't overflow the box
                                        height: '47%', // Adjusted to fit within the column
                                        transition: 'all 0.5s ease',
                                    }}
                                >
                                    <img
                                        src={profile.userDetails.profile_path.value}
                                        alt="Profile Visualization"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            borderRadius: '16px',
                                            opacity: '100%',
                                            transition: 'all 0.5s ease',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0,
                                            width: '100%',
                                            background: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
                                            color: bgColor,
                                            padding: '16px',
                                            borderRadius: '0 0 16px 16px', // Rounded corners at the bottom
                                            transition: 'all 0.5s ease',
                                        }}
                                    >
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                fontWeight: 'bold',
                                                color: 'white',
                                                transition: 'all 0.5s ease',
                                            }}
                                        >
                                            {profile.userDetails?.fullName?.value && !profile.userDetails.fullName.privacy && profile.userDetails.fullName.value}
                                        </Typography>
                                        {profile.userDetails?.location?.value && !profile.userDetails.location.privacy && (
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    color: 'white',
                                                    transition: 'all 0.5s ease',
                                                }}
                                            >
                                                {profile.userDetails.location.value}
                                            </Typography>
                                        )}
                                        <Box sx={{ display: 'flex', gap: 2, marginTop: 1 }}>
                                            {profile.userDetails?.email?.value && !profile.userDetails.email.privacy && (
                                                <Tooltip title={profile.userDetails.email.value}>
                                                    <Link href={`mailto:${profile.userDetails.email.value}`} sx={{ color: 'white' }}>
                                                        <SiMaildotru fontSize="large" />
                                                    </Link>
                                                </Tooltip>
                                            )}
                                            {profile.userDetails?.phone?.value && !profile.userDetails.phone.privacy && (
                                                <Tooltip title={profile.userDetails.phone.value}>
                                                    <Link href={`tel:${profile.userDetails.phone.value}`} sx={{ color: 'white' }}>
                                                        <MdOutlinePhoneAndroid fontSize="large" />
                                                    </Link>
                                                </Tooltip>
                                            )}
                                            {profile.userDetails?.linkedin?.value && !profile.userDetails.linkedin.privacy && (
                                                <Tooltip title={profile.userDetails.linkedin.value}>
                                                    <Link href={profile.userDetails.linkedin.value} target="_blank" rel="noopener noreferrer" sx={{ color: 'white' }}>
                                                        <FaLinkedin fontSize="large" />
                                                    </Link>
                                                </Tooltip>
                                            )}
                                            {profile.userDetails?.imdbProfileLink?.value && !profile.userDetails.imdbProfileLink.privacy && (
                                                <Tooltip title={profile.userDetails.imdbProfileLink.value}>
                                                    <Link href={profile.userDetails.imdbProfileLink.value} target="_blank" rel="noopener noreferrer" sx={{ color: 'white' }}>
                                                        <FaImdb fontSize="large" />
                                                    </Link>
                                                </Tooltip>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                                {/* Profile Expanded Information */}
                                {expandProfile && (
                                    <Box
                                        sx={{
                                            backgroundColor: theme.palette.background.paper,
                                            borderRadius: '16px',
                                            padding: '16px',
                                            width: '100%',
                                            transition: 'all 0.5s ease',
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <Box
                                                    sx={{
                                                        backgroundColor: theme.palette.background.paper,
                                                        borderRadius: '16px',
                                                        padding: '16px',
                                                        transition: 'all 0.5s ease',
                                                    }}
                                                >
                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Qualifications</Typography>
                                                    <Box sx={{ marginBottom: '8px' }}>
                                                        {profile.qualifications?.awards?.map((award, index) => (
                                                            <Typography key={index} variant="body1">{award.name}</Typography>
                                                        ))}
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box
                                                    sx={{
                                                        backgroundColor: theme.palette.background.paper,
                                                        borderRadius: '16px',
                                                        padding: '16px',
                                                        transition: 'all 0.5s ease',
                                                    }}
                                                >
                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Education</Typography>
                                                    <Box sx={{ marginBottom: '8px' }}>
                                                        {profile.qualifications?.education?.map((education, index) => (
                                                            <Typography key={index} variant="body1">{education.institution} - {education.degree}</Typography>
                                                        ))}
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box
                                                    sx={{
                                                        backgroundColor: theme.palette.background.paper,
                                                        borderRadius: '16px',
                                                        padding: '16px',
                                                        transition: 'all 0.5s ease',
                                                    }}
                                                >
                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Work Experience</Typography>
                                                    <Box sx={{ marginBottom: '8px' }}>
                                                        {profile.qualifications?.workExperience?.map((experience, index) => (
                                                            <Typography key={index} variant="body1">{experience.company} - {experience.position}</Typography>
                                                        ))}
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <LanguagesCard profile={profile} />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box
                                                    sx={{
                                                        backgroundColor: theme.palette.background.paper,
                                                        borderRadius: '16px',
                                                        padding: '16px',
                                                        transition: 'all 0.5s ease',
                                                    }}
                                                >
                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Story of My Life</Typography>
                                                    <Box sx={{ marginBottom: '8px' }}>
                                                        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: profile.storyOfmyLife?.value }} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                            </Box>
                        )}

                        {/* Mid and Right Columns Combined */}
                        {!hideMidAndRightColumns && !showSmallContact && !expandProfile && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: hideLeftColumn ? '100%' : '100%',
                                    flexDirection: { xs: 'column', md: 'row' }, // Align children horizontally
                                    justifyContent: { xs: 'center', md: 'space-between' },
                                    marginBottom: '16px',
                                    height: '92%',
                                    transition: 'all 0.5s ease',
                                    marginTop: { xs: '-70px', md: 'inherit' }
                                }}
                            >
                                {/* Mid Column */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: hideLeftColumn ? '50%' : '100%', // Expand to 100% when showing favorites
                                        flexDirection: 'column', // Align children vertically
                                        justifyContent: { xs: 'center', md: 'space-between' },
                                        marginRight: '16px',
                                        height: '100%', // Ensure the column takes the full height
                                        transition: 'all 0.5s ease',
                                    }}
                                >
                                    {/* Mid Shape UC Box 1 */}
                                    <Box
                                        sx={{
                                            width: '100%', // Expand to 100% when showing favorites
                                            borderRadius: "16px",
                                            backgroundColor: 'transparent',
                                            justifyContent: 'center', // Center horizontally
                                            alignItems: 'center', // Center vertically
                                            overflow: 'hidden', // Ensure the image doesn't overflow the box
                                            position: 'relative', // Needed for the absolute positioning of the text
                                            height: '47%', // Adjusted to fit within the column
                                            transition: 'all 0.5s ease',
                                            display: { xs: 'none', md: 'flex' }, // Show only on md and larger
                                        }}
                                    >
                                        <img
                                            src={pvImage}
                                            alt="Profile Visualization"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                borderRadius: '16px',
                                                opacity: '15%',
                                                transition: 'all 0.5s ease',
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                width: '100%',
                                                color: bgColor,
                                                padding: '16px',
                                                borderRadius: '0 0 16px 16px', // Rounded corners at the bottom
                                                transition: 'all 0.5s ease',
                                            }}
                                        >
                                            <Typography
                                                variant="h4"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: textColor,
                                                    fontFamily: "Black Ops One",
                                                    fontStyle: 'normal',
                                                    transition: 'all 0.5s ease',
                                                }}
                                            >
                                                {showFavorites ? (
                                                    "MY FAVS"
                                                ) : showExperience ? (
                                                    "MY EXP"
                                                ) : (
                                                    <>
                                                        {profile.userDetails.fullName?.value}
                                                    </>
                                                )}

                                            </Typography>

                                        </Box>
                                    </Box>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: textColor,
                                            fontFamily: "Black Ops One",
                                            fontStyle: 'normal',
                                            transition: 'all 0.5s ease',
                                            display: { xs: 'block', md: 'none' }, // Show only on xs
                                            padding: '0', // Reset any default padding
                                            margin: '0', // Remove any default margin to reduce space
                                            paddingBottom: '8px', // Optionally adjust bottom padding if needed
                                            paddingTop: '60px'
                                        }}
                                    >
                                        {showFavorites ? "MY FAVS" : showExperience ? "MY EXP" : "MY WORK"}
                                    </Typography>

                                    {/* Bottom UC Box */}
                                    {showExperience ? (
                                        <Box
                                            sx={{
                                                width: { xs: '90vw', md: '100%' }, // Expand to 100% when showing favorites
                                                borderRadius: "16px",
                                                height: '60%', // Adjust the height as needed
                                                backgroundColor: theme.palette.background.paper,
                                                transition: 'all 0.5s ease',
                                            }}
                                        >
                                            {/* Experience Table */}
                                            {profile?.workedIn.credited && (
                                                <TableContainer component={Paper} sx={{ maxHeight: '300px', height: { xs: '130px', md: 'auto' } }}>
                                                    <Table stickyHeader aria-label="experience table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Title</TableCell>
                                                                <TableCell>Format</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {profile.workedIn.credited.map((work, index) => (
                                                                <TableRow
                                                                    key={index}
                                                                    sx={{ cursor: 'pointer' }}
                                                                    onClick={() => handleExperienceRowClick(work)}
                                                                >
                                                                    <TableCell component="th" scope="row">
                                                                        {work.name}
                                                                    </TableCell>
                                                                    <TableCell>{work.format}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            )}
                                        </Box>
                                    ) : showFavorites ? (
                                        <Box
                                            sx={{
                                                width: { xs: '80vw', md: '100%' },
                                                display: 'flex',
                                                flexWrap: { xs: 'nowrap', md: 'wrap' },
                                                gap: 2,
                                                justifyContent: 'space-between',
                                                paddingTop: 2,
                                                margin: 'auto',
                                                marginTop: 'inherit',
                                                transition: 'all 0.5s ease',
                                            }}
                                        >
                                            {Object.entries(categoryIcons).map(([category, IconComponent]) => (
                                                <Card
                                                    key={category}
                                                    sx={{
                                                        flex: { xs: '1 1 16%', md: '1 1 calc(33% - 16px)' }, // Adjust basis for xs screen
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        height: { xs: '30px', md: 150 },
                                                        cursor: 'pointer',
                                                        boxShadow: 3,
                                                        borderRadius: '16px',
                                                        backgroundColor: 'background.paper',
                                                        transition: 'all 0.5s ease',
                                                    }}
                                                    onClick={() => handleCategoryClick(category)}
                                                >
                                                    <CardContent
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            height: { xs: '30px', md: '100%' },
                                                            transition: 'all 0.5s ease',
                                                        }}
                                                    >
                                                        {IconComponent}
                                                        <Typography variant="body2" sx={{ display: { xs: 'none', md: 'block' } }}>{category}</Typography>
                                                    </CardContent>
                                                </Card>
                                            ))}
                                        </Box>
                                    ) : (
                                        <Box
                                            sx={{
                                                width: { xs: '90vw', md: '100%' }, // Expand to 100% when showing favorites
                                                borderRadius: "16px",
                                                height: '50%', // Adjust the height as needed
                                                backgroundColor: theme.palette.background.paper,
                                                transition: 'all 0.5s ease',
                                            }}
                                        >
                                            {/* OwnWorks Table */}
                                            {profile?.ownWorks && (
                                                <TableContainer component={Paper} sx={{ maxHeight: '300px', height: { xs: "100px", md: "auto" } }}>
                                                    <Table stickyHeader aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Name</TableCell>
                                                                <TableCell>Genre</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {profile.ownWorks.portfolio.map((work) => (
                                                                <TableRow
                                                                    key={work.name}
                                                                    sx={{ cursor: 'pointer' }}
                                                                    onClick={() => handleRowClick(work)}
                                                                >
                                                                    <TableCell component="th" scope="row">
                                                                        {work.name}
                                                                    </TableCell>
                                                                    <TableCell>{work.genre}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            )}
                                        </Box>
                                    )}
                                </Box>

                                {/* Right Column */}
                                <Box
                                    sx={{
                                        marginTop: { xs: showFavorites ? '10px' : (showExperience ? '90px' : '0px'), md: 'inherit' },
                                        width: '100%',
                                        height: { xs: showFavorites ? '-100%' : (showExperience ? '100%' : '140%'), md: '100%' },
                                        borderRadius: "16px",
                                        backgroundColor: theme.palette.background.paper,
                                        padding: { xs: '2px', md: '16px' },
                                        overflow: 'auto',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        transition: 'all 0.3s ease',
                                    }}
                                >
                                    {showExperience ? (
                                        selectedExperience ? (
                                            <Box sx={{ width: '100%' }}>
                                                <Typography variant="h5" sx={{ fontWeight: '900' }}>{selectedExperience.name}</Typography>
                                                <Typography variant="body1">{selectedExperience.logLine}</Typography>
                                                {selectedExperienceUrl && (
                                                    <MetaPreview url={selectedExperienceUrl} />
                                                )}
                                            </Box>
                                        ) : (
                                            <Typography variant="body1" sx={{ marginTop: 2 }}>
                                                Select an experience to view details.
                                            </Typography>
                                        )
                                    ) : showFavorites ? (
                                        <>
                                            {selectedCategory === 'MOVIES' && <MovieGrid movieData={media.movie} />}
                                            {selectedCategory === 'TV SHOWS' && <SeriesCarouselCard seriesData={media.series} />}
                                            {selectedCategory === 'DOCUMENTARY' && <DocCarouselCard documentaryData={media.documentary} />}
                                            {selectedCategory === 'GAMES' && <GameGrid gameData={media.game} />}
                                            {selectedCategory === 'BOOKS' && <BookGrid bookData={media.book} />}
                                            {selectedCategory === 'OTHERS' && <PeopleAndOthersCard peopleData={media} type="others" />}
                                        </>
                                    ) : (
                                        <>
                                            {selectedPoster && (
                                                <img
                                                    src={selectedPoster}
                                                    alt="Selected Poster"
                                                    style={{
                                                        width: '200px', //{ xs: "90vw", md: "25vvw" },
                                                        height: '300px', //{ xs: "auto", md: '25vh' },
                                                        objectFit: 'cover',
                                                        filter: {
                                                            xs: 'none',         // No filter on xs screens
                                                            sm: 'grayscale(100%)'  // Grayscale on sm screens and larger
                                                        },
                                                        borderRadius: '16px',
                                                        marginBottom: '16px',
                                                        transition: 'all 0.5s ease',
                                                    }}
                                                />
                                            )}
                                            {selectedWorkDetails && (
                                                <Box sx={{ width: '100%' }}>
                                                    <Typography variant="h5" sx={{ fontWeight: '900' }}>{selectedWorkDetails.name}</Typography>
                                                    <Typography variant="body1">{selectedWorkDetails.genre}</Typography>
                                                    <Typography variant="h6" sx={{ color: 'transparent' }}>"-"</Typography>
                                                    <Typography variant="h6" sx={{ fontWeight: '900' }}>Log Line</Typography>
                                                    <Box sx={{
                                                        marginTop: { xs: showFavorites ? '10px' : (showExperience ? '90px' : '0px'), md: 'inherit' },
                                                        width: '100%',
                                                        height: '100px',
                                                        borderRadius: "16px",
                                                        backgroundColor: theme.palette.background.paper,
                                                        padding: { xs: '2px', md: '16px' },
                                                        overflow: 'auto',
                                                    }}>
                                                        <Typography variant="body1" sx={{ fontStyle: 'italic' }}>"{selectedWorkDetails.logLine}"</Typography>
                                                    </Box>
                                                    <Typography variant="h6" sx={{ color: 'transparent' }}>"-"</Typography>
                                                    <Typography variant="h6">Status: {selectedWorkDetails.status}</Typography>
                                                </Box>
                                            )}
                                        </>
                                    )}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            </div>
            <ReachOutModal
                open={modalIsOpen}
                handleClose={() => setModalIsOpen(false)}
                writerName={profile?.userDetails?.fullName?.value}
                ownWorks={profile?.ownWorks}
                userId={profile?.userId}
            />
        </ProfileViewLogger>
    );
};

export default PublicView;
