// src/components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useLogin } from '../contexts/LoginContext';

const PrivateRoute = ({ element }) => {
    const { isLoggedIn } = useLogin();
    return isLoggedIn ? element : <Navigate to="/" />;
};

export default PrivateRoute;