import React, {useState, useEffect} from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
  Link, IconButton, Avatar, Tooltip, Button, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, Snackbar, TextField
} from '@mui/material';import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {useAxios} from '../../contexts/AxiosContext'

const baseURL = process.env.REACT_APP_HOSTED_DOMAIN || 'https://justwrite.soozh.app/';

const UsersDataGrid = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState({ id: '', name: '', isAdmin: false });
  const [deleteConfirm, setDeleteConfirm] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const axios = useAxios()
  const [users, setUsers] = useState([])
  const [dataChangeTrigger, setDataChangeTrigger] = useState(0);
  
  const handleOpenDialog = (user) => {
    setCurrentUser(user);
    setOpenDialog(true);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/admin/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Failed to fetch users', error);
        // Handle error appropriately in your app
      }
    };
    fetchUsers();
  }, [axios, dataChangeTrigger]); 

  const handleConfirmDelete = async () => {
    try {
        const response = await axios.delete(`/admin/user/${currentUser.id}`);
        if (response.status === 200) {
            setSnackbar({ open: true, message: 'User deleted successfully' });
            setDataChangeTrigger(prev => prev + 1);  // Trigger a data refresh
        }
    } catch (error) {
        console.error('Error deleting user:', error);
        setSnackbar({ open: true, message: 'Failed to delete user' });
    } finally {
        setOpenDialog(false);
    }
};

const handleToggleAdmin = async () => {
  try {
      const response = await axios.post('/admin/update-privileges', {
          userId: currentUser.id,
          isAdmin: !currentUser.isAdmin,
      });
      if (response.status === 200) {
          setSnackbar({ open: true, message: `User ${currentUser.isAdmin ? 'demoted' : 'promoted'} successfully` });
          setDataChangeTrigger(prev => prev + 1);  // Trigger a data refresh
        }
  } catch (error) {
      console.error('Error updating user privileges:', error);
      setSnackbar({ open: true, message: 'Failed to update privileges' });
  } finally {
      setOpenDialog(false);
  }
};

  const columns = [
    {
      field: 'profile_path',
      headerName: 'Profile',
      width: 100,
      renderCell: (params) => (
        <Avatar src={params.value || "path_to_default_image.png"} alt="Profile" />
      ),
    },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    {
      field: 'verified',
      headerName: 'Verified',
      width: 120,
      renderCell: (params) => (
        params.value ? <CheckIcon color="success" /> : <ClearIcon color="error" />
      ),
    },
    {
      field: 'isAdmin',
      headerName: 'Admin',
      width: 120,
      renderCell: (params) => (
        params.value ? <CheckIcon color="success" /> : <ClearIcon color="error" />
      ),
    },
    {
      field: 'pubProfileLink',
      headerName: 'Profile Link',
      width: 200,
      renderCell: (params) => (
        <Link href={`${baseURL}p/${params.value}`} target="_blank">View Profile</Link>
      ),
    },
    { field: 'createdAt', headerName: 'Joined On', width: 150 },
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <Tooltip title="Edit User">
          <IconButton onClick={() => handleOpenDialog({
            id: params.id,
            name: params.row.name,
            isAdmin: params.row.isAdmin
          })}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      ),
    }
  ];

  // Check for duplicates and log them
  const uniqueUsers = users?.reduce((acc, user) => {
    if (!acc.some(u => u._id === user._id)) {
      acc.push(user);
    } else {
      console.warn('Duplicate key found:', user._id);
    }
    return acc;
  }, []);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={uniqueUsers}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 20, 50]}
        getRowId={(row) => row._id}  // Ensure unique keys
        components={{ Toolbar: GridToolbar }}
        disableColumnMenu={false}  // Enable column menu
        disableSelectionOnClick  // Disable row selection
      />
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Actions</DialogTitle>
        <DialogContent>
          <DialogContentText>To delete this user, type the username to confirm.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Username Confirmation"
            type="text"
            fullWidth
            variant="outlined"
            value={deleteConfirm}
            onChange={(e) => setDeleteConfirm(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleToggleAdmin()} color="warning">
            {currentUser.isAdmin ? 'Demote' : 'Promote'}
          </Button>
          <Button onClick={handleConfirmDelete} color="error" disabled={deleteConfirm !== currentUser.name}>
            Delete User
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </div>
  );
};

export default UsersDataGrid;
