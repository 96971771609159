import { z } from 'zod';

// Validator for MongoDB ObjectId
const objectIdValidator = z.string().regex(/^[0-9a-fA-F]{24}$/, "Invalid MongoDB ObjectId format");

const referenceSchema = z.object({
    reference: objectIdValidator,
    personalNote: z.string().min(10, "Personal note must be at least 10 characters long").optional()
});

const favouritesSchema = z.object({
    interests: z.object({
        favourites: z.object({
            series: z.object({
                value: z.array(referenceSchema).optional(),
                privacy: z.boolean().optional()
            }).optional(),
            movie: z.object({
                value: z.array(referenceSchema).optional(),
                privacy: z.boolean().optional()
            }).optional(),
            genre: z.object({
                value: z.array(z.string()).optional(),
                privacy: z.boolean().optional()
            }).optional(),
            documentary: z.object({
                value: z.array(referenceSchema).optional(),
                privacy: z.boolean().optional()
            }).optional(),
            writer: z.object({
                value: z.array(referenceSchema).optional(),
                privacy: z.boolean().optional()
            }).optional(),
            director: z.object({
                value: z.array(referenceSchema).optional(),
                privacy: z.boolean().optional()
            }).optional(),
            book: z.object({
                value: z.array(referenceSchema).optional(),
                privacy: z.boolean().optional()
            }).optional(),
            game: z.object({
                value: z.array(referenceSchema).optional(),
                privacy: z.boolean().optional()
            }).optional()
        }).optional(),
    }).optional()
});

export default favouritesSchema;
