import React, { createContext, useContext } from 'react';
import axios from 'axios';

// Create an axios instance
const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001/api'
});

// Add a request interceptor
api.interceptors.request.use((config) => {
    // Retrieve the token from local storage
    const token = localStorage.getItem('token');
    
    // If a token is present, add it to the headers of all requests
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, (error) => {
    // Do something with request error
    return Promise.reject(error);
});

const AxiosContext = createContext(api);

export const AxiosProvider = ({ children }) => {
    return (
        <AxiosContext.Provider value={api}>
            {children}
        </AxiosContext.Provider>
    );
};

export const useAxios = () => {
    return useContext(AxiosContext);
};