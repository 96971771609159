import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Fix import by removing destructuring
import logger from '../logger'; // Update the path to your logger file
import { useAxios } from '../contexts/AxiosContext';
import errorCodes from '../utils/errorCodes.json';


const LoginContext = createContext();

export const useLogin = () => useContext(LoginContext);

export const LoginProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decoded = jwtDecode(token);
            if (decoded.exp * 1000 > Date.now()) {
                return { userId: decoded.user.id, username: decoded.user.name };
            }
        }
        return null;
    });
    const [error, setError] = useState('');
    const [showResend, setShowResend] = useState(false);
    const [pubProfileLink, setpubProfileLink] =useState('')
    const axios = useAxios()
    const navigate = useNavigate();
    const [isNewUser, setIsNewUser] = useState(false)

    const logout = useCallback(() => {
        localStorage.removeItem('token');
        setUser(null);
        logger('User logged out', 'info', ['env:production', 'event:logout', `userId:${user?.userId}`], 'soozh-beta-frontend');
        navigate('/');
    }, [navigate, user]);

    useEffect(() => {
        if (user) {
            const token = localStorage.getItem('token');
            const decoded = jwtDecode(token);
            if (decoded.exp * 1000 < Date.now()) {
                logout();
            }
        }
    }, [user, logout]);

    const login = async (email, password, redirectPath = '') => {
        try {
            const response = await axios.post(`/auth/login`,
                { email, password },
                { headers: { 'Content-Type': 'application/json' } }
            );
        
            const data = response.data;
        
            if (response.status !== 200) {
                const errorMessage = errorCodes[data.code]?.message || 'An error occurred while logging in. Please try again.';
                setError(errorMessage);
                setShowResend(data.code === 'EMAIL_NOT_VERIFIED');
                logger('Failed login attempt', 'error', ['env:production', 'event:login', 'status:failed'], 'soozh-beta-frontend');
                return;
            }

            if (data.redirectURL) {
                window.location.href = data.redirectURL; // Redirect to consent page
                return;
            }
        
            if (data.token) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('userData', JSON.stringify({
                    userId: data.user._id,
                    username: data.user.name,
                    isNewUser: data.user.isNewUser,
                    pubProfileLink: data.user.pubProfileLink // Save pubProfileLink to local storage
                }));
                setUser({ userId: data.user._id, username: data.user.name, isNewUser: data.user.isNewUser });
                setIsNewUser(data.user.isNewUser); // Update isNewUser state
                logger(`User logged in: ${JSON.stringify({ userId: data.user._id, username: data.user.name })}`, 'info', ['env:production', 'event:login', 'status:success'], 'soozh-beta-frontend');
                setError(''); // Clear any previous error messages
                setShowResend(false); // Hide resend button if login is successful
                if (isNewUser === true) {
                    navigate(`/edit-profile/${data.user._id}`);
                } else {
                    navigate(redirectPath);
                }
            }
        } catch (error) {
            if (error.response) {
                const data = error.response.data;
                const errorMessage = errorCodes[data.code]?.message || 'An error occurred while logging in. Please try again.';
                setError(errorMessage);
                setShowResend(data.code === 'EMAIL_NOT_VERIFIED');
            } else {
                setError('An error occurred while logging in. Please try again.');
            }
            logger(`Error logging in: ${error.message}`, 'error', ['env:production', 'event:login', 'status:failed'], 'soozh-beta-frontend');
            console.error('Error logging in:', error);
        }
    };

    const googleLogin = useCallback(async (token, redirectPath = '') => {
        try {
            localStorage.setItem('token', token);
            const decoded = jwtDecode(token);
            localStorage.setItem('userData', JSON.stringify({
                userId: decoded.user._id,
                username: decoded.user.name,
                isNewUser: decoded.user.isNewUser,
                pubProfileLink: decoded.user.pubProfileLink // Save pubProfileLink to local storage
            }));
            setUser({ userId: decoded.user.id, username: decoded.user.name, isNewUser: decoded.user.isNewUser });
            setIsNewUser(decoded.user.isNewUser); // Update isNewUser state
            logger(`User logged in with Google: ${JSON.stringify({ userId: decoded.user.id, username: decoded.user.name })}`, 'info', ['env:production', 'event:login', 'status:success'], 'soozh-beta-frontend');
            setError(''); // Clear any previous error messages
            setShowResend(false); // Hide resend button if login is successful
            if (isNewUser === true) {
                navigate(`/edit-profile/${decoded.user.id}`);
            } else {
                navigate('/');
            }
        } catch (error) {
            setError('An error occurred while logging in with Google. Please try again.');
            logger(`Error logging in with Google: ${error.message}`, 'error', ['env:production', 'event:login', 'status:failed'], 'soozh-beta-frontend');
            console.error('Error logging in with Google:', error);
        }
    }, [navigate]);

    const toggleIsNewUser = async (userId, isNewUser) => {
        try {
            const response = await axios.post(`/auth/toggleIsNewUser/${userId}`, { isNewUser });
            if (response.status === 200) {
                setUser((prevUser) => ({
                    ...prevUser,
                    isNewUser,
                }));
                console.log('User status updated');
            } else {
                console.error('Failed to update user status:', response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchUserProfile = useCallback(async (userId) => {
        try {
            const response = await axios.get(`/profile/${userId}`);
            if (response.status === 200) {
                return response.data;
            } else {
                console.error('Failed to fetch user data:', response.data.message);
                return null;
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            return null;
        }
    }, [axios]);

    const isLoggedIn = !!user;
    const userId = user ? user.userId : null;
    const username = user ? user.username : null;

    return (
        <LoginContext.Provider value={{ login, googleLogin, logout, isLoggedIn, userId, username, error, showResend, isNewUser, toggleIsNewUser, pubProfileLink, fetchUserProfile }}>
            {children}
        </LoginContext.Provider>
    );
};
