import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography, Link } from '@mui/material';
import { useAxios } from '../contexts/AxiosContext';
import { useLogin } from '../contexts/LoginContext';
import { useTheme } from '../contexts/ThemeContext';
import { z } from 'zod';

const schema = z.object({
    name: z.string().min(1, { message: "Name is required" }),
    email: z.string().email({ message: "Invalid email address" }),
    password: z.string().min(6, { message: "Password must be at least 6 characters long" }),
    termsAccepted: z.boolean().refine((value) => value === true, {
        message: "You must accept the terms and conditions"
    })
});

const SignUp = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        termsAccepted: false
    });
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const { login } = useLogin();
    const navigate = useNavigate();
    const { theme } = useTheme();
    const axios = useAxios();

    const onChange = e => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const onSubmit = async e => {
        e.preventDefault();
        setError('');
        setMessage('');  // Reset success message on new submission

        const result = schema.safeParse(formData);
        if (!result.success) {
            const newErrors = result.error.flatten().fieldErrors;
            setError(Object.values(newErrors).join(', '));
            return;
        }

        try {
            const res = await axios.post(`/auth/register`, formData);
            if (res.status === 200) {
                setMessage('Registration successful! Please check your email to verify your account.');
                setFormData({ name: '', email: '', password: '', termsAccepted: false }); // Clear the form
                navigate('/');
            }
        } catch (err) {
            setError(err.response?.data?.msg || 'Error occurred during registration');
        }
    };

    const ConsentLabel = () => (
        <Typography variant="body2">
            I accept the{' '}
            <Link href="/terms-and-conditions" target="_blank" sx={{ color: 'blue', fontWeight: 'bold' }}>
                terms and conditions
            </Link>{' '}
            and{' '}
            <Link href="/privacy-policy" target="_blank" sx={{ color: 'blue', fontWeight: 'bold' }}>
                privacy policy
            </Link>.
        </Typography>
    );

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
                position: 'relative',
            }}
        >
            {theme.palette.mode === 'dark' ? (
                <div
                    className="background"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: 'url(/Cinema_BG_Dark.jpg)',
                        zIndex: -1,
                    }}
                />
            ) : (
                <div
                    className="background"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: 'url(/Cinema_BG.jpg)',
                        zIndex: -1,
                    }}
                />
            )}

            <Box
                component="form"
                onSubmit={onSubmit}
                sx={{
                    zIndex: 1,
                    textAlign: 'center',
                    p: 4,
                    borderRadius: 2,
                    maxWidth: '500px',
                    width: '100%',
                    padding: '20px',
                    transform: 'translateY(-50px)',
                }}
            >
                <Typography variant="h5">Sign Up</Typography>
                {error && <Typography color="error">{error}</Typography>}
                {message && <Typography color="primary">{message}</Typography>}
                <TextField
                    label="Name"
                    name="name"
                    type="text"
                    value={formData.name}
                    onChange={onChange}
                    fullWidth
                    required
                    margin="normal"
                />
                <TextField
                    label="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={onChange}
                    fullWidth
                    required
                    margin="normal"
                />
                <TextField
                    label="Password"
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={onChange}
                    fullWidth
                    required
                    margin="normal"
                />
                <Box display="flex" alignItems="center" sx={{ marginTop: 2 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="termsAccepted"
                                checked={formData.termsAccepted}
                                onChange={onChange}
                                color="primary"
                            />
                        }
                        label="" // Leave the label empty
                    />
                    <Typography variant="body2">
                        I accept the{' '}
                    </Typography>
                    <a
                        href="/terms-and-conditions"
                        style={{ color: 'blue', fontWeight: 'bold', textDecoration: 'none' }}
                    >
                        terms and conditions
                    </a>{' '}
                    <Typography variant="body2">
                        and{' '}
                    </Typography>
                    <a href="/privacy-policy" target="_blank" style={{ color: 'blue !important', fontWeight: 'bold !important', textDecoration: 'none' }}>
                        privacy policy
                    </a>.
                </Box>

                <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} fullWidth>
                    Register
                </Button>
            </Box>
        </Box>
    );
};

export default SignUp;
