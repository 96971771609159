import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const baseURL = process.env.REACT_HOSTED_DOMAIN || 'https://justwrite.soozh.app/';
const PublicShare = ({ pubProfileLink }) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const copyToClipboard = (link) => {
        navigator.clipboard.writeText(link)
            .then(() => setOpenSnackbar(true))
            .catch(err => console.error('Failed to copy:', err));
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const fullURL = `${baseURL}p/${pubProfileLink}`;

    return (
        <div>
            <IconButton onClick={() => copyToClipboard(fullURL)} style={{ color: 'inherit' }}>
                <ShareIcon />
            </IconButton>
            <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    {fullURL} copied to clipboard!
                </Alert>
            </Snackbar>
        </div>
    );
};

export default PublicShare;
