import ShareIcon from '@mui/icons-material/Share';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import LanguagesImage from '../assets/JoyrideTour/ProfileForm/Languages.png';
import BioImage from '../assets/JoyrideTour/ProfileForm/Bio.png';
import useMediaQuery from '@mui/material/useMediaQuery';  // Import useMediaQuery from @mui/material
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import StyledPNTooltip from './FormAddons/StyledPNTooltip'
import PersonalNote from './modals/PersonalNote';
import UploadModel from './modals/uploadFrame';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClearIcon from '@mui/icons-material/Clear';
import MetaPreview from './FormAddons/MetaPreview';
import LocationField from './FormAddons/LocationField';
import { FormProvider } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller, useFieldArray, useWatch } from 'react-hook-form';
import { optional, z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
    TextField, Button, Container, Typography, CircularProgress, Snackbar, Checkbox, Slider,
    Tabs, Tab, Box, IconButton, Grid, Divider, InputAdornment, FormHelperText , Stepper, Step, StepButton, StepContent, MenuItem, Select, FormControlLabel 
} from '@mui/material';
import Joyride from 'react-joyride';
import { styled } from '@mui/system';
import { useTheme } from '../contexts/ThemeContext';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useAxios } from '../contexts/AxiosContext';
import 'react-quill/dist/quill.snow.css';
import logger from "../logger"
import './ProfileForm.css'
import Mediaquery from "./FormAddons/Mediaquery"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import StoryOfMyLifeComponent from './FormAddons/FormBreaks/StoryofMyLife';
import { useLogin } from '../contexts/LoginContext';
import ImageWithNoContextMenu from './FormAddons/ImageWithNoContextMenu';
import YouTubeModal from './modals/YouTubeModal';
import PublicShare from '../utils/PublicShare';
import fetchProfileHelper from '../helperFunctions/fetchProfileHelper'; // Adjust the path as necessary

const SliderComponent = React.memo(({ name, control, label }) => {
    // console.log(`Rendering SliderComponent for ${name}`);
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
            <Typography>{label}:</Typography>
            <Controller
                name={name}
                control={control}
                render={({ field }) => {
                    // console.log(`Slider value for ${name}:`, field.value);
                    return (
                        <Slider
                            {...field}
                            value={typeof field.value === 'number' ? field.value : 0}
                            onChange={(e, value) => field.onChange(value)}
                            min={0}
                            max={10}
                            step={1}
                        />
                    );
                }}
            />
        </div>
    );
});


const CheckboxComponent = React.memo(({ name, control, label }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography>{label}:</Typography>
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    color="primary"
                />
            )}
        />
    </div>
));
const UserProfile_fieldLabels = {
    fullName: "Full Name",
    profile_path: "Profile Picture",
    email: "Email",
    phone: "Phone",
    location: "Location",
    linkedin: "LinkedIn",
    imdbProfileLink: "IMDB",
    website: "Website",
    languages: "Languages",
    creativeTraits: "Creative Traits"
};

const FavouritesLabels = {
    author: 'Authors',
    movie: 'Movies',
    genre: 'Genres',
    game: 'Games',
    writer: 'Writers',
    director: 'Directors',
    book: 'Books',
    documentary: 'Documentaries',
    series: 'Series'
};

const qualificationFieldLabels = {
    awards: "Awards",
    education: "Education",
    workExperience: "Work Experience",
    skills: "Skills",
};

const tabs = [
    { label: "User Details", key: "userDetails", title: "Profile" },
    { label: "Favourites", key: "interests" },
    { label: "Qualifications", key: "qualifications" },
    { label: "My Stories", key: "ownWorks" },
    { label: "Experience", key: "workedIn" },
    { label: "Story Of My Life", key: "storyOfmyLife" }
];

const LSRWSchema = z.object({
    l: z.boolean(),
    s: z.number().min(0).max(10),
    r: z.number().min(0).max(10),
    w: z.number().min(0).max(10),
});

const LanguageSchema = z.object({
    value: z.string().min(1, "Select a Language"),
    privacy: z.boolean(),
    lsrw: LSRWSchema,
});

const referenceSchema = z.object({
    reference: z.string(), // Assuming reference is stored as a string, typically ObjectId in MongoDB
    personalNote: z.string().optional().default('') // Default empty string for personalNote
});

const optionalUrl = z.string().refine((data) => {
    return data === '' || /^https?:\/\/[^\s$.?#].[^\s]*$/.test(data) || /^www\.[^\s$.?#].[^\s]*$/.test(data);
}, {
    message: "Must be a valid URL with www or an empty string"
});

// Define the Zod schema
const profileSchema = z.object({
    userDetails: z.object({
        fullName: z.object({
            value: z.string().min(4, "Full Name is required"),
            privacy: z.boolean()
        }).optional(),
        profile_path: z.object({
            value: z.string().nullable().optional(),
            privacy: z.boolean().optional()
        }).optional(),
        email: z.object({
            value: z.string().email("Invalid email address"),
            privacy: z.boolean().optional()
        }).optional(),
        phone: z.object({
            value: z.string().nullable().optional(),
            privacy: z.boolean().optional()
        }).optional(),
        location: z.object({
            value: z.string().nullable().optional(),
            privacy: z.boolean().optional()
        }).optional(),
        linkedin: z.object({
            value: optionalUrl.optional(),
            privacy: z.boolean().optional()
        }).optional(),
        imdbProfileLink: z.object({
            value: optionalUrl.optional(),
            privacy: z.boolean().optional()
        }).optional(),
        website: z.object({
            value: optionalUrl.optional(),
            privacy: z.boolean().optional()
        }).optional(),
        languages: z.array(LanguageSchema).optional(),
        creativeTraits: z.object({
            value: z.array(z.string()).optional(),
            privacy: z.boolean().optional()
        }).optional(),
    }).optional(),
    interests: z.object({
        favourites: z.object({
            series: z.object({
                value: z.array(referenceSchema).optional(),
                privacy: z.boolean().optional()
            }).optional(),
            movie: z.object({
                value: z.array(referenceSchema).optional(),
                privacy: z.boolean().optional()
            }).optional(),
            genre: z.object({
                value: z.array(z.string()).optional(),
                privacy: z.boolean().optional()
            }).optional(),
            documentary: z.object({
                value: z.array(referenceSchema).optional(),
                privacy: z.boolean().optional()
            }).optional(),
            writer: z.object({
                value: z.array(referenceSchema).optional(),
                privacy: z.boolean().optional()
            }).optional(),
            director: z.object({
                value: z.array(referenceSchema).optional(),
                privacy: z.boolean().optional()
            }).optional(),
            book: z.object({
                value: z.array(referenceSchema).optional(),
                privacy: z.boolean().optional()
            }).optional(),
            game: z.object({
                value: z.array(referenceSchema).optional(),
                privacy: z.boolean().optional()
            }).optional()
        }).optional()
    }).optional(),
    qualifications: z.object({
        awards: z.array(z.object({
            name: z.string().min(3, 'Must be at least 3 characters long'),
            year: z.string().optional(),
            awardedBy: z.string().optional(),
            privacy: z.boolean().optional()
        })).optional(),
        education: z.array(z.object({
            institution: z.string().min(2,"Must not be empty"),
            degree: z.string().min(2,"Must not be empty"),
            privacy: z.boolean().optional()
        })).optional(),
        workExperience: z.array(z.object({
            company: z.string().min(3, 'Must be at least 3 characters long'),
            position: z.string().min(3, 'Must be at least 3 characters long'),
            privacy: z.boolean().optional()
        })).optional(),
    }).optional(),
    ownWorks: z.object({
        portfolio: z.array(z.object({
            poster: z.string().optional(),
            name: z.string().optional(),
            logLine: z.string().optional(),
            genre: z.string().optional(),
            format: z.string().optional(),
            privacy: z.boolean().optional(),
            status: z.string().optional(),
            credit: z.array(z.string()).optional(),
            proof_link: z.string().optional()
        })).optional(),
        privacy: z.boolean().optional()
    }).optional(),
    workedIn: z.object({
        credited: z.array(z.object({
            poster: z.string().optional(),
            name: z.string().min(3, 'Must be at least 3 characters long'),
            logLine: z.string().min(10, 'Must be at least 10 characters long'),
            genre: z.string().optional(),
            format: z.string().min(3, 'Must be at least 3 characters long'),
            privacy: z.boolean().optional(),
            proof_link: optionalUrl.optional(),
        })).optional(),
        privacy: z.boolean().optional()
    }).optional(),
    storyOfmyLife: z.object({
        value: z.string().nullable().optional(),
        privacy: z.boolean().optional()
    }).optional()
});

const genreOptions = [
    "Adventure", "Biography", "Chick Lit", "Children's Literature", "Classic", "Comedy",
    "Coming of Age", "Contemporary", "Crime", "Drama", "Dystopian", "Epic", "Fairy Tale",
    "Fantasy", "Gothic", "Graphic Novel", "Historical Fiction", "Horror", "Humor", "LGBTQ+",
    "Literary Fiction", "Magic Realism", "Manga", "Memoir", "Mystery", "Mythology",
    "Paranormal", "Philosophical", "Poetry", "Political Thriller", "Psychological Thriller",
    "Realistic Fiction", "Religious", "Romance", "Satire", "Science Fiction", "Short Story",
    "Southern Gothic", "Speculative Fiction", "Spy Fiction", "Steampunk", "Suspense",
    "Teen Fiction", "Thriller", "Tragedy", "True Crime", "Urban Fantasy", "Western",
    "Women's Fiction", "Young Adult (YA)", "Absurdist Fiction", "Action", "African Literature",
    "Allegory", "Alternative History", "Anthology", "Apocalyptic", "Art Fiction", "Asian Literature",
    "Autobiographical Fiction", "Bangsian Fantasy", "Bildungsroman", "Black Comedy", "Campus Novel",
    "Cyberpunk", "Dark Fantasy", "Domestic Fiction", "Dramedy", "Eco-Fiction", "Epic Fantasy",
    "Erotica", "Family Saga", "Feminist Fiction", "Folk Tale", "Gothic Romance", "Hard Science Fiction",
    "Heroic Fantasy", "High Fantasy", "Historical Romance", "Inspirational Fiction", "Juvenile Fiction",
    "Legal Thriller", "Low Fantasy", "Martial Arts Fiction", "Medical Thriller", "Metafiction",
    "Military Fiction", "Modernist Fiction", "Native American Literature", "New Adult", "Noir",
    "Occult Fiction", "Outsider Fiction", "Picaresque", "Political Fiction", "Post-Apocalyptic",
    "Postmodern Fiction", "Procedural", "Psychological Fiction", "Quest", "Religious Fiction",
    "Retellings", "Romantic Suspense", "Science Fantasy", "Serial", "Social Fiction", "Space Opera",
    "Splatterpunk", "Superhero Fiction", "Supernatural Thriller", "Techno-Thriller", "Time Travel",
    "Tragicomedy", "Transgressive Fiction", "Utopian", "Vampire Fiction", "Victorian Literature",
    "War Fiction", "Werewolf Fiction", "Wuxia", "Zombie Fiction"
];

const allLanguages = [
    "Afrikaans", "Albanian", "Amharic", "Arabic", "Armenian", "Azerbaijani", "Basque", "Belarusian", "Bengali", "Bosnian",
    "Bulgarian", "Catalan", "Cebuano", "Chinese", "Croatian", "Czech", "Danish", "Dutch", "English", "Esperanto",
    "Estonian", "Finnish", "French", "Galician", "Georgian", "German", "Greek", "Gujarati", "Haitian Creole", "Hausa",
    "Hawaiian", "Hebrew", "Hindi", "Hmong", "Hungarian", "Icelandic", "Igbo", "Indonesian", "Irish", "Italian", "Japanese",
    "Javanese", "Kannada", "Kazakh", "Khmer", "Korean", "Kurdish", "Kyrgyz", "Lao", "Latin", "Latvian", "Lithuanian",
    "Luxembourgish", "Macedonian", "Malagasy", "Malay", "Malayalam", "Maltese", "Maori", "Marathi", "Mongolian", "Myanmar",
    "Nepali", "Norwegian", "Nyanja", "Pashto", "Persian", "Polish", "Portuguese", "Punjabi", "Romanian", "Russian",
    "Samoan", "Scots Gaelic", "Serbian", "Sesotho", "Shona", "Sindhi", "Sinhala", "Slovak", "Slovenian", "Somali",
    "Spanish", "Sundanese", "Swahili", "Swedish", "Tajik", "Tamil", "Tatar", "Telugu", "Thai", "Turkish", "Turkmen",
    "Ukrainian", "Urdu", "Uyghur", "Uzbek", "Vietnamese", "Welsh", "Xhosa", "Yiddish", "Yoruba", "Zulu"
];



const creativeTraitsOptions = [
    "Observant", "Curious", "Playful", "Sensitivity", "Risk-takers", "Energy", "Flexibility", "Independent",
    "Openness to experience", "Passion", "A rebellious spirit", "Ambitious", "Creative people have high standards",
    "Curiosity", "Daydreaming", "Deep thinkers", "Dreamer", "Intuition", "Objective", "Open to new ideas"
];

const StyledTabs = styled(Tabs)(({ theme }) => ({
    // '& .MuiTabs-indicator': {
    //     display: 'none',
    // },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    borderRadius: '20px',
    margin: theme.spacing(0.5),
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    padding: theme.spacing(1, 2),
    fontSize: '0.875rem',
    '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
    color: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}));

const TabLabel = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const ProgressLabel = styled('span')`
    color: ${({ completed }) => (completed ? '97ffba' : 'inherit')};
    font-weight: ${({ completed }) => (completed ? 'normal' : 'light')};
`;

const MemoizedStyledTabs = React.memo(StyledTabs);
const MemoizedStyledTab = React.memo(StyledTab);
const MemoizedTabLabel = React.memo(TabLabel);
const MemoizedProgressLabel = React.memo(ProgressLabel);


const joyrideStepConfig = {
    userDetails: [
        {
            target: '.user-details-step-1',
            content: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1, paddingRight: '10px' }}>
                    Enter your personal details here to begin with. Note: <strong>Full Name</strong> will be the <strong>Display Name</strong>.
                    </div>
                    <div style={{ flex: 1, textAlign: 'center' }}>
                        <img src={BioImage} alt="Proficiency Example" style={{ width: '100%', height: '100%'}} />
                    </div>
                </div>
            ),
        },
        {
            target: '.user-details-step-2',
            content: (
                <div>
                    Enter your social links here.
                </div>
            ),
        },
        {
            target: '.user-details-step-3',
            content: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1, paddingRight: '10px' }}>
                        Enter the languages you know here. You can add multiple languages. Please fill the proficiency as follows: <strong>Listen</strong> as <strong>Yes</strong> or <strong>No</strong>, <strong>DRW</strong> (Degree of Reading, Writing, and Speaking) as a scale from <strong>0-10</strong>.
                    </div>
                    <div style={{ flex: 1, textAlign: 'center' }}>
                        <img src={LanguagesImage} alt="Proficiency Example" style={{ width: '100%', height: 'auto', maxWidth: '500px' }} />
                    </div>
                </div>
            ),
        },
        {
            target: '.user-details-step-4',
            content: (
                <div>
                    Choose one or more creative traits that describe you.
                </div>
            ),
        }
    ],
    favourites: [
        {
            target: '.search-movies-input', // Assuming this is the class for the search movies text field
            content: 'Type the movie name here.',
        },
        {
            target: '.personal-note-modal', // Assuming this is the class for the personal note modal
            content: 'This is where you can add a personal note for the selected movie.',
        },
        {
            target: '.edit-delete-media', // Assuming this is the class for the edit/delete media section
            content: 'Here you can edit or delete the media later.',
        }
    ]
};

function ProfileForm() {
    const axios = useAxios();
    const { theme, toggleTheme } = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Example of using Material-UI's useMediaQuery hook
    const { logout, userId, toggleIsNewUser } = useLogin();
    const [isNewUser, setIsNewUser] = useState(() => {
        const userDataJson = localStorage.getItem('userData');
        // console.log(userDataJson)
        return userDataJson ? JSON.parse(userDataJson).isNewUser : '';
    });
    const [pubProfileLink, setPubProfileLink] = useState(() => {
        const userDataJson = localStorage.getItem('userData');
        return userDataJson ? JSON.parse(userDataJson).pubProfileLink : '';
    });
    const textColor = theme.palette.text.primary;
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [media, setMedia] = useState({ movie: [], series: [], documentary: [], director: [], game: [], book: [], writer: [] });
    const [selectedSection, setSelectedSection] = useState(0);
    const [currentStep, setCurrentStep] = useState([0, 0, 0, 0, 0]);
    const [personalNoteModalOpen, setPersonalNoteModalOpen] = useState(false);
    const [selectedMediaDetails, setSelectedMediaDetails] = useState(null);
    const [selectedMediaType, setSelectedMediaType] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const navigate = useNavigate();
    const [autosaveTime, setAutosaveTime] = useState()
    const [tourProgress, setTourProgress] = useState(null);
    const [showLink, setShowLink] = useState(false);
    const [selectedLanguages, setSelectedLanguages] = useState([]);

    const toggleLinkVisibility = () => {
        setShowLink(!showLink);
    };

    const copyToClipboard = (link) => {
        navigator.clipboard.writeText(link)
            .then(() => alert("Link copied to clipboard!"))
            .catch(err => console.error('Failed to copy:', err));
    };

    const [openyouTubeModal, setOpenyouTubeModal] = useState(false);
    useEffect(() => {
        if (isNewUser) {
            setOpenyouTubeModal(true);
        }
    }, [isNewUser]);
    const videoId = process.env.PROFILE_FORM_TUTORIAL_YT_VID? process.env.PROFILE_FORM_TUTORIAL_YT_VID : ''; // Replace with your YouTube video ID

    const handleYTOpen = () => setOpenyouTubeModal(true);
    const handleYTClose = () => {
        setOpenyouTubeModal(false);
        handleToggle(false); // Assuming handleToggle toggles isNewUser2 state
    };
    const [joyrideRun, setJoyrideRun] = useState(false);
    const [joyrideSteps, setJoyrideSteps] = useState([]);
    const [joyrideStepIndex, setJoyrideStepIndex] = useState(0);
    
    const handleJoyrideCallback = (data) => {
        const { status, index, type, action } = data;
        const finishedStatuses = ['finished', 'skipped'];
    
        if (finishedStatuses.includes(status)) {
            setJoyrideRun(false);
            setJoyrideStepIndex(0);
            handleStepChange(selectedSection, 0); // Reset MUI stepper to step 0 for the current section
        } else if (type === 'step:after' || type === 'target:click') {
            let newStepIndex = index;
            if (action === 'next') {
                newStepIndex = index + 1;
            } else if (action === 'prev') {
                newStepIndex = index - 1;
            }
            handleStepChange(selectedSection, newStepIndex);
    
            setTimeout(() => {
                setJoyrideStepIndex(newStepIndex);
            }, 100); // Short delay to account for animation
        }
    };
    

    const methods = useForm({
        resolver: zodResolver(profileSchema),
        defaultValues: {
            userDetails: {
                fullName: { value: '', privacy: false },
                profile_path: { value: '', privacy: false },
                email: { value: '', privacy: false },
                phone: { value: '', privacy: false },
                location: { value: '', privacy: false },
                linkedin: { value: '', privacy: false },
                imdbProfileLink: { value: '', privacy: false },
                website: { value: '', privacy: false },
                languages: [{ value: '', privacy: false, lsrw: { l: false, s: 0, r: 0, w: 0 } }],
                creativeTraits: { value: [], privacy: false },
            },
            interests: {
                favourites: {
                    series: { value: [], privacy: false },
                    movie: { value: [], privacy: false },
                    genre: { value: [], privacy: false },
                    documentary: { value: [], privacy: false },
                    writer: { value: [], privacy: false },
                    director: { value: [], privacy: false },
                    book: { value: [], privacy: false },
                    game: { value: [], privacy: false },
                }
            },
            qualifications: {
                awards: [],
                education: [],
                workExperience: [],
            },
            workedIn: {
                credited: [],
            },
            ownWorks: {
                portfolio: [{
                    poster: '', name: '', logLine: '', genre: '', format: '', privacy: false, status: null, credit: [], proof_link: ''
                }]
            },
            storyOfmyLife: { value: '', privacy: false }
        }
    });
    const { control, register, handleSubmit, setValue, getValues, setFocus, formState: { errors, isDirty }, watch } = methods

    const profilePath = watch('userDetails.profile_path.value');

    const { fields: languagesFields, append: appendLanguage, remove: removeLanguage } = useFieldArray({
        control,
        name: 'userDetails.languages'
    });

    const filteredLanguagesOptions = useMemo(() => {
        return allLanguages.filter(lang => !selectedLanguages.includes(lang));
    }, [selectedLanguages]);

    const handleLanguageChange = (index, e) => {
        const newLanguagesFields = languagesFields.slice();
        const previousValue = newLanguagesFields[index].value;
        const newValue = e.target.value;

        // Update the selected language in the fields array
        newLanguagesFields[index].value = newValue;

        // Update the selected languages state
        setSelectedLanguages((prevSelected) => {
            const updatedSelected = [...prevSelected];
            if (previousValue) {
                const prevIndex = updatedSelected.indexOf(previousValue);
                if (prevIndex > -1) updatedSelected.splice(prevIndex, 1);
            }
            updatedSelected.push(newValue);
            return updatedSelected;
        });

        // Update the control
        setValue(`userDetails.languages[${index}].value`, newValue);
    };

    const renderedLanguageFields = useMemo(() => {
        return languagesFields.map((item, index) => (
            <Grid item xs={12} key={item.id} container alignItems="center" justifyContent="space-between" spacing={3}>
                <Grid item xs={5}>
                    <Controller
                        name={`userDetails.languages[${index}].value`}
                        control={control}
                        error={!!errors.userDetails?.fullName?.value} helperText={errors.userDetails?.fullName?.value ? errors.userDetails.fullName.value.message : ''}
                        render={({ field }) => (
                                <Select
                                    {...field}
                                    fullWidth
                                    onChange={(e) => handleLanguageChange(index, e)}
                                    displayEmpty
                                    error={!!errors.userDetails?.languages?.[index]?.value}
                                >
                                    <MenuItem value="" disabled>Select language</MenuItem>
                                    {filteredLanguagesOptions.concat(item.value).map((lang) => (
                                        <MenuItem key={lang} value={lang}>{lang}</MenuItem>
                                    ))}
                                </Select>
                            )}
                    />
                </Grid>
                <Grid item xs={7}>
                    <div display='flex' spacing={3}>
                        <CheckboxComponent name={`userDetails.languages[${index}].lsrw.l`} control={control} label="L" />
                        <SliderComponent name={`userDetails.languages[${index}].lsrw.s`} control={control} label="S" />
                        <SliderComponent name={`userDetails.languages[${index}].lsrw.r`} control={control} label="R" />
                        <SliderComponent name={`userDetails.languages[${index}].lsrw.w`} control={control} label="W" />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={() => removeLanguage(index)}>Remove Language</Button>
                </Grid>
            </Grid>
        ));
    }, [languagesFields, control, filteredLanguagesOptions]);

    useEffect(()=>{
        if(isNewUser){
            toggleIsNewUser(userId,false)
        }
    },[])

    const { fields: awardsFields, append: appendAward, remove: removeAward } = useFieldArray({
        control,
        name: 'qualifications.awards'
    });

    const { fields: educationFields, append: appendEducation, remove: removeEducation } = useFieldArray({
        control,
        name: 'qualifications.education'
    });

    const { fields: workExperienceFields, append: appendWorkExperience, remove: removeWorkExperience } = useFieldArray({
        control,
        name: 'qualifications.workExperience'
    });

    const { fields: workedInFields, append: appendWorkedIn, remove: removeWorkedIn } = useFieldArray({
        control,
        name: 'workedIn.credited'
    });

    const { fields: ownWorksFields, append: appendOwnWorks, remove: removeOwnWorks } = useFieldArray({
        control,
        name: 'ownWorks.portfolio'
    });

    function circularReplacer() {
        const seen = new WeakSet();
        return (key, value) => {
            if (typeof value === "object" && value !== null) {
                if (seen.has(value)) {
                    return;
                }
                seen.add(value);
            }
            return value;
        };
    }

    const getFirstErrorPathAndTab = (errorObj) => {
        let errorPath = { path: null, tab: null };

        const findError = (obj, path = '', tab = 0) => {
            for (const key in obj) {
                if (obj[key]?.message) { // Check for message instead of type
                    errorPath = { path: path ? `${path}.${key}` : key, tab: tab };
                    return true;
                } else if (typeof obj[key] === 'object') {
                    const subPath = path ? `${path}.${key}` : key;
                    if (findError(obj[key], subPath, tab)) {
                        return true;
                    }
                }
            }
            return false;
        };

        for (let i = 0; i < tabs.length; i++) {
            if (findError(errorObj[tabs[i].key], '', i)) {
                break;
            }
        }

        return errorPath;
    };

    const handleAddMedia = (selectedItem, type) => {
        setSelectedMediaDetails(selectedItem);
        setSelectedMediaType(type);
        setIsEditMode(false);  // Set to add mode
        setPersonalNoteModalOpen(true); // Open the PersonalNote modal
    };

    const handleEditMedia = (mediaDetails, type) => {
        setSelectedMediaDetails(mediaDetails);
        setSelectedMediaType(type);
        setIsEditMode(true);  // Set to edit mode
        setPersonalNoteModalOpen(true); // Open the PersonalNote modal
    };

    const handleSaveNote = async (personalNote) => {
        if (selectedMediaDetails) {
            try {
                console.log("type: ", selectedMediaType, " Data: ", selectedMediaDetails)
                const response = await axios.post('/mediaQuery/lookup', { type: selectedMediaType, data: selectedMediaDetails });
                const newMediaId = response.data.id;

                const updatedProfile = getValues();
                const mediaPath = `interests.favourites.${selectedMediaType}.value`;

                // Check for duplicates when adding
                const existingMedia = updatedProfile.interests.favourites[selectedMediaType]?.value || [];
                const newMediaObject = { reference: newMediaId, personalNote: personalNote };

                if (isEditMode) {
                    // Update existing media
                    const updatedValues = existingMedia.map(media =>
                        media.reference === newMediaId ? newMediaObject : media
                    );
                    setValue(mediaPath, updatedValues);
                    setMedia(prevMedia => ({
                        ...prevMedia,
                        [selectedMediaType]: prevMedia[selectedMediaType].map(media =>
                            media._id === newMediaId ? { ...media, personalNote: personalNote } : media
                        )
                    }));
                } else {
                    // Add new media
                    if (!existingMedia.some(media => media.reference === newMediaId)) {
                        updatedProfile.interests.favourites[selectedMediaType].value.push(newMediaObject);
                        setValue(mediaPath, updatedProfile.interests.favourites[selectedMediaType].value);

                        const newMedia = { ...selectedMediaDetails, _id: newMediaId, personalNote: personalNote };
                        setMedia(prevMedia => ({
                            ...prevMedia,
                            [selectedMediaType]: [...(prevMedia[selectedMediaType] || []), newMedia]
                        }));
                    }
                }
            } catch (error) {
                console.error(`Error adding/updating ${selectedMediaType}:`, error);
            }
        }
        setPersonalNoteModalOpen(false);
    };

    const handleClosePersonalNote = () => {
        setPersonalNoteModalOpen(false);
        setSelectedMediaDetails(null);
        setSelectedMediaType('');
    };

    const handleRemoveMedia = (mediaId, mediaType) => {
        const updatedProfile = getValues();
        const mediaPath = `interests.favourites.${mediaType}.value`;

        // Check if the mediaType exists in the profile and has a value array
        if (updatedProfile.interests.favourites[mediaType] && Array.isArray(updatedProfile.interests.favourites[mediaType].value)) {
            const currentValues = updatedProfile.interests.favourites[mediaType].value;
            const updatedValues = currentValues.filter(media => media.reference !== mediaId);

            if (currentValues.length === updatedValues.length) {
                // console.log(`Media ID ${mediaId} not found in ${mediaType}.`);
            } else {
                setValue(mediaPath, updatedValues);
                setMedia(prevMedia => ({
                    ...prevMedia,
                    [mediaType]: prevMedia[mediaType].filter(media => media._id !== mediaId)
                }));
            }
        } else {
            console.error(`Media type ${mediaType} does not exist in profile or does not have a value property.`);
        }
        // console.log("after removing: ", updatedProfile.interests.favourites[mediaType])
    };

    useEffect(() => {
        if (errors && Object.keys(errors).length > 0) {
            const stringifiedErrors = JSON.stringify(errors, circularReplacer());
            const truncatedErrors = stringifiedErrors.length > 1000 ? stringifiedErrors.slice(0, 1000) : stringifiedErrors;
            console.log("Validation Error:", stringifiedErrors);
            const firstError = getFirstErrorPathAndTab(errors);
            if (firstError.path) {
                setSelectedSection(firstError.tab); // Switch to the tab containing the first error
                // console.log("Switching tab to : ", firstError.tab)
                setFocus(firstError.path); // Focus on the first error field
                console.log("Focusing: ", firstError.path)
            }
            setTimeout(() => {
                if(firstError.path.includes('languages') ) {
                    alert('Validation Error in Languages')
                }else {
                alert(`Validation Error: ${stringifiedErrors}`);
                }
            }, 150); // Delay the alert slightly to ensure focus is set
            logger(`Form Validation Error: ${truncatedErrors}`, 'error', ['env:production', 'issue:form-validation', 'component:ProfileForm'], 'soozh-beta-frontend');
        }
    }, [errors, selectedSection, setSelectedSection]);


    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const profileData = await fetchProfileHelper(axios, userId);
                if (profileData) {
                    setValue('userDetails.fullName', profileData.userDetails.fullName || { value: '', privacy: false });
                    setValue('userDetails.profile_path', profileData.userDetails.profile_path || { value: '', privacy: false });
                    setValue('userDetails.email', profileData.userDetails.email || { value: '', privacy: false });
                    setValue('userDetails.phone', profileData.userDetails.phone || { value: '', privacy: false });
                    setValue('userDetails.location', profileData.userDetails.location || { value: '', privacy: false });
                    setValue('userDetails.linkedin', profileData.userDetails.linkedin || { value: '', privacy: false });
                    setValue('userDetails.imdbProfileLink', profileData.userDetails.imdbProfileLink || { value: '', privacy: false });
                    setValue('userDetails.website', profileData.userDetails.website || { value: '', privacy: false });
                    setValue('userDetails.languages', profileData.userDetails.languages || [{ value: '', privacy: false, lsrw: { l: false, s: 0, r: 0, w: 0 } }]);
                    setValue('userDetails.creativeTraits', profileData.userDetails.creativeTraits || { value: [], privacy: false });

                    setSelectedLanguages(profileData.userDetails.languages.map( (item) => item.value))

                    // Setting the values for series with checks for value and privacy
                    setValue('interests.favourites.series.value',
                        profileData.interests?.favourites?.series?.value !== undefined ?
                            profileData.interests?.favourites?.series.value : []
                    );
                    setValue('interests.favourites.series.privacy',
                        profileData.interests?.favourites?.series?.privacy !== undefined ?
                            profileData.interests?.favourites?.series.privacy : false
                    );

                    // Setting the values for movie with checks for value and privacy
                    setValue('interests.favourites.movie.value',
                        profileData.interests?.favourites?.movie?.value !== undefined ?
                            profileData.interests?.favourites?.movie.value : []
                    );
                    setValue('interests.favourites.movie.privacy',
                        profileData.interests?.favourites?.movie?.privacy !== undefined ?
                            profileData.interests?.favourites?.movie.privacy : false
                    );

                    // Setting the values for genre with checks for value and privacy
                    setValue('interests.favourites.genre.value',
                        profileData.interests?.favourites?.genre?.value !== undefined ?
                            profileData.interests?.favourites?.genre.value : []
                    );
                    setValue('interests.favourites.genre.privacy',
                        profileData.interests?.favourites?.genre?.privacy !== undefined ?
                            profileData.interests?.favourites?.genre.privacy : false
                    );

                    // Setting the values for documentary with checks for value and privacy
                    setValue('interests.favourites.documentary.value',
                        profileData.interests?.favourites?.documentary?.value !== undefined ?
                            profileData.interests?.favourites?.documentary.value : []
                    );
                    setValue('interests.favourites.documentary.privacy',
                        profileData.interests?.favourites?.documentary?.privacy !== undefined ?
                            profileData.interests?.favourites?.documentary.privacy : false
                    );

                    // Setting the values for writer with checks for value and privacy
                    setValue('interests.favourites.writer.value',
                        profileData.interests?.favourites?.writer?.value !== undefined ?
                            profileData.interests?.favourites?.writer.value : []
                    );
                    setValue('interests.favourites.writer.privacy',
                        profileData.interests?.favourites?.writer?.privacy !== undefined ?
                            profileData.interests?.favourites?.writer.privacy : false
                    );

                    // Setting the values for director with checks for value and privacy
                    setValue('interests.favourites.director.value',
                        profileData.interests?.favourites?.director?.value !== undefined ?
                            profileData.interests?.favourites?.director.value : []
                    );
                    setValue('interests.favourites.director.privacy',
                        profileData.interests?.favourites?.director?.privacy !== undefined ?
                            profileData.interests?.favourites?.director.privacy : false
                    );

                    // Setting the values for game with checks for value and privacy
                    setValue('interests.favourites.game.value',
                        profileData.interests?.favourites?.game?.value !== undefined ?
                            profileData.interests?.favourites?.game.value : []
                    );
                    setValue('interests.favourites.game.privacy',
                        profileData.interests?.favourites?.game?.privacy !== undefined ?
                            profileData.interests?.favourites?.game.privacy : false
                    );

                    // Setting the values for book with checks for value and privacy
                    setValue('interests.favourites.book.value',
                        profileData.interests?.favourites?.book?.value !== undefined ?
                            profileData.interests?.favourites?.book.value : []
                    );
                    setValue('interests.favourites.book.privacy',
                        profileData.interests?.favourites?.book?.privacy !== undefined ?
                            profileData.interests?.favourites?.book.privacy : false
                    );

                    setValue('qualifications.awards', Array.isArray(profileData.qualifications?.awards) ? profileData.qualifications.awards : []);
                    setValue('qualifications.education', Array.isArray(profileData.qualifications?.education) ? profileData.qualifications.education : []);
                    setValue('qualifications.workExperience', Array.isArray(profileData.qualifications?.workExperience) ? profileData.qualifications.workExperience : []);
                    //console.log(profileData.ownWorks?.portfolio)

                    setValue('ownWorks.portfolio', Array.isArray(profileData.ownWorks?.portfolio) ? profileData.ownWorks.portfolio : []);
                    setValue('workedIn.credited', Array.isArray(profileData.workedIn?.credited) ? profileData.workedIn.credited : []);
                    setValue('storyOfmyLife', profileData.storyOfmyLife || { value: '', privacy: false });
                }
            } catch (error) {
                console.error('Error fetching profile:', error);
                logger(`Error fetching profile: ${error.message}`, 'error', ['env:production', 'issue:profile-fetch'], 'soozh-beta-frontend');
                alert('Error fetching profile data. Please try again later.');
                navigate(`/profile/${userId}`)
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();

        const fetchMedia = async () => {
            try {
                const response = await axios.get(`/mediaquery/${userId}/media_v2`);
                if (response.data) {
                    setMedia(response.data || {});
                }
            } catch (err) {
                console.log(err.message);
            }
        }

        fetchMedia();

        const fetchTourProgress = async () => {
            try {
                const response = await axios.get(`/tourProgress/${userId}`);
                setTourProgress(response.data);
            } catch (error) {
                console.error('Error fetching tour progress:', error);
            }
        };

        fetchTourProgress();
        // console.log("TourProgress: ",tourProgress)

    }, [userId, axios, setValue]);

    useEffect(() => {
        if (tourProgress) {
            if (selectedSection === 0 && !tourProgress.profileForm.userDetails) {
                setJoyrideSteps(joyrideStepConfig.userDetails);
                setJoyrideRun(true);
            } else if (selectedSection === 1 && !tourProgress.profileForm.favourites) {
                setJoyrideSteps(joyrideStepConfig.favourites);
                setJoyrideRun(true);
            }
        }
    }, [selectedSection, tourProgress]);

    const onSubmitProfile = useCallback(async (data) => {
        console.log("Submitting form with data:", data);
        setSaving(true)
        try {
            const profileData = {
                userDetails: {
                    fullName: data.userDetails.fullName || { value: '', privacy: false },
                    profile_path: data.userDetails.profile_path || { value: '', privacy: false },
                    email: data.userDetails.email || { value: '', privacy: false },
                    phone: data.userDetails.phone || { value: '', privacy: false },
                    location: data.userDetails.location || { value: '', privacy: false },
                    linkedin: data.userDetails.linkedin || { value: '', privacy: false },
                    imdbProfileLink: data.userDetails.imdbProfileLink || { value: '', privacy: false },
                    website: data.userDetails.website || { value: '', privacy: false },
                    languages: data.userDetails.languages || [{ value: '', privacy: false, lsrw: { l: false, s: 0, r: 0, w: 0 } }],
                    creativeTraits: data.userDetails.creativeTraits || { value: [], privacy: false },
                },
                interests: {
                    favourites: {
                        series: data.interests?.favourites?.series || { value: [], privacy: false },
                        movie: data.interests?.favourites?.movie || { value: [], privacy: false },
                        genre: data.interests?.favourites?.genre || { value: [], privacy: false },
                        documentary: data.interests?.favourites?.documentary || { value: [], privacy: false },
                        writer: data.interests?.favourites?.writer || { value: [], privacy: false },
                        director: data.interests?.favourites?.director || { value: [], privacy: false },
                        book: data.interests?.favourites?.book || { value: [], privacy: false },
                        game: data.interests?.favourites?.game || { value: [], privacy: false }
                    }
                },
                qualifications: {
                    awards: Array.isArray(data.qualifications.awards) ? data.qualifications.awards.map(award => ({
                        name: award.name,
                        year: award.year,
                        awardedBy: award.awardedBy,
                        privacy: award.privacy || false
                    })) : [],
                    education: Array.isArray(data.qualifications.education) ? data.qualifications.education.map(edu => ({
                        institution: edu.institution,
                        degree: edu.degree,
                        privacy: edu.privacy || false
                    })) : [],
                    workExperience: Array.isArray(data.qualifications.workExperience) ? data.qualifications.workExperience.map(work => ({
                        company: work.company,
                        position: work.position,
                        privacy: work.privacy || false
                    })) : [],
                },
                ownWorks: {
                    portfolio: Array.isArray(data.ownWorks.portfolio) ? data.ownWorks.portfolio.map(work => ({
                        poster: work.poster,
                        name: work.name,
                        logLine: work.logLine,
                        genre: work.genre,
                        format: work.format,
                        privacy: work.privacy || false,
                        status: work.status || '',
                        credit: work.credit || [],
                        proof_link: work.proof_link || '',
                    })) : []
                },
                workedIn: {
                    credited: Array.isArray(data.workedIn.credited) ? data.workedIn.credited.map(work => ({
                        poster: work.poster,
                        name: work.name,
                        logLine: work.logLine,
                        genre: work.genre,
                        format: work.format,
                        privacy: work.privacy || false,
                        proof_link: work.proof_link || '',
                    })) : []
                },
                storyOfmyLife: data.storyOfmyLife || { value: '', privacy: false }
            };

            await axios.post('/profile', { userId, ...profileData });
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Error saving profile:', error);
        } finally {
            setSaving(false)
        }
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
          const formData = getValues();
          onSubmitProfile(formData);
          setAutosaveTime(new Date().toLocaleTimeString()); // Update autosave time
        }, 1*60*1000);
    
        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [getValues, onSubmitProfile]);
    
    const handleFileUpload = async (event, { folderName, fileName, fieldArrayName, index }) => {
        // console.log('Uploading file for:', fieldArrayName, index);
        const file = event.target.files[0];
        if (file) {
            try {
                const formData = new FormData();
                formData.append('file', file);
    
                const response = await axios.post(`/upload/${folderName}/${fileName}-${userId}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                });

                if (fieldArrayName === "userDetails.profile_path") {
                    const imageUrl = `${response.data.fileLink}?timestamp=${new Date().getTime()}`;
                    setValue('userDetails.profile_path.value', imageUrl);
                } else {
                    if (response.data && response.data.fileLink) {
                        const imageUrl = `${response.data.fileLink}?timestamp=${new Date().getTime()}`;
                        console.log("Image uploaded successfully:", imageUrl);
                        const updatedFields = getValues(fieldArrayName);
                        // console.log('Updated fields before setting value:', updatedFields);
                        if (updatedFields && updatedFields[index]) {
                            updatedFields[index].poster = imageUrl;
                            setValue(fieldArrayName, updatedFields);
                            // console.log("Updated fieldArray state:", updatedFields);
                        } else {
                            console.error(`Invalid index ${index} for field array ${fieldArrayName}`);
                        }

                        // console.log("Form value after upload:", getValues());
                    }
                }
            } catch (error) {
                console.error("Error uploading image:", error);
            }
        }
    };

    const handleRemoveFile = (fieldArrayName, index) => {
        // console.log('Uploading file for:', fieldArrayName, index);
        const updatedFields = getValues(fieldArrayName);
        if (fieldArrayName === "userDetails.profile_path") {
            const imageUrl = ``;
            setValue('userDetails.profile_path.value', imageUrl);
        } else {
            if (updatedFields && updatedFields[index]) {
                updatedFields[index].poster = '';
                setValue(fieldArrayName, updatedFields);
                console.log("Updated fieldArray state after removal:", updatedFields);
            } else {
                console.error(`Invalid index ${index} for field array ${fieldArrayName}`);
            }
        }

        console.log("Form value after removal:", getValues());
    };

    const handleMetaURLchange = (event, { fieldArrayName, index } = {}) => {
        if (!fieldArrayName || typeof index !== 'number') return;

        const updatedFields = getValues(fieldArrayName) || [];
        const newValue = event?.target?.value ?? '';

        if (updatedFields[index]?.proof_link !== newValue) {
            updatedFields[index] = { ...updatedFields[index], proof_link: newValue };
            setValue(fieldArrayName, updatedFields);
        }
    };

    const calculateProgress = (section) => {
        const totalFields = Object.keys(section).length;
        let populatedFields = 0;

        Object.values(section).forEach(field => {
            if (Array.isArray(field)) {
                populatedFields+= field.length>0 ? 1 : 0 
            } else if (field && typeof field === 'object') {
                if (Array.isArray(field.value)) {
                    populatedFields += field.value.length > 0 ? 1 : 0;
                } else if (typeof field.value === 'string') {
                    populatedFields += field.value.trim() !== '' ? 1 : 0;
                }
            }
        });

        return Math.round((populatedFields / totalFields) * 100);
    };

    const calculateCount = (section) => {
        return section?.length || 0;
    };

    const focusField = (fieldPath) => {
        const [section, ...rest] = fieldPath.split('.');
        const sectionIndex = tabs.findIndex(tab => tab.key === section);

        if (sectionIndex !== -1) {
            setSelectedSection(sectionIndex);

            const fieldToStepMap = {
                "userDetails.fullName.value": 0,
                "userDetails.email.value": 0,
                "userDetails.phone.value": 0,
                "userDetails.location.value": 0,
                "userDetails.linkedin.value": 1,
                "userDetails.imdbProfileLink.value": 1,
                "userDetails.website.value": 1,
                "userDetails.languages.value": 2,
                "userDetails.creativeTraits.value": 3,
                "qualifications.awards": 0,
                "qualifications.education": 1,
                "qualifications.workExperience": 2,
                "ownWorks.portfolio": 0,
                "workedIn.credited": 0,
                "workedIn": 0  // Adjust if you have specific steps for workedIn
            };

            const stepIndex = fieldToStepMap[fieldPath];
            if (typeof stepIndex === 'number') {
                setCurrentStep(prev => {
                    const newSteps = [...prev];
                    newSteps[sectionIndex] = stepIndex;
                    return newSteps;
                });
            }

            setTimeout(() => {
                setFocus(fieldPath);
            }, 300);
        }
    };


    const watchedUserDetails = useWatch({ control, name: 'userDetails', defaultValue: {} });
    const watchedInterests = useWatch({ control, name: 'interests.favourites' });
    const watchedQualifications = useWatch({ control, name: 'qualifications' });
    const watchedOwnWorks = useWatch({ control, name: 'ownWorks.portfolio' });
    const watchedWorkedIn = useWatch({ control, name: 'workedIn.credited' });

    const userDetailsProgress = useMemo(() => {
        return calculateProgress(watchedUserDetails);
    }, [watchedUserDetails]); const interestsProgress = useMemo(() => calculateProgress(watchedInterests), [watchedInterests]);
    const qualificationsProgress = useMemo(() => calculateProgress(watchedQualifications), [watchedQualifications]);
    const ownWorksCount = useMemo(() => calculateCount(watchedOwnWorks), [watchedOwnWorks]);
    const workedInCount = useMemo(() => calculateCount(watchedWorkedIn), [watchedWorkedIn]);

    // Custom Tooltip styles
    const StyledTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 14,
            borderRadius: '8px',
            backdropFilter: 'blur(10px)', // For the glass-like effect
            padding: '10px 15px',
        },
    }));

    // Function to generate tooltip content for User Details
    const userDetailsTooltip = () => {
        if (!watchedUserDetails) return 'No data available';

        const unfilledFields = Object.keys(watchedUserDetails).filter(key => {
            const field = watchedUserDetails[key];
            if (Array.isArray(field)) return field.length === 0;
            if (field && typeof field === 'object') {
                const fieldValue = field.value;
                return (Array.isArray(fieldValue) && fieldValue.length === 0) || 
                        (typeof fieldValue === 'string' && fieldValue.trim() === '');
            }
            return !field;
        });
        return (
            <div>
                <Typography variant="h6" component="h4">Unfilled fields</Typography>
                {unfilledFields.map((field, index) => (
                    <Typography
                        key={index}
                        variant="body2"
                        color="error"
                        onClick={() => focusField(`userDetails.${field}.value`)}
                        style={{ cursor: 'pointer' }}
                    >
                        {UserProfile_fieldLabels[field]}
                    </Typography>
                ))}
            </div>
        );
    };

    // Function to generate tooltip content for Favourites
    const favouritesTooltip = (fields) => {
        if (!fields) return 'No data available';
        const categories = Object.keys(fields).map(key => {
            const fieldValue = fields[key].value || [];
            return `${FavouritesLabels[key]}: ${fieldValue.length}`;
        });
        return (
            <div>
                <Typography variant="h6" style={{ fontWeight: 500, marginBottom: '5px' }}>
                    Favourites:
                </Typography>
                {categories.map((category, index) => (
                    <Typography key={index} style={{ fontSize: '14px' }}>
                        {category}
                    </Typography>
                ))}
            </div>
        );
    };

    const qualificationsTooltip = (fields, focusField) => {
        if (!fields) return 'No data available';

        const filledCounts = {
            awards: fields.awards ? fields.awards.length : 0,
            education: fields.education ? fields.education.length : 0,
            workExperience: fields.workExperience ? fields.workExperience.length : 0,
        };

        return (
            <div>
                <Typography variant="h6" style={{ fontWeight: 500, marginBottom: '5px' }}>
                    Filled Counts:
                </Typography>
                {Object.keys(filledCounts).map((key, index) => (
                    <Typography
                        key={index}
                        style={{ fontSize: '14px', cursor: 'pointer' }}
                        onClick={() => focusField(`qualifications.${key}`)}
                    >
                        {qualificationFieldLabels[key]}: {filledCounts[key]}
                    </Typography>
                ))}
            </div>
        );
    };

    const ownWorksTooltip = (works, focusField) => {
        if (!Array.isArray(works)) return 'No data available';
        if (!Array.isArray(works)) return 'No data available';
        return (
            <div>
                <Typography variant="h6" style={{ fontWeight: 500, marginBottom: '5px' }}>
                    My Stories:
                </Typography>
                {works.map((work, index) => (
                    <Typography
                        key={index}
                        style={{ fontSize: '14px', cursor: 'pointer' }}
                        onClick={() => focusField(`ownWorks.portfolio.${index}.name`)}
                    >
                        {work.name ? work.name : 'Unnamed'}
                    </Typography>
                ))}
            </div>
        );
    };

    const workedInTooltip = (works, focusField) => {
        if (!Array.isArray(works)) return 'No data available';
        if (works.length===0) return null
        return (
            <div>
                <Typography variant="h6" style={{ fontWeight: 500, marginBottom: '5px' }}>
                    {works.length>0 ? "Experience:" : null}
                </Typography>
                {works.map((work, index) => (
                    <Typography
                        key={index}
                        style={{ fontSize: '14px', cursor: 'pointer' }}
                        onClick={() => focusField(`workedIn.${index}.name`)}
                    >
                        {work.name ? work.name : 'Unnamed'}
                    </Typography>
                ))}
            </div>
        );
    };

    if (loading) {
        return (
            <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '20%' }}>
                <CircularProgress />
            </Container>
        );
    }

    const userDetailsSteps = [
        {
            label: 'Enter Your Personal Details',
            content: (
                <div>
                    <Grid container columnSpacing={2} justifyContent="left" alignItems="left" className="user-details-step-1">
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className="upload-placeholder" style={{ width: '100px', height: '100px' }} onClick={() => document.getElementById('fileInput').click()}>
                                {profilePath ? <img src={profilePath} alt="Profile" /> : <CloudUploadIcon className="upload-icon" />}
                                <input id="fileInput" type="file" accept="image/*" onChange={(event) => handleFileUpload(event, { folderName: 'UserProfile', fileName: `${userId}`, fieldArrayName: 'userDetails.profile_path', index: 0 })} style={{ display: 'none' }} />
                            </div>
                            {profilePath === '' ? null : <IconButton className="remove-button" onClick={() => handleRemoveFile('userDetails.profile_path', 0)}><span className="remove-icon">×</span></IconButton>}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth label={`${UserProfile_fieldLabels.fullName}`} margin="normal" {...register('userDetails.fullName.value')} error={!!errors.userDetails?.fullName?.value} helperText={errors.userDetails?.fullName?.value ? errors.userDetails.fullName.value.message : ''} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label={`${UserProfile_fieldLabels.email}`} margin="normal" {...register('userDetails.email.value')} error={!!errors.userDetails?.email?.value} helperText={errors.userDetails?.email?.value ? errors.userDetails.email.value.message : ''} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label={`${UserProfile_fieldLabels.phone}`} margin="normal" {...register('userDetails.phone.value')} error={!!errors.userDetails?.phone?.value} helperText={errors.userDetails?.phone?.value ? errors.userDetails.phone.value.message : ''} />
                        </Grid>
                        <Grid item xs={12}>
                            <LocationField />
                        </Grid>
                    </Grid>
                </div>
            ),
        },
        {
            label: 'Enter Your Socials',
            content: (
                <div>
                    <Grid container columnSpacing={2} justifyContent="left" alignItems="left" className="user-details-step-2">
                        <Grid item xs={12} sm={4}>
                            <TextField fullWidth label={`${UserProfile_fieldLabels.linkedin}`} margin="normal" {...register('userDetails.linkedin.value')} error={!!errors.userDetails?.linkedin?.value} helperText={errors.userDetails?.linkedin?.value ? errors.userDetails.linkedin.value.message : ''} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField fullWidth label={`${UserProfile_fieldLabels.imdbProfileLink}`} margin="normal" {...register('userDetails.imdbProfileLink.value')} error={!!errors.userDetails?.imdbProfileLink?.value} helperText={errors.userDetails?.imdbProfileLink?.value ? errors.userDetails.imdbProfileLink.value.message : ''} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField fullWidth label={`${UserProfile_fieldLabels.website}`} margin="normal" {...register('userDetails.website.value')} error={!!errors.userDetails?.website?.value} helperText={errors.userDetails?.website?.value ? errors.userDetails.website.value.message : ''} />
                        </Grid>
                    </Grid>
                </div>
            ),
        },
        {
            label: 'Enter Your Languages',
            content: (
                <Grid container spacing={2} className="user-details-step-3">
                    {renderedLanguageFields}
                    <Grid item xs={12}>
                        <Button
                            onClick={() => appendLanguage({ value: '', privacy: false, lsrw: { l: false, s: 0, r: 0, w: 0 } })}
                            disabled={languagesFields.some(field => !field.value)}
                        >
                            Add Language
                        </Button>
                    </Grid>
                </Grid>
            ),
        },
        // {
        //     label: 'Enter your creative traits',
        //     content: (
        //         <Grid container spacing={2} sx={{ marginTop: "30px" }} className="user-details-step-4">
        //             <Controller name="userDetails.creativeTraits.value" control={control} render={({ field }) => (
        //                 <Autocomplete {...field} multiple options={creativeTraitsOptions} getOptionLabel={(option) => option} defaultValue={[]} onChange={(event, value) => field.onChange(value)} isOptionEqualToValue={(option, value) => option === value} renderTags={(value, getTagProps) => value.map((option, index) => {
        //                     const { key, ...tagProps } = getTagProps({ index });
        //                     return <Chip key={index} label={option} {...tagProps} />;
        //                 })} renderInput={(params) => (
        //                     <TextField {...params} label={`${UserProfile_fieldLabels.creativeTraits}`} placeholder="Select Creative Traits" error={!!errors.userDetails?.creativeTraits} helperText={errors.userDetails?.creativeTraits ? errors.userDetails.creativeTraits.message : ''} margin="normal" />
        //                 )} />
        //             )} />
        //         </Grid>
        //     )
        // }
    ];

    const favouriteSteps = [
        {
            label: 'Movie/Series/Documentary',
            content: (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row', flexWrap: 'wrap' }}>
                        <div style={{ flex: '0 0 33%', width: isMobile ? '100%' : '33%', padding: '10px' }} className='search-movies-input'>
                        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                            <Typography variant="h6">Movies</Typography>
                            <Controller
                                name={`interests.favourites.movie.privacy`}
                                control={control}
                                render={({ field }) => (
                                    <IconButton {...field} onClick={() => field.onChange(!field.value)}>
                                        {field.value ? (
                                            <VisibilityOffIcon style={{ color: 'red', fontSize: '1.4rem' }} />
                                        ) : (
                                            <VisibilityIcon style={{ color: 'green', fontSize: '1.4rem' }} />
                                        )}
                                    </IconButton>
                                )}
                            />
                        </Box>
                            <Mediaquery type="movie" label={`${FavouritesLabels.movie}`} onSelect={(selectedItem) => handleAddMedia(selectedItem, 'movie')} />
                            <div className="gallery">
                                {media.movie?.map((item, index) => (
                                    <div className="gallery-item" key={item._id || index}>
                                        <StyledPNTooltip
                                            title={item.personalNote || "No personal note"}
                                            arrow
                                            showEditIcon={true}
                                            item={item}
                                            onEdit={handleEditMedia} // This line passes the handleEditMedia function
                                            itemType={'movie'}
                                        >
                                            <ImageWithNoContextMenu src={item.poster_path} alt={item.title} />
                                        </StyledPNTooltip>
                                        <IconButton className="remove-button" onClick={() => handleRemoveMedia(item._id, 'movie')}>
                                            <span className="remove-icon">×</span>
                                        </IconButton>
                                        <div className="details">
                                            <Typography variant="subtitle1">{item.title}</Typography>
                                            <Typography variant="caption" color="textSecondary">
                                                {new Date(item.release_date).getFullYear()}
                                            </Typography>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{ flex: '0 0 33%', width: isMobile ? '100%' : '33%', padding: '10px' }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                            <Typography variant="h6">Series</Typography>
                            <Controller
                                name={`interests.favourites.series.privacy`}
                                control={control}
                                render={({ field }) => (
                                    <IconButton {...field} onClick={() => field.onChange(!field.value)}>
                                        {field.value ? (
                                            <VisibilityOffIcon style={{ color: 'red', fontSize: '1.4rem' }} />
                                        ) : (
                                            <VisibilityIcon style={{ color: 'green', fontSize: '1.4rem' }} />
                                        )}
                                    </IconButton>
                                )}
                            />
                        </Box>                            
                        <Mediaquery type="series" label={`${FavouritesLabels.series}`} onSelect={(selectedItem) => handleAddMedia(selectedItem, 'series')} />
                            <div className="gallery">
                                {media.series?.map((item, index) => (
                                    <div className="gallery-item" key={item._id || index}>
                                        <StyledPNTooltip
                                            title={item.personalNote || "No personal note"}
                                            arrow
                                            showEditIcon={true}
                                            item={item}
                                            onEdit={handleEditMedia} // This line passes the handleEditMedia function
                                            itemType={'series'}
                                        >
                                            <ImageWithNoContextMenu src={item.poster_path} alt={item.title} />
                                            </StyledPNTooltip>
                                        <IconButton className="remove-button" onClick={() => handleRemoveMedia(item._id, 'series')}>
                                            <span className="remove-icon">×</span>
                                        </IconButton>
                                        <div className="details">
                                            <Typography variant="subtitle1">{item.title}</Typography>
                                            <Typography variant="caption" color="textSecondary">
                                                {new Date(item.release_date).getFullYear()}
                                            </Typography>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{ flex: '0 0 33%', width: isMobile ? '100%' : '33%', padding: '10px' }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                            <Typography variant="h6">Documentary</Typography>
                            <Controller
                                name={`interests.favourites.documentary.privacy`}
                                control={control}
                                render={({ field }) => (
                                    <IconButton {...field} onClick={() => field.onChange(!field.value)}>
                                        {field.value ? (
                                            <VisibilityOffIcon style={{ color: 'red', fontSize: '1.4rem' }} />
                                        ) : (
                                            <VisibilityIcon style={{ color: 'green', fontSize: '1.4rem' }} />
                                        )}
                                    </IconButton>
                                )}
                            />
                        </Box>
                            <Mediaquery type="documentary" label={`${FavouritesLabels.documentary}`} onSelect={(selectedItem) => handleAddMedia(selectedItem, 'documentary')} />
                            <div className="gallery">
                                {media.documentary?.map((item, index) => (
                                    <div className="gallery-item" key={item._id || index}>
                                        <StyledPNTooltip
                                            title={item.personalNote || "No personal note"}
                                            arrow
                                            showEditIcon={true}
                                            item={item}
                                            onEdit={handleEditMedia} // This line passes the handleEditMedia function
                                            itemType={'documentary'}
                                        >
                                            <ImageWithNoContextMenu src={item.poster_path} alt={item.title} />
                                        </StyledPNTooltip>
                                        <IconButton className="remove-button" onClick={() => handleRemoveMedia(item._id, 'documentary')}>
                                            <span className="remove-icon">×</span>
                                        </IconButton>
                                        <div className="details">
                                            <Typography variant="subtitle1">{item.title}</Typography>
                                            <Typography variant="caption" color="textSecondary">
                                                {new Date(item.release_date).getFullYear()}
                                            </Typography>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            label: 'Writer/Director',
            content: (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row', flexWrap: 'wrap' }}>
                    <div style={{ flex: '0 0 33%', width: isMobile ? '100%' : '33%', padding: '10px' }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                            <Typography variant="h6">Writer</Typography>
                            <Controller
                                name={`interests.favourites.writer.privacy`}
                                control={control}
                                render={({ field }) => (
                                    <IconButton {...field} onClick={() => field.onChange(!field.value)}>
                                        {field.value ? (
                                            <VisibilityOffIcon style={{ color: 'red', fontSize: '1.4rem' }} />
                                        ) : (
                                            <VisibilityIcon style={{ color: 'green', fontSize: '1.4rem' }} />
                                        )}
                                    </IconButton>
                                )}
                            />
                        </Box>
                            <Mediaquery type="writer" label={`${FavouritesLabels.writer}`} onSelect={(selectedItem) => handleAddMedia(selectedItem, 'writer')} />
                            <div className="gallery">
                                {media.writer?.map((item, index) => (
                                    <div className="gallery-item" key={item._id || index}>
                                        <StyledPNTooltip
                                            title={item.personalNote || "No personal note"}
                                            arrow
                                            showEditIcon={true}
                                            item={item}
                                            onEdit={handleEditMedia} // This line passes the handleEditMedia function
                                            itemType={'writer'}
                                        >
                                            <ImageWithNoContextMenu src={item.poster_path} alt={item.title} />
                                        </StyledPNTooltip>
                                        <IconButton className="remove-button" onClick={() => handleRemoveMedia(item._id, 'writer')}>
                                            <span className="remove-icon">×</span>
                                        </IconButton>
                                        <div className="details">
                                            <Typography variant="subtitle1">{item.title}</Typography>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{ flex: '0 0 33%', width: isMobile ? '100%' : '33%', padding: '10px' }}>
                            
                            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                            <Typography variant="h6" style={{ marginTop: '20px' }}>{`${FavouritesLabels.genre}`}</Typography>
                                <Controller
                                    name={`interests.favourites.genre.privacy`}
                                    control={control}
                                    render={({ field }) => (
                                        <IconButton {...field} onClick={() => field.onChange(!field.value)}>
                                            {field.value ? (
                                                <VisibilityOffIcon style={{ color: 'red', fontSize: '1.4rem' }} />
                                            ) : (
                                                <VisibilityIcon style={{ color: 'green', fontSize: '1.4rem' }} />
                                            )}
                                        </IconButton>
                                    )}
                                />
                            </Box>
                            <Controller
                                name="interests.favourites.genre.value"
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        multiple
                                        options={genreOptions}
                                        getOptionLabel={(option) => option}
                                        defaultValue={[]}
                                        onChange={(event, value) => field.onChange(value)}
                                        isOptionEqualToValue={(option, value) => option === value}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => {
                                                const { key, ...tagProps } = getTagProps({ index });
                                                return (
                                                    <Chip
                                                        key={index}
                                                        label={option}
                                                        {...tagProps}
                                                        style={{ backgroundColor: '#3f51b5', color: 'white' }} // Primary color for selected pills
                                                    />
                                                );
                                            })
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Genres"
                                                placeholder="Select Genres"
                                                error={!!errors.interests?.favourites?.genre}
                                                helperText={errors.interests?.favourites?.genre ? errors.interests.favourites.genre.message : ''}
                                                margin="normal"
                                                variant="outlined" // Make the autocomplete more sleek
                                                InputProps={{
                                                    ...params.InputProps,
                                                    style: {
                                                        borderRadius: '25px', // Make the autocomplete more sleek
                                                        padding: '5px' // Adjust padding for a sleek look
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </div>
                        <div style={{ flex: '0 0 33%', width: isMobile ? '100%' : '33%', padding: '10px' }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                            <Typography variant="h6">Director</Typography>
                            <Controller
                                name={`interests.favourites.director.privacy`}
                                control={control}
                                render={({ field }) => (
                                    <IconButton {...field} onClick={() => field.onChange(!field.value)}>
                                        {field.value ? (
                                            <VisibilityOffIcon style={{ color: 'red', fontSize: '1.4rem' }} />
                                        ) : (
                                            <VisibilityIcon style={{ color: 'green', fontSize: '1.4rem' }} />
                                        )}
                                    </IconButton>
                                )}
                            />
                        </Box>
                            <Mediaquery type="director" label={`${FavouritesLabels.director}`} onSelect={(selectedItem) => handleAddMedia(selectedItem, 'director')} />
                            <div className="gallery">
                                {media.director?.map((item, index) => (
                                    <div className="gallery-item" key={item._id || index}>
                                        <StyledPNTooltip
                                            title={item.personalNote || "No personal note"}
                                            arrow
                                            showEditIcon={true}
                                            item={item}
                                            onEdit={handleEditMedia} // This line passes the handleEditMedia function
                                            itemType={'director'}
                                        >
                                            <ImageWithNoContextMenu src={item.poster_path} alt={item.title} />
                                        </StyledPNTooltip>
                                        <IconButton className="remove-button" onClick={() => handleRemoveMedia(item._id, 'director')}>
                                            <span className="remove-icon">×</span>
                                        </IconButton>
                                        <div className="details">
                                            <Typography variant="subtitle1">{item.title}</Typography>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            label: 'Games/Books',
            content: (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row', flexWrap: 'wrap' }}>
                    <div style={{ flex: '0 0 33%', width: isMobile ? '100%' : '33%', padding: '10px' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                            <Typography variant="h6">Game</Typography>
                            <Controller
                                name={`interests.favourites.game.privacy`}
                                control={control}
                                render={({ field }) => (
                                    <IconButton {...field} onClick={() => field.onChange(!field.value)}>
                                        {field.value ? (
                                            <VisibilityOffIcon style={{ color: 'red', fontSize: '1.4rem' }} />
                                        ) : (
                                            <VisibilityIcon style={{ color: 'green', fontSize: '1.4rem' }} />
                                        )}
                                    </IconButton>
                                )}
                            />
                        </Box>
                            <Mediaquery type="game" label={`${FavouritesLabels.game}`} onSelect={(selectedItem) => handleAddMedia(selectedItem, 'game')} />
                            <div className="gallery">
                                {media.game?.map((item, index) => (
                                    <div className="gallery-item" key={item._id || index}>
                                        <StyledPNTooltip
                                            title={item.personalNote || "No personal note"}
                                            arrow
                                            showEditIcon={true}
                                            item={item}
                                            onEdit={handleEditMedia} // This line passes the handleEditMedia function
                                            itemType={'game'}
                                        >
                                            <ImageWithNoContextMenu src={item.poster_path} alt={item.title} />
                                        </StyledPNTooltip>
                                        <IconButton className="remove-button" onClick={() => handleRemoveMedia(item._id, 'game')}>
                                            <span className="remove-icon">×</span>
                                        </IconButton>
                                        <div className="details">
                                            <Typography variant="subtitle1">{item.title}</Typography>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{ flex: '0 0 33%', width: isMobile ? '100%' : '33%', padding: '10px' }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                            <Typography variant="h6">Book</Typography>
                            <Controller
                                name={`interests.favourites.book.privacy`}
                                control={control}
                                render={({ field }) => (
                                    <IconButton {...field} onClick={() => field.onChange(!field.value)}>
                                        {field.value ? (
                                            <VisibilityOffIcon style={{ color: 'red', fontSize: '1.4rem' }} />
                                        ) : (
                                            <VisibilityIcon style={{ color: 'green', fontSize: '1.4rem' }} />
                                        )}
                                    </IconButton>
                                )}
                            />
                        </Box>
                            <Mediaquery type="book" providedHelperText="If the book is in a specific language like Tamil, search for it using the title in that language (e.g., பொன்னியின் செல்வன்)" label={`${FavouritesLabels.book}`} onSelect={(selectedItem) => handleAddMedia(selectedItem, 'book')} />
                            <div className="gallery">
                                {media.book?.map((item, index) => (
                                    <div className="gallery-item" key={item._id || index}>
                                        <StyledPNTooltip
                                            title={item.personalNote || "No personal note"}
                                            arrow
                                            showEditIcon={true}
                                            item={item}
                                            onEdit={handleEditMedia} // This line passes the handleEditMedia function
                                            itemType={'book'}
                                        >
                                            <ImageWithNoContextMenu src={item.poster_path} alt={item.title} />
                                        </StyledPNTooltip>
                                        <IconButton className="remove-button" onClick={() => handleRemoveMedia(item._id, 'book')}>
                                            <span className="remove-icon">×</span>
                                        </IconButton>
                                        <div className="details">
                                            <Typography variant="subtitle1">{item.title}</Typography>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
    ];

    const qualificationSteps = [
        {
            label: 'Awards',
            content: (
                <>
                    {awardsFields.map((item, index) => (
                        <Grid container alignItems="flex-start" key={item.id} spacing={2} >
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    label="Award Name"
                                    {...register(`qualifications.awards.${index}.name`)}
                                    margin="normal"
                                    error={!!errors?.qualifications?.awards?.[index]?.name}
                                    helperText={errors?.qualifications?.awards?.[index]?.name?.message || ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    label="Year"
                                    {...register(`qualifications.awards.${index}.year`)}
                                    margin="normal"
                                    error={!!errors?.qualifications?.awards?.[index]?.year}
                                    helperText={errors?.qualifications?.awards?.[index]?.year?.message || ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    label="Awarded By"
                                    {...register(`qualifications.awards.${index}.awardedBy`)}
                                    margin="normal"
                                    error={!!errors?.qualifications?.awards?.[index]?.awardedBy}
                                    helperText={errors?.qualifications?.awards?.[index]?.awardedBy?.message || ''}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Controller
                                    name={`qualifications.awards.${index}.privacy`}
                                    control={control}
                                    render={({ field }) => (
                                        <IconButton {...field} onClick={() => field.onChange(!field.value)}>
                                            {field.value ? (
                                                <VisibilityOffIcon style={{ color: 'red' }} />
                                            ) : (
                                                <VisibilityIcon style={{ color: 'green' }} />
                                            )}
                                        </IconButton>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={1}>
                            <Tooltip title="Remove Award">
                                <IconButton onClick={() => removeAward(index)}>
                                    <DeleteForeverIcon style={{ color: 'red' }} />
                                </IconButton>
                            </Tooltip>
                            </Grid>
                        </Grid>
                    ))}
                    <Button onClick={() => appendAward({ name: '', year: '', awardedBy: '', privacy: false })} startIcon={<AddIcon />} variant="contained">
                        Add Award
                    </Button>
                </>
            ),
        },
        {
            label: 'Education',
            content: (
                <>
                    {educationFields.map((item, index) => (
                        <Grid container alignItems="flex-start" key={item.id} spacing={2}>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    label="Institution"
                                    {...register(`qualifications.education.${index}.institution`)}
                                    margin="normal"
                                    error={!!errors?.qualifications?.education?.[index]?.institution}
                                    helperText={errors?.qualifications?.education?.[index]?.institution?.message || ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    label="Degree"
                                    {...register(`qualifications.education.${index}.degree`)}
                                    margin="normal"
                                    error={!!errors?.qualifications?.education?.[index]?.degree}
                                    helperText={errors?.qualifications?.education?.[index]?.degree?.message || ''}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Controller
                                    name={`qualifications.education.${index}.privacy`}
                                    control={control}
                                    render={({ field }) => (
                                        <IconButton {...field} onClick={() => field.onChange(!field.value)}>
                                            {field.value ? (
                                                <VisibilityOffIcon style={{ color: 'red' }} />
                                            ) : (
                                                <VisibilityIcon style={{ color: 'green' }} />
                                            )}
                                        </IconButton>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={1} >
                            <Tooltip title="Remove Education">
                                <IconButton onClick={() => removeEducation(index)}>
                                    <DeleteForeverIcon style={{ color: 'red' }} />
                                </IconButton>
                            </Tooltip>
                            </Grid>
                        </Grid>
                    ))}
                    <Button onClick={() => appendEducation({ institution: '', degree: '', privacy: false })} startIcon={<AddIcon />} variant="contained">
                        Add Education
                    </Button>
                </>
            ),
        },
        {
            label: 'Work Experience',
            content: (
                <>
                    {workExperienceFields.map((item, index) => (
                        <Grid container alignItems="flex-start" key={item.id} spacing={2}>
                            <Grid item xs={12} md={3}>
                            <TextField
                                    fullWidth
                                    label="Company"
                                    {...register(`qualifications.workExperience.${index}.company`)}
                                    margin="normal"
                                    error={!!errors?.qualifications?.workExperience?.[index]?.company}
                                    helperText={errors?.qualifications?.workExperience?.[index]?.company?.message || ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    label="Position"
                                    {...register(`qualifications.workExperience.${index}.position`)}
                                    margin="normal"
                                    error={!!errors?.qualifications?.workExperience?.[index]?.position}
                                    helperText={errors?.qualifications?.workExperience?.[index]?.position?.message || ''}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Controller
                                    name={`qualifications.workExperience.${index}.privacy`}
                                    control={control}
                                    render={({ field }) => (
                                        <IconButton {...field} onClick={() => field.onChange(!field.value)}>
                                            {field.value ? (
                                                <VisibilityOffIcon style={{ color: 'red' }} />
                                            ) : (
                                                <VisibilityIcon style={{ color: 'green' }} />
                                            )}
                                        </IconButton>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Tooltip title="Remove Word">
                                    <IconButton onClick={() => removeWorkExperience(index)}>
                                        <DeleteForeverIcon style={{ color: 'red' }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    ))}
                    <Button onClick={() => appendWorkExperience({ company: '', position: '', privacy: false })} startIcon={<AddIcon />} variant="contained">
                        Add Work Experience
                    </Button>
                </>
            ),
        },
    ];

    const handleStepChange = (sectionIndex, stepIndex) => {
        setCurrentStep(prev => {
            if (!Array.isArray(prev)) {
                prev = [0, 0, 0, 0, 0];
            }
            const newSteps = [...prev];
            newSteps[sectionIndex] = stepIndex;
            return newSteps;
        });
    };

    const handleSectionChange = (event, newValue) => {
        setSelectedSection(newValue);
    };

    const handleUploadSuccess = (s3Url, index) => {
        setValue(`ownWorks.portfolio.${index}.poster`, s3Url, { shouldDirty: true, shouldValidate: true });
    };

    const handleToggle = (newStatus) => {
        setIsNewUser(newStatus)
        toggleIsNewUser(userId, newStatus);
    };

    return (
        <Box>
        <Box sx={{ position: 'absolute', top: 16, right: 16, zIndex: 1000, display: 'flex', alignItems: 'center'}}>
            {/* <Typography variant={isMobile ? "h7" : "h4"} component="h1" style={{ margin: 'auto' }}>
                Edit Profile
            </Typography> */}
            {/* <button onClick={() => handleToggle(!isNewUser2)}>Toggle</button> */}
            {/* <h1>public ID: {pubProfileLink}</h1>
            <h1>Is New User: {String(isNewUser)}</h1> */}
            {/* <IconButton onClick={()=> handleYTOpen()}>
                <HelpCenterIcon />
            </IconButton> */}
            <IconButton onClick={toggleTheme}>
            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
            <IconButton onClick={() => navigate('/')} sx={{ color: textColor }}>
                <HomeIcon />
            </IconButton>
            <IconButton
            onClick={() => navigate(`/profile/${pubProfileLink}`)}
            sx={{
              color: textColor,
              fontSize: { xs: "20px", sm: "24px", md: "28px", lg: "32px" },
            }}
          >
            <PersonIcon />
          </IconButton>
            <IconButton onClick={logout} sx={{ color: textColor }}>
                <ExitToAppIcon />
            </IconButton>
            <PublicShare pubProfileLink={pubProfileLink } /> {/* Insert the share button */}
            <Grid display="flex" flexDirection={"column"}>
            <Button type="submit" variant="contained" color="primary" disabled={saving} form="UserProfileForm">
                {saving ? 'Saving...' : isMobile? 'Save' : 'Save Profile'}
            </Button>
            {autosaveTime && <p style={{ fontSize: '10px', margin: '4px 0', opacity: 0.7, marginLeft:'20px' }}>Autosaved at: {autosaveTime}</p>}
            </Grid>
        </Box>
            <FormProvider {...methods}>
            {/* <YouTubeModal open={openyouTubeModal} handleClose={handleYTClose} videoId={videoId} /> */}
                <Container className="tabs-container" sx={{ backgroundColor: 'paperBackground', marginTop:'70px', height: 'calc(100vh - 70px)'}}>
                    <form onSubmit={handleSubmit(onSubmitProfile)} id="UserProfileForm">
                    <MemoizedStyledTabs value={selectedSection} onChange={handleSectionChange} variant="scrollable" scrollButtons="auto">
                        <StyledTooltip title={userDetailsProgress !== 100 ? userDetailsTooltip() : ''} arrow>
                            <MemoizedStyledTab label={
                                <MemoizedTabLabel>
                                    <span>User Details</span>
                                    <MemoizedProgressLabel completed={userDetailsProgress === 100}>
                                        {userDetailsProgress === 100 ? 'Completed' : `${userDetailsProgress}%`}
                                    </MemoizedProgressLabel>
                                </MemoizedTabLabel>
                            } />
                        </StyledTooltip>
                        <StyledTooltip title={favouritesTooltip(watchedInterests)} arrow>
                            <MemoizedStyledTab label={
                                <MemoizedTabLabel>
                                    <span>Favourites</span>
                                    <MemoizedProgressLabel completed={interestsProgress === 100}>
                                        {interestsProgress === 100 ? 'Completed' : `${interestsProgress}%`}
                                    </MemoizedProgressLabel>
                                </MemoizedTabLabel>
                            } />
                        </StyledTooltip>
                        <StyledTooltip title={qualificationsTooltip(watchedQualifications, focusField)} arrow>
                            <MemoizedStyledTab label={
                                <MemoizedTabLabel>
                                    <span>Qualifications</span>
                                    <MemoizedProgressLabel completed={qualificationsProgress === 100}>
                                        {qualificationsProgress === 100 ? 'Completed' : `${qualificationsProgress}%`}
                                    </MemoizedProgressLabel>
                                </MemoizedTabLabel>
                            } />
                        </StyledTooltip>
                        <StyledTooltip title={ownWorksTooltip(watchedOwnWorks, focusField)} arrow>
                            <MemoizedStyledTab label={
                                <MemoizedTabLabel>
                                    <span>My Stories</span>
                                    <MemoizedProgressLabel >
                                        {`${ownWorksCount} Stories`}
                                    </MemoizedProgressLabel>
                                </MemoizedTabLabel>
                            } />
                        </StyledTooltip>
                        <StyledTooltip title={workedInTooltip(watchedWorkedIn, focusField)} arrow>
                            <MemoizedStyledTab label={
                                <MemoizedTabLabel>
                                    <span>Experience</span>
                                    <MemoizedProgressLabel completed={workedInCount >= 4}>
                                        {workedInCount >= 4 ? 'Completed' : `${workedInCount} Works`}
                                    </MemoizedProgressLabel>
                                </MemoizedTabLabel>
                            } />
                        </StyledTooltip>
                        <MemoizedStyledTab label={
                            <MemoizedTabLabel>
                                <span>Story Of My Life</span>
                            </MemoizedTabLabel>
                        } />
                    </MemoizedStyledTabs>
                        <Box my={4} className="form-content">
                        {selectedSection === 0 && (
                                <>
                                    <Stepper nonLinear activeStep={currentStep[0]} orientation="vertical">
                                        {userDetailsSteps.map((step, index) => (
                                            <Step key={index}>
                                                <StepButton onClick={() => handleStepChange(0, index)}>{step.label}</StepButton>
                                                <StepContent>{step.content}</StepContent>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </>
                            )}
                            {selectedSection === 1 && (
                                <>
                                <Stepper nonLinear activeStep={currentStep[1]} orientation="vertical">
                                    {favouriteSteps.map((step, index) => (
                                        <Step key={index}>
                                            <StepButton onClick={() => handleStepChange(1, index)}>{step.label}</StepButton>
                                            <StepContent>{step.content}</StepContent>
                                        </Step>
                                    ))}
                                </Stepper>
                                <PersonalNote
                                    open={personalNoteModalOpen}
                                    handleClose={handleClosePersonalNote}
                                    mediaDetails={selectedMediaDetails}
                                    onSave={handleSaveNote}
                                    isEditMode={isEditMode}  // Pass the mode to the modal
                                />
                                </>
                            )}
                            {selectedSection === 2 && (
                                <>
                                <Box display='flex' justifyContent='flex-end' alignItems='center' width='100%' mb={2}>
                                    <Box display='flex' alignItems='center' mr={2}>
                                        <VisibilityIcon style={{ color: 'green' }} />
                                        <Typography variant="body2" component="span" style={{ marginLeft: '8px' }}>
                                            Public
                                        </Typography>
                                    </Box>
                                    <Box display='flex' alignItems='center'>
                                        <VisibilityOffIcon style={{ color: 'red' }} />
                                        <Typography variant="body2" component="span" style={{ marginLeft: '8px' }}>
                                            Private
                                        </Typography>
                                    </Box>
                                </Box>
                                <Stepper nonLinear activeStep={currentStep[2]} orientation="vertical">
                                    {qualificationSteps.map((step, index) => (
                                        <Step key={index}>
                                            <StepButton onClick={() => handleStepChange(2, index)}>{step.label}</StepButton>
                                            <StepContent>{step.content}</StepContent>
                                        </Step>
                                    ))}
                                </Stepper>
                                </>
                            )}
                            {selectedSection === 3 && (
                                <div>
                                    <Box display='flex' justifyContent='flex-end' alignItems='center' width='100%' mb={2}>
                                        <Box display='flex' alignItems='center' mr={2}>
                                            <VisibilityIcon style={{ color: 'green' }} />
                                            <Typography variant="body2" component="span" style={{ marginLeft: '8px' }}>
                                                Public
                                            </Typography>
                                        </Box>
                                        <Box display='flex' alignItems='center'>
                                            <VisibilityOffIcon style={{ color: 'red' }} />
                                            <Typography variant="body2" component="span" style={{ marginLeft: '8px' }}>
                                                Private
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {ownWorksFields.map((item, index) => (
                                        <Grid key={item.id}>
                                            <Box display="flex" alignItems="center" justifyContent="space-between" marginTop='-20px'>
                                                <Divider sx={{ color: 'grey', flexGrow: 1 }}>{`Story: ${getValues(`ownWorks.portfolio.${index}.name`)}`}</Divider>
                                                <Grid item xs={1}>
                                                    <Controller
                                                        name={`ownWorks.portfolio.${index}.privacy`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <IconButton {...field} onClick={() => field.onChange(!field.value)}>
                                                                {field.value ? (
                                                                    <VisibilityOffIcon style={{ color: 'red' }} />
                                                                ) : (
                                                                    <VisibilityIcon style={{ color: 'green' }} />
                                                                )}
                                                            </IconButton>
                                                        )}
                                                    />
                                                </Grid>
                                                <Tooltip title="Remove Story">
                                                    <IconButton onClick={() => removeOwnWorks(index)}>
                                                        <DeleteForeverIcon style={{ color: 'red' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                            <Grid container display="flex"  spacing={2} >
                                                <Grid item xs={4} sm={3} > 
                                                    {watchedOwnWorks.length > index && watchedOwnWorks[index]?.poster ? (
                                                        <img
                                                            key={watchedOwnWorks[index].poster}
                                                            src={watchedOwnWorks[index].poster}
                                                            alt="Poster"
                                                        />
                                                    ) : (
                                                        <CloudUploadIcon className="upload-icon" />
                                                    )}
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'flex-end', marginTop: '-20px', marginBottom: '10px' }}>
                                                        <UploadModel
                                                            key={`${index}-${getValues(`ownWorks.portfolio.${index}.logLine`)}-${getValues(`ownWorks.portfolio.${index}.genre`)}-${getValues(`ownWorks.portfolio.${index}.name`)}`}
                                                            upload_state={!watchedOwnWorks[index]?.poster}
                                                            initialLogline={getValues(`ownWorks.portfolio.${index}.logLine`) || ''}
                                                            initialFormat={getValues(`ownWorks.portfolio.${index}.format`) || ''}
                                                            initialGenre={getValues(`ownWorks.portfolio.${index}.genre`) || ''}
                                                            initialTitle={getValues(`ownWorks.portfolio.${index}.name`) || ''}
                                                            remainingGenerations={100}
                                                            onSuccess={(s3Url) => handleUploadSuccess(s3Url, index)}
                                                        />
                                                        <Button onClick={() => setValue(`ownWorks.portfolio.${index}.poster`, '')}><CloseIcon variant="filled" sx={{ marginTop: 'auto' }} /> </Button>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        fullWidth
                                                        label="Title"
                                                        {...register(`ownWorks.portfolio.${index}.name`)}
                                                        margin="normal"
                                                        size="small"
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Log Line"
                                                        {...register(`ownWorks.portfolio.${index}.logLine`)}
                                                        margin="normal"
                                                        size="small"
                                                        multiline
                                                        minRows={4}
                                                        maxRows={5}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Genre"
                                                        {...register(`ownWorks.portfolio.${index}.genre`)}
                                                        margin="normal"
                                                        size="small"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                <Controller
                                                        name={`ownWorks.portfolio.${index}.format`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Autocomplete
                                                                {...field}
                                                                options={["Feature Films", "Short Films", "Documentaries", "Web Series", "Television Series", "Animated Series", "Video Games", "Interactive Media", "Transmedia Storytelling", "Experimental Formats"]                                                                }
                                                                getOptionLabel={(option) => option}
                                                                value={field.value || null}
                                                                onChange={(event, value) => field.onChange(value)}
                                                                isOptionEqualToValue={(option, value) => option === value || value === null}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Format"
                                                                        placeholder=""
                                                                        margin="normal"
                                                                        size="small"
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                    <Controller
                                                        name={`ownWorks.portfolio.${index}.status`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Autocomplete
                                                                {...field}
                                                                options={["Available", "Acquired", "In Production"]}
                                                                getOptionLabel={(option) => option}
                                                                value={field.value || null}
                                                                onChange={(event, value) => field.onChange(value)}
                                                                isOptionEqualToValue={(option, value) => option === value || value === null}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Status"
                                                                        placeholder=""
                                                                        margin="normal"
                                                                        size="small"
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                    {watchedOwnWorks[index]?.status === "Acquired" && (
                                                        <Controller
                                                            name={`ownWorks.portfolio.${index}.credit`}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Autocomplete
                                                                    {...field}
                                                                    multiple
                                                                    options={["Story", "Screenplay", "Additional Screenplay", "Dialogue", "Doctoring", "Script Consultation"]}
                                                                    getOptionLabel={(option) => option}
                                                                    value={field.value || []}
                                                                    onChange={(event, value) => field.onChange(value)}
                                                                    isOptionEqualToValue={(option, value) => option === value || value.length === 0}
                                                                    renderTags={(value, getTagProps) =>
                                                                        value.map((option, index) => {
                                                                            const { key, ...tagProps } = getTagProps({ index });
                                                                            return <Chip key={index} label={option} {...tagProps} />;
                                                                        })
                                                                    }
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            label="Credit"
                                                                            placeholder=""
                                                                            margin="normal"
                                                                            size="small"
                                                                        />
                                                                    )}
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                    <Button onClick={() => appendOwnWorks({ poster: '', name: '', logLine: '', genre: '', format: '', privacy: false, status: '', credit: [], proof_link: '' })} startIcon={<AddIcon />} variant="contained">
                                        Add Work
                                    </Button>
                                </div>
                            )}

                            {selectedSection === 4 && (
                                <div>
                                <Box display='flex' justifyContent='flex-end' alignItems='center' width='100%' mb={2}>
                                        <Box display='flex' alignItems='center' mr={2}>
                                            <VisibilityIcon style={{ color: 'green' }} />
                                            <Typography variant="body2" component="span" style={{ marginLeft: '8px' }}>
                                                Public
                                            </Typography>
                                        </Box>
                                        <Box display='flex' alignItems='center'>
                                            <VisibilityOffIcon style={{ color: 'red' }} />
                                            <Typography variant="body2" component="span" style={{ marginLeft: '8px' }}>
                                                Private
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {workedInFields.map((item, index) => (
                                        <Grid key={item.id}>
                                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                                <Divider sx={{ color: 'grey', flexGrow: 1 }}>{`Story: ${getValues(`workedIn.credited.${index}.name`)}`}</Divider>
                                                <Grid item xs={1}>
                                                    <Controller
                                                        name={`workedIn.credited.${index}.privacy`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <IconButton {...field} onClick={() => field.onChange(!field.value)}>
                                                                {field.value ? (
                                                                    <VisibilityOffIcon style={{ color: 'red' }} />
                                                                ) : (
                                                                    <VisibilityIcon style={{ color: 'green' }} />
                                                                )}
                                                            </IconButton>
                                                        )}
                                                    />
                                                </Grid>
                                                <Tooltip title="Remove Experience">
                                                    <IconButton onClick={() => removeWorkedIn(index)}>
                                                        <DeleteForeverIcon style={{ color: 'red' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                            <Grid container key={item.id} spacing={2}>
                                                <Grid item xs={12} sm={3} marginTop={'-15px'}>
                                                    <TextField
                                                        fullWidth
                                                        label="Title"
                                                        {...register(`workedIn.credited.${index}.name`)}
                                                        margin="normal"
                                                        size="small"
                                                        error={!!errors?.workedIn?.credited?.[index]?.name}
                                                        helperText={errors?.workedIn?.credited?.[index]?.name?.message || ""}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Description"
                                                        {...register(`workedIn.credited.${index}.logLine`)}
                                                        margin="normal"
                                                        size="small"
                                                        multiline
                                                        minRows={4}
                                                        maxRows={10} // Adjust maxRows as needed
                                                        error={!!errors?.workedIn?.credited?.[index]?.logLine}
                                                        helperText={errors?.workedIn?.credited?.[index]?.logLine?.message || ""}
                                                    />
                                                    <Controller
                                                        name={`workedIn.credited.${index}.format`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Autocomplete
                                                                {...field}
                                                                options={["Feature Films", "Short Films", "Documentaries", "Web Series", "Television Series", "Animated Series", "Video Games", "Interactive Media", "Transmedia Storytelling", "Experimental Formats"]                                                                }
                                                                getOptionLabel={(option) => option}
                                                                value={field.value || null}
                                                                onChange={(event, value) => field.onChange(value)}
                                                                isOptionEqualToValue={(option, value) => option === value || value === null}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Format"
                                                                        placeholder=""
                                                                        margin="normal"
                                                                        size="small"
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={5}>
                                                    <TextField
                                                        fullWidth
                                                        label="Proof Link"
                                                        value={item.proof_link || ''}
                                                        onChange={(event) => handleMetaURLchange(event, { fieldArrayName: 'workedIn.credited', index })}
                                                        size="small"
                                                        error={!!errors?.workedIn?.credited?.[index]?.proof_link}
                                                        helperText={errors?.workedIn?.credited?.[index]?.proof_link?.message || "Tip: Paste a link to see"}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => handleMetaURLchange({ target: { value: '' } }, { fieldArrayName: 'workedIn.credited', index })}
                                                                    >
                                                                        <ClearIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                    <MetaPreview url={item.proof_link} />
                                                </Grid>
                                            </Grid>
                                            
                                        </Grid>
                                    ))}
                                    <Button onClick={() => appendWorkedIn({ name: '', logLine: '', genre: '', format: '', privacy: false })} startIcon={<AddIcon />} variant="contained">
                                        Add Worked In
                                    </Button>
                                </div>
                            )}
                            {selectedSection === 5 && (
                                <StoryOfMyLifeComponent />
                            )}
                        </Box>
                    </form>
                    {/* <Joyride
                        steps={joyrideSteps}
                        run={joyrideRun}
                        stepIndex={joyrideStepIndex}
                        callback={handleJoyrideCallback}
                        continuous
                        scrollToFirstStep
                        showProgress
                        showSkipButton
                        styles={{
                            options: {
                                zIndex: 10000,
                            },
                            tooltip: {
                                maxWidth: '80%', // Set a max-width but allow it to be dynamic
                                width: 'auto', // Ensure the width is dynamic
                            }
                        }}
                    /> */}
                    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)} message="Profile saved successfully" />
                </Container>
            </FormProvider>
        </Box>
    );
}

export default ProfileForm;