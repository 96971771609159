import React, { useState, useEffect } from 'react';
import { useAxios } from '../../contexts/AxiosContext';
import { Box, Grid, Typography, Card, Backdrop, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import { useTheme } from '../../contexts/ThemeContext';

const PosterCard = styled(Card)(({ theme }) => ({
  // position: 'relative',
  // width: '100%',
  height: 500,
  perspective: '1000px',
  cursor: 'pointer',
}));

const PosterInner = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFlipped',
})(({ isFlipped }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  transformStyle: 'preserve-3d',
  transition: 'transform 0.6s',
  transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
}));

const PosterFront = styled(Box)({
  height: '100%',
  backfaceVisibility: 'hidden',
  overflow: 'hidden', // Add this line to ensure the image doesn't overflow

});

const PosterBack = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  backfaceVisibility: 'hidden',
  transform: 'rotateY(180deg)',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const FullscreenCard = styled(Box)({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85vw',
  height: '85vh',
  zIndex: 1300,
  perspective: '1000px',
});

const StoriesWall = () => {
  const axios = useAxios();
  const [stories, setStories] = useState([]);
  const [flippedCard, setFlippedCard] = useState(null);
  const [animatedText, setAnimatedText] = useState({});
  const [reachOutCounts, setReachOutCounts] = useState({});
  const { theme, toggleTheme } = useTheme();
  const [AiPosters, setAiPosters] = useState()
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPoster, setSelectedPoster] = useState(null);

  const handleClickOpendialog = (poster) => {
      setSelectedPoster(poster);
      setOpenDialog(true);
  };

  const handleClosedialog = () => {
      setOpenDialog(false);
      setSelectedPoster(null);
  };

  useEffect(() => {
    const fetchStories = async () => {
      const response = await axios.get('/admin/mystories');
      setStories(response.data.filter(story => story.poster)); // Filter out stories without posters
    };
    fetchStories();
  }, [axios]);

  useEffect(() => {
    const fetchaiposters = async () => {
      const response = await axios.get('/admin/aiposter');
      setAiPosters(response); // Filter out stories without posters
      // console.log("poster: ",response)
    };
    fetchaiposters();
  }, [axios]);

  useEffect(() => {
    const fetchReachOutCounts = async () => {
      const response = await axios.get('/admin/reachouts');
      const counts = response.data.reduce((acc, reachOut) => {
        acc[reachOut.userId] = (acc[reachOut.userId] || 0) + 1;
        return acc;
      }, {});
      setReachOutCounts(counts);
    };
    fetchReachOutCounts();
  }, [axios]);

  const handleCardClick = (uniqueKey, story) => {
    setFlippedCard(uniqueKey);

    if (!animatedText[uniqueKey]) {
      animateText(uniqueKey, story.logLine);
    }
  };

  const handleClose = () => {
    setFlippedCard(null);
  };

  const animateText = (id, text) => {
    let displayText = '';
    const interval = 800 / text.length; // Duration of 2 seconds
    let index = 0;

    const intervalId = setInterval(() => {
      displayText += text[index];
      setAnimatedText((prev) => ({ ...prev, [id]: displayText }));
      index += 1;
      if (index === text.length) {
        clearInterval(intervalId);
      }
    }, interval);
  };

  const getFontSize = (text) => {
    const wordCount = text.split(' ').length;
    if (wordCount <= 100) return '1.2rem';
    if (wordCount <= 150) return '0.9rem';
    if (wordCount <= 200) return '0.8rem';
    return '1.0rem';
};

const animatedTextContent = animatedText[flippedCard] || '';
const fontSize = getFontSize(animatedTextContent);

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        {stories.map((story, index) => {
          const uniqueKey = `${story._id}-${index}`; // Define uniqueKey here
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={uniqueKey}>
              <PosterCard onClick={() => handleCardClick(uniqueKey, story)}>
                <PosterInner isFlipped={flippedCard === uniqueKey}>
                  <PosterFront>
                    <img
                      src={story.poster}
                      alt={story.name}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: '8px',
                      }}
                    />
                  </PosterFront>
                  <PosterBack>
                  <Typography variant="h6" gutterBottom>
                    {story.name}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {animatedText[story._id] || ''}
                  </Typography>
                  <Typography variant="body2">
                    Genre: {story.genre}
                  </Typography>
                  <Typography variant="body2">
                    Status: {story.status}
                  </Typography>
                  <Typography variant="body2">
                    Reach Outs: {reachOutCounts[story.userId] || 0}
                  </Typography>
                </PosterBack>
              </PosterInner>
            </PosterCard>
          </Grid>
          );
        })}
      </Grid>

      {flippedCard && (
      <Backdrop open={Boolean(flippedCard)} sx={{ zIndex: 1200 }} onClick={handleClose}>
          <FullscreenCard onClick={(e) => e.stopPropagation()}>
              <PosterInner isFlipped={true}>
                  <PosterBack sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'flex-start', overflow: 'hidden' }}>
                      <Box sx={{ width: { xs: '100%', md: '30%' }, height: '300px' }}>
                          <img
                              src={stories[parseInt(flippedCard.split('-')[1])].poster}
                              alt={stories[parseInt(flippedCard.split('-')[1])].name}
                              style={{
                                  width: '100%',
                                  height: 'auto',
                                  maxHeight: '500px',  // Ensures the image doesn't exceed 500px height on larger screens
                                  objectFit: 'cover',
                                  borderRadius: '8px',
                              }}
                          />
                      </Box>

                      <link href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Playfair+Display:wght@700&display=swap" rel="stylesheet" />
                      <Box sx={{
                          width: { xs: '100%', md: '70%' },
                          margin: 'auto',
                          padding: '20px',
                          backgroundColor: theme.palette.background.paper,
                          color: theme.palette.text.primary,
                          boxShadow: theme.shadows[1],
                          fontFamily: 'Roboto, sans-serif',
                          transition: 'all 0.3s ease-in-out',
                          textRendering: 'optimizeLegibility',
                          WebkitFontSmoothing: 'antialiased',
                          overflowY: 'auto',  // Enable vertical scrolling
                          maxHeight: '500px', // Limit the height to enable scrolling
                          marginTop:"0px"
                      }}>
                          <Typography variant="h6" gutterBottom sx={{
                              fontFamily: 'Montserrat, sans-serif',
                              color: theme.palette.text.primary,
                              mb: 2,
                              fontSize: { xs: '6vw', md: '4vw' },
                              fontWeight: '700',
                          }}>
                              {stories[parseInt(flippedCard.split('-')[1])].name}
                          </Typography>
                          <Typography variant="body2" gutterBottom sx={{
                              mb: '8px',
                              fontSize: fontSize,
                          }}>
                              {animatedText[flippedCard] || ''}
                          </Typography>
                          <Box sx={{
                              display: 'flex',
                              flexDirection: { xs: 'column', sm: 'row' },
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              width: '100%',
                              py: 1,
                          }}>
                              <Typography variant="body2" sx={{
                                  fontSize: '0.8rem',
                                  mx: 1,
                              }}>
                                  Genre: {stories[parseInt(flippedCard.split('-')[1])].genre}
                              </Typography>
                              <Typography variant="body2" sx={{
                                  fontSize: '0.8rem',
                                  mx: 1,
                              }}>
                                  Status: {stories[parseInt(flippedCard.split('-')[1])].status}
                              </Typography>
                              <Typography variant="body2" sx={{
                                  fontSize: '0.8rem',
                                  mx: 1,
                              }}>
                                  Reach Outs: {reachOutCounts[stories[parseInt(flippedCard.split('-')[1])].userId] || 0}
                              </Typography>
                              <Typography variant="body2" sx={{
                                  fontSize: '0.8rem',
                                  mx: 1,
                              }}>
                                  Author: {stories[parseInt(flippedCard.split('-')[1])].username}
                              </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', overflowX: 'auto', mt: 2 }}>
                              {AiPosters.data && AiPosters.data.map((poster) => (
                                  stories[parseInt(flippedCard.split('-')[1])].name === poster.title && (
                                      <Box key={poster._id} sx={{ mr: 1, cursor: 'pointer' }} onClick={() => handleClickOpendialog(poster)}>
                                          <img
                                              src={poster.imagePath}
                                              alt={poster.title}
                                              style={{ width: '100px', height: '150px', objectFit: 'cover' }}
                                          />
                                      </Box>
                                  )
                              ))}
                          </Box>
                      </Box>
                  </PosterBack>
              </PosterInner>
          </FullscreenCard>
          <Dialog
            open={openDialog}
            onClose={(e) => {
              e.stopPropagation(); // Prevent event propagation
              handleClosedialog();
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
              <DialogTitle sx={{ m: 0, p: 2, position: 'relative', textAlign: 'center' }}>
                  <Typography variant="h6" gutterBottom sx={{
                      fontFamily: 'Montserrat, sans-serif',
                      color: 'inherit',
                      fontSize: '1.5rem',
                      fontWeight: '700',
                  }}>
                      {selectedPoster?.title}
                  </Typography>
                  <IconButton
                      aria-label="close"
                      onClick={handleClosedialog}
                      sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                      }}
                  >
                      <CloseIcon />
                  </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                  <img
                      src={selectedPoster?.imagePath}
                      alt={selectedPoster?.title}
                      style={{ width: 'auto', height: '500px' }}
                  />
              </DialogContent>
          </Dialog>
      </Backdrop>
)}

    </Box>
  );
};

export default StoriesWall;
