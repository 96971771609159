import { useAxios } from '../../contexts/AxiosContext';
import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Box, Typography } from "@mui/material";
import { useTheme } from '../../contexts/ThemeContext';
import { format, parseISO } from 'date-fns';


const SeriesCarouselCard = ({ seriesData }) => {
    const { theme } = useTheme();
    const [series, setSeries] = useState(seriesData);
    const renderMovieItems = (items) => (
        items.map((item, index) => {
            let releaseDate = 'N/A';
            if (item.release_date) {
                try {
                    const date = parseISO(item.release_date);
                    releaseDate = format(date, 'dd-MMM-yyyy');
                } catch (error) {
                    console.error('Invalid date format:', item.release_date, error);
                }
            }

            return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            justifyContent: 'space-between',
                            borderRadius: 1,
                            boxShadow: 1,
                            overflow: 'hidden',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                overflow: 'hidden',
                            }}
                        >
                            <img src={item.poster_path} alt={item.title || item.name} style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: 4 }} />
                        </Box>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                flexGrow: 1,
                                backgroundColor: theme.palette.background.paper,
                            }}
                        >
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.text.primary, marginBottom: 1 }}>
                                    {item.title || item.name}
                                </Typography>
                                <Typography variant="body2" sx={{ color: theme.palette.text.secondary, marginBottom: 1 }}>
                                    {item.personalNote}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                                    Release: {releaseDate}
                                </Typography>
                                <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                                    Language: {item.original_language.toUpperCase()}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            );
        })
    );

    return (
        <Grid container spacing={2}>
            {renderMovieItems(series)}
        </Grid>
    );
};


export default SeriesCarouselCard;