import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Select, MenuItem, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAxios } from '../../contexts/AxiosContext';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70vw',
  height: '90vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2
};

const ReachOutModal = ({ open, handleClose, writerName, ownWorks, userId }) => {
    const [option, setOption] = useState('');
    const [selectedStory, setSelectedStory] = useState('');
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [designation, setDesignation] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const axios = useAxios()

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = {
            userId: userId, // Assuming profile contains user details including id
            option,
            selectedStory: option === 'Enquire a story' ? selectedStory : null,
            name,
            email,
            company,
            designation,
            message
        };
    
        try {
            const res = await axios.post('/public/reachout', response);
            handleClose()
        // console.log(res.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="reach-out-modal-title"
            aria-describedby="reach-out-modal-description"
        >
            <Box sx={modalStyle}>
                <Box display="flex" justifyContent="space-between" alignItems="center" >
                    <Typography id="reach-out-modal-title" variant="h6" component="h2">
                        Reach Out
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Typography variant="h6">{writerName}</Typography>
                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <Select
                        value={option}
                        onChange={(e) => setOption(e.target.value)}
                        displayEmpty
                    >
                        <MenuItem value=""><em>Select an option</em></MenuItem>
                        <MenuItem value="Hire the Writer">Hire the Writer</MenuItem>
                        <MenuItem value="Enquire a story">Enquire a story</MenuItem>
                    </Select>
                    {option === 'Enquire a story' && (
                        <Select
                            value={selectedStory}
                            onChange={(e) => setSelectedStory(e.target.value)}
                            displayEmpty
                        >
                            <MenuItem value=""><em>Select a story</em></MenuItem>
                            {ownWorks.portfolio.map((work, index) => (
                                <MenuItem key={index} value={work.name}>{work.name}</MenuItem>
                            ))}
                        </Select>
                    )}
                    <TextField
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <TextField
                        label="Company / Organisation"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                    />
                    <TextField
                        label="Designation"
                        value={designation}
                        onChange={(e) => setDesignation(e.target.value)}
                    />
                    <TextField
                        label="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <TextField
                        label="Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        multiline
                        rows={3}
                        required
                    />
                    <Button type="submit" variant="contained" color="primary">
                        Submit
                    </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default ReachOutModal;
