import React, { useEffect, useState } from "react";
import { Typography, Tooltip, Box, Grid, useMediaQuery } from "@mui/material";
import { PieChart } from '@mui/x-charts/PieChart';
import { useTheme } from '../../contexts/ThemeContext';

const getColor = (skill) => {
    switch (skill) {
        case 'listen':
            return '#fff800'; // Yellow
        case 'read':
            return '#4caf50'; // Green
        case 'write':
            return '#2196f3'; // Blue
        case 'speak':
            return '#ff9800'; // Orange
        default:
            return '#e0e0e0'; // Grey for background
    }
};

const renderLanguageProficiency = (data, theme, chartSize) => (
    <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
            {data.map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index} sx={{ textAlign: 'center' }}>
                    <Tooltip title={item.language}>
                        <Typography variant="body1" sx={{ fontFamily: 'Lilita One, sans-serif', fontWeight: 'bold', marginBottom: 1 }}>
                            {item.language}
                        </Typography>
                    </Tooltip>
                    <PieChart
                        series={[
                            {
                                data: [{ label: 'Listen', value: item.listen, color: getColor('listen') }],
                                innerRadius: chartSize / 15,
                                outerRadius: chartSize / 10,
                                paddingAngle: 0,
                                cornerRadius: 0,
                                startAngle: -90,
                                endAngle: ((item.listen / 12) * 360) - 90,
                            },
                            {
                                data: [{ label: 'Read', value: item.read, color: getColor('read') }],
                                innerRadius: chartSize / 10,
                                outerRadius: chartSize / 7.5,
                                paddingAngle: 0,
                                cornerRadius: 0,
                                startAngle: -90,
                                endAngle: ((item.read / 12) * 360) - 90,
                            },
                            {
                                data: [{ label: 'Write', value: item.write, color: getColor('write') }],
                                innerRadius: chartSize / 7.5,
                                outerRadius: chartSize / 5,
                                paddingAngle: 0,
                                cornerRadius: 0,
                                startAngle: -90,
                                endAngle: ((item.write / 12) * 360) - 90,
                            },
                            {
                                data: [{ label: 'Speak', value: item.speak, color: getColor('speak') }],
                                innerRadius: chartSize / 5,
                                outerRadius: chartSize / 3.6,
                                paddingAngle: 0,
                                cornerRadius: 0,
                                startAngle: -90,
                                endAngle: ((item.speak / 12) * 360) - 90,
                            }
                        ]}
                        width={chartSize*1.1}
                        height={chartSize}
                        legend={{ hidden: true }}
                    />
                </Grid>
            ))}
        </Grid>
    </Box>
);

const LanguagesCard = ({ profile }) => {
    const { theme } = useTheme();
    const [languages, setLanguages] = useState([]);

    // Use MediaQuery to get screen size
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    // Determine chart size based on screen size
    const chartSize = isXs ? 180 : isSm ? 200 : isMd ? 200 : 200;
    console.log(chartSize)

    useEffect(() => {
        if (profile && profile.userDetails && profile.userDetails.languages_v2) {
            const processedLanguages = profile.userDetails.languages_v2.map(lang => ({
                language: lang.language,
                listen: lang.lsrw.l ? lang.lsrw.l : 0,
                read: lang.lsrw.r ? lang.lsrw.r : 0,
                write: lang.lsrw.w ? lang.lsrw.w : 0,
                speak: lang.lsrw.s ? lang.lsrw.s : 0,
            }));
            setLanguages(processedLanguages.sort((a, b) => {
                const aMax = Math.max(a.listen, a.read, a.write, a.speak);
                const bMax = Math.max(b.listen, b.read, b.write, b.speak);
                return bMax - aMax;
            }).slice(0, 5));
        }
    }, [profile]);

    return (
        <Box sx={{ width: '100%', padding: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                Languages
            </Typography>
            <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                    <Box sx={{ width: 15, height: 15, backgroundColor: getColor('listen'), borderRadius: '4px', marginRight: 0.5 }}></Box>
                    <Typography variant="body2" sx={{ marginRight: 1 }}>Listen</Typography>
                    <Box sx={{ width: 15, height: 15, backgroundColor: getColor('read'), borderRadius: '4px', marginRight: 0.5 }}></Box>
                    <Typography variant="body2" sx={{ marginRight: 1 }}>Read</Typography>
                    <Box sx={{ width: 15, height: 15, backgroundColor: getColor('write'), borderRadius: '4px', marginRight: 0.5 }}></Box>
                    <Typography variant="body2" sx={{ marginRight: 1 }}>Write</Typography>
                    <Box sx={{ width: 15, height: 15, backgroundColor: getColor('speak'), borderRadius: '4px', marginRight: 0.5 }}></Box>
                    <Typography variant="body2">Speak</Typography>
                </Box>
            </Grid>
            {renderLanguageProficiency(languages, theme, chartSize)}
        </Box>
    );
};

export default LanguagesCard;
