import AccessTimeIcon from '@mui/icons-material/AccessTime';
import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Overlay = styled(Box)(({ theme, show }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  padding: theme.spacing(2),
  background: 'rgba(0, 0, 0, 0.7)',
  color: '#fff',
  transform: show ? 'translateY(0%)' : 'translateY(100%)',
  transition: 'transform 0.5s ease-in-out',
  textAlign: 'left',  // Ensure text is left-aligned
  fontFamily: 'Montserrat, sans-serif',
  '& .title': {
    fontWeight: 'bold',
    fontSize: '1.2rem',  // Adjust size as needed for your design
  },
  '& .logline': {
    fontSize: '0.85rem',  // Smaller text size for the logline
    marginBottom: theme.spacing(2),  // Add a gap between logline and the date
  },
  '& .date': {
    position: 'absolute',
    bottom: theme.spacing(1),
    left: theme.spacing(2),
    fontSize: '0.75rem',  // Small text size for copyright-like appearance
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '1rem',
      marginRight: theme.spacing(0.5),
    }
  }
}));

const ImageContainer = styled('div')({
  width: '100%',
  height: '100%',
  backgroundSize: 'cover'
});

const Carousel = ({ data={} }) => {
  const [hovered, setHovered] = useState(null);
  if (data.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          textAlign: 'center',
          fontFamily: 'Montserrat, sans-serif',
        }}
      >
        <Typography variant="h4">You don't have any history to view</Typography>
      </Box>
    );
  }
  return (
    <Box sx={{ overflowY: 'auto', padding: 2 }}>
      <Grid container spacing={4}>
        {data.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item._id}>
            <Box
              sx={{
                position: 'relative',
                cursor: 'pointer',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 300,
                backgroundImage: `url(${item.imagePath})`,
                padding: 0,  // Ensure no padding
                borderRadius: '20px',  // Ensure no border-radius if not needed
                background: 'none',  // Remove any background if not needed
                backdropFilter: 'none',  // Remove backdrop filter if not needed
              }}
              onMouseEnter={() => setHovered(item._id)}
              onMouseLeave={() => setHovered(null)}
            >
              <ImageContainer style={{ backgroundImage: `url(${item.imagePath})`}} />
              <Overlay show={hovered === item._id}>
                {/* <Typography className="title">{item.title}</Typography> */}
                {/* <Typography className="logline">{item.logLine}</Typography> */}
                <Box className="date">
                  <AccessTimeIcon />
                  {new Date(item.createdAt).toLocaleDateString('en-GB', {
                    day: 'numeric', month: 'long', year: '2-digit'
                  })}
                </Box>
              </Overlay>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Carousel;
