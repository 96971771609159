import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import { LoginProvider } from './contexts/LoginContext';
import Navbar from './components/Navbar';
import LandingPage from './components/LandingPage';
import UserProfile from './components/UserProfile';
import ProfileForm from './components/ProfileForm';
import SignUp from './components/SignUp';
import PrivateRoute from './components/PrivateRoute';
import { AxiosProvider } from './contexts/AxiosContext';
import VerifyEmail from './components/VerifyEmail';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import { Test } from './Playground/sparklesDemo';
import { WelcomeUserPage } from './components/WelcomeUserPage';
import PublicViewTest from './components/customViews/PublicViewTest';
import CustomView from './components/customViews/CustomView';
import PublicView from './components/customViews/PublicView';
import AdminDashboard from './components/AdminConsole/AdminDashboard';
import NetworkGraph from './Playground/NetworkGraph';
import SocialGraph from './Playground/SocialGraph';
import ProfileFormComponent from './components/ProfileForm/ProfileFormComponent';
import ConsentPage from "./components/legal/ConsentPage"
import PrivacyPolicy from './components/legal/PrivacyPolicy';
import TermsAndCondition from './components/legal/TermsAndCondition';

// import Quillplayground from './components/Quillplayground/Quillplaygroud';
// import TestMetaPreview from './Playground/TestMetaPreview';
// import Tester from './Playground/Tester';

const AppContent = () => {
    const location = useLocation();
    const excludeNavbarPaths = [
        '/test', '/text', '/', '/signup', '/forgot-password',
        '/reset-password', '/verify', '/flipwordsdemo', '/backgroundBeamsDemo'
    ];

    // Check if the current path matches any path in excludeNavbarPaths or is a dynamic path like /profile/:userId
    const isExcludedPath = excludeNavbarPaths.some(path => location.pathname.startsWith(path)) ||
        /^\/profile\/[^/]+$/.test(location.pathname);

    return (
        <>
            {!isExcludedPath && <Navbar />}
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/network" element={<NetworkGraph />} />
                <Route path="/socialgraph" element={<SocialGraph />} />
                {/* <Route path='/quillplayground' element={<Quillplayground/>} />*/}
                <Route path='/p/:pubProfileLink' element={<PublicView />} />
                <Route path='/profile/:pubProfileLink' element={<PublicView />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="/verify/:token" element={<VerifyEmail />} />
                <Route path="/buildprofile" element={<ProfileFormComponent />} />
                <Route path="/testing" element={<Test />} />
                <Route path="/text" element={<WelcomeUserPage />} />
                {/* <Route path="/tester" element={<Tester />} /> */}
                <Route
                    path="/admin"
                    element={<PrivateRoute element={<AdminDashboard />} />}
                />
                <Route
                    path="/profile/:userId"
                    element={<PrivateRoute element={<UserProfile />} />}
                />
                <Route
                    path="/edit-profile/:userId"
                    element={<PrivateRoute element={<ProfileForm />} />}
                />
                <Route
                    path="/t/:pubProfileLink"
                    element={<PrivateRoute element={<PublicViewTest />} />}
                />

                {/* Public routes that do not require authentication */}
                <Route path="/consent" element={<ConsentPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-and-conditions" element={<TermsAndCondition />} />



                {/* Catch-all route to redirect to home page */}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </>
    );
};

function App() {
    return (
        <AxiosProvider>
            <Router>
                <ThemeProvider>
                    <LoginProvider>
                        <AppContent />
                    </LoginProvider>
                </ThemeProvider>
            </Router>
        </AxiosProvider>
    );
}

export default App;
