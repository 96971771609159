import axios from 'axios';

export const handleFileUpload = async (event, { folderName, fileName, fieldArrayName, index, setValue, getValues, userId, axios }) => {
    const file = event.target.files[0];
    if (file) {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.post(`/upload/${folderName}/${fileName}-${userId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (fieldArrayName === "userDetails.profile_path") {
                const imageUrl = `${response.data.fileLink}?timestamp=${new Date().getTime()}`;
                setValue('userDetails.profile_path.value', imageUrl);
            } else {
                if (response.data && response.data.fileLink) {
                    const imageUrl = `${response.data.fileLink}?timestamp=${new Date().getTime()}`;
                    console.log("Image uploaded successfully:", imageUrl);
                    const updatedFields = getValues(fieldArrayName);
                    if (updatedFields && updatedFields[index]) {
                        updatedFields[index].poster = imageUrl;
                        setValue(fieldArrayName, updatedFields);
                    } else {
                        console.error(`Invalid index ${index} for field array ${fieldArrayName}`);
                    }
                }
            }
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    }
};

export const handleRemoveFile = (fieldArrayName, index, setValue, getValues) => {
    const updatedFields = getValues(fieldArrayName);
    if (fieldArrayName === "userDetails.profile_path") {
        const imageUrl = ``;
        setValue('userDetails.profile_path.value', imageUrl);
    } else {
        if (updatedFields && updatedFields[index]) {
            updatedFields[index].poster = '';
            setValue(fieldArrayName, updatedFields);
            console.log("Updated fieldArray state after removal:", updatedFields);
        } else {
            console.error(`Invalid index ${index} for field array ${fieldArrayName}`);
        }
    }
    console.log("Form value after removal:", getValues());
};
