import { useAxios } from '../../contexts/AxiosContext';
import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { useTheme } from '../../contexts/ThemeContext';

const PeopleAndOthersCard = ({ peopleData }) => {
    const { theme } = useTheme();
    const [data, setData] = useState({
        director: peopleData.director,
        game: peopleData.game,
        book: peopleData.book,
        writer: peopleData.writer
    });
    const api = useAxios();

    return (
        <Grid item xs={12} sm={12} md={6} lg={9}>
            <Card
                sx={{
                    width: '100%',
                    height: '45vh',
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    boxShadow: 3,
                    borderRadius: 2,
                    padding: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    position: 'relative'
                }}
            >
                <CardContent sx={{ textAlign: 'left', overflow: 'auto', height: '100%', width: "100%"}}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                        Director
                    </Typography>
                    {data.director.map((item) => (
                        <Typography key={item.tmdb_id} variant="body2" sx={{ marginBottom: 1 }}>
                            {item.title}
                        </Typography>
                    ))}
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                        Game
                    </Typography>
                    {data.game.map((item) => (
                        <Typography key={item.game_id} variant="body2" sx={{ marginBottom: 1 }}>
                            {item.title}
                        </Typography>
                    ))}
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                        Book
                    </Typography>
                    {data.book.map((item) => (
                        <Typography key={item.google_books_id} variant="body2" sx={{ marginBottom: 1 }}>
                            {item.title}
                        </Typography>
                    ))}
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                        Writer
                    </Typography>
                    {data.writer.map((item) => (
                        <Typography key={item.tmdb_id} variant="body2" sx={{ marginBottom: 1 }}>
                            {item.title}
                        </Typography>
                    ))}
                </CardContent>
            </Card>
        </Grid>
    );
};

export default PeopleAndOthersCard;
