import React, { useState, useRef, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme,
  CircularProgress,
  TextField,
  Alert,
} from '@mui/material';
import { CloudUpload as CloudUploadIcon, Close as CloseIcon, ChangeCircle as ChangeCircleIcon} from '@mui/icons-material';
import { styled } from '@mui/system';
import { useAxios } from '../../contexts/AxiosContext';
import { useLogin } from '../../contexts/LoginContext';
import Carousel from '../AdminConsole/Carousel';

const StyledTabs = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: 'transparent',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1, 0),
  '& .MuiButton-root': {
    borderRadius: '20px',
    margin: theme.spacing(0.5),
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    padding: theme.spacing(0.5, 2),
    fontSize: '0.875rem',
    // minHeight: 'auto',
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
    color: theme.palette.text.primary,
  },
}));

const StyledTab = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  margin: theme.spacing(0.5),
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  padding: theme.spacing(1, 2),
  fontSize: '0.875rem',
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
  color: theme.palette.text.primary,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '20px',
    background: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(10px)',
    padding: theme.spacing(2),
    position: 'relative',
  },
  '& .MuiDialogTitle-root': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const TabPanel = ({ children, value, index, ...other }) => (
  <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
    {value === index && (
      <Box sx={{ p: 3 }}>
        <Typography component="div">{children}</Typography>
      </Box>
    )}
  </div>
);

const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

const UploadModel = ({ initialLogline, initialFormat, initialGenre, initialTitle, remainingGenerations, onSuccess, upload_state }) => {

  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [preview, setPreview] = useState(null);
  const [title, setTitle] = useState(initialTitle);
  const [logline, setLogline] = useState(initialLogline);
  const [genre, setGenre] = useState(initialGenre);
  const [format, setFormat] = useState(initialFormat);
  const [otherRemarks, setOtherRemarks] = useState('');
  const [loading, setLoading] = useState(false);
  const [uploadedImageData, setUploadedImageData] = useState(null);
  const [generatedImageData, setGeneratedImageData] = useState(null);
  const [longLoadingMessage, setLongLoadingMessage] = useState('');
  const { userId } = useLogin();
  const [aiposterHistory, SetAiposterHistory] = useState()

  useEffect(() => {
    if (value === 2) { // This checks if the AI History tab is selected
      const fetchData = async () => {
        try {
          const response = await axios.get(`/aiposter_history/${userId}`);
          const filteredData = response.data.filter(item => item.title === initialTitle);
          SetAiposterHistory(filteredData);
        } catch (error) {
          console.error('Failed to fetch AI history:', error);
        }
      };
  
      fetchData();
    }
  }, [value, userId]); 
  
  useEffect(() => {
    let timer;
    if (loading) {
      timer = setTimeout(() => {
        setLongLoadingMessage('Generation is taking longer than expected, please be patient...');
      }, 8000); //8 seconds
    } else {
      setLongLoadingMessage('');
    }
    
    return () => clearTimeout(timer);
  }, [loading]);

  const fileInputRef = useRef(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const axios = useAxios();

  const handleChange = (event, newValue) => setValue(newValue);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setPreview(null);
    setUploadedImageData(null);
    setGeneratedImageData(null);
  };

  const handleFileUpload = (event, isDrop = false) => {
    const files = isDrop ? event.dataTransfer.files : event.target.files;
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
        setUploadedImageData(e.target.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleRemovePreview = () => {
    setPreview(null);
    setUploadedImageData(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset the file input value
      fileInputRef.current.click(); // Trigger the file input click
    }
  };

  const handleGenerateImage = async () => {
    if (remainingGenerations <= 0) {
      alert('You have exhausted your AI generation quota. Try again later.');
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post('/generate-poster', {
        logLine: logline,
        genre,
        format,
        title,
        other_remarks: otherRemarks,
        userId: userId,
      });
      const imageData = response.data.imageData;
      setGeneratedImageData(`data:image/png;base64,${imageData}`);
    } catch (error) {
      alert(error.message);
    }
    finally {
      setLoading(false);
    }
  };

  const handleUploadToS3 = async () => {
    setLoading(true);
    try {
      let file;
      if (value === 0 && uploadedImageData) {
        file = dataURLtoFile(uploadedImageData, 'uploaded_image.png');
      } else if (value === 1 && generatedImageData) {
        file = dataURLtoFile(generatedImageData, `${title}-${Date.now()}.png`);
      }
  
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
  
        const response = await axios.post(`/upload/AI_Poster/${title}-${Date.now()}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        
        const fileLink = response.data.fileLink;
        // alert(`Image uploaded successfully: ${response.data.fileLink}`);
        handleClose();
        onSuccess(fileLink); // Return the S3 UR
      } else {
        alert('No file to upload');
      }
    } catch (error) {
      console.error('Error uploading to S3:', error);
    } finally {
      setLoading(false);
    }
  };  

  return (
    <Box sx={{ textAlign: 'center', mt: 5 }}>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        {upload_state === true ? <CloudUploadIcon /> : <ChangeCircleIcon />}
      </Button>

      <StyledDialog open={open} onClose={handleClose} maxWidth="md" fullWidth fullScreen={fullScreen}>
        <DialogContent sx={{ height: '600px', overflow: {xs: 'auto', md: 'auto'}  }}>
          <Box sx={{ width: '100%' }}>
            <StyledTabs position="static">
              <StyledTab onClick={(e) => handleChange(e, 0)} className={value === 0 ? 'Mui-selected' : ''}>
                Upload
              </StyledTab>
              <StyledTab onClick={(e) => handleChange(e, 1)} className={value === 1 ? 'Mui-selected' : ''}>
                AI Generation
              </StyledTab>
              <StyledTab onClick={(e) => handleChange(e, 2)} className={value === 2 ? 'Mui-selected' : ''}>
                AI History
              </StyledTab>
            </StyledTabs>
            <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
            <TabPanel value={value} index={0}>
              {preview ? (
                <Box>
                  <Typography variant="h6" color="primary">Preview:</Typography>
                  <img
                    src={preview}
                    alt="Preview"
                    style={{ maxWidth: '300px', maxHeight: '300px', marginTop: '10px', borderRadius: '10px' }}
                  />
                  <Button variant="contained" color="secondary" onClick={handleRemovePreview} sx={{ mt: 2 }}>
                    Change
                  </Button>
                </Box>
              ) : (
                <Box
                  sx={{
                    border: '2px dashed',
                    borderColor: theme.palette.primary.main,
                    padding: 4,
                    borderRadius: 2,
                    textAlign: 'center',
                  }}
                  onDrop={(e) => {
                    e.preventDefault();
                    handleFileUpload(e, true);
                  }}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <CloudUploadIcon sx={{ fontSize: 50, color: theme.palette.primary.main }} />
                  <Typography variant="h6" gutterBottom color="primary">
                    Drag & drop files here, or click to select files
                  </Typography>
                  <Button variant="contained" component="label" startIcon={<CloudUploadIcon />} color="primary">
                    Upload Files
                    <input type="file" hidden onChange={handleFileUpload} accept="image/*" ref={fileInputRef} />
                  </Button>
                </Box>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
            <>
              <Alert 
      severity="success" variant="outlined"  bgcolor="none"
      sx={{ 
        fontSize: '0.875rem', 
        marginTop: '-45px',
        marginBottom:'10px'
      }}
    >
This is a beta version so the Texts visualized are not as expected.    </Alert>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ flex: '0 0 55%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <TextField label="Title" value={title} onChange={(e) => setTitle(e.target.value)} fullWidth />
                  <TextField label="Logline" value={logline} onChange={(e) => setLogline(e.target.value)} fullWidth multiline rows={3} />
                  <TextField label="Genre" value={genre} onChange={(e) => setGenre(e.target.value)} fullWidth />
                  <TextField label="Format" value={format} onChange={(e) => setFormat(e.target.value)} fullWidth />
                  <TextField label="Customize Prompt" value={otherRemarks} onChange={(e) => setOtherRemarks(e.target.value)} fullWidth multiline rows={3} />
                </Box>
                <Box sx={{ flex: '0 0 45%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                  <Button variant={generatedImageData ? "outlined" : "contained"} color="primary" onClick={handleGenerateImage} disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : generatedImageData ? 'Regenerate' : 'Generate'}
                  </Button>
                  {loading && (
                    <Typography variant="body2" color="textSecondary" component="div"> 
                      {longLoadingMessage}
                    </Typography>
                  )}
                  {generatedImageData && (
                    <>
                      {loading && !generatedImageData ? <CircularProgress size={24} /> : <img src={generatedImageData} alt="Generated" style={{ height: '60%', borderRadius: '10px' }} />}
                    </>
                  )}
                </Box>
              </Box>
            </>
            </TabPanel>
            <TabPanel value={value} index={2}>
            {aiposterHistory && <Carousel data={aiposterHistory} />}
            </TabPanel>
          </Box>
        </DialogContent>
        {value === 0 && uploadedImageData && (
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleUploadToS3} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Upload'}
            </Button>
          </DialogActions>
        )}

        {value === 1 && generatedImageData && (
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleUploadToS3} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Upload'}
            </Button>
          </DialogActions>
        )}

        {value === 2 && (
          <></>
        )}

      </StyledDialog>
    </Box>
  );
};

export default UploadModel;