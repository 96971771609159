import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { useTheme } from '../../contexts/ThemeContext';

const TermsAndCondition = () => {
  const { theme } = useTheme();
  const bgColor = theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper;
  const textColor = theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.text.primary;
  const headingColor = theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark;

  return (
    <Container maxWidth="md" style={{ backgroundColor: bgColor, padding: '20px', borderRadius: '8px' }}>
      <Typography variant="h4" gutterBottom style={{ color: headingColor, fontWeight: 'bold', textAlign: 'center' }}>
        Terms and Conditions - Just Write
      </Typography>
      <Typography variant="subtitle1" gutterBottom style={{ color: textColor, textAlign: 'center' }}>
        Last Updated: [Insert Date]
      </Typography>

      <Box my={4}>
        <Typography variant="h6" gutterBottom style={{ color: headingColor }}>
          1. Introduction
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          Welcome to Just Write, a platform operated by Soozh LLC, a Wyoming-based company. These Terms and Conditions govern your use of the Just Write app. By accessing or using our app, you agree to comply with and be bound by these terms.
        </Typography>
      </Box>

      <Box my={4}>
        <Typography variant="h6" gutterBottom style={{ color: headingColor }}>
          2. Acceptance of Terms
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          By registering for and/or using the Just Write app, you agree to these Terms and Conditions and any policies or practices that may be updated from time to time.
        </Typography>
      </Box>

      <Box my={4}>
        <Typography variant="h6" gutterBottom style={{ color: headingColor }}>
          3. User Obligations
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          As a user, you agree to use Just Write responsibly and in compliance with all applicable laws. You are responsible for the content you upload and must not infringe on the intellectual property rights of others.
        </Typography>
      </Box>

      <Box my={4}>
        <Typography variant="h6" gutterBottom style={{ color: headingColor }}>
          4. Intellectual Property
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          All content you upload to Just Write remains your intellectual property. However, by uploading content, you grant Just Write a non-exclusive, worldwide license to use, display, and distribute your content for the purpose of providing our services. Just Write is not responsible for the protection of content you choose to make public.
        </Typography>
      </Box>

      <Box my={4}>
        <Typography variant="h6" gutterBottom style={{ color: headingColor }}>
          5. Privacy Policy
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          Your use of Just Write is also governed by our Privacy Policy, which outlines how we collect, use, and protect your information.
        </Typography>
      </Box>

      <Box my={4}>
        <Typography variant="h6" gutterBottom style={{ color: headingColor }}>
          6. Limitation of Liability
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          Just Write and Soozh LLC are not liable for any damages arising from your use of the app. You agree to indemnify and hold Just Write and Soozh LLC harmless from any claims resulting from your use of the app or your violation of these terms.
        </Typography>
      </Box>

      <Box my={4}>
        <Typography variant="h6" gutterBottom style={{ color: headingColor }}>
          7. Governing Law
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          These terms are governed by and construed in accordance with the laws of the State of Wyoming, USA. Any disputes arising under these terms will be subject to the exclusive jurisdiction of the courts of Wyoming.
        </Typography>
      </Box>

      <Box my={4}>
        <Typography variant="h6" gutterBottom style={{ color: headingColor }}>
          8. Modifications to Terms
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          Just Write reserves the right to modify these Terms and Conditions at any time. We will notify you of any significant changes, and your continued use of the app after such changes constitutes your acceptance of the new terms.
        </Typography>
      </Box>

      <Box my={4}>
        <Typography variant="h6" gutterBottom style={{ color: headingColor }}>
          9. Contact Information
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          If you have any questions or concerns about these Terms and Conditions, please contact us at: [Insert Contact Email]
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsAndCondition;
