import React, { useState, useEffect, useCallback } from 'react';
import { useAxios } from '../../contexts/AxiosContext';

const useMetaObjectPreview = (url) => {
    const [metaData, setMetaData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const axios = useAxios();

    const fetchMetaData = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await axios.post('/metadata', { url });
            setMetaData(response.data);
        } catch (error) {
            console.error('Error fetching metadata:', error);
            setError('Failed to fetch metadata');
        } finally {
            setLoading(false);
        }
    }, [axios, url]);

    useEffect(() => {
        if (url) {
            fetchMetaData();
        }
    }, [url, fetchMetaData]);

    return { metaData, loading, error };
};

export default useMetaObjectPreview;
