import React, { useState } from 'react';
import { Grid, TextField, Button, Stepper, Step, StepLabel, Typography, Box, Tooltip, IconButton, Card, CardContent, MenuItem, Slider, Checkbox } from '@mui/material';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import config from '../config.json';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useAxios } from '../../../contexts/AxiosContext';
import { handleFileUpload, handleRemoveFile } from '../helpers/FileUploadRemove';
import Lottie from 'lottie-react';
import uploadAnimation from '../lottie/uploadAnimation.json';
import LocationField from '../FormAddons/LocationField';
import Qualifications from './Qualifications';
import LanguageProficiencyComponent from './LanguageProficiencyComponent';
import { FaLinkedin, FaImdb, FaGlobe } from 'react-icons/fa';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import PersonIcon from '@mui/icons-material/Person';
import LanguageIcon from '@mui/icons-material/Language';
import LinkIcon from '@mui/icons-material/Link';
import SchoolIcon from '@mui/icons-material/School';
const defaultProfileImg = 'https://via.placeholder.com/150';  // You can replace this URL with any suitable dummy image URL

const steps = [
    { label: 'Bio', key: 'bio', icon: <PersonIcon /> },
    { label: 'Languages Known', key: 'languages_v2', icon: <LanguageIcon /> },
    { label: 'Social Links', key: 'socialLinks', icon: <LinkIcon /> },
    { label: 'Qualification', key: 'qualifications', icon: <SchoolIcon /> },
];

const UserDetails = ({ userId, handleNextSection, theme, isMobile, step }) => {
    const { control, formState: { errors }, trigger, watch, handleSubmit, setValue, getValues } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'userDetails.languages'
    });
    const [activeStep, setActiveStep] = useState(step || 0);
    const axios = useAxios();
    const profilePath = watch('userDetails.profile_path.value', '');
    const [isUploading, setIsUploading] = useState(false);
    const isDark = theme.palette.mode === "dark"

    const handleNext = async () => {
        const result = await trigger();  // Trigger validation
        if (result) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            const firstError = Object.keys(errors)[0];
            if (firstError) {
                const element = document.querySelector(`[name="${firstError}"]`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    element.focus();
                }
            }
        }
    };

    const handleBack = async () => {
        const result = await trigger();  // Trigger validation
        if (result) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        } else {
            const firstError = Object.keys(errors)[0];
            if (firstError) {
                const element = document.querySelector(`[name="${firstError}"]`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    element.focus();
                }
            }
        }
    };

    const handleStepClick = async (index) => {
        const result = await trigger();  // Trigger validation
        if (result) {
            setActiveStep(index);
        } else {
            const firstError = Object.keys(errors)[0];
            if (firstError) {
                const element = document.querySelector(`[name="${firstError}"]`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    element.focus();
                }
            }
        }
    };

    // const onSubmit = (data) => console.log(data);

    const handleAddLanguage = () => {
        append({ value: '', privacy: false, lsrw: { l: false, s: 0, r: 0, w: 0 } });
    };

    const handleRemoveLanguage = (index) => {
        remove(index);
    };

    const selectedLanguages = fields.map(field => field.value);

    return (
        <Box>
            {/* <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 3 }}>
                {steps.map((step, index) => (
                    <Step key={step.label} onClick={() => handleStepClick(index)}>
                        <Tooltip title={config.userDetails.tooltips[step.key]} arrow>
                            <StepLabel>{step.label}</StepLabel>
                        </Tooltip>
                    </Step>
                ))}
            </Stepper> */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    ...(isMobile && { marginLeft: '-30px', marginRight: '-30px' })
                }}
            >
                {!isMobile && <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1, marginTop: "3px" }}
                    startIcon={<ArrowBackIcon />}
                >
                    Back
                </Button>}
                <Box sx={{ flex: '1 1 auto', overflowX: "auto" }}>
                    <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 3, '& .MuiStepConnector-root': { display: 'none' } }}>
                        {steps.map((step, index) => (
                            <Step key={step.label} onClick={() => handleStepClick(index)}>
                                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <IconButton
                                        onClick={() => handleStepClick(index)}
                                        sx={{
                                            borderRadius: '8px',
                                            border: activeStep === index ? `2px solid ${!isDark ? theme.palette.primary.main : 'white'}` : '2px solid transparent',
                                            color: activeStep === index ? (!isDark ? theme.palette.primary.main : 'white') : 'inherit',
                                            transition: 'border 0.3s ease-in-out',
                                            padding: '6px 6px',
                                            margin: '0 10px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {step.icon}
                                        {!isMobile && (<Typography variant="button" sx={{ ml: 1 }}>
                                            {step.label}
                                        </Typography>)}
                                    </IconButton>
                                    {!isMobile && (index < steps.length - 1 && (
                                        <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: '#DDD' }} />
                                    ))}
                                </Box>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
                {!isMobile && <Button
                    onClick={activeStep === steps.length - 1 ? handleNextSection : handleNext}
                    endIcon={<ArrowForwardIcon />}
                    sx={{ marginTop: "3px" }}
                >
                    {activeStep === steps.length - 1 ? 'Go To Next Section' : 'Next'}
                </Button>}
            </Box>
            <Grid container spacing={2} style={{ justifyContent: 'center' }}>
                {activeStep === 0 && (
                    <>
                        <Grid item style={{ width: isMobile? '95vw' : '30vw' }}
                            container
                            sx={{
                                flexDirection: 'row',
                                ...(isMobile && { flexWrap: 'nowrap', flexDirection: 'column' })
                            }}
                        >
                            <Grid item xs={12} style={{
                                display: 'flex', justifyContent: 'center', alignItems: 'center',
                                ...(isMobile && { flexDirection: 'column', flexWrap: 'wrap' })

                            }}>
                                {isUploading ? (
                                    <Lottie animationData={uploadAnimation} style={{ width: '50%', height: 'auto' }} />
                                ) : (
                                    <div
                                        className="upload-placeholder"
                                        style={{
                                            width: isMobile ? '100px' : '150px',
                                            height: isMobile ? '100px' : '150px',
                                            borderRadius: '50%',
                                            overflow: 'hidden',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            position: 'relative',
                                            cursor: 'pointer',
                                            margin: '0 auto', // Ensures the div stays centered
                                            marginBottom: isMobile ? 'auto' : '10px'
                                        }}
                                        onClick={() => document.getElementById('fileInput').click()}
                                    >
                                        {profilePath ? (
                                            <img
                                                src={profilePath}
                                                alt="Profile"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover'
                                                }}
                                            />
                                        ) : (
                                            <CloudUploadIcon
                                                className="upload-icon"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    maxHeight: '250px',
                                                    objectFit: 'contain'
                                                }}
                                            />
                                        )}
                                        <input
                                            id="fileInput"
                                            type="file"
                                            accept="image/*"
                                            onChange={async (event) => {
                                                setIsUploading(true);
                                                await handleFileUpload(event, {
                                                    folderName: 'UserProfile',
                                                    fileName: `${userId}`,
                                                    fieldArrayName: 'userDetails.profile_path',
                                                    index: 0,
                                                    setValue,
                                                    getValues,
                                                    userId,
                                                    axios
                                                });
                                                setIsUploading(false);
                                            }}
                                            style={{ display: 'none' }}
                                        />
                                    </div>
                                )}
                                {profilePath && (
                                    <IconButton
                                        className="remove-button"
                                        onClick={() => handleRemoveFile('userDetails.profile_path', 0, setValue, getValues)}
                                    // style={{ position: 'absolute', top: '10px', right: '10px' }}
                                    >
                                        <span className="remove-icon">×</span>
                                    </IconButton>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="userDetails.fullName.value"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    label="Full Name"
                                                    {...field}
                                                    error={!!errors.userDetails?.fullName?.value}
                                                    helperText={errors.userDetails?.fullName?.value?.message}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="userDetails.email.value"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    label="Email"
                                                    {...field}
                                                    error={!!errors.userDetails?.email?.value}
                                                    helperText={errors.userDetails?.email?.value?.message}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <LocationField />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="userDetails.phone.value"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    label={config.userDetails.phone}
                                                    error={!!errors.userDetails?.phone?.value}
                                                    helperText={errors.userDetails?.phone?.value?.message}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
                {activeStep === 1 && (
                    <LanguageProficiencyComponent isDark={isDark} />
                )}
                {activeStep === 2 && (
                    <>
                        <Grid container spacing={2} justifyContent="center" style={{ minHeight: '100vh' }}>
                            <Grid item xs={12} sm={6} md={6} lg={6} style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item>
                                                <FaLinkedin color="#0A66C2" size={24} />
                                            </Grid>
                                            <Grid item xs>
                                                <Controller
                                                    name="userDetails.linkedin.value"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            fullWidth
                                                            label={config.userDetails.linkedin}
                                                            error={!!errors.userDetails?.linkedin?.value}
                                                            helperText={errors.userDetails?.linkedin?.value?.message}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Card style={{ marginTop: '20px' }}> {/* Add margin for spacing between cards */}
                                    <CardContent>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item>
                                                <FaImdb color="#F5C518" size={24} />
                                            </Grid>
                                            <Grid item xs>
                                                <Controller
                                                    name="userDetails.imdbProfileLink.value"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            fullWidth
                                                            label={config.userDetails.imdbProfileLink}
                                                            error={!!errors.userDetails?.imdbProfileLink?.value}
                                                            helperText={errors.userDetails?.imdbProfileLink?.value?.message}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Card style={{ marginTop: '20px' }}> {/* Add margin for spacing between cards */}
                                    <CardContent>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item>
                                                <FaGlobe color="#4285F4" size={24} />
                                            </Grid>
                                            <Grid item xs>
                                                <Controller
                                                    name="userDetails.website.value"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            fullWidth
                                                            label={config.userDetails.website}
                                                            error={!!errors.userDetails?.website?.value}
                                                            helperText={errors.userDetails?.website?.value?.message}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </>
                )}
                {activeStep === 3 && (
                    <Qualifications />
                )}
            </Grid>
        </Box>
    );
};

export default UserDetails;