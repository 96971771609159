import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAxios } from '../contexts/AxiosContext';
import logger from '../logger';

const VerifyEmail = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState('Verifying your email...');
  const [isVerificationComplete, setIsVerificationComplete] = useState(false);
  const axios = useAxios()

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        logger(`Verifying email with token :${token}` , 'info', ['env:production', 'issue:email-verification'], 'soozh-beta-frontend');
        const response = await axios.get(`/auth/verify/${token}`);
        setMessage(response.data.msg);
        setIsVerificationComplete(true);
      } catch (error) {
        try{
        logger(`Error  Verifying email with token :${token} with error: ${JSON.stringify(error)}` , 'error', ['env:production', 'issue:email-verification'], 'soozh-beta-frontend');
        }catch(err){
          console.log('error while stringify the error log')
        }

        console.error('Error verifying email:', error);
        setMessage('Error verifying email');
        setIsVerificationComplete(true);
      }
    };

    verifyEmail();
  }, [token]);

  useEffect(() => {
    if (isVerificationComplete) {
      alert(message);
      if (message === 'Email verified successfully') {
        logger(`Verifying email success :${token}` , 'ok', ['env:production', 'issue:email-verification'], 'soozh-beta-frontend');
        navigate('/');
      }
    }
  }, [isVerificationComplete, message, navigate]);

  return (
    <div>
      <h1>Email Verification</h1>
      <p>{message}</p>
    </div>
  );
};

export default VerifyEmail;
