"use client";
import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Box, Card, CardContent, Typography, Avatar, Link, Grid, Tooltip } from '@mui/material';
import { SiMaildotru } from "react-icons/si";
import { FaLinkedin, FaImdb } from "react-icons/fa";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { useMediaQuery } from '@mui/material';

const UserDetailsCard = ({ userDetails }) => {
    const { theme } = useTheme();
    const isXsScreen = useMediaQuery('(max-width:600px)');

    if (!userDetails) {
        return <div>No user details available</div>;
    }

    const {
        fullName,
        profile_path,
        location,
        imdbProfileLink,
        email,
        phone,
        linkedin,
        website,
        languages,
        creativeTraits,
    } = userDetails;

    return (
        <Card
            sx={{
                margin: '20px',
                padding: '20px',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
            }}
        >
            <CardContent>
                <img
                    src={profile_path.value}
                    alt="Profile Visualization"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: '16px',
                        opacity: '100%',
                        transition: 'all 0.5s ease',
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        background: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
                        // color: bgColor,
                        padding: '16px',
                        borderRadius: '0 0 16px 16px', // Rounded corners at the bottom
                        transition: 'all 0.5s ease',
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 'bold',
                            color: 'white',
                            transition: 'all 0.5s ease',
                        }}
                    >
                        {fullName?.value && !fullName.privacy && fullName.value}
                    </Typography>
                    {location?.value && !location.privacy && (
                        <Typography
                            variant="body2"
                            sx={{
                                color: 'white',
                                transition: 'all 0.5s ease',
                            }}
                        >
                            {location.value}
                        </Typography>
                    )}
                    <Box sx={{ display: 'flex', gap: 2, marginTop: 1 }}>
                        {email?.value && !email.privacy && (
                            <Tooltip title={email.value}>
                                <Link href={`mailto:${email.value}`} sx={{ color: 'white' }}>
                                    <SiMaildotru fontSize="large" />
                                </Link>
                            </Tooltip>
                        )}
                        {phone?.value && !phone.privacy && (
                            <Tooltip title={phone.value}>
                                <Link href={`tel:${phone.value}`} sx={{ color: 'white' }}>
                                    <MdOutlinePhoneAndroid fontSize="large" />
                                </Link>
                            </Tooltip>
                        )}
                        {linkedin?.value && !linkedin.privacy && (
                            <Tooltip title={linkedin.value}>
                                <Link href={linkedin.value} target="_blank" rel="noopener noreferrer" sx={{ color: 'white' }}>
                                    <FaLinkedin fontSize="large" />
                                </Link>
                            </Tooltip>
                        )}
                        {imdbProfileLink?.value && !imdbProfileLink.privacy && (
                            <Tooltip title={imdbProfileLink.value}>
                                <Link href={imdbProfileLink.value} target="_blank" rel="noopener noreferrer" sx={{ color: 'white' }}>
                                    <FaImdb fontSize="large" />
                                </Link>
                            </Tooltip>
                        )}
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default UserDetailsCard;
