import React from 'react';
import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form'; // Import useFormContext if not already imported
import { useLoadScript, Autocomplete as GoogleMapsAutocomplete } from '@react-google-maps/api';

const libraries = ['places']; // Specify libraries

function LocationField() {
    const { register, setValue, formState: { errors } } = useFormContext(); // Use useFormContext to access form methods
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDXFIGqLqv0CKY7cU6m5-iUtBGiqSKpsPo", // Replace with your API key
        libraries
    });

    return isLoaded ? (
        <GoogleMapsAutocomplete
            onLoad={autocomplete => {
                // Listener for place changes
                autocomplete.addListener("place_changed", () => {
                    const place = autocomplete.getPlace();
                    if (place.formatted_address) {
                        setValue("userDetails.location.value", place.formatted_address);
                    }
                });
            }}
        >
            <TextField
                fullWidth
                label="Location"
                margin="normal"
                {...register('userDetails.location.value')}
                error={!!errors.userDetails?.location?.value}
                helperText={errors.userDetails?.location?.value ? errors.userDetails.location.value.message : ''}
            />
        </GoogleMapsAutocomplete>
    ) : (
        <TextField
            fullWidth
            label="Location"
            margin="normal"
            {...register('userDetails.location.value')}
            error={!!errors.userDetails?.location?.value}
            helperText={errors.userDetails?.location?.value ? errors.userDetails.location.value.message : ''}
        />
    );
}

export default LocationField;
