// storyofmylifeSchema.js
import { z } from 'zod';

const storyofmylifeSchema = z.object({
    storyOfmyLife: z.object({
        value: z.string().optional(),
        privacy: z.boolean().optional(),
    }).optional(),
});

export default storyofmylifeSchema;
