import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import BookIcon from '@mui/icons-material/Book';
import MovieIcon from '@mui/icons-material/Movie';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import StoriesIcon from '@mui/icons-material/LibraryBooks';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'; // Icon for AiPosterV2
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter'; // Icon for AiPoster
import { useAxios } from '../../contexts/AxiosContext';
import StatCard from './StatCard';

function StatsCard() {
    const axios = useAxios();
    const [stats, setStats] = useState({
        totalUsers: 0,
        verifiedUsers: 0,
        ownStories: 0,
        avgOwnStories: 0,
        workedInStories: 0,
        avgWorkedInStories: 0,
        mediaCounts: {
            movies: 0,
            series: 0,
            documentaries: 0,
            games: 0,
            books: 0,
            authors: 0,
            directors: 0
        },
        totalAiPoster: 0,
        totalAiPosterV2: 0,
        modelCountsV2: []
    });

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const response = await axios.get('/admin/stats');
                console.log(response.data)
                setStats(response.data);
            } catch (error) {
                console.error('Failed to fetch stats', error);
            }
            try {
                const response = await axios.get('/admin/allpublicviews');
                console.log("Public Views: ", response.data)
            } catch (error) {
                console.error('Failed to fetch stats', error);
            }
        };
        fetchStats();
    }, []);

    return (
      <Grid container spacing={2} sx={{ backgroundColor: 'transparent' }}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <StatCard icon={PeopleIcon} title="Total Users" value={stats.totalUsers} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <StatCard icon={VerifiedUserIcon} title="Verified Users" value={stats.verifiedUsers} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <StatCard icon={StoriesIcon} title="Own Stories" value={stats.ownStories} avgValue={stats.avgOwnStories} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <StatCard icon={StoriesIcon} title="Worked In Stories" value={stats.workedInStories} avgValue={stats.avgWorkedInStories} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <StatCard icon={MovieIcon} title="Movies" value={stats.mediaCounts.movies} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <StatCard icon={MovieIcon} title="Series" value={stats.mediaCounts.series} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <StatCard icon={MovieIcon} title="Documentaries" value={stats.mediaCounts.documentaries} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <StatCard icon={VideogameAssetIcon} title="Games" value={stats.mediaCounts.games} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <StatCard icon={LocalLibraryIcon} title="Books" value={stats.mediaCounts.books} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <StatCard icon={HistoryEduIcon} title="Authors" value={stats.mediaCounts.authors} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <StatCard icon={EmojiPeopleIcon} title="Directors" value={stats.mediaCounts.directors} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <StatCard icon={PhotoFilterIcon} title="AiPoster V1" value={stats.totalAiPoster} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <StatCard icon={AutoAwesomeIcon} title="Total AiPoster V2" value={stats.totalAiPosterV2} />
            </Grid>
            {stats.modelCountsV2.map((model, index) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                    <StatCard icon={AutoAwesomeIcon} title={`Model: ${model._id}`} value={model.count} />
                </Grid>
            ))}
        </Grid>
    );
}

export default StatsCard;
