import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useAxios } from '../../contexts/AxiosContext';
import { useTheme } from '../../contexts/ThemeContext';
import axios from 'axios';

const createIcon = (color) => {
    return new L.Icon({
        iconUrl: `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="10" fill="${color}" stroke="black" stroke-width="2"/></svg>`,
        iconSize: [24, 24],
        iconAnchor: [12, 24],
    });
};

const LocationMap = () => {
    const axiosInstance = useAxios();
    const [userLocations, setUserLocations] = useState([]);
    const [missingLocations, setMissingLocations] = useState([]);
    const { theme } = useTheme();

    const geocodeAddress = async (address) => {
        try {
            // console.log(`Geocoding address: ${address}`);
            const response = await axios.get('https://nominatim.openstreetmap.org/search', {
                params: {
                    q: address,
                    format: 'json',
                },
            });
            const results = response.data;
            if (results.length > 0) {
                const { lat, lon } = results[0];
                // console.log(`Geocoded ${address}: ${lat}, ${lon}`);
                return { lat: parseFloat(lat), lon: parseFloat(lon) };
            } else {
                // console.warn(`Failed to geocode ${address}`);
                return null;
            }
        } catch (error) {
            console.error(`Error geocoding ${address}`, error);
            return null;
        }
    };

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const response = await axiosInstance.get('/admin/userLocations');
                const data = response.data;

                const geocodedLocations = [];
                const locationsWithMissingData = [];

                for (const locationData of data) {
                    if (locationData.location) {
                        const geocoded = await geocodeAddress(locationData.location);
                        if (geocoded) {
                            geocodedLocations.push({ ...locationData, lat: geocoded.lat, lon: geocoded.lon });
                        } else {
                            locationsWithMissingData.push(locationData.location);
                        }
                    } else {
                        locationsWithMissingData.push(locationData.location || 'Unknown Location');
                    }
                }

                setUserLocations(geocodedLocations);
                setMissingLocations(locationsWithMissingData);
            } catch (error) {
                console.error('Failed to fetch stats', error);
            }
        };
        fetchStats();
    }, [axiosInstance]);

    const iconColor = theme === 'dark' ? 'green' : 'blue';

    const glassCardStyle = {
        background: 'rgba(255, 255, 255, 0.8)',
        borderRadius: '15px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
        padding: '10px',
        backdropFilter: 'blur(10px)',
    };

    return (
        <Box sx={{ margin: 2 }}>
            <MapContainer center={[20, 0]} zoom={2} style={{ height: '500px', width: '100%' }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                />
                {userLocations.map((locationData, index) => {
                    const position = [locationData.lat, locationData.lon];
                    return (
                        <Marker key={index} position={position} icon={createIcon(iconColor)}>
                            <Popup style={glassCardStyle}>
                                {console.log(locationData.fullName.value, ' - ', locationData.location)}
                                <Typography>Email: {locationData.email.value}</Typography>
                                <Typography>Full Name: {locationData.fullName.value}</Typography>
                                <img src={locationData.profile_path || 'https://via.placeholder.com/100'} alt="Profile" style={{ width: '100px', height: '100px' }} />
                            </Popup>
                        </Marker>
                    );
                })}
            </MapContainer>
            <Box sx={{ marginTop: 2 }}>
                <Typography variant="h6">Locations with Missing Data:</Typography>
                {missingLocations.map((location, index) => (
                    <Typography key={index}>
                        {location} - Missing Latitude/Longitude
                    </Typography>
                ))}
            </Box>
        </Box>
    );
};

export default LocationMap;