import React, { useState, useEffect } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useAxios } from '../../contexts/AxiosContext';
import { Box } from '@mui/material';

const ReachOutsDataGrid = () => {
  const axios = useAxios();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchReachOuts();
  }, []);

  const fetchReachOuts = async () => {
    const response = await axios.get('/admin/reachouts');
    const reachOuts = response.data;
    const groupedReachOuts = groupByUser(reachOuts);
    setRows(groupedReachOuts);
  };

  const groupByUser = (reachOuts) => {
    const usersMap = new Map();

    reachOuts.forEach((reachOut) => {
      if (!usersMap.has(reachOut.userId)) {
        usersMap.set(reachOut.userId, {
          id: reachOut.userId,
          name: reachOut.name,
          email: reachOut.email,
          reachOutCount: 0,
          isGroup: true,
          reachOuts: [],
        });
      }

      const user = usersMap.get(reachOut.userId);
      user.reachOutCount += 1;
      user.reachOuts.push({
        id: `${reachOut.userId}-${reachOut._id}`, // Unique ID for each reach out
        ...reachOut,
        isGroup: false,
      });
    });

    const groupedRows = [];
    usersMap.forEach((user) => {
      groupedRows.push(user);
      groupedRows.push(...user.reachOuts);
    });

    return groupedRows;
  };

  const columns = [
    {
      field: 'expand',
      headerName: '',
      width: 50,
      sortable: false,
      renderCell: (params) => {
        if (params.row.isGroup) {
          return null;
        }
        return <Box sx={{ pl: `${params.row.depth * 2}rem` }} />;
      },
    },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    {
      field: 'reachOutCount',
      headerName: 'Reach Outs',
      width: 150,
      renderCell: (params) => (params.row.isGroup ? params.row.reachOutCount : ''),
    },
    {
      field: 'option',
      headerName: 'Option',
      width: 200,
      renderCell: (params) => (params.row.isGroup ? '' : params.value),
    },
    {
      field: 'selectedStory',
      headerName: 'Selected Story',
      width: 200,
      renderCell: (params) => (params.row.isGroup ? '' : params.value),
    },
    {
      field: 'company',
      headerName: 'Company',
      width: 200,
      renderCell: (params) => (params.row.isGroup ? '' : params.value),
    },
    {
      field: 'designation',
      headerName: 'Designation',
      width: 200,
      renderCell: (params) => (params.row.isGroup ? '' : params.value),
    },
    {
      field: 'message',
      headerName: 'Message',
      width: 300,
      renderCell: (params) => (params.row.isGroup ? '' : params.value),
    },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      width: 200,
      renderCell: (params) => (params.row.isGroup ? '' : new Date(params.value).toLocaleString()),
    },
  ];

  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGridPro
        rows={rows}
        columns={columns}
        getTreeDataPath={(row) => (row.isGroup ? [row.id] : [row.userId, row.id])}
        treeData
        autoHeight
        disableSelectionOnClick
        disableColumnMenu
      />
    </div>
  );
};

export default ReachOutsDataGrid;
