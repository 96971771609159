import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, Card, CardContent, CardMedia, Typography, Button, Grid, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { useAxios } from '../../contexts/AxiosContext';

const AiPosterV2Table = ({ onUseTemplate }) => {
    const axios = useAxios();
    const [aiPosters, setAiPosters] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [selectedTitle, setSelectedTitle] = useState('');
    const [userSuggestions, setUserSuggestions] = useState([]);
    const [titleSuggestions, setTitleSuggestions] = useState([]);
    const [selectedPoster, setSelectedPoster] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        fetchUsers(); // Fetch all users when the component mounts
        fetchData(); // Fetch all posters when the component mounts
    }, []); // Empty dependency array ensures this runs only once when the component mounts

    useEffect(() => {
        if (selectedUserId) {
            fetchTitles(); // Fetch titles when a user is selected
        }
    }, [selectedUserId]);

    useEffect(() => {
        if (selectedUserId || selectedTitle) {
            fetchData(); // Fetch data again when userId or title changes
        }
    }, [currentPage, selectedUserId, selectedTitle]);

    const fetchUsers = async () => {
        try {
            const response = await axios.get('/admin/ai-poster-v2-users');
            setUserSuggestions(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchTitles = async () => {
        try {
            const response = await axios.get('/admin/ai-poster-v2-titles', {
                params: { userId: selectedUserId }
            });
            setTitleSuggestions(response.data);
        } catch (error) {
            console.error('Error fetching titles:', error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get('/admin/ai-poster-v2', {
                params: {
                    page: currentPage,
                    limit,
                    userId: selectedUserId || undefined,
                    title: selectedTitle || undefined
                }
            });

            setAiPosters(response.data.aiPosters);
            setTotalRecords(response.data.totalRecords);
        } catch (error) {
            console.error('Error fetching AI posters:', error);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleUserChange = (event, value) => {
        setSelectedUserId(value?.userId || '');
        setSelectedTitle('');
        setTitleSuggestions([]);
        setAiPosters([]);
    };

    const handleTitleChange = (event, value) => {
        setSelectedTitle(value);
    };

    const handleCardClick = (poster) => {
        setSelectedPoster(poster);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleUseTemplate = () => {
        if (selectedPoster?.template) {
            onUseTemplate(selectedPoster.template); // Trigger the section change and pass the template
            setDialogOpen(false); // Close the dialog after the button is clicked
        }
    };

    return (
        <div style={{ padding: '20px' }}>
            <Typography variant="h4" gutterBottom>
                AI Poster V2 History
            </Typography>
            <Autocomplete
                options={userSuggestions}
                getOptionLabel={(option) => option.userName ? option.userName : option.userId}
                onChange={handleUserChange}
                renderInput={(params) => <TextField {...params} label="Search by User..." variant="outlined" />}
                style={{ marginBottom: '20px' }}
            />
            <Autocomplete
                options={titleSuggestions}
                onInputChange={handleTitleChange}
                renderInput={(params) => <TextField {...params} label="Search by Title..." variant="outlined" />}
                style={{ marginBottom: '20px' }}
                disabled={!selectedUserId}
            />
            <Grid container spacing={2}>
                {aiPosters.map((poster) => (
                    <Grid item xs={12} sm={6} md={4} key={poster._id}>
                        <Card
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                width: '250px',
                                height: '350px',
                                cursor: 'pointer',
                                position: 'relative' // To position the bulb icon
                            }}
                            onClick={() => handleCardClick(poster)}
                        >
                            {poster.template && (
                                <IconButton
                                    style={{
                                        position: 'absolute',
                                        top: 10,
                                        right: 10,
                                        color: 'white',
                                        backgroundColor: 'black',
                                    }}
                                    size="small"
                                >
                                    <TipsAndUpdatesIcon style={{ color: poster.template ? 'yellow' : 'grey' }} />
                                </IconButton>
                            )}
                            <CardMedia
                                component="img"
                                image={poster.imagePath}
                                alt={poster.title}
                                style={{ height: '180px', width: '100%', objectFit: 'cover' }}
                            />
                            <CardContent style={{ textAlign: 'center' }}>
                                <Typography variant="h6">{poster.title}</Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {poster.genre}
                                </Typography>
                                {poster.userName && (
                                    <Typography variant="caption" color="textSecondary">
                                        {poster.userName}
                                    </Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                    style={{ marginRight: '10px' }}
                >
                    Previous
                </Button>
                <Typography variant="body1" display="inline">
                    Page {currentPage} of {Math.ceil(totalRecords / limit)}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={currentPage === Math.ceil(totalRecords / limit)}
                    onClick={() => handlePageChange(currentPage + 1)}
                    style={{ marginLeft: '10px' }}
                >
                    Next
                </Button>
            </div>
            <Typography variant="body1" style={{ marginTop: '20px', textAlign: 'center' }}>
                Total Records: {totalRecords}
            </Typography>

            {/* Dialog for Poster Details */}
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUseTemplate}
                        sx={{ marginBottom: '10px' }}
                    >
                        Use this template to generate more
                    </Button>
                    {selectedPoster?.title}
                </DialogTitle>
                <DialogContent>
                    <Typography><strong>Log Line:</strong> {selectedPoster?.logLine}</Typography>
                    <Typography><strong>Genre:</strong> {selectedPoster?.genre}</Typography>
                    <Typography><strong>Format:</strong> {selectedPoster?.format}</Typography>
                    <Typography><strong>Model:</strong> {selectedPoster?.model}</Typography>
                    <Typography><strong>Created At:</strong> {new Date(selectedPoster?.createdAt).toLocaleDateString()}</Typography>
                    <Typography><strong>Other Remarks:</strong> {selectedPoster?.other_remarks}</Typography>
                    <Typography><strong>Request Payload:</strong></Typography>
                    <Typography style={{ whiteSpace: 'pre-wrap' }}>
                        {JSON.stringify(selectedPoster?.requestPayload, null, 2)}
                    </Typography>
                    <img
                        src={selectedPoster?.imagePath}
                        alt={selectedPoster?.title}
                        style={{ width: '100%', marginTop: '20px' }}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default AiPosterV2Table;
