import React from 'react';
import { Chip, Avatar } from '@mui/material';
import { MdOpenInNew } from "react-icons/md";

const ClickableChip = ({ label, url, favicon }) => {
    const handleClick = () => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <Chip
            avatar={
                <Avatar
                    alt="favicon"
                    src={favicon}
                />
            }
            label={
                <span style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                    Open Link <MdOpenInNew style={{ fontSize: '1.2rem', marginLeft: '5px' }} />
                </span>
            }
            onClick={handleClick}
            style={{
                cursor: 'pointer',
                borderRadius: '16px',
                animation: 'glow 2.5s ease-in-out infinite',
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
            }}
        />
    );
};

// Inline CSS keyframes
const styles = document.createElement('style');
styles.innerHTML = `
    @keyframes glow {
        0% {
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        }
        50% {
            box-shadow: 0 0 15px rgba(255, 255, 255, 0.8);
        }
        100% {
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        }
    }
`;
document.head.appendChild(styles);

export default ClickableChip;
