// StoryOfMyLife.js
import { Typography } from '@mui/material';
import React, { useEffect, useRef, forwardRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Box } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const MudTextureBox = (props) => (
    <Box
        {...props}
        sx={{
            backgroundImage: 'url(/path/to/mud-texture.jpg)',
            backgroundSize: 'cover',
            padding: '16px',
            borderRadius: '8px',
            height: 'calc(100vh - 100px)', // Adjust height as needed
            overflow: 'auto',
        }}
    />
);

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['blockquote', 'code-block'],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ['link', 'image', 'video'],
        // Remove the 'clean' button from the toolbar configuration
    ]
};

// Forward ref to avoid findDOMNode warning
const QuillWrapper = forwardRef((props, ref) => (
    <ReactQuill {...props} ref={ref} />
));

const StoryOfMyLife = ({ userId, handleNextSection, isMobile }) => {
    const { control, register } = useFormContext();
    const quillRef = useRef(null);

    useEffect(() => {
        register('storyOfmyLife.value');
    }, [register]);

    useEffect(() => {
        const editorContainer = quillRef.current?.getEditor()?.container;
        if (editorContainer) {
            editorContainer.style.height = 'calc(100vh - 200px)'; // Adjust height as needed
            editorContainer.style.overflow = 'auto';
        }
    }, []);

    return (
        <MudTextureBox>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center', // Centers the content horizontally
                    alignItems: 'center', // Centers the content vertically (if needed)
                    textAlign: 'center', // Centers the text inside the Typography
                    width: '100%', // Makes sure the box takes full width
                    marginBottom: '16px', 
                    marginTop: '-16px',
                    flexDirection: 'column'
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Adjust the background color as needed
                        padding: '8px 4px', // Add padding to create space around the text
                        borderRadius: '4px', // Optional: Add rounded corners to the background
                        display: 'inline-block', // Ensure it doesn't stretch the full width
                    }}
                >
                    Enter Your Personal Story
                </Typography>
                {!isMobile && <Typography
                    sx={{
                        padding: '2px 4px', // Add padding to create space around the text
                        display: 'inline-block', // Ensure it doesn't stretch the full width
                    }}
                >
                    Your life story is the most authentic narrative you can share. Let your words bring it to life and truly express who you are as a writer.
                </Typography>}
            </Box>
            <Controller
                name="storyOfmyLife.value"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <QuillWrapper
                        ref={quillRef}
                        value={field.value}
                        onChange={field.onChange}
                        modules={modules}
                        style={{
                            height: '100%',
                            position: 'relative',
                        }}
                    />
                )}
            />
        </MudTextureBox>
    );
};

export default StoryOfMyLife;
