import React from 'react';
import { styled } from '@mui/system';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

const StyledPNTooltip = styled(({ className, showEditIcon, item, itemType, onEdit, ...props }) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
        title={
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pointerEvents: 'auto' }}>
                <Typography>{item.personalNote || "No personal note"}</Typography>
                {showEditIcon && (
                    <IconButton className="edit-button" onClick={() => onEdit(item, itemType)} size="small">
                        <EditIcon fontSize="small" />
                    </IconButton>
                )}
            </div>
        }
    >
    {props.children}
    </Tooltip>
))(({ theme }) => ({
[`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(10px)',
    borderRadius: '10px',
    padding: theme.spacing(2),
    maxWidth: '400px',
    position: 'relative',
    '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: '10px',
        padding: '2px', // Adjust padding as needed to control border thickness
        background: 'linear-gradient(45deg, #FE6B8B, #FF8E53, #FF8E53, #FE6B8B)',
        backgroundSize: '400% 400%',
        animation: 'gradient 3s ease infinite',
        zIndex: -1,
        mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)', // This ensures the gradient only appears on the border
        maskComposite: 'exclude',
        WebkitMaskComposite: 'xor', // For Webkit browsers
    },
},
'@keyframes gradient': {
        '0%': {
            backgroundPosition: '0% 50%',
        },
        '50%': {
            backgroundPosition: '100% 50%',
        },
        '100%': {
            backgroundPosition: '0% 50%',
        },
    },
}));

export default StyledPNTooltip;
