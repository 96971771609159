import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { useTheme } from '../../contexts/ThemeContext';

const PrivacyPolicy = () => {
  const { theme } = useTheme();
  const bgColor = theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper;
  const textColor = theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.text.primary;

  return (
    <Container maxWidth="md" style={{ backgroundColor: bgColor, padding: '20px', borderRadius: '8px' }}>
      <Typography variant="h4" gutterBottom style={{ color: textColor, fontWeight: 'bold' }}>
        Privacy Policy - Just Write
      </Typography>
      <Typography variant="subtitle1" gutterBottom style={{ color: textColor }}>
        Last Updated: [Insert Date Here]
      </Typography>

      <Box my={3}>
        <Typography variant="h6" gutterBottom style={{ color: textColor }}>
          1. Introduction
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          Welcome to Just Write, a platform operated by Soozh LLC. This Privacy Policy explains how we collect, use, disclose, and safeguard your information, including intellectual property (IP), when you use the Just Write app. By accessing or using our app, you agree to the terms outlined in this Privacy Policy.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom style={{ color: textColor }}>
          2. Information We Collect
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          We collect various types of information, including personal data, usage data, and intellectual property information such as the content you upload.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom style={{ color: textColor }}>
          3. How We Use Your Information
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          We use your information to provide and maintain our services, personalize your experience, communicate with you, and comply with legal obligations.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom style={{ color: textColor }}>
          4. Intellectual Property Rights and Responsibilities
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          You retain all rights to the IP that you create and upload to Just Write. We are not responsible for protecting content you make public, and you assume all risks associated with making your content public.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom style={{ color: textColor }}>
          5. Sharing and Disclosure of Information
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          We may share your information with third-party service providers, in business transfers, or to comply with legal requirements.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom style={{ color: textColor }}>
          6. Data Security
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          We implement reasonable security measures to protect your data and IP from unauthorized access, use, or disclosure, but no system can guarantee absolute security.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom style={{ color: textColor }}>
          7. Data Retention
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          We retain your data for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom style={{ color: textColor }}>
          8. User Rights
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          You have the right to access, correct, delete, or restrict your personal data and content. You can also object to the processing of your data for certain purposes.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom style={{ color: textColor }}>
          9. International Data Transfers
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          Your data may be transferred to and processed in countries outside your country of residence, including the U.S., with appropriate safeguards.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom style={{ color: textColor }}>
          10. Children's Privacy
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          Just Write is not intended for users under the age of 18, and we do not knowingly collect personal information from children.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom style={{ color: textColor }}>
          11. Changes to the Privacy Policy
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          We may update this Privacy Policy from time to time, and significant changes will be communicated via email or in-app notifications.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom style={{ color: textColor }}>
          12. Contact Information
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          If you have any questions or concerns about this Privacy Policy, please contact us at: [Insert Contact Email]
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom style={{ color: textColor }}>
          13. Legal Basis for Processing
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          Processing of personal data and content is based on your consent, contractual necessity, or legitimate interests.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom style={{ color: textColor }}>
          14. California Privacy Rights
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          California residents have rights under the California Consumer Privacy Act (CCPA), including the right to know, delete, and opt-out of the sale of personal data and content.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom style={{ color: textColor }}>
          15. User Consent and Acknowledgment
        </Typography>
        <Typography variant="body1" style={{ color: textColor }}>
          By using Just Write, you acknowledge that you have read and understood this Privacy Policy and agree to its terms, including those related to intellectual property.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
