import axios from 'axios';
import UAParser from 'ua-parser-js';

const logger = async (message, level, tags = [], service) => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const parser = new UAParser();
  const uaResult = parser.getResult();

  const currentUrl = window.location.href;  // Get the full URL from the browser

  // Constructing the full message with additional details
  const fullMessage = `${message} | URL=${currentUrl} | Browser=${uaResult.browser.name} ${uaResult.browser.version} | OS=${uaResult.os.name} ${uaResult.os.version} | Device=${uaResult.device.model || 'unknown'}`;

  const logData = {
    message: fullMessage, // Use the full message including all details
    level,
    tags, // Using tags exactly as provided
    service: "react-frontend",
  };

  // try {
  //   const response = await axios.post(`${baseURL}/log`, logData);
  //   console.log('Log sent:', response.data);
  // } catch (error) {
  //   console.error('Error sending log:', error);
  // }
};

export default logger;
