"use client";
import React, { forwardRef } from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { cn } from "../../utils/cn"; // Adjust the import path as necessary

const LabelComponent = forwardRef((props, ref) => {
    const { className, ...restProps } = props;

    return (
        <LabelPrimitive.Root
            ref={ref}
            className={cn(
                "text-sm font-medium text-black dark:text-white leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
                className
            )}
            {...restProps}
        />
    );
});

LabelComponent.displayName = LabelPrimitive.Root.displayName;

export { LabelComponent };
