import React, { useState } from 'react';
import { Grid, Card, CardContent, Box, Typography } from "@mui/material";
import { useTheme } from '../../contexts/ThemeContext';

const BookGrid = ({ bookData }) => {
    const { theme } = useTheme();
    const [books, setBooks] = useState(bookData);

    const renderBookItems = (items) => (
        items.map((item, index) => {
            return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            justifyContent: 'space-between',
                            borderRadius: 1,
                            boxShadow: 1,
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                overflow: 'auto',
                            }}
                        >
                            <img src={item.poster_path} alt={item.title} style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: 4 }} />
                        </Box>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                flexGrow: 1,
                                backgroundColor: theme.palette.background.paper,
                            }}
                        >
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.text.primary, marginBottom: 1 }}>
                                    {item.title}
                                </Typography>
                                <Typography variant="body2" sx={{ color: theme.palette.text.secondary, marginBottom: 1 }}>
                                    {item.personalNote}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                                    Genre: {item.genre || 'N/A'}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            );
        })
    );

    return (
        <Grid container spacing={2}>
            {renderBookItems(books)}
        </Grid>
    );
};

export default BookGrid;
