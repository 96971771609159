import React, { useEffect, useState } from "react";
import { useAxios } from "../../contexts/AxiosContext";
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Typography, Link
} from "@mui/material";

const PublicViewStats = () => {
    const [data, setData] = useState([]);
    const api = useAxios();
    const baseURL = process.env.REACT_APP_HOSTED_DOMAIN || 'https://justwrite.soozh.app/';

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get('/admin/allpublicviews');
                setData(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [api]);

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Public Profile Views Stats
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Username</TableCell>
                            <TableCell>Public Profile Link</TableCell>
                            <TableCell>Last Viewed Date</TableCell>
                            <TableCell>Simple Views</TableCell>
                            <TableCell>Interaction Views</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map(profileData => (
                            Object.keys(profileData.hits).map(date => (
                                <TableRow key={`${profileData.pubProfileLink}-${date}`}>
                                    <TableCell>{profileData.username || 'Unknown'}</TableCell>
                                    <TableCell>
                                        <Link href={`${baseURL}p/${profileData.pubProfileLink}`} target="_blank">
                                            View Profile
                                        </Link>
                                    </TableCell>                                    <TableCell>{date}</TableCell>
                                    <TableCell>{profileData.hits[date].simple}</TableCell>
                                    <TableCell>{profileData.hits[date].interaction}</TableCell>
                                </TableRow>
                            ))
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default PublicViewStats;
