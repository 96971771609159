// src/components/Navbar.js
import React from 'react';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton'; // Using IconButton for better UI
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useLogin } from '../contexts/LoginContext';
import Brightness4Icon from '@mui/icons-material/Brightness4'; // Icon for dark mode
import Brightness7Icon from '@mui/icons-material/Brightness7'; // Icon for light mode

const Navbar = () => {
    const { toggleTheme, theme } = useTheme();
    const { isLoggedIn, logout, userId } = useLogin();
    const navigate = useNavigate();
    const location = useLocation(); // Get location to check current pathname
    const currentPath = location.pathname;
    const isTransparent = currentPath === '/' || currentPath === '/signup';

    return (
        <AppBar 
            position="fixed" 
            sx={{ 
                backgroundColor: isTransparent ? 'transparent' : theme.palette.background.paper,
                boxShadow: 'none',
                color: isTransparent ? '#fff' : 'inherit' // Adjust text color based on background
            }}
        >
            <Toolbar>
                <Typography variant="h6" >
                    <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>Home</Link>
                </Typography>

                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h5" sx={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
                    Just Write
                    </Typography>
                </Box>

                {isLoggedIn ? (
                    <Button color="inherit" onClick={() => navigate(`/profile/${userId}`)}>
                        Profile
                    </Button>
                ) : null // Render nothing if not logged in, or you can put any other component here
                }
                {isLoggedIn ? (
                    <>
                        <Button color="inherit" onClick={logout}>Logout</Button>
                    </>
                ) : (
                    <Button color="inherit" onClick={() => navigate('/')}>Login</Button>
                )}
                <IconButton color="inherit" onClick={toggleTheme} disableRipple sx={{ transition: 'transform 0.3s ease', '&:hover': { transform: 'rotate(180deg)' } }}>
                    {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
