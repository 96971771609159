import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  TextField,
  Grid
} from '@mui/material';
import { styled } from '@mui/system';
import Lottie from 'lottie-react';
import animationData from '../../assets/LottieAnimations/confettiTick.json'; // Adjust the path as needed

const minCharLength = 50;

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 4, 3),
}));

const CharacterCounter = styled(({ isValid, ...other }) => <Typography {...other} />)(({ theme, isValid }) => ({
  fontSize: '0.8rem',
  color: isValid ? 'green' : 'red',
  display: 'flex',
  alignItems: 'center',
}));

const PersonalNote = ({ open, handleClose, mediaDetails, onSave, isEditMode }) => {
  const [personalNote, setPersonalNote] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [showLottie, setShowLottie] = useState(false);

  useEffect(() => {
    if (isEditMode && mediaDetails) {
      const note = mediaDetails.personalNote || '';
      setPersonalNote(note); // Preset the text field if in edit mode
      setCharCount(note.length);
    } else {
      setPersonalNote('');  // Clear the text field if in add mode
      setCharCount(0);
    }
  }, [isEditMode, mediaDetails]);

  const handleInputChange = (event) => {
    const note = event.target.value;
    setPersonalNote(note);
    setCharCount(note.length);

    if (note.length >= minCharLength) {
      setShowLottie(true);
    } else {
      setShowLottie(false);
    }
  };

  const handleSave = () => {
    if (charCount >= minCharLength) {
      onSave(personalNote);
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{isEditMode ? 'Edit Note' : 'Add Note'}</DialogTitle>
      <DialogContent>
        <StyledBox>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  {mediaDetails?.poster_path && (
                      <img src={mediaDetails.poster_path} alt={mediaDetails.title} style={{ width: '80%', marginTop: '20px' }} />
                    )}
                  <Typography variant="h6">
                    {mediaDetails?.title}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    {mediaDetails?.release_date ? new Date(mediaDetails.release_date).getFullYear() : ''}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                label="Personal Note"
                value={personalNote}
                onChange={handleInputChange}
                multiline
                rows={8}
                variant="outlined"
                fullWidth
                margin="normal"
                style={{ marginTop: '20px' }}
              />
              {charCount >= minCharLength ? (
                <Lottie animationData={animationData} style={{ width: 50, height: 50 }} />
              ) : (
                <CharacterCounter isValid={charCount >= minCharLength}>
                  - {minCharLength - charCount}
                </CharacterCounter>
              )}
            </Grid>
          </Grid>
        </StyledBox>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" disabled={charCount < minCharLength}>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PersonalNote;
