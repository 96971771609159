import React, { useState, useEffect, useCallback } from 'react';
import { useAxios } from '../../../contexts/AxiosContext';
import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Tooltip,
    Box,
    Link,
    Skeleton
} from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';

const MetaPreview = ({ url, size = 'default', minimal = false }) => {
    const [metaData, setMetaData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const axios = useAxios();

    const fetchMetaData = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await axios.post('/metadata', { url });
            const data = response.data;

            setMetaData(data);
        } catch (error) {
            console.error('Error fetching metadata:', error);
            setError('Failed to fetch metadata');
        } finally {
            setLoading(false);
        }
    }, [axios, url]);

    useEffect(() => {
        if (url) {
            fetchMetaData();
        }
    }, [url, fetchMetaData]);

    if (!url || error) {
        return null;
    }

    const getStyles = (size) => {
        if (size === 'big') {
            return bigStyles;
        }
        return defaultStyles;
    };

    const styles = getStyles(size);

    return (
        <Card style={minimal ? minimalStyles.card : styles.card}>
            {loading ? (
                <>
                    <Skeleton variant="rectangular" width="100%" height={minimal ? '100%' : (size === 'big' ? 300 : 80)} animation="wave" />
                </>
            ) : (
                <>
                    {metaData?.image ? (
                        <CardMedia
                            component="img"
                            image={metaData.image}
                            alt="Meta Image"
                            style={minimal ? minimalStyles.media : styles.media}
                            onError={() => setMetaData(prev => ({ ...prev, image: null }))}
                        />
                    ) : (
                        <Skeleton variant="rectangular" width="100%" height={minimal ? '100%' : 100} animation="wave" />
                    )}
                    {!minimal &&  (
                        <CardContent style={styles.content}>
                            <Box display="flex" alignItems="center" style={styles.header}>
                                {metaData?.favicon ? (
                                    <img
                                        src={metaData.favicon}
                                        alt="Favicon"
                                        style={styles.favicon}
                                        onError={() => setMetaData(prev => ({ ...prev, favicon: null }))}
                                    />
                                ) : (
                                    <Skeleton variant="circular" width={24} height={24} animation="wave" />
                                )}
                                <Tooltip title={url}>
                                    <Link href={url} target="_blank" rel="noopener noreferrer" style={styles.urlLink}>
                                        <LinkIcon style={styles.linkIcon} />
                                    </Link>
                                </Tooltip>
                                <Typography variant="h6" component="div" noWrap style={styles.title}>
                                    {metaData ? metaData.title : ''}
                                </Typography>
                            </Box>
                            <Tooltip title={metaData ? metaData.description : ''}>
                                <Typography variant="body2" color="text.secondary" style={styles.description}>
                                    {metaData ? metaData.description : ''}
                                </Typography>
                            </Tooltip>
                        </CardContent>
                    )}
                </>
            )}
        </Card>
    );
};

const defaultStyles = {
    card: {
        height: '200px',
        marginTop: '10px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden'
    },
    media: {
        width: '100%',
        height: '80px',
        objectFit: 'contain',
        marginBottom: '10px'
    },
    content: {
        padding: '10px',
        width: '100%'
    },
    header: {
        marginBottom: '10px',
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    favicon: {
        width: '24px',
        height: '24px',
        marginRight: '10px'
    },
    linkIcon: {
        fontSize: 'small',
        verticalAlign: 'middle',
        marginLeft: '5px'
    },
    urlLink: {
        marginRight: '10px',
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        flexGrow: 1
    },
    description: {
        maxHeight: '60px',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
};

const bigStyles = {
    card: {
        height: 'auto',
        marginTop: '10px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden'
    },
    media: {
        width: '100%',
        height: '300px',
        objectFit: 'contain',
        marginBottom: '10px'
    },
    content: {
        padding: '10px',
        width: '100%'
    },
    header: {
        marginBottom: '10px',
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    favicon: {
        width: '24px',
        height: '24px',
        marginRight: '10px'
    },
    linkIcon: {
        fontSize: 'small',
        verticalAlign: 'middle',
        marginLeft: '5px'
    },
    urlLink: {
        marginRight: '10px',
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        flexGrow: 1
    },
    description: {
        maxHeight: '60px',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
};

const minimalStyles = {
    card: {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden'
    },
    media: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'  // Change to 'cover' to maintain aspect ratio and hide overflow
    }
};


export default MetaPreview;
