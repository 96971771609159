// StatCard.js
import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

const StatCard = ({ icon: Icon, title, value, avgValue }) => {
    const cardStyle = {
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
            transform: 'translateY(-10px)',
        },
    };

    return (
        <Box sx={{ margin: 2 }}>
            <Card sx={cardStyle}>
                <CardContent>
                    <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Icon sx={{ marginRight: 1 }} />
                        {title}: {value}
                    </Typography>
                    {avgValue !== undefined && (
                        <Typography variant="body2" color="textSecondary">
                            Avg: {avgValue}
                        </Typography>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};

export default StatCard;
