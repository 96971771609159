import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid, TextField, IconButton, Button, Typography, Tooltip, Divider, Autocomplete, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Skeleton } from '@mui/material';
import { useFormContext, Controller, useFieldArray, useWatch } from 'react-hook-form';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon, DeleteForever as DeleteForeverIcon, Add as AddIcon, Preview as PreviewIcon } from '@mui/icons-material';
import MetaPreview from '../FormAddons/MetaPreview';
import config from '../config.json';
import PreviewDialog from '../FormAddons/PreviewDialog';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const Experience = ({ userId, theme, isMobile }) => {
    const { control, setValue, trigger, formState: { errors } } = useFormContext();
    const { fields: workedInFields, append: appendWorkedIn, remove: removeWorkedIn } = useFieldArray({
        control,
        name: 'workedIn.credited'
    });
    const watchedWorkedIn = useWatch({ control, name: 'workedIn.credited' });
    const [selectedExperienceIndex, setSelectedExperienceIndex] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');
    const [openInvitingMessage, setOpenInvitingMessage] = useState(false);
    const isDark = theme.palette.mode === "dark";
    const [loadingMeta, setLoadingMeta] = useState({}); // Already present

    useEffect(() => {
        if (workedInFields.length === 0) {
            setOpenInvitingMessage(true);
        }
    }, [workedInFields.length]);

    const titleRef = useRef(null);
    const logLineRef = useRef(null);
    const formatRef = useRef(null);
    const proofLinkRef = useRef(null);
    const dateRef = useRef(null);

    useEffect(() => {
        if (titleRef.current) {
            titleRef.current.value = watchedWorkedIn[selectedExperienceIndex]?.name || '';
        }
        if (logLineRef.current) {
            logLineRef.current.value = watchedWorkedIn[selectedExperienceIndex]?.logLine || '';
        }
        if (formatRef.current) {
            formatRef.current.value = watchedWorkedIn[selectedExperienceIndex]?.format || '';
        }
        if (proofLinkRef.current) {
            proofLinkRef.current.value = watchedWorkedIn[selectedExperienceIndex]?.proof_link || '';
        }
        if (dateRef.current) {
            dateRef.current.setSelected(watchedWorkedIn[selectedExperienceIndex]?.date ? new Date(watchedWorkedIn[selectedExperienceIndex]?.date) : null);
        }
    }, [selectedExperienceIndex, watchedWorkedIn]);

    const handleAddNewExperience = () => {
        appendWorkedIn({ name: '', logLine: '', genre: '', format: '', privacy: false, proof_link: '', date: null });
    };

    useEffect(() => {
        setSelectedExperienceIndex(workedInFields.length - 1);
    }, [workedInFields.length]);

    const handleNextExperience = async (index) => {
        const isValid = await trigger(`workedIn.credited.${selectedExperienceIndex}`);
        if (isValid) {
            setSelectedExperienceIndex(index);
        } else {
            const firstErrorField = document.querySelector(`[name^="workedIn.credited.${selectedExperienceIndex}"].Mui-error`);
            if (firstErrorField) {
                firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
                firstErrorField.focus();
            }
        }
    };

    const handlePrivacyToggle = () => {
        const currentExperience = watchedWorkedIn[selectedExperienceIndex];
        if (currentExperience) {
            setValue(`workedIn.credited.${selectedExperienceIndex}.privacy`, !currentExperience.privacy);
        }
    };

    const handleDeleteExperience = () => {
        removeWorkedIn(deleteIndex);
        setDeleteIndex(null);
        setOpenDialog(false);
        if (selectedExperienceIndex === deleteIndex) {
            setSelectedExperienceIndex(0);
        }
    };

    const handleDialogOpen = (index) => {
        setDeleteIndex(index);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setDeleteIndex(null);
    };

    const handleOpenPreview = (url) => {
        setPreviewUrl(url);
        setOpenPreviewDialog(true);
    };

    const handleClosePreview = () => {
        setOpenPreviewDialog(false);
        setPreviewUrl('');
    };

    const currentExperience = watchedWorkedIn[selectedExperienceIndex] || {};

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    style={{
                        height: '25%',
                        overflowX: 'auto',
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        display="inline-block"
                        onClick={handleAddNewExperience}
                        sx={{
                            cursor: 'pointer',
                            width: isMobile ? '50px' : '100px',
                            height: isMobile ? '75px' : '150px',
                            backgroundColor: isDark ? '' : '#f0f0f0',
                            border: `2px solid white`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: 2,
                            position: 'relative'
                        }}
                    >
                        <AddIcon style={{ fontSize: '48px' }} />
                    </Box>
                    {workedInFields.map((item, index) => (
                        <Box
                            key={item.id}
                            display="inline-block"
                            onClick={() => handleNextExperience(index)}
                            sx={{
                                cursor: 'pointer',
                                border: selectedExperienceIndex === index ? `2px solid ${!isDark ? theme.palette.primary.main : 'white'}` : 'none',
                                marginRight: 2,
                                position: 'relative',
                                width: isMobile ? 'auto' : 'auto',
                                height: isMobile ? '75px' : '150px'
                            }}
                        >
                            {loadingMeta[index] ? (
                                <Skeleton variant="rectangular" width={100} height={150} />
                            ) : (
                                watchedWorkedIn[index]?.proof_link ? (
                                    <MetaPreview
                                        url={watchedWorkedIn[index].proof_link}
                                        size="default"
                                        minimal={true}
                                        onLoad={() => setLoadingMeta((prev) => ({ ...prev, [index]: false }))}
                                        onLoading={() => setLoadingMeta((prev) => ({ ...prev, [index]: true }))}
                                    />
                                ) : (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{ width: isMobile ? '50px' : '100%', height: isMobile ? '75px' : '150px', backgroundColor: '#f0f0f0' }}
                                    >
                                        <Typography variant="caption">
                                            {watchedWorkedIn[index]?.name || 'No Title'}
                                        </Typography>
                                    </Box>
                                )
                            )}

                        </Box>
                    ))}
                </Grid>

                <Grid item xs={12}>
                    {workedInFields.length > 0 && (
                        <Box>
                            <Box display="flex" alignItems="center" justifyContent={isMobile ? "flex-end" : "space-between"} marginTop='10px'>
                                {/* {!isMobile && <Divider sx={{ color: 'grey', flexGrow: 1 }}>{`Experience: ${currentExperience.name || 'No Title'}`}</Divider>} */}
                                <Box display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'}>
                                    <Grid item xs={2}>
                                        {isMobile ? (<IconButton onClick={handlePrivacyToggle}>
                                            {watchedWorkedIn[selectedExperienceIndex]?.privacy ? (
                                                <VisibilityOffIcon style={{ color: 'red' }} />
                                            ) : (
                                                <VisibilityIcon style={{ color: 'green' }} />
                                            )}
                                        </IconButton>) : (
                                            <Button
                                                onClick={handlePrivacyToggle}
                                                variant="outlined"
                                                color={watchedWorkedIn[selectedExperienceIndex]?.privacy ? 'error' : 'success'}
                                                startIcon={watchedWorkedIn[selectedExperienceIndex]?.privacy ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                size={isMobile ? "small" : "medium"}
                                            >
                                                {!isMobile && (watchedWorkedIn[selectedExperienceIndex]?.privacy ? 'Private' : 'Public')}
                                            </Button>
                                        )}
                                    </Grid>
                                    {!isMobile && <Typography item > ← Toggle Between Private and Public mode to be shown in public profile</Typography>}
                                </Box>
                                <Tooltip title="Remove Experience">
                                    {isMobile ? (
                                        <IconButton onClick={() => handleDialogOpen(selectedExperienceIndex)}>
                                            <DeleteForeverIcon style={{ color: 'red' }} />
                                        </IconButton>
                                    ) : (
                                        <Button
                                            onClick={() => handleDialogOpen(selectedExperienceIndex)}
                                            variant="outlined"
                                            color="error"
                                            startIcon={<DeleteForeverIcon />}
                                        >
                                            Delete Experience
                                        </Button>
                                    )}
                                </Tooltip>

                            </Box>
                            <Grid container display="flex" spacing={2} marginTop={1}>
                                <Grid item xs={12} sm={3}>
                                    <MetaPreview url={currentExperience.proof_link} size="default" minimal={true} />
                                    {currentExperience.proof_link && <Button
                                        variant="contained"
                                        startIcon={<PreviewIcon />}
                                        onClick={() => handleOpenPreview(currentExperience.proof_link)}
                                        sx={{ marginTop: "-65px", marginLeft: "5px", justifyContent: "center" }}
                                    >
                                        {!isMobile && ("Preview")}
                                    </Button>}
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{ marginTop: "10px" }}>
                                    <TextField
                                        inputRef={titleRef}
                                        fullWidth
                                        label={config.workedIn.credited.title.label}
                                        margin="normal"
                                        size="small"
                                        helperText={errors?.workedIn?.credited?.[selectedExperienceIndex]?.name?.message || config.workedIn.credited.title.helperText}
                                        error={!!errors?.workedIn?.credited?.[selectedExperienceIndex]?.name}
                                        onChange={(e) => setValue(`workedIn.credited.${selectedExperienceIndex}.name`, e.target.value)}
                                    />
                                    <TextField
                                        inputRef={logLineRef}
                                        fullWidth
                                        label={config.workedIn.credited.logLine.label}
                                        margin="normal"
                                        size="small"
                                        multiline
                                        minRows={4}
                                        maxRows={5}
                                        helperText={errors?.workedIn?.credited?.[selectedExperienceIndex]?.logLine?.message || config.workedIn.credited.logLine.helperText}
                                        error={!!errors?.workedIn?.credited?.[selectedExperienceIndex]?.logLine}
                                        onChange={(e) => setValue(`workedIn.credited.${selectedExperienceIndex}.logLine`, e.target.value)}
                                    />
                                    <Autocomplete
                                        options={config.workedIn.credited.format.options}
                                        getOptionLabel={(option) => option}
                                        value={currentExperience.format || null}
                                        onChange={(event, value) => setValue(`workedIn.credited.${selectedExperienceIndex}.format`, value)}
                                        isOptionEqualToValue={(option, value) => option === value || value === null}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={config.workedIn.credited.format.label}
                                                placeholder=""
                                                margin="normal"
                                                size="small"
                                                helperText={errors?.workedIn?.credited?.[selectedExperienceIndex]?.format?.message || config.workedIn.credited.format.helperText}
                                                error={!!errors?.workedIn?.credited?.[selectedExperienceIndex]?.format}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        inputRef={proofLinkRef}
                                        fullWidth
                                        label={config.workedIn.credited.proof_link.label}
                                        margin="normal"
                                        size="small"
                                        helperText={errors?.workedIn?.credited?.[selectedExperienceIndex]?.proof_link?.message || config.workedIn.credited.proof_link.helperText}
                                        error={!!errors?.workedIn?.credited?.[selectedExperienceIndex]?.proof_link}
                                        onChange={(e) => setValue(`workedIn.credited.${selectedExperienceIndex}.proof_link`, e.target.value)}
                                    />
                                    <Controller
                                        name={`workedIn.credited.${selectedExperienceIndex}.date`}
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) => (
                                            <DatePicker
                                                ref={dateRef}
                                                selected={field.value ? new Date(field.value) : null}
                                                onChange={(date) => {
                                                    setValue(`workedIn.credited.${selectedExperienceIndex}.date`, date);
                                                    field.onChange(date);
                                                }}
                                                dateFormat="yyyy/MM"
                                                showMonthYearPicker
                                                showIcon
                                                placeholderText="Select month and year"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </Grid>
            </Grid>

            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Delete Experience</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this experience? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteExperience} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <PreviewDialog open={openPreviewDialog} onClose={handleClosePreview} url={previewUrl} />
            <Dialog open={openInvitingMessage} onClose={() => setOpenInvitingMessage(false)}>
                <DialogTitle>{config.workedIn.invitingMessage.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {config.workedIn.invitingMessage.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenInvitingMessage(false);
                        handleAddNewExperience();
                    }} color="primary">
                        Yes
                    </Button>
                    <Button onClick={() => setOpenInvitingMessage(false)} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Experience;
