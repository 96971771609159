// StoryOfMyLifeComponent.jsx
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Typography } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles

const StoryOfMyLifeComponent = () => {
    const { control } = useFormContext(); // Accessing form context for control

    const toolbarOptions = [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['link', 'image', 'video'],
        ['clean']  // remove formatting button
    ];

    return (
        <div>
            <Typography variant="h6">Story Of My Life</Typography>
            <Controller
                name="storyOfmyLife.value"
                control={control}
                render={({ field }) => (
                    <ReactQuill
                        value={field.value || ''}
                        onChange={field.onChange}
                        theme="snow"
                        modules={{
                            toolbar: {
                                container: toolbarOptions
                            }
                        }}
                    />
                )}
            />
        </div>
    );
};

export default StoryOfMyLifeComponent;
