import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import './Mediaquery.css';
import { useAxios } from '../../contexts/AxiosContext'

const Mediaquery = ({ type, label, onSelect, providedHelperText="" }) => {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const axios = useAxios()

    useEffect(() => {
        if (query.length < 3) {
            setSuggestions([]);
            return;
        }

        const fetchSuggestions = async () => {
            try {
                const response = await axios.get(`/mediaquery/autocomplete`, {
                    params: { type, query }
                });
                // console.log(response.data)
                setSuggestions(response.data);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        };

        fetchSuggestions();
    }, [query, type]);

    const handleSelect = (suggestion) => {
        onSelect(suggestion);
        setQuery('');
        setSuggestions([]);
    };

    return (
        <div className="autocomplete-section">
            <TextField
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder={`Search ${label}`}
                helperText={providedHelperText}
                fullWidth
            />
            {suggestions.length > 0 && (
                <ul className="suggestions-list">
                    {suggestions.map((suggestion, index) => (
                        <li key={index} onClick={() => handleSelect(suggestion)}>
                            {suggestion.poster_path && <img src={suggestion.poster_path} alt={suggestion.title || 'Poster'} />}
                            <div>
                                <span>{suggestion.title}</span>
                                <small>{suggestion.release_date}</small>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Mediaquery;
