import React from 'react';

const ImageWithNoContextMenu = React.forwardRef(({ src, alt, ...props }, ref) => {
  return (
    <img 
      src={src} 
      alt={alt} 
      onContextMenu={(e) => e.preventDefault()} 
      ref={ref} // Attach the ref here
      style={{ display: 'block', maxWidth: '100%' }} // Add any other styles as needed
      {...props} // Pass any additional props
    />
  );
});

export default ImageWithNoContextMenu;
