import React, { useState, useEffect } from 'react';
import {
  Box, Grid, Typography, TextField, Button, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, IconButton
} from '@mui/material';
import {
  Add as AddIcon, Movie as MovieIcon, Tv as TvIcon,
  Book as BookIcon, VideogameAsset as VideogameAssetIcon,
  Category as CategoryIcon
} from '@mui/icons-material';
import { useFormContext, useWatch } from 'react-hook-form';
import Mediaquery from '../FormAddons/Mediaquery';
import { useAxios } from '../../../contexts/AxiosContext';

const Favourites = ({ media, onMediaUpdate, isMobile, theme, step }) => {
  const { setValue, getValues, formState: { errors }, trigger, control } = useFormContext();
  const [selectedFavourite, setSelectedFavourite] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(step || 'movie');
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [currentMediaCategory, setCurrentMediaCategory] = useState('');
  const axios = useAxios();
  const isDark = theme.palette.mode === "dark"

  const watchedFavourites = useWatch({
    control,
    name: 'interests.favourites',
    defaultValue: {},
  });


  const openAddMediaDialog = (category) => {
    setSelectedFavourite(null);
    setCurrentMediaCategory(category);
    setAddDialogOpen(true);
  };

  const closeAddMediaDialog = () => {
    setAddDialogOpen(false);
  };

  const handleAddMedia = async (selectedItem, category) => {
    // console.log("type: ", category, " Data: ", selectedItem);
    try {
      const response = await axios.post('/mediaQuery/lookup', {
        type: category,
        data: selectedItem
      });
      console.log("Selected: ", selectedItem)

      const { id } = response.data;

      const mediaPath = `interests.favourites.${category}.value`;
      const updatedFavourites = [...getValues(mediaPath), { reference: id, personalNote: '' }];

      setValue(mediaPath, updatedFavourites);
      const newMedia = { ...selectedItem, _id: id, personalNote: '' };
      console.log("adding new media: ", newMedia)
      const updatedMedia = {
        ...media,
        [category]: [...(media[category] || []), newMedia]
      };
      onMediaUpdate(updatedMedia);
      // setSelectedFavourite({ ...selectedItem, _id: id, personalNote: '' });

      handleThumbnailClick({ reference: id, personalNote: '' })
      closeAddMediaDialog();
    } catch (error) {
      console.error(`Error adding/updating ${category}:`, error);
    }
  };

  const handleThumbnailClick = (item) => {
    if (selectedFavourite) {
      trigger(`interests.favourites.${selectedCategory}.value`).then(isValid => {
        if (isValid) {
          const selectedItemArray = watchedFavourites[selectedCategory]?.value || [];
          const selectedItem = selectedItemArray.find(fav => fav.reference === item.reference);
          if (!selectedItem) {
            console.error('No matching favourite found for the selected item:', item);
            return;
          }
          setSelectedFavourite(selectedItem);
          //   console.log("Selected item for preview:", selectedItem);
        } else {
          console.log("Validation errors: ", errors);
        }
      });
    } else {
      const selectedItemArray = watchedFavourites[selectedCategory]?.value || [];
      const selectedItem = selectedItemArray.find(fav => fav.reference === item.reference);
      if (!selectedItem) {
        console.error('No matching favourite found for the selected item:', item);
        return;
      }
      setSelectedFavourite(selectedItem);
      //   console.log("Selected item for preview:", selectedItem);
    }
  };





  const handleNoteChange = (event) => {
    const newNote = event.target.value;
    setSelectedFavourite(prev => prev ? ({ ...prev, personalNote: newNote }) : null);

    if (selectedFavourite) {
      const updatedFavourites = watchedFavourites[selectedCategory]?.value?.map(fav =>
        fav.reference === selectedFavourite.reference ? { ...fav, personalNote: newNote } : fav
      ) || [];

      setValue(`interests.favourites.${selectedCategory}.value`, updatedFavourites, {
        shouldValidate: true,
        shouldDirty: true
      });

      // trigger(`interests.favourites.${selectedCategory}.value`).then((isValid) => {
      //     if (!isValid) {
      //         console.log("Validation errors:", errors);
      //     }
      // });
    }
  };





  const handleDelete = () => {
    // Get the current category's favourites array
    const currentFavourites = watchedFavourites[selectedCategory]?.value || [];

    // Filter out the item to be deleted
    const updatedFavourites = currentFavourites.filter(fav => fav.reference !== selectedFavourite.reference);

    // Update the form state with the new favourites array
    setValue(`interests.favourites.${selectedCategory}.value`, updatedFavourites, {
      shouldValidate: true,
      shouldDirty: true
    });

    setDeleteDialogOpen(false);
    setSelectedFavourite(null);

    // console.log("Deleted media item:", selectedFavourite);
    // console.log("Updated form state after delete:", getValues(`interests.favourites.${selectedCategory}.value`));
  };


  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const renderCategoryButton = (icon, category) => {
    const label = category.charAt(0).toUpperCase() + category.slice(1); // Capitalize the first letter
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          onClick={async () => {
            if (selectedFavourite) {
              const isValid = await trigger(`interests.favourites.${selectedCategory}.value`);
              if (isValid) {
                setSelectedCategory(category);
                setSelectedFavourite(null)
              } else {
                // console.log("Validation errors: ", errors);
              }
            } else {
              setSelectedCategory(category);
            }
          }}
          sx={{
            borderRadius: '8px',
            border: selectedCategory === category ? `2px solid ${!isDark ? theme.palette.primary.main : 'white'}` : '2px solid transparent',
            color: selectedCategory === category ? (!isDark ? theme.palette.primary.main : 'white') : 'inherit',
            transition: 'border 0.3s ease-in-out',
            padding: isMobile ? '2px 2px' : '6px 12px',
            margin: '0 10px', // Adjust margin to position the lines better
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {icon}
          {!isMobile && <Typography variant="button" sx={{ ml: 1 }}>
            {label}
          </Typography>}
        </IconButton>
        {!isMobile && (category !== 'book' && <hr style={{ width: '30px', border: '1px solid #DDD', verticalAlign: 'middle' }} />)}
      </Box>
    );
  };



  const renderMediaGallery = (items, title) => (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => openAddMediaDialog(selectedCategory)}
        >
          Add {title}
        </Button>
      </Box>
      <Box sx={{
        backgroundColor: isDark ? "#181818" : '#f8f8f8',
        padding: '10px',
        borderRadius: '5px',
        marginBottom: '20px',
        display: 'flex',
        flexWrap: isMobile ? 'nowrap' : 'wrap', // Set flexWrap to nowrap for mobile view
        gap: '10px',
        justifyContent: 'flex-start',
        overflowX: isMobile ? 'auto' : 'visible', // Add overflowX scroll for mobile view
        height: isMobile ? '150px' : 'auto', // Set height to 100px for mobile view
      }}>
        {items.map((item, index) => {
          const mediaItem = media[selectedCategory].find(mediaEntry => mediaEntry._id === item.reference);

          return (
            <Box
              key={item.reference}
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={() => handleThumbnailClick(item)}
              sx={{
                cursor: 'pointer',
                width: isMobile ? '100px' : 'calc(25% - 10px)', // Responsive width
                flexShrink: 0, // Prevent shrinking
                height: isMobile ? '130px' : 'auto', // Set height to 150px for mobile view
                aspectRatio: '2 / 3', // Aspect ratio for thumbnails
                backgroundColor: '#f0f0f0',
                borderRadius: '3px',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)', // Slightly larger scale
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Soft shadow
                },
                animation: `fade-in 0.5s ease-in-out ${index * 0.1}s both`,  // Smooth fade-in animation
              }}
            >
              {mediaItem?.poster_path ? (
                <img
                  src={mediaItem.poster_path}
                  alt={mediaItem.title}
                  style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '3px' }}
                />
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  style={{ width: '100%', height: '100%', backgroundColor: '#f0f0f0', padding: '5px', borderRadius: '3px' }}
                >
                  <Typography variant="caption" align="center">
                    {mediaItem?.title || 'Unknown Title'}
                  </Typography>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </>
  );






  useEffect(() => {
    // console.log("Selected category changed to:", selectedCategory);
    // console.log("Updated gallery items on category change:", watchedFavourites);

    // Only update combinedMedia for logging purposes
    // setCombinedMedia(media[selectedCategory] || []);
  }, [selectedCategory, watchedFavourites, media]);

  useEffect(() => {
    // console.log("Rendering gallery items:", watchedFavourites);
  }, [watchedFavourites]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ overflow: "auto" }}>
        <Box display="flex" justifyContent={isMobile ? "flex-start" : "center"} alignItems="center" mb={2} >
          {renderCategoryButton(<MovieIcon />, 'movie')}
          {renderCategoryButton(<TvIcon />, 'series')}
          {renderCategoryButton(<CategoryIcon />, 'documentary')}
          {renderCategoryButton(<VideogameAssetIcon />, 'game')}
          {renderCategoryButton(<BookIcon />, 'book')}
        </Box>
      </Grid>
      <Box sx={{ marginBottom: '16px' }}>
        <Typography variant="body3" sx={{ marginBottom: '8px'}}>
          Share your favourite Film, Series, Documentary, Game, Book and Creators (Will be added shortly), along with a note on why they inspire you.
          This helps others understand your taste and makes you more discoverable for potential hires.
        </Typography>

      </Box>
      <Grid item xs={12} md={7}>
        {renderMediaGallery(watchedFavourites[selectedCategory]?.value || [], `${selectedCategory.charAt(0).toUpperCase() + selectedCategory.slice(1)}`)}
      </Grid>


      <Grid item xs={12} md={5}>
        {selectedFavourite ? (() => {
          const mediaItem = media[selectedCategory]?.find(mediaItem => mediaItem._id === selectedFavourite.reference);
          const currentIndex = watchedFavourites[selectedCategory]?.value?.findIndex(fav => fav.reference === selectedFavourite.reference);

          return (
            <Box display="flex" flexDirection="column" height="100%" marginTop={isMobile ? "auto" : "55px"}>
              <Box display="flex" mb={2}>
                <Box flex="1 0 auto">
                  {mediaItem?.poster_path ? (
                    <img
                      src={mediaItem.poster_path}
                      alt={mediaItem.title}
                      style={{ width: '150px', height: '225px', objectFit: 'cover' }}
                    />
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      style={{ width: '150px', height: '225px', backgroundColor: '#f0f0f0', padding: '5px' }}
                    >
                      <Typography variant="caption" align="center">
                        {mediaItem?.title || 'Unknown Title'}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box ml={2} flex="2 0 auto" sx={{ maxWidth: 'min-content', overflow: 'hidden' }}> {/* Set a fixed maxWidth */}
                  <Typography variant="h6" sx={{ whiteSpace: 'normal', wordWrap: 'break-word', fontSize: '0.875rem' }}>
                    {mediaItem?.title}
                  </Typography>
                  <Typography variant="body2">
                    {mediaItem?.genres?.join(', ')}
                  </Typography>
                  {/* {console.log(mediaItem)} */}
                  <Typography variant="body2">
                    {new Date(mediaItem?.release_date).getFullYear()}
                  </Typography>
                  <Box display="flex" justifyContent="space-between" flexDirection="column" gap={2} mt={2}>
                    <Button variant="contained" onClick={() => {
                      trigger(`interests.favourites.${selectedCategory}.value`).then(isValid => {
                        if (isValid) {
                          setSelectedFavourite(null);
                        } else {
                          // console.log("Validation errors: ", errors);
                        }
                      });
                    }}>
                      Close
                    </Button>
                    <Button variant="contained" color="secondary" onClick={openDeleteDialog}>Delete</Button>
                  </Box>
                </Box>
              </Box>

              <Box flex="1 0 auto" >
                <TextField
                  label="Personal Note"
                  fullWidth
                  multiline
                  minRows={4}
                  maxRows={6}
                  value={selectedFavourite?.personalNote || ''}
                  onChange={handleNoteChange}
                  error={!!selectedFavourite && !!errors?.interests?.favourites?.[selectedCategory]?.value?.[currentIndex]?.personalNote}
                  helperText={
                    !!selectedFavourite &&
                    errors?.interests?.favourites?.[selectedCategory]?.value?.[currentIndex]?.personalNote?.message
                  }
                />
                {/* {console.log("Form errors: ", errors)} */}
              </Box>
            </Box>
          );
        })() : (<Typography variant='h3'>Select a Favourite</Typography>)}
      </Grid>



      <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
        <DialogTitle>Delete Favourite</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this favourite? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={addDialogOpen}
        onClose={closeAddMediaDialog}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            height: '60vh',
            overflow: 'auto'
          }
        }}
      >
        <DialogTitle>Add {currentMediaCategory.charAt(0).toUpperCase() + currentMediaCategory.slice(1)}</DialogTitle>
        <DialogContent>
          <Mediaquery type={currentMediaCategory} label={`Add ${currentMediaCategory.charAt(0).toUpperCase() + currentMediaCategory.slice(1)}`} onSelect={(selectedItem) => handleAddMedia(selectedItem, currentMediaCategory)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAddMediaDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Favourites;
