import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Fade,
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Autocomplete,
  TextField,
  IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BookIcon from '@mui/icons-material/Book';
import SchoolIcon from '@mui/icons-material/School';
import DescriptionIcon from '@mui/icons-material/Description';
import LanguageIcon from '@mui/icons-material/Language';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import formConfig from "../config.json";

// Configuration Object
const config = formConfig.userDetails.languageOptions;

const skills = ['l', 's', 'r', 'w'];

const iconsMap = {
  BookIcon: <BookIcon />,
  SchoolIcon: <SchoolIcon />,
  DescriptionIcon: <DescriptionIcon />,
  LanguageIcon: <LanguageIcon />,
  ImportContactsIcon: <ImportContactsIcon />,
  CastForEducationIcon: <CastForEducationIcon />
};


const LanguageProficiencyComponent = (isDark) => {
  const { control, formState: { errors } } = useFormContext();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'userDetails.languages_v2'
  });
  const watchedLanguages = useWatch({
    control,
    name: 'userDetails.languages_v2',
    defaultValue: []
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState({ value: '', privacy: false, lsrw: { l: '', s: '', r: '', w: '' } });
  const [editMode, setEditMode] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [languageToDelete, setLanguageToDelete] = useState(null);

  useEffect(() => {
    if (watchedLanguages.length === 0) {
      handleOpenDialog();
    }
  }, [watchedLanguages]);

  const availableLanguages = config.availableLanguages.filter(
    lang => !watchedLanguages.some(l => l.value === lang)
  );

  const handleOpenDialog = (language = { value: '', privacy: false, lsrw: { l: '', s: '', r: '', w: '' } }) => {
    setCurrentLanguage(language);
    setEditMode(!!language.value);
    setOpenDialog(true);
  };

  const handleOpenConfirmDialog = (language) => {
    setLanguageToDelete(language);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    if (languageToDelete) {
      remove(watchedLanguages.findIndex(lang => lang.value === languageToDelete.value));
    }
    setOpenConfirmDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const handleSaveLanguage = () => {
    if (!currentLanguage.value || Object.values(currentLanguage.lsrw).some(level => !level)) {
      alert(config.messages.selectLevel);
      return;
    }
    if (editMode) {
      update(watchedLanguages.findIndex(lang => lang.value === currentLanguage.value), currentLanguage);
    } else {
      append(currentLanguage);
    }
    setOpenDialog(false);
  };

  const handleLanguageChange = (event, newValue) => {
    setCurrentLanguage(prev => ({ ...prev, value: newValue, lsrw: { l: '', s: '', r: '', w: '' } }));
  };

  const handleSelectLevel = (skill, level) => {
    setCurrentLanguage(prev => ({ ...prev, lsrw: { ...prev.lsrw, [skill]: level } }));
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Button variant="contained" startIcon={<AddIcon />} onClick={() => handleOpenDialog()}>
        {config.buttonTexts.addLanguage} ({watchedLanguages.length})
      </Button>
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        {watchedLanguages.map((lang, index) => (
          <Grid item key={index}>
            <Card sx={{
              boxShadow: 1,
              borderRadius: 2,
              backgroundColor: 'background.paper',
              width: '300px',
              height: '250px',
              display: 'flex',
              flexDirection: 'column',
              padding: 2,
              position: 'relative',
            }}>
              <CardContent sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                paddingRight: 4
              }}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                  borderBottom: '1px solid #ddd',
                  paddingBottom: 1
                }}>
                  <Typography variant="h6" sx={{ fontSize: '1rem', flexGrow: 1 }}>
                    {lang.value}
                  </Typography>
                  <IconButton size="small" onClick={() => handleOpenDialog(lang)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton size="small" onClick={() => handleOpenConfirmDialog(lang)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
                <Grid container spacing={1}>
                  {Object.entries(lang.lsrw).map(([key, value]) => (
                    <Grid container key={key} spacing={1} sx={{ borderBottom: `1px dotted ${isDark ? "#484848" : "gba(0, 0, 0, 0.1)"}`, paddingBottom: '2px', marginBottom: "2px" }}>
                      <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '0.875rem' }}>{config.skillMap[key]}</Typography>
                      </Grid>
                      <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                        {iconsMap[config.levelMap[value]?.icon]}
                      </Grid>
                      <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <Typography sx={{ fontSize: '0.875rem' }}>{config.levelMap[value]?.label}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="md">
        <DialogTitle>{watchedLanguages.length === 0 ? config.dialogTitles.addFirstLanguage : editMode ? config.dialogTitles.edit : config.dialogTitles.add}</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={availableLanguages}
            value={currentLanguage.value}
            onChange={handleLanguageChange}
            renderInput={(params) => <TextField {...params} label="Select Language" variant="outlined" fullWidth required />}
            disabled={editMode}
          />
          <Box sx={{ marginTop: 2 }}>
            <Typography sx={{ fontSize: 12, color: 'gray', marginBottom: 2 }}>
              {config.messages.clickToSelect}
            </Typography>
            <Table sx={{ minWidth: 700, boxShadow: 3 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Skill / Level</TableCell>
                  {Object.values(config.levelMap).map(({ label, icon }, index) => (
                    <TableCell key={index} align="center">
                      {iconsMap[icon]}<br />
                      {label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {skills.map(skill => (
                  <TableRow key={skill}>
                    <TableCell component="th" scope="row">{config.skillMap[skill]}</TableCell>
                    {[1, 2, 3, 4, 5, 6].map(level => (
                      <TableCell key={level} align="center" sx={{ position: 'relative', padding: 1 }}>
                        <Fade in={currentLanguage.lsrw[skill] === level}>
                          <CheckCircleIcon style={{ color: 'green', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                        </Fade>
                        {currentLanguage.lsrw[skill] !== level && (
                          <div onClick={() => handleSelectLevel(skill, level)} style={{ height: '24px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', cursor: 'pointer' }}>
                            <CheckCircleIcon style={{ color: 'lightgrey' }} />
                          </div>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          <Button onClick={handleSaveLanguage} disabled={!currentLanguage.value || Object.values(currentLanguage.lsrw).some(level => !level)} sx={{ marginTop: 2, float: 'right' }}>
            {config.buttonTexts.save}
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog open={openConfirmDialog} onClose={handleCancelDelete} maxWidth="sm" fullWidth>
        <DialogTitle>{config.dialogTitles.confirmDelete}</DialogTitle>
        <DialogContent>
          <Typography>{config.messages.confirmDelete.replace("${language}", languageToDelete?.value)}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
            <Button onClick={handleCancelDelete} color="primary">
              {config.buttonTexts.cancel}
            </Button>
            <Button onClick={handleConfirmDelete} color="error" sx={{ marginLeft: 1 }}>
              {config.buttonTexts.delete}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default LanguageProficiencyComponent;
