import React, { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { List, ListItem, ListItemText, IconButton, Box, Tooltip, Typography, ButtonGroup, Button } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import StarIcon from '@mui/icons-material/Star';
import config from "../config.json";

const itemLabels = {
  ownWorks: config.sections.find(section => section.key === 'myStories').label,
  workedIn: config.sections.find(section => section.key === 'experience').label,
  favourites: config.sections.find(section => section.key === 'favourites').label
};

const PublicOrderPreference = ({ theme }) => {
  const { setValue, getValues, control } = useFormContext();
  const [items, setItems] = useState(getValues('userPreference.publicOrder'));

  // Watch form state for counts
  const watchedOwnWorks = useWatch({ control, name: 'ownWorks.portfolio', defaultValue: [] });
  const watchedCreditedWorks = useWatch({ control, name: 'workedIn.credited', defaultValue: [] });
  const watchedFavourites = useWatch({ control, name: 'interests.favourites', defaultValue: {} });

  useEffect(() => {
    if (items.length === 0) {
      const defaultOrder = ['ownWorks', 'workedIn', 'favourites'];
      setItems(defaultOrder);
      setValue('userPreference.publicOrder', defaultOrder);
    }
  }, [items, setValue]);

  const moveItem = (index, direction) => {
    const newIndex = index + direction;
    if (newIndex < 0 || newIndex >= items.length) return;

    const updatedItems = [...items];
    const [movedItem] = updatedItems.splice(index, 1);
    updatedItems.splice(newIndex, 0, movedItem);

    setItems(updatedItems);
    setValue('userPreference.publicOrder', updatedItems);
  };

  const renderListItem = (item, index) => (
    <ListItem
      key={item}
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        margin: theme.spacing(1, 0),
        backgroundColor: theme.palette.action.hover,
        borderRadius: theme.shape.borderRadius,
        transition: 'background-color 0.3s',
        '&:hover': {
          backgroundColor: theme.palette.action.selected,
        },
      }}
    >
      <ListItemText primary={itemLabels[item]} />
      <ButtonGroup variant="outlined" size="small" sx={{ marginLeft: 'auto' }}>
        <Button
          onClick={() => moveItem(index, -1)}
          disabled={index === 0}
        >
          <ArrowUpwardIcon />
        </Button>
        <Button
          onClick={() => moveItem(index, 1)}
          disabled={index === items.length - 1}
        >
          <ArrowDownwardIcon />
        </Button>
      </ButtonGroup>
      {item === calculateRecommendation() && (
        <Tooltip title="Recommended">
          <StarIcon color="primary" sx={{ marginLeft: 'auto' }} />
        </Tooltip>
      )}
    </ListItem>
  );

  const calculateRecommendation = () => {
    const ownWorksCount = watchedOwnWorks.length;
    const creditedWorksCount = watchedCreditedWorks.length;
    const favouritesCount = Object.values(watchedFavourites).reduce((acc, val) => acc + (val?.value?.length || 0), 0);

    if (ownWorksCount > 0 && creditedWorksCount > 0) {
      return ownWorksCount >= creditedWorksCount ? 'ownWorks' : 'workedIn';
    } else if (ownWorksCount > 0) {
      return 'ownWorks';
    } else if (creditedWorksCount > 0) {
      return 'workedIn';
    } else if (favouritesCount > 0) {
      return 'favourites';
    } else {
      return null;
    }
  };

  const isMobile = theme.breakpoints.down('xs');

  return (
    <Box sx={{ padding: theme.spacing(2), backgroundColor: theme.palette.background.default }}>
      <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', marginBottom: theme.spacing(2) }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ marginLeft: theme.spacing(1) }}>My Stories: {watchedOwnWorks.length}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ marginLeft: theme.spacing(1) }}>My Experience: {watchedCreditedWorks.length}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ marginLeft: theme.spacing(1) }}>Favourites: {Object.values(watchedFavourites).reduce((acc, val) => acc + (val?.value?.length || 0), 0)}</Typography>
        </Box>
      </Box>
      <List sx={{ width: '100%', backgroundColor: theme.palette.background.paper, borderRadius: theme.shape.borderRadius }}>
        {items.map((item, index) => renderListItem(item, index))}
      </List>
    </Box>
  );
};

export default PublicOrderPreference;
