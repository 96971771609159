import errorCodes from '../utils/errorCodes.json'
import logger from '../logger'

const fetchProfileHelper = async (axios, userId) => {
    try {
        const response = await axios.get(`/profile/${userId}`);
        
        if (response.status !== 200) {
            logger(errorCodes.NO_RESPONSE_FROM_SERVER.message, 'error', ['env:production', 'issue:network', 'component:ProfileComponent'], 'soozh-beta-frontend');
            throw new Error(errorCodes.NO_RESPONSE_FROM_SERVER.message);
        }

        const data = response.data;
        logger(`Profile data fetched successfully: ${JSON.stringify(data)}`, 'debug', ['env:production', 'issue:profile-fetch', 'component:ProfileComponent'], 'soozh-beta-frontend');
        return data;
    } catch (error) {
        if (error.response && error.response.data && error.response.data.code) {
            const errorMessage = errorCodes[error.response.data.code]?.message || 'An error occurred while fetching the profile. Please try again.';
            logger(`Error fetching profile: ${errorMessage}`, 'error', ['env:production', 'issue:profile-fetch', 'component:ProfileComponent'], 'soozh-beta-frontend');
        } else {
            logger(`Error fetching profile: ${errorCodes.INTERNAL_SERVER_ERROR.message}`, 'error', ['env:production', 'issue:profile-fetch', 'component:ProfileComponent'], 'soozh-beta-frontend');
        }
        console.error('Error fetching profile:', error);
        throw error;
    }
};

export default fetchProfileHelper;
