import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, Typography } from '@mui/material';

const PreviewDialog = ({ open, onClose, url }) => {
    const handleOpenInBrowser = () => {
        window.open(url, '_blank');
    };

    const getPreviewContent = (url) => {
        // Handling direct image links
        if (url.match(/\.(jpeg|jpg|gif|png)$/)) {
            return <img src={url} alt="Preview" style={{ width: '100%', height: 'auto' }} />;
        }
        // Handling YouTube and Vimeo links
        else if (url.match(/(youtube\.com|youtu\.be|vimeo\.com)/)) {
            const videoId = url.match(/(?:v=|\/)([a-zA-Z0-9_-]{11})/)[1];
            return (
                <iframe
                    src={`https://www.youtube.com/embed/${videoId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Video"
                    style={{ width: '100%', height: '400px' }}
                ></iframe>
            );
        }
        // Handling PDF files
        else if (url.match(/\.pdf$/)) {
            return (
                <iframe
                    src={`https://docs.google.com/viewer?url=${url}&embedded=true`}
                    frameBorder="0"
                    style={{ width: '100%', height: '400px' }}
                    title="Document"
                ></iframe>
            );
        }
        // Fallback for other URLs
        else {
            // Instead of trying to embed unknown content, suggest opening in browser
            return (
                <Typography variant="body1" style={{ textAlign: 'center' }}>
                    This content cannot be previewed directly here. Please use the "Open in Browser" button to view.
                </Typography>
            );
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogContent>
                {getPreviewContent(url)}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOpenInBrowser} color="primary">
                    Open in Browser
                </Button>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PreviewDialog;
