import React, { useState, useRef, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Checkbox,
  FormControlLabel,
  Fade
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import { Controller, useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import formConfig from '../config.json';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const sampleData = {
  education: {
    id: 'sample-education',
    institution: 'School / College / Uni',
    degree: 'B.Sc. Sample Degree',
    fromDate: new Date().setFullYear(new Date().getFullYear() - 4),
    toDate: new Date(),
    privacy: true
  },
  awards: {
    id: 'sample-award',
    name: 'Award Name',
    awardedBy: 'Sample Organization',
    year: new Date().setFullYear(new Date().getFullYear() - 1),
    privacy: true
  },
  workExperience: {
    id: 'sample-work',
    company: 'Organisation Name',
    position: 'Job Position',
    fromDate: new Date().setFullYear(new Date().getFullYear() - 5),
    toDate: new Date(),
    privacy: true
  }
};


const config = formConfig.qualifications;

const QualificationComponent = () => {
  const { control, formState: { errors } } = useFormContext();
  const { fields: educationFields, append: appendEducation, remove: removeEducation, update: updateEducation } = useFieldArray({
    control,
    name: 'qualifications.education'
  });
  const { fields: awardFields, append: appendAward, remove: removeAward, update: updateAward } = useFieldArray({
    control,
    name: 'qualifications.awards'
  });
  const { fields: workExperienceFields, append: appendWorkExperience, remove: removeWorkExperience, update: updateWorkExperience } = useFieldArray({
    control,
    name: 'qualifications.workExperience'
  });
  const watchedEducation = useWatch({ control, name: 'qualifications.education', defaultValue: [] });
  const watchedAwards = useWatch({ control, name: 'qualifications.awards', defaultValue: [] });
  const watchedWorkExperience = useWatch({ control, name: 'qualifications.workExperience', defaultValue: [] });

  const [openDialog, setOpenDialog] = useState(false);
  const [currentCategory, setCurrentCategory] = useState('');
  const [currentItem, setCurrentItem] = useState({ id: '', institution: '', degree: '', name: '', year: '', awardedBy: '', company: '', position: '', privacy: false });
  const [currentIndex, setCurrentIndex] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const [eduFromDate, setEduFromDate] = useState(null);
  const [eduToDate, setEduToDate] = useState(null);
  const [expFromDate, setExpFromDate] = useState(null);
  const [expToDate, setExpToDate] = useState(null);
  const [awardedOn, setAwardedOn] = useState(null);

  const eduFromDateRef = useRef(null);
  const eduToDateRef = useRef(null);
  const expFromDateRef = useRef(null);
  const expToDateRef = useRef(null);
  const awardedOnRef = useRef(null);

  useEffect(() => {
    if (openDialog) {
      if (currentCategory === 'education') {
        setEduFromDate(currentItem.fromDate || null);
        setEduToDate(currentItem.toDate || null);
      } else if (currentCategory === 'workExperience') {
        setExpFromDate(currentItem.fromDate || null);
        setExpToDate(currentItem.toDate || null);
      } else if (currentCategory === 'awards') {
        setAwardedOn(currentItem.year || null);
      }
    }
  }, [openDialog, currentItem, currentCategory]);

  const handleOpenDialog = (category, item = { id: Date.now().toString(), institution: '', degree: '', name: '', year: '', awardedBy: '', company: '', position: '', privacy: false }, index = null) => {
    setCurrentItem(item);
    setCurrentCategory(category);
    setEditMode(index !== null);
    setCurrentIndex(index);
    setOpenDialog(true);
  };

  const handleOpenDeleteConfirmDialog = (item, category) => {
    setItemToDelete(item);
    setCurrentCategory(category);
    setOpenDeleteConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    if (itemToDelete) {
      const index = currentCategory === 'education' ? watchedEducation.findIndex(e => e.id === itemToDelete.id)
        : currentCategory === 'awards' ? watchedAwards.findIndex(a => a.id === itemToDelete.id)
          : watchedWorkExperience.findIndex(w => w.id === itemToDelete.id);
      currentCategory === 'education' ? removeEducation(index)
        : currentCategory === 'awards' ? removeAward(index)
          : removeWorkExperience(index);
    }
    setOpenDeleteConfirmDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleSaveItem = () => {
    const updatedItem = {
      ...currentItem,
      fromDate: currentCategory === 'education' ? eduFromDate : currentCategory === 'workExperience' ? expFromDate : undefined,
      toDate: currentCategory === 'education' ? eduToDate : currentCategory === 'workExperience' ? expToDate : undefined,
      year: currentCategory === 'awards' ? awardedOn : undefined
    };

    if (editMode) {
      currentCategory === 'education'
        ? updateEducation(currentIndex, updatedItem)
        : currentCategory === 'awards'
          ? updateAward(currentIndex, updatedItem)
          : updateWorkExperience(currentIndex, updatedItem);
    } else {
      currentCategory === 'education'
        ? appendEducation(updatedItem)
        : currentCategory === 'awards'
          ? appendAward(updatedItem)
          : appendWorkExperience(updatedItem);
    }
    setOpenDialog(false);
    setCurrentIndex(null);
  };

  const renderCards = (items, category) => {
    if (items.length === 0) {
      // Render a default card when there are no items
      return (
        <>
          <Card sx={{
            marginBottom: '5px',
            boxShadow: 2,
            width: '100%',
            height: '130px',
            borderRadius: '8px',
            border: '2px dashed grey', // Grey outline for the default card
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'grey'
          }}>
            <CardContent>
              <Typography variant="h6">
                No {category} Added
              </Typography>
            </CardContent>
          </Card>
          {/* Sample filled card below the default card */}
          <Card sx={{
            marginBottom: '5px',
            boxShadow: 2,
            width: '100%',
            height: '130px',
            borderRadius: '8px',
            transition: 'transform 0.2s ease-in-out',
            ':hover': {
              transform: 'translateY(-5px)',
              boxShadow: 4,
            }
          }}>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ flexGrow: 1, flexShrink: 1, marginRight: '10px' }}>
                  {sampleData[category].name || sampleData[category].institution || sampleData[category].company}
                </Typography>
                <Box sx={{ flexShrink: 0 }}>
                  <IconButton disabled>
                    <EditIcon />
                  </IconButton>
                  <IconButton disabled>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
              <Typography variant="body2">
                {category === 'awards' ? `Awarded On: ${new Date(sampleData[category].year).getFullYear()}` : `From: ${new Date(sampleData[category].fromDate).toLocaleDateString('en-GB', { month: 'short', year: 'numeric' })}`}
              </Typography>
              <Typography variant="body2">
                {category !== 'awards' ? `To: ${new Date(sampleData[category].toDate).toLocaleDateString('en-GB', { month: 'short', year: 'numeric' })}` : sampleData[category].awardedBy}
              </Typography>
              <Typography variant="body2">{sampleData[category].degree || sampleData[category].position}</Typography>
            </CardContent>
          </Card>
        </>
      );
    }

    return items.map((item, index) => (
      <Card key={item.id || index} sx={{
        marginBottom: '5px',
        boxShadow: 2,
        width: '100%',
        height: '130px',
        borderRadius: '8px',
        transition: 'transform 0.2s ease-in-out',
        ':hover': {
          transform: 'translateY(-5px)',
          boxShadow: 4,
        }
      }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ flexGrow: 1, flexShrink: 1, marginRight: '10px' }}>
              {item.name || item.institution || item.company}
            </Typography>
            <Box sx={{ flexShrink: 0 }}>
              <IconButton onClick={() => handleOpenDialog(category, item, index)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleOpenDeleteConfirmDialog(item, category)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
          {item.year && (
            <Typography variant="body2">
              Awarded On: {new Date(item.year).getFullYear()}
            </Typography>
          )}
          {item.awardedBy &&
            <Typography variant="body2">{item.awardedBy}</Typography>
          }
          {item.fromDate && (
            <Typography variant="body2">
              From: {new Date(item.fromDate).toLocaleDateString('en-GB', { month: 'short', year: 'numeric' })}
            </Typography>
          )}
          {item.toDate && (
            <Typography variant="body2">
              To: {new Date(item.toDate).toLocaleDateString('en-GB', { month: 'short', year: 'numeric' })}
            </Typography>
          )}
          <Typography variant="body2">{item.degree || item.position}</Typography>
        </CardContent>
      </Card>
    ));
  };


  const dialogTitle = currentCategory === 'education' ? (editMode ? config.dialogTitles.editEducation : config.dialogTitles.addEducation)
    : currentCategory === 'awards' ? (editMode ? config.dialogTitles.editAward : config.dialogTitles.addAward)
      : (editMode ? config.dialogTitles.editWorkExperience : config.dialogTitles.addWorkExperience);

  return (
    <Box sx={{
      borderRadius: '5px',
      height: '400px',
      width: '100%',
      overflowY: 'auto',
      position: 'relative',
      boxSizing: 'border-box',
      margin: 0,
    }}>
      <Grid container spacing={2} sx={{ width: '100%', margin: 0 }}>
        <Grid item xs={12} md={4} sx={{ height: '400px', position: 'relative' }}>
          <Button variant="contained" startIcon={<AddIcon />} onClick={() => handleOpenDialog('education')} sx={{ mb: 1 }}>
            {config.buttonTexts.addEducation}
          </Button>
          <Box sx={{
            borderRadius: '5px',
            padding: '10px',
            backgroundColor: 'background.default',
            boxShadow: 1,
            height: '100%',
            width: '100%',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '5px',
            border: '2px dashed #ccc',
            backgroundImage: 'linear-gradient(145deg, #f0f0f0, #e0e0e0)',
            backgroundSize: 'cover',
            position: 'relative',
            boxSizing: 'border-box'
          }}>
            {renderCards(watchedEducation, 'education')}
          </Box>
        </Grid>
        <Grid item xs={12} md={4} sx={{ height: '400px', position: 'relative' }}>
          <Button variant="contained" startIcon={<AddIcon />} onClick={() => handleOpenDialog('awards')} sx={{ mb: 1 }}>
            {config.buttonTexts.addAward}
          </Button>
          <Box sx={{
            borderRadius: '5px',
            padding: '10px',
            backgroundColor: 'background.default',
            boxShadow: 1,
            height: '100%',
            width: '100%',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '5px',
            border: '2px dashed #ccc',
            backgroundImage: 'linear-gradient(145deg, #f0f0f0, #e0e0e0)',
            backgroundSize: 'cover',
            position: 'relative',
            boxSizing: 'border-box'
          }}>
            {renderCards(watchedAwards, 'awards')}
          </Box>
        </Grid>
        <Grid item xs={12} md={4} sx={{ height: '400px', position: 'relative' }}>
          <Button variant="contained" startIcon={<AddIcon />} onClick={() => handleOpenDialog('workExperience')} sx={{ mb: 1 }}>
            {config.buttonTexts.addWorkExperience}
          </Button>
          <Box sx={{
            borderRadius: '5px',
            padding: '10px',
            backgroundColor: 'background.default',
            boxShadow: 1,
            height: '100%',
            width: '100%',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '5px',
            border: '2px dashed #ccc',
            backgroundImage: 'linear-gradient(145deg, #f0f0f0, #e0e0e0)',
            backgroundSize: 'cover',
            position: 'relative',
            boxSizing: 'border-box'
          }}>
            {renderCards(watchedWorkExperience, 'workExperience')}
            <Box sx={{ display: 'none', position: 'absolute', bottom: '10px', right: '10px', animation: 'bounce 2s infinite' }}>
              <KeyboardDoubleArrowDownIcon />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="sm">
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          {currentCategory === 'education' && (
            <>
              <TextField
                label={config.educationFields.institution.label}
                value={currentItem.institution || ''}
                onChange={(e) => setCurrentItem({ ...currentItem, institution: e.target.value })}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label={config.educationFields.degree.label}
                value={currentItem.degree || ''}
                onChange={(e) => setCurrentItem({ ...currentItem, degree: e.target.value })}
                fullWidth
                sx={{ mb: 2 }}
              />
              <DatePicker
                ref={eduFromDateRef}
                selected={eduFromDate ? new Date(eduFromDate) : null}
                onChange={(date) => setEduFromDate(date)}
                dateFormat="yyyy/MM"
                showMonthYearPicker
                showIcon
                placeholderText="Select start date"
                sx={{ mb: 2 }}
              />
              <DatePicker
                ref={eduToDateRef}
                selected={eduToDate ? new Date(eduToDate) : null}
                onChange={(date) => setEduToDate(date)}
                dateFormat="yyyy/MM"
                showMonthYearPicker
                showIcon
                placeholderText="Select end date"
                sx={{ mb: 2 }}
              />
            </>
          )}
          {currentCategory === 'awards' && (
            <>
              <TextField
                label={config.awardFields.name.label}
                value={currentItem.name || ''}
                onChange={(e) => setCurrentItem({ ...currentItem, name: e.target.value })}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label={config.awardFields.awardedBy.label}
                value={currentItem.awardedBy || ''}
                onChange={(e) => setCurrentItem({ ...currentItem, awardedBy: e.target.value })}
                fullWidth
                sx={{ mb: 2 }}
              />
              <DatePicker
                ref={awardedOnRef}
                selected={awardedOn ? new Date(awardedOn) : null}
                onChange={(date) => setAwardedOn(date)}
                dateFormat="yyyy"
                showYearPicker
                placeholderText="Select year"
                sx={{ mb: 2 }}
              />
            </>
          )}
          {currentCategory === 'workExperience' && (
            <>
              <TextField
                label={config.workExperienceFields.company.label}
                value={currentItem.company || ''}
                onChange={(e) => setCurrentItem({ ...currentItem, company: e.target.value })}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label={config.workExperienceFields.position.label}
                value={currentItem.position || ''}
                onChange={(e) => setCurrentItem({ ...currentItem, position: e.target.value })}
                fullWidth
                sx={{ mb: 2 }}
              />
              <DatePicker
                ref={expFromDateRef}
                selected={expFromDate ? new Date(expFromDate) : null}
                onChange={(date) => setExpFromDate(date)}
                dateFormat="yyyy/MM"
                showMonthYearPicker
                showIcon
                placeholderText="Select start date"
                sx={{ mb: 2 }}
              />
              <DatePicker
                ref={expToDateRef}
                selected={expToDate ? new Date(expToDate) : null}
                onChange={(date) => setExpToDate(date)}
                dateFormat="yyyy/MM"
                showMonthYearPicker
                showIcon
                placeholderText="Select end date"
                sx={{ mb: 2 }}
              />
            </>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              {config.buttonTexts.cancel}
            </Button>
            <Button onClick={handleSaveItem} color="primary" sx={{ ml: 2 }}>
              {config.buttonTexts.save}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={openDeleteConfirmDialog} onClose={handleCancelDelete} maxWidth="sm" fullWidth>
        <DialogTitle>{config.dialogTitles.confirmDelete}</DialogTitle>
        <DialogContent>
          <Typography>{config.messages.confirmDelete}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={handleCancelDelete} color="primary">
              {config.buttonTexts.cancel}
            </Button>
            <Button onClick={handleConfirmDelete} color="error" sx={{ ml: 2 }}>
              {config.buttonTexts.delete}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default QualificationComponent;
