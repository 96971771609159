import React, { useState, useEffect } from 'react';
import { useAxios } from '../../contexts/AxiosContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Typography, Checkbox, Link, Button, FormControlLabel, CircularProgress, Snackbar, Alert } from '@mui/material';

const ConsentPage = () => {
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState('');
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const axios = useAxios();
    const location = useLocation();
    const navigate = useNavigate();

    // Function to extract query parameters from the URL
    const getTokenFromUrl = () => {
        const params = new URLSearchParams(location.search);
        return params.get('token'); // Get the 'token' parameter from the URL
    };

    useEffect(() => {
        const extractedToken = getTokenFromUrl();
        if (extractedToken) {
            setToken(extractedToken); // Store the token in state
        } else {
            setSnackbar({ open: true, message: 'Token not found in URL.', severity: 'error' });
        }
    }, [location.search]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!termsAccepted) {
            setSnackbar({ open: true, message: 'You must accept the terms and conditions.', severity: 'warning' });
            return;
        }
        try {
            setLoading(true);
            const response = await axios.post('/auth/accept-terms',
                { termsAccepted: true },
                { headers: { Authorization: `Bearer ${token}` } } // Include the token in the headers
            );
            setSnackbar({ open: true, message: 'Terms accepted successfully!', severity: 'success' });
            setTimeout(() => {
                navigate('/'); // Redirect using useNavigate
            }, 2000);
        } catch (error) {
            setSnackbar({ open: true, message: error.response ? error.response.data.message : error.message, severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Container maxWidth="sm" style={{ marginTop: '50px' }}>
            <Typography variant="h4" align="center" gutterBottom>
                Accept Privacy Policy & Terms of Service
            </Typography>
            <form onSubmit={handleSubmit}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={termsAccepted}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                            name="termsAccepted"
                            color="primary"
                        />
                    }
                    label={
                        <Typography variant="body1">
                            I accept the{' '}
                            <Link href="/privacy-policy" sx={{ color: '#5aa8ff', fontWeight: 'bold', textDecoration: 'none' }}>
                                Privacy Policy
                            </Link>{' '}
                            and the{' '}
                            <Link href="/terms-and-conditions" sx={{ color: '#5aa8ff', fontWeight: 'bold', textDecoration: 'none' }}>
                                Terms of Service
                            </Link>.
                        </Typography>
                    }
                />
                <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="outlined" color="secondary" href="/">
                        Cancel Signup
                    </Button>
                    <Button variant="contained" color="primary" type="submit" disabled={!termsAccepted || loading}>
                        {loading ? <CircularProgress size={24} /> : 'Submit'}
                    </Button>
                </div>
            </form>
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ConsentPage;
