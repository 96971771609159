import { z } from 'zod';
const LSRWSchema = z.object({
    l: z.number().min(1).max(6),
    s: z.number().min(1).max(6),
    r: z.number().min(1).max(6),
    w: z.number().min(1).max(6),
});

const LanguageSchema = z.object({
    value: z.string().min(2, "Select a Language"),
    privacy: z.boolean(),
    lsrw: LSRWSchema,
});

const optionalUrl = z.string().refine((data) => {
    return data === '' || /^https?:\/\/[^\s$.?#].[^\s]*$/.test(data) || /^www\.[^\s$.?#].[^\s]*$/.test(data);
}, {
    message: "Must be a valid URL with www or an empty string"
});

const userDetailsSchema = z.object({
    userDetails: z.object({
        fullName: z.object({
            value: z.string().min(4, "Full Name is required"),
            privacy: z.boolean()
        }).optional(),
        profile_path: z.object({
            value: z.string().nullable().optional(),
            privacy: z.boolean().optional()
        }).optional(),
        email: z.object({
            value: z.string().email("Invalid email address"),
            privacy: z.boolean().optional()
        }).optional(),
        phone: z.object({
            value: z.string().nullable().optional(),
            privacy: z.boolean().optional()
        }).optional(),
        location: z.object({
            value: z.string().nullable().optional(),
            privacy: z.boolean().optional()
        }).optional(),
        linkedin: z.object({
            value: optionalUrl.optional(),
            privacy: z.boolean().optional()
        }).optional(),
        imdbProfileLink: z.object({
            value: optionalUrl.optional(),
            privacy: z.boolean().optional()
        }).optional(),
        website: z.object({
            value: optionalUrl.optional(),
            privacy: z.boolean().optional()
        }).optional(),
        languages_v2: z.array(LanguageSchema).optional(),
        creativeTraits: z.object({
            value: z.array(z.string()).optional(),
            privacy: z.boolean().optional()
        }).optional(),
    }).optional()
});

export default userDetailsSchema;
