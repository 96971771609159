import React, { useEffect } from 'react';
import { useLogin } from './LoginContext';
import { Typography, Button, Grid } from "@mui/material";
import { useTheme } from "../contexts/ThemeContext";
import googleIcon from '../assets/SVG/socialLogins/google_colour_icon.svg';

const GoogleLoginButton = () => {
    const { googleLogin } = useLogin();
    const { theme } = useTheme();
    const bgColor = theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper;
    const textColor = theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.text.primary;
    const buttonBg = theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[300];

    const handleGoogleLogin = () => {
        window.location.href = `${process.env.REACT_APP_API_BASE_URL}/auth/google`;
    };

    const BottomGradient = () => {
        return (
            <>
                <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
                <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
            </>
        );
    };

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const token = query.get('token');
        if (token) {
            googleLogin(token);
        }
    }, [googleLogin]);

    return (
        <Button
            className="relative group/btn block w-full h-10 font-medium rounded-md"
            type="submit"
            onClick={handleGoogleLogin}
            style={{
                backgroundColor: buttonBg,
                color: theme.palette.getContrastText(buttonBg),
                boxShadow: theme.shadows[1]
            }}
        >
            <img src={googleIcon} alt="Google icon" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
            Continue with Google
            <BottomGradient />
        </Button>
    );
};

export default GoogleLoginButton;
