import { z } from 'zod';

// Define the formatDetails schema
const formatDetailsSchema = z.object({
    runtime: z.union([z.number(), z.null()]).optional(), // Allow number or null
    seasons: z.union([z.number(), z.null()]).optional(), // Allow number or null
    episodes: z.union([z.number(), z.null()]).optional(), // Allow number or null
    episodeRuntime: z.union([z.number(), z.null()]).optional(), // Allow number or null
});

const ownWorksSchema = z.object({
    ownWorks: z.object({
        portfolio: z.array(z.object({
            poster: z.string().optional(),
            name: z.string().min(1, "Title is required"),
            logLine: z.string().optional(),
            genre: z.string().optional(),
            format: z.string().optional(),
            formatDetails: z.union([formatDetailsSchema, z.null()]).optional(), // Allow formatDetails to be null or the schema
             privacy: z.boolean().optional(),
            status: z.string().optional(),
            language: z.string().optional(),
            credit: z.array(z.string()).optional(),
            assetsAvailable: z.array(z.string()).optional(), 
            proof_link: z.string().optional()
        })).optional(),
        privacy: z.boolean().optional()
    }).optional()
});

export default ownWorksSchema;
