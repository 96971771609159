import React, { useState, useRef, useEffect } from 'react';
import {
    Box, Grid, TextField, IconButton, Button, Typography, Tooltip, Divider, Chip,
    Autocomplete, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { useFormContext, Controller, useFieldArray, useWatch } from 'react-hook-form';
import { CloudUpload as CloudUploadIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon, DeleteForever as DeleteForeverIcon, Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import UploadModel from '../FormAddons/uploadFrame'; // Updated import
import config from "../config.json";

const MyStories = ({ userId, handleNextSection, theme, isMobile = { isMobile } }) => {
    const { control, setValue, trigger, formState: { errors } } = useFormContext();
    const { fields: ownWorksFields, append: appendOwnWorks, remove: removeOwnWorks } = useFieldArray({
        control,
        name: 'ownWorks.portfolio'
    });
    const watchedOwnWorks = useWatch({ control, name: 'ownWorks.portfolio' });
    const [selectedStoryIndex, setSelectedStoryIndex] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);

    const titleRef = useRef(null);
    const logLineRef = useRef(null);
    const genreRef = useRef(null);
    const formatRef = useRef(null);
    const statusRef = useRef(null);
    const languageRef = useRef(null);
    const assetsAvailableRef = useRef(null);
    const [openInvitingMessage, setOpenInvitingMessage] = useState(false);
    const isDark = theme.palette.mode === "dark"

    useEffect(() => {
        if (ownWorksFields.length === 0) {
            setOpenInvitingMessage(true);
        }
    }, [ownWorksFields.length]);


    useEffect(() => {
        if (titleRef.current) {
            titleRef.current.value = watchedOwnWorks[selectedStoryIndex]?.name || '';
        }
        if (logLineRef.current) {
            logLineRef.current.value = watchedOwnWorks[selectedStoryIndex]?.logLine || '';
        }
        if (genreRef.current) {
            genreRef.current.value = watchedOwnWorks[selectedStoryIndex]?.genre || '';
        }
        if (formatRef.current) {
            formatRef.current.value = watchedOwnWorks[selectedStoryIndex]?.format || '';
        }
        if (statusRef.current) {
            statusRef.current.value = watchedOwnWorks[selectedStoryIndex]?.status || '';
        }
        if (languageRef.current) {
            languageRef.current.value = watchedOwnWorks[selectedStoryIndex]?.language || '';
        }
        if (assetsAvailableRef.current) {
            assetsAvailableRef.current.value = watchedOwnWorks[selectedStoryIndex]?.assetsAvailable || [];
        }
    }, [selectedStoryIndex, watchedOwnWorks]);

    const handleUploadSuccess = (s3Url, index) => {
        setValue(`ownWorks.portfolio.${index}.poster`, s3Url);
    };

    const handlePrivacyToggle = () => {
        const currentStory = watchedOwnWorks[selectedStoryIndex];
        if (currentStory) {
            const currentPrivacy = currentStory.privacy;
            const newPrivacy = currentPrivacy !== undefined ? !currentPrivacy : false;
            setValue(`ownWorks.portfolio.${selectedStoryIndex}.privacy`, newPrivacy);
        }
    };

    const handleAddNewStory = () => {
        appendOwnWorks({
            poster: '',
            name: '',
            logLine: '',
            genre: '',
            format: '',
            privacy: false, // Initialize privacy with a default value
            status: '',
            credit: [],
            language: '',
            proof_link: '',
            formatDetails: { runtime: null, seasons: null, episodes: null, episodeRuntime: null } // Initialize with null
        });
        setTimeout(() => {
            setSelectedStoryIndex(ownWorksFields.length);
        }, 100);
    };

    useEffect(() => {
        setSelectedStoryIndex(ownWorksFields.length - 1);
    }, [ownWorksFields.length]);


    const handleNextStory = async (index) => {
        const isValid = await trigger(`ownWorks.portfolio.${selectedStoryIndex}`);
        if (isValid) {
            setSelectedStoryIndex(index);
        } else {
            const firstErrorField = document.querySelector(`[name^="ownWorks.portfolio.${selectedStoryIndex}"].Mui-error`);
            if (firstErrorField) {
                firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
                firstErrorField.focus();
            }
        }
    };

    const handleDeleteStory = () => {
        removeOwnWorks(deleteIndex);
        setDeleteIndex(null);
        setOpenDialog(false);
        if (selectedStoryIndex === deleteIndex) {
            setSelectedStoryIndex(0);
        }
    };

    const handleDialogOpen = (index) => {
        setDeleteIndex(index);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setDeleteIndex(null);
    };

    const currentStory = watchedOwnWorks[selectedStoryIndex] || {};

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    style={{
                        height: '25%',
                        overflowX: 'auto',
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        display="inline-block"
                        onClick={handleAddNewStory}
                        sx={{
                            cursor: 'pointer',
                            width: isMobile ? '50px' : '100px',
                            height: isMobile ? '75px' : '150px',
                            backgroundColor: isDark ? '' : '#f0f0f0',
                            border: `2px solid white`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: 2,
                            position: 'relative'
                        }}
                    >
                        <AddIcon style={{ fontSize: '48px' }} />
                    </Box>
                    {ownWorksFields.map((item, index) => (
                        <Box
                            key={item.id}
                            display="inline-block"
                            onClick={() => handleNextStory(index)}
                            sx={{
                                cursor: 'pointer',
                                border: selectedStoryIndex === index ? `2px solid ${!isDark ? theme.palette.primary.main : 'white'}` : 'none',
                                marginRight: 2,
                                position: 'relative',
                                width: isMobile ? '50px' : 'auto', // Ensure consistent width
                                height: isMobile ? '75px' : '150px', // Ensure consistent height
                                flexShrink: 0, // Prevent shrinking
                                transform: selectedStoryIndex === index ? 'scale(1.05)' : 'scale(1)',
                                transition: 'transform 0.3s ease-in-out' // Smooth transition

                            }}
                        >
                            {watchedOwnWorks.length > index && watchedOwnWorks[index]?.poster ? (
                                <img
                                    src={watchedOwnWorks[index].poster}
                                    alt="Poster"
                                    style={{ width: isMobile ? '50px' : 'auto',maxWidth: '150px', height: isMobile ? '75px' : '150px' }} // Ensure the image fits the box
                                />
                            ) : (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{ width: isMobile ? '50px' : '100px', height: isMobile ? '75px' : '150px', backgroundColor: '#f0f0f0' }}
                                >
                                    <Typography variant="caption">
                                        {watchedOwnWorks[index]?.name || 'No Title'}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    ))}
                </Grid>

                <Grid item xs={12}>
                    {ownWorksFields.length > 0 && (
                        <Box>
                            <Box display="flex" alignItems="center" justifyContent={isMobile ? "flex-end" : "space-between"} marginTop='10px'>
                                {/* {!isMobile && <Divider sx={{ color: 'grey', flexGrow: 1 }}>{`Story: ${currentStory.name || 'No Title'}`}</Divider>} */}
                                <Box display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'}>
                                    <Grid item xs={2}>
                                        {isMobile ? (<IconButton onClick={handlePrivacyToggle}>
                                            {watchedOwnWorks[selectedStoryIndex]?.privacy ? (
                                                <VisibilityOffIcon style={{ color: 'red' }} />
                                            ) : (
                                                <VisibilityIcon style={{ color: 'green' }} />
                                            )}
                                        </IconButton>) : (
                                            <Button
                                                onClick={handlePrivacyToggle}
                                                variant="outlined"
                                                color={watchedOwnWorks[selectedStoryIndex]?.privacy ? 'error' : 'success'}
                                                startIcon={watchedOwnWorks[selectedStoryIndex]?.privacy ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                size={isMobile ? "small" : "medium"}
                                            >
                                                {!isMobile && (watchedOwnWorks[selectedStoryIndex]?.privacy ? 'Private' : 'Public')}
                                            </Button>
                                        )}
                                    </Grid>
                                    {!isMobile && <Typography item > ← Toggle Between Private and Public mode to be shown in public profile</Typography>}
                                </Box>
                                <Tooltip title="Remove Story">
                                    {isMobile ? (
                                        <IconButton onClick={() => handleDialogOpen(selectedStoryIndex)}>
                                            <DeleteForeverIcon style={{ color: 'red' }} />
                                        </IconButton>
                                    ) : (
                                        <Button
                                            onClick={() => handleDialogOpen(selectedStoryIndex)}
                                            variant="outlined"
                                            color="error"
                                            startIcon={<DeleteForeverIcon />}
                                        >
                                            Delete Story
                                        </Button>
                                    )}
                                </Tooltip>

                            </Box>
                            <Grid container display="flex" spacing={2} marginTop={1} >
                                <Grid item xs={12} sm={3} sx={{ ...(isMobile && { display: 'flex', flexDirection: 'row', alignItems: 'center' }) }}>
                                    {currentStory.poster ? (
                                        <img
                                            src={currentStory.poster}
                                            alt="Poster"
                                            style={{ width: '100%', height: isMobile ? '200px' : '300px', objectFit: 'contain' }}
                                        />
                                    ) : (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            style={{ width: '100%', height: '300px', backgroundColor: '#f0f0f0' }}
                                        >
                                            <CloudUploadIcon className="upload-icon" />
                                        </Box>
                                    )}
                                    <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '20px', alignContent: 'flex-end', marginTop: '-20px', marginBottom: '10px' }}>
                                        <UploadModel
                                            key={`${selectedStoryIndex}-${currentStory.logLine || ''}-${currentStory.genre || ''}-${currentStory.name || ''}`}
                                            upload_state={!currentStory.poster}
                                            initialLogline={currentStory.logLine || ''}
                                            initialFormat={currentStory.format || ''}
                                            initialGenre={currentStory.genre || ''}
                                            initialTitle={currentStory.name || ''}
                                            remainingGenerations={100}
                                            onSuccess={(s3Url) => handleUploadSuccess(s3Url, selectedStoryIndex)}
                                            selectedStoryIndex={selectedStoryIndex}  // Pass the index
                                        />
                                        <Button onClick={() => setValue(`ownWorks.portfolio.${selectedStoryIndex}.poster`, '')}><CloseIcon variant="filled" sx={{ marginTop: 'auto' }} /> </Button>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        inputRef={titleRef}
                                        fullWidth
                                        label={config.ownWorks.portfolio.title.label}
                                        margin="normal"
                                        size="small"
                                        helperText={errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.name?.message || config.ownWorks.portfolio.title.helperText}
                                        error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.name}
                                        onChange={(e) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.name`, e.target.value)}
                                    />
                                    <TextField
                                        inputRef={logLineRef}
                                        fullWidth
                                        label={config.ownWorks.portfolio.logLine.label}
                                        margin="normal"
                                        size="small"
                                        multiline
                                        minRows={4}
                                        maxRows={5}
                                        helperText={errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.logLine?.message || config.ownWorks.portfolio.logLine.helperText}
                                        error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.logLine}
                                        onChange={(e) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.logLine`, e.target.value)}
                                    />
                                    <Autocomplete
                                        options={config.ownWorks.portfolio.genre.options}
                                        getOptionLabel={(option) => option}
                                        value={currentStory.genre || null}
                                        onChange={(event, value) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.genre`, value)}
                                        isOptionEqualToValue={(option, value) => option === value || value === null}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={config.ownWorks.portfolio.genre.label}
                                                placeholder=""
                                                margin="normal"
                                                size="small"
                                                helperText={errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.genre?.message || config.ownWorks.portfolio.genre.helperText}
                                                error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.genre}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Autocomplete
                                        options={config.ownWorks.portfolio.format.options}
                                        getOptionLabel={(option) => option}
                                        value={currentStory.format || null}
                                        onChange={(event, value) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.format`, value)}
                                        isOptionEqualToValue={(option, value) => option === value || value === null}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={config.ownWorks.portfolio.format.label}
                                                placeholder=""
                                                margin="normal"
                                                size="small"
                                                // helperText={errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.format?.message || config.ownWorks.portfolio.format.helperText}
                                                error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.format}
                                            />
                                        )}
                                    />
                                    {config.ownWorks.portfolio.formatDetails.conditionalFormats.runtimeOnly.includes(currentStory.format) && (
                                        <TextField
                                            type="number"
                                            // label={config.ownWorks.portfolio.formatDetails.runtime.label}
                                            helperText={config.ownWorks.portfolio.formatDetails.runtime.helperText}
                                            value={currentStory.formatDetails?.runtime || ''}
                                            onChange={(e) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.formatDetails.runtime`, Number(e.target.value))}
                                            error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.formatDetails?.runtime}
                                        />
                                    )}

                                    {config.ownWorks.portfolio.formatDetails.conditionalFormats.seriesDetails.includes(currentStory.format) && (
                                        <Box>
                                            <Box display="flex" gap={2}>
                                                <TextField
                                                    type="number"
                                                    label={config.ownWorks.portfolio.formatDetails.seasons.label}
                                                    // helperText={config.ownWorks.portfolio.formatDetails.seasons.helperText}
                                                    value={currentStory.formatDetails?.seasons || ''}
                                                    onChange={(e) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.formatDetails.seasons`, Number(e.target.value))}
                                                    error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.formatDetails?.seasons}
                                                    sx={{ flex: 1 }}
                                                />

                                                <TextField
                                                    type="number"
                                                    label={config.ownWorks.portfolio.formatDetails.episodes.label}
                                                    // helperText={config.ownWorks.portfolio.formatDetails.episodes.helperText}
                                                    value={currentStory.formatDetails?.episodes || ''}
                                                    onChange={(e) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.formatDetails.episodes`, Number(e.target.value))}
                                                    error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.formatDetails?.episodes}
                                                    sx={{ flex: 1 }}
                                                />
                                            </Box>

                                            <TextField
                                                type="number"
                                                label={config.ownWorks.portfolio.formatDetails.episodeRuntime.label}
                                                // helperText={config.ownWorks.portfolio.formatDetails.episodeRuntime.helperText}
                                                value={currentStory.formatDetails?.episodeRuntime || ''}
                                                onChange={(e) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.formatDetails.episodeRuntime`, Number(e.target.value))}
                                                error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.formatDetails?.episodeRuntime}
                                                sx={{ marginTop: 2 }}
                                            />
                                        </Box>
                                    )}

                                    <Autocomplete
                                        options={config.ownWorks.portfolio.status.options}
                                        getOptionLabel={(option) => option}
                                        value={currentStory.status || null}
                                        onChange={(event, value) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.status`, value)}
                                        isOptionEqualToValue={(option, value) => option === value || value === null}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={config.ownWorks.portfolio.status.label}
                                                placeholder=""
                                                margin="normal"
                                                size="small"
                                                helperText={errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.status?.message || config.ownWorks.portfolio.status.helperText}
                                                error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.status}
                                            />
                                        )}
                                    />
                                    {currentStory.status === "Available" && (
                                        <Autocomplete
                                            multiple
                                            options={config.ownWorks.portfolio.assetsAvailable.options}
                                            getOptionLabel={(option) => option}
                                            value={currentStory.assetsAvailable || []}
                                            onChange={(event, value) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.assetsAvailable`, value)}
                                            isOptionEqualToValue={(option, value) => option === value || value.length === 0}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip key={index} label={option} {...getTagProps({ index })} />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputRef={assetsAvailableRef}
                                                    label={config.ownWorks.portfolio.assetsAvailable.label}
                                                    placeholder="Select available assets"
                                                    margin="normal"
                                                    size="small"
                                                    helperText={errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.assetsAvailable?.message || config.ownWorks.portfolio.assetsAvailable.helperText}
                                                    error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.assetsAvailable}
                                                />
                                            )}
                                        />
                                    )}
                                    {currentStory.status === "Acquired" && (
                                        <Autocomplete
                                            multiple
                                            options={config.ownWorks.portfolio.credit.options}
                                            getOptionLabel={(option) => option}
                                            value={currentStory.credit || []}
                                            onChange={(event, value) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.credit`, value)}
                                            isOptionEqualToValue={(option, value) => option === value || value.length === 0}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => {
                                                    const { key, ...tagProps } = getTagProps({ index });
                                                    return <Chip key={index} label={option} {...tagProps} />;
                                                })
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={config.ownWorks.portfolio.credit.label}
                                                    placeholder=""
                                                    margin="normal"
                                                    size="small"
                                                    helperText={errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.credit?.message || config.ownWorks.portfolio.credit.helperText}
                                                    error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.credit}
                                                />
                                            )}
                                        />
                                    )}
                                    <Autocomplete
                                        options={config.userDetails.languageOptions.availableLanguages}
                                        getOptionLabel={(option) => option}
                                        value={currentStory.language || null}
                                        onChange={(event, value) => setValue(`ownWorks.portfolio.${selectedStoryIndex}.language`, value)}
                                        isOptionEqualToValue={(option, value) => option === value || value === null}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                inputRef={languageRef}
                                                label="Language"
                                                placeholder="Select a language"
                                                margin="normal"
                                                size="small"
                                                helperText={errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.language?.message || 'Choose the language for your story'}
                                                error={!!errors?.ownWorks?.portfolio?.[selectedStoryIndex]?.language}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </Grid>
            </Grid>

            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Delete Story</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this story? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteStory} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openInvitingMessage} onClose={() => setOpenInvitingMessage(false)}>
                <DialogTitle>{config.ownWorks.invitingMessage.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {config.ownWorks.invitingMessage.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenInvitingMessage(false);
                        handleAddNewStory();
                    }} color="primary">
                        Yes
                    </Button>
                    <Button onClick={() => setOpenInvitingMessage(false)} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>


        </Box>
    );
};

export default MyStories;
