import { FormControl, InputLabel, Select, MenuItem, OutlinedInput, Checkbox, ListItemText } from '@mui/material';
import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { useAxios } from '../contexts/AxiosContext';

const NetworkGraph = () => {
    const [data, setData] = useState({ nodes: [], links: [] });
    const [mediaType, setMediaType] = useState('movie');
    const svgRef = useRef(null);
    const axios = useAxios();  // Get Axios instance from context

    useEffect(() => {
        // Fetch data when mediaType changes
        axios.get(`/public/favouriteList/${mediaType}`)
            .then(response => {
                processData(response.data);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, [mediaType]);
    

    const processData = (rawData) => {
        const nodes = [];
        const links = [];
        const itemIds = new Map();

        // Transform raw data to nodes and links
        Object.entries(rawData).forEach(([userName, items], index) => {
            const userNode = { id: `user-${index}`, name: userName, type: 'user' };
            nodes.push(userNode);

            items.forEach(item => {
                if (!itemIds.has(item)) {
                    const itemNode = { id: `item-${itemIds.size}`, title: item, type: mediaType };
                    nodes.push(itemNode);
                    itemIds.set(item, itemNode.id);
                }

                links.push({
                    source: userNode.id,
                    target: itemIds.get(item)
                });
            });
        });

        setData({ nodes, links });
    };

    useEffect(() => {
        const svg = d3.select(svgRef.current);
        const width = +svg.attr('width');
        const height = +svg.attr('height');

        // Clear previous contents
        svg.selectAll("*").remove();

        // Create zoom functionality
        const zoom = d3.zoom()
            .scaleExtent([0.5, 4])
            .on('zoom', (event) => {
                svg.attr('transform', event.transform);
            });
        svg.call(zoom);

        const g = svg.append('g');

        // Tooltip for node details
        const tooltip = d3.select("body").append("div")
            .attr("class", "tooltip")
            .style("position", "absolute")
            .style("visibility", "hidden")
            .style("background", "white")
            .style("border", "1px solid #ddd")
            .style("padding", "10px")
            .style("border-radius", "5px")
            .style("text-align", "left");

        // Setup simulation
        const simulation = d3.forceSimulation(data.nodes)
            .force("link", d3.forceLink(data.links).id(d => d.id).distance(50))
            .force("charge", d3.forceManyBody().strength(-400))
            .force("center", d3.forceCenter(width / 2, height / 2));

        const link = g.append("g")
            .attr("stroke", "#999")
            .selectAll("line")
            .data(data.links)
            .enter().append("line")
            .attr("stroke-width", 2);

        const node = g.append("g")
            .attr("stroke", "#fff")
            .attr("stroke-width", 1.5)
            .selectAll("circle")
            .data(data.nodes)
            .enter().append("circle")
            .attr("r", 10)
            .attr("fill", d => d.type === mediaType ? "red" : "blue")
            .call(d3.drag()
                .on("start", dragstarted)
                .on("drag", dragged)
                .on("end", dragended))
            .on("mouseover", (event, d) => {
                tooltip.html(d.name || d.title)
                       .style("visibility", "visible");
            })
            .on("mousemove", (event) => {
                tooltip.style("top", (event.pageY - 10) + "px")
                       .style("left", (event.pageX + 10) + "px");
            })
            .on("mouseout", () => {
                tooltip.style("visibility", "hidden");
            });

        // Drag functions
        function dragstarted(event, d) {
            if (!event.active) simulation.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
        }

        function dragged(event, d) {
            d.fx = event.x;
            d.fy = event.y;
        }

        function dragended(event, d) {
            if (!event.active) simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;
        }

        simulation.on("tick", () => {
            link
                .attr("x1", d => Math.max(5, Math.min(width - 5, d.source.x)))
                .attr("y1", d => Math.max(5, Math.min(height - 5, d.source.y)))
                .attr("x2", d => Math.max(5, Math.min(width - 5, d.target.x)))
                .attr("y2", d => Math.max(5, Math.min(height - 5, d.target.y)));
        
            node
                .attr("cx", d => Math.max(5, Math.min(width - 5, d.x)))
                .attr("cy", d => Math.max(5, Math.min(height - 5, d.y)));
        });

        return () => {
            tooltip.remove(); // Clean up tooltip
        };
    }, [data]);

    return (
        <div>
            <select value={mediaType} onChange={e => setMediaType(e.target.value.toLowerCase())}>
                <option value="movie">Movie</option>
                <option value="game">Game</option>
                <option value="book">Book</option>
                <option value="series">Series</option>
                <option value="documentary">Documentary</option>
                <option value="all">All</option>
            </select>
            <h1>Network Graph of Users and Their Favorite {mediaType}s</h1>
            <svg ref={svgRef} width="960" height="600"></svg>
        </div>
    );
};

export default NetworkGraph;
