"use client";
import React, { useState } from "react";
import { cn } from "../utils/cn";
import { LabelComponent } from "./ui/label-component";
import { InputBox } from "./ui/input-box";
import { Typography, Button, Grid, Checkbox, FormControlLabel } from "@mui/material";
import { useTheme } from "../contexts/ThemeContext";
import GoogleLoginButton from "../contexts/GoogleLoginButton";

export function Signing({
    handleSubmit,
    isSignUp,
    isForgotPassword,
    email,
    setEmail,
    password,
    setPassword,
    name,
    setName,
    toggleSignUp,
    toggleForgotPassword
}) {
    const [errors, setErrors] = useState({}); // Use local state for errors
    const [termsAccepted, setTermsAccepted] = useState(false); // State to track if the terms are accepted
    const { theme } = useTheme();
    const bgColor = theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper;
    const textColor = theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.text.primary;
    const buttonBg = theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[300];

    const handleGoogleAuth = () => {
        const backendUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
        window.location.href = `${backendUrl}/auth/google`;
    };

    const onFormSubmit = (e) => {
        e.preventDefault();

        // Clear previous errors
        setErrors({});

        // Check if the terms are accepted
        if (isSignUp && !termsAccepted) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                termsAccepted: "You must accept the terms and conditions"
            }));
            return;
        }

        // Call the provided handleSubmit function if validation passes
        handleSubmit(e);
    };

    return (
        <div className="max-w-md w-full mx-auto rounded-none md:rounded-2xl p-4 md:p-8 shadow-input" style={{ backgroundColor: bgColor, borderRadius: '20px' }}>
            <h2 className="font-bold text-xl" style={{ color: textColor }}>
                Welcome to Just Write
            </h2>
            <p className="text-sm max-w-sm mt-2" style={{ color: textColor }}>
                Login to unlock your full potential
            </p>

            <form className="my-8" onSubmit={onFormSubmit}>
                {isSignUp && (
                    <LabelInputContainer>
                        <LabelComponent htmlFor="firstname">First name</LabelComponent>
                        <InputBox
                            id="firstname"
                            placeholder="Tyler"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        {errors.name && <Typography color="error">{errors.name}</Typography>}
                    </LabelInputContainer>
                )}
                <LabelInputContainer className="mb-4">
                    <LabelComponent htmlFor="email">Email Address</LabelComponent>
                    <InputBox
                        id="email"
                        placeholder="projectmayhem@fc.com"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && <Typography color="error">{errors.email}</Typography>}
                </LabelInputContainer>
                {!isForgotPassword && (
                    <LabelInputContainer className="mb-4">
                        <LabelComponent htmlFor="password">Password</LabelComponent>
                        <InputBox
                            id="password"
                            placeholder="••••••••"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {errors.password && <Typography color="error">{errors.password}</Typography>}
                    </LabelInputContainer>
                )}

                {/* Checkbox for terms and conditions */}
                {isSignUp && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={termsAccepted}
                                onChange={(e) => setTermsAccepted(e.target.checked)}
                                color="primary"
                            />
                        }
                        label={
                            <Typography variant="body2" style={{ color: textColor }}>
                                I accept the <a href="/terms-and-conditions" target="/privacy-policy">terms and conditions</a> and <a href="/privacy-policy" target="/privacy-policy">privacy policy</a>.
                            </Typography>
                        }
                        sx={{ marginTop: 2 }}
                    />
                )}
                {errors.termsAccepted && <Typography color="error">{errors.termsAccepted}</Typography>}

                <Button
                    className="relative group/btn block w-full h-10 font-medium rounded-md"
                    type="submit"
                    style={{
                        backgroundColor: buttonBg,
                        color: theme.palette.getContrastText(buttonBg),
                        boxShadow: theme.shadows[1]
                    }}
                >
                    {isSignUp ? 'Register' : isForgotPassword ? 'Reset Password' : 'Sign In'} &rarr;
                    <BottomGradient />
                </Button>
                <Grid container spacing={1}>
                    {!isForgotPassword && (
                        <Grid item xs={12}>
                            <Button
                                type="button"
                                fullWidth
                                variant="outlined"
                                onClick={toggleSignUp}
                                sx={{ mt: 1 }}
                            >
                                {isSignUp ? 'Sign In' : 'Sign Up'}
                            </Button>
                        </Grid>
                    )}
                    {!isSignUp && (
                        <Grid item xs={12}>
                            <Button
                                type="button"
                                fullWidth
                                variant="outlined"
                                onClick={toggleForgotPassword}
                                sx={{ opacity: 0.8 }}
                            >
                                {isForgotPassword ? 'Sign In' : 'Forgot Password'}
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography>Or</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <GoogleLoginButton />
                    </Grid>
                </Grid>
            </form>
        </div>
    );
}

const BottomGradient = () => {
    return (
        <>
            <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
            <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
        </>
    );
};

const LabelInputContainer = ({ children, className }) => {
    return (
        <div className={cn("flex flex-col space-y-2 w-full", className)}>
            {children}
        </div>
    );
};
