import { Grid, Card, CardContent, Box } from '@mui/material';
import Sidebar from './Sidebar';
import StatsCard from './StatsCard';
import UsersDataGrid from './UsersDataGrid';
import ReachOutsDataGrid from './ReachOutsDataGrid';
import StoriesWall from './StoriesWall';
import LocationMap from './LocationMap';
import React, { useState } from 'react';
import AiHistoryTable from './AiHistoryTable';
import AiPosterV2Table from './AiPosterV2Table';
import IdeogramAPIPlayground from './Ideogram/IdeogramAPIPlayground';
import PublicViewStats from './PublicViewStats';

function AdminDashboard() {
    const [activeView, setActiveView] = useState('dashboard');
    const [template, setTemplate] = useState('');

    const handleMenuClick = (view) => {
        setActiveView(view);
    };

    const handleUseTemplate = (templateData) => {
        setTemplate(templateData);
        setActiveView('ideogram_API'); // Switch to the IdeogramAPIPlayground view
    };

    return (
        <Box sx={{ display: 'flex', height: '100vh', overflowY:'auto' }}>
            <Box>
                <Sidebar username='Admin' onMenuClick={handleMenuClick} />
            </Box>
            <Box sx={{ flexGrow: 1, padding: '10px' }}>
                <Card sx={{ height: '100vh', overflow: 'auto' }}>
                    <CardContent>
                        {activeView === 'dashboard' && <StatsCard />}
                        {activeView === 'users' && <UsersDataGrid />}
                        {activeView === 'reachOuts' && <ReachOutsDataGrid />}
                        {activeView === 'publicView' && <PublicViewStats />}
                        {activeView === 'stories' && <StoriesWall />}
                        {activeView === 'locationMap' && <LocationMap />}
                        {activeView === 'aiHistory' && <AiHistoryTable />}
                        {activeView === 'ai_V2_History' && (
                            <AiPosterV2Table onUseTemplate={handleUseTemplate} /> // Pass the handler to AiPosterV2Table
                        )}
                        {activeView === 'ideogram_API' && (
                            <IdeogramAPIPlayground template={template} /> // Pass the template to IdeogramAPIPlayground
                        )}
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
}

export default AdminDashboard;
