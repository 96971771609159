"use client";

// Importing necessary assets and libraries
import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box, IconButton, Typography, Card, CardMedia, CardContent, CardActionArea, Grid, Tabs, Tab
} from "@mui/material";
import { useAxios } from "../../contexts/AxiosContext";
import { useTheme } from "../../contexts/ThemeContext";
import { useLogin } from "../../contexts/LoginContext";
import { useMediaQuery } from '@mui/material';
import JWIcon from '../../assets/JW_icon.png';
import PVDark from '../../assets/PVDark.jpg';
import PVLight from '../../assets/PVLight.jpg';
import ReachOutModal from '../modals/ReachOutModal';
import useMetaObjectPreview from "../FormAddons/MetadataObject";
import logger from "../../logger";
import paperTexture from '../../assets/paperTexture.jpg'
import ClickableChip from "../ProfileComponents/ChipLink";

// Icons
import { Home as HomeIcon, Movie as MovieIcon, Tv as TvIcon, Theaters as TheatersIcon, SportsEsports as SportsEsportsIcon, Book as BookIcon, MoreHoriz as MoreHorizIcon, ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material';
import { IoIosInformationCircleOutline, IoIosHeartEmpty, IoIosQuote } from "react-icons/io";

const categoryIcons = {
    HOME: <HomeIcon />,
    MOVIES: <MovieIcon />,
    TV_SHOWS: <TvIcon />,
    DOCUMENTARY: <TheatersIcon />,
    GAMES: <SportsEsportsIcon />,
    BOOKS: <BookIcon />,
    EXPERIENCE: <MoreHorizIcon />
};

// Centralized Tab Indexes
const TAB_INDEXES = {
    HOME: 0,
    MOVIES: 1,
    TV_SHOWS: 2,
    DOCUMENTARY: 3,
    BOOKS: 4,
    EXPERIENCE: 5
};

const youtubeVideoMapping = {
    "Deadpool & Wolverine": "73_1biulkYk",
    "Avengers: Endgame": "TcMBFSGVi1c",
    "Nayakan": "1S6YkmYvgi8",
    "Cha Cha Real Smooth": "QRyyagJ9GPo",
    "Manchester by the Sea": "gsVoD0pTge0",
    "The Fall Guy": "j7jPnwVGdZ8",
    "Por Thozhil": "CfKZUZR1UDk",
    "Dallas Buyers Club": "U8utPuIFVnU",
    "Vasool Raja MBBS": "IEiJ1JaE7Ag",
    "Avengers: Infinity War": "6ZfuNTqbHE8"
};

const PublicViewTest = () => {
    const { pubProfileLink } = useParams();
    const navigate = useNavigate();
    const api = useAxios();
    const { theme } = useTheme();
    const { isLoggedIn } = useLogin();
    const [profile, setProfile] = useState(null);
    const [media, setMedia] = useState({ movie: [], series: [], documentary: [], book: [] });
    const [loading, setLoading] = useState(true);
    const [selectedPoster, setSelectedPoster] = useState(null);
    const [selectedWorkDetails, setSelectedWorkDetails] = useState(null);
    const isXsScreen = useMediaQuery('(max-width:800px)');
    const [showSmallContact, setShowSmallContact] = useState(false);
    const pvImage = theme.palette.mode === "light" ? PVDark : PVLight;
    const [currentMovie, setCurrentMovie] = useState(null);
    const [currentBookIndex, setCurrentBookIndex] = useState(0);
    const [activeContent, setActiveContent] = useState('');
    const [hoveredMovie, setHoveredMovie] = useState(null);
    const hoverTimeoutRef = useRef(null);
    const [selectedTab, setSelectedTab] = useState(TAB_INDEXES.HOME);  // Using centralized index
    const [currentUrlIndex, setCurrentUrlIndex] = useState(0);


    const creditedUrls = profile?.workedIn?.credited.map((item) => item.proof_link) || [];
    const { metaData, loading: metaLoading, error: metaError } = useMetaObjectPreview(creditedUrls[currentUrlIndex]);

    useEffect(() => {
        setShowSmallContact(isXsScreen);
    }, [isXsScreen]);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const profileResponse = await api.get(`/public/profile/${pubProfileLink}`);
                if (profileResponse.data) {
                    setProfile(profileResponse.data);
                    logger(`Profile data fetched successfully`, 'debug', ['env:production', 'component:PublicViewTest'], 'soozh-beta-frontend');
                    if (profileResponse.data.ownWorks?.portfolio.length > 0) {
                        setSelectedPoster(profileResponse.data.ownWorks.portfolio[0].poster);
                        setSelectedWorkDetails(profileResponse.data.ownWorks.portfolio[0]);
                    }
                } else {
                    logger('Profile data is null, navigating to edit profile', 'warn', ['env:production', 'component:PublicViewTest'], 'soozh-beta-frontend');
                    navigate('/edit-profile');
                }
            } catch (error) {
                logger(`Error fetching profile: ${error.message}`, 'error', ['env:production', 'component:PublicViewTest'], 'soozh-beta-frontend');
            }

            try {
                const mediaResponse = await api.get(`/public/media_v2/${pubProfileLink}`);
                if (mediaResponse.data) {
                    setMedia(mediaResponse.data);
                    if (mediaResponse.data.movie?.length > 0) {
                        setCurrentMovie(mediaResponse.data.movie[0]);
                    }
                }
            } catch (err) {
                logger(`Error fetching media: ${err.message}`, 'error', ['env:production', 'component:PublicViewTest'], 'soozh-beta-frontend');
            }

            setLoading(false);
        };

        setLoading(true);
        fetchProfile();
    }, [api, navigate]);

    const handleCardClick = (movie) => {
        clearTimeout(hoverTimeoutRef.current);
        setHoveredMovie(movie);
        setCurrentMovie(movie);
    };

    const handleCardMouseLeave = () => {
        clearTimeout(hoverTimeoutRef.current);
        setHoveredMovie(null);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);

        if (newValue === TAB_INDEXES.HOME) {
            setCurrentMovie(null);
        } else if (newValue === TAB_INDEXES.MOVIES) {
            setCurrentMovie(media.movie[0]);
        } else if (newValue === TAB_INDEXES.TV_SHOWS) {
            setCurrentMovie(media.series[0]);
        } else if (newValue === TAB_INDEXES.DOCUMENTARY) {
            setCurrentMovie(media.documentary[0]);
        } else if (newValue === TAB_INDEXES.BOOKS) {
            setCurrentMovie(media.book[0]);
        } else if (newValue === TAB_INDEXES.EXPERIENCE) {
            // Handle the experience tab, currently rendering it blank
            setCurrentMovie(null);
        }
    };

    const currentBook = media.book?.[currentBookIndex] || null;

    useEffect(() => {
        if (!currentMovie && !currentBook) return;

        if (currentMovie) {
            setActiveContent(currentMovie.overview);
        } else if (currentBook) {
            setActiveContent(currentBook.overview);
        }
    }, [currentMovie, currentBook]);

    const handleNext = () => {
        if (media.book.length > 0) {
            setCurrentBookIndex((prevIndex) => {
                const newIndex = (prevIndex + 1) % media.book.length;
                setActiveContent(media.book[newIndex].overview);
                return newIndex;
            });
        }
    };

    const handlePrevious = () => {
        if (media.book.length > 0) {
            setCurrentBookIndex((prevIndex) => {
                const newIndex = (prevIndex - 1 + media.book.length) % media.book.length;
                setActiveContent(media.book[newIndex].overview);
                return newIndex;
            });
        }
    };

    const handleIconClick = (type) => {
        const contentSource = selectedTab === TAB_INDEXES.BOOKS ? currentBook : currentMovie;
        if (!contentSource) return;

        const content = {
            overview: contentSource.overview,
            personalNote: contentSource.personalNote,
            like: `You liked this ${selectedTab === TAB_INDEXES.BOOKS ? 'book' : 'movie'}!`
        }[type];

        setActiveContent(content);
    };
    
    const renderHome = () => {
        return (
            <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', textAlign: 'center', fontFamily: 'Arial, sans-serif' }}>
                <Typography variant="h3" style={{ marginBottom: '20px' }}>
                    Welcome to the Home Tab!
                </Typography>
                <Typography variant="body1" style={{ lineHeight: '1.6' }}>
                    This is the home section where you can put an overview or introduction to the content available in your application.
                    Feel free to customize this section with images, links, or any other components.
                </Typography>
                {/* Additional content for the home section can go here */}
            </div>
        );
    };

    const renderSection = (section) => (
        <div>
            {/* Background Image Section */}
            <div
                style={{
                    backgroundImage: `url(${currentMovie?.backdrop_path})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    width: isXsScreen ? '100vw' : '60vw',
                    height: isXsScreen ? '40vh' : '60vh',
                    position: 'fixed',
                    top: isXsScreen ? '10vh' : 0,
                    left: isXsScreen ? 0 : '40vw',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: -1,
                    transition: 'opacity 2s ease-in-out',
                }}
            />

            {/* Additional Content for Non-Mobile Screens */}
            {!isXsScreen && (
                <div
                    style={{
                        background: 'linear-gradient(to right, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0) 80%)',
                        width: '70%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        padding: 'initial',
                        zIndex: 1,
                    }}
                >
                    <Grid container spacing={2} style={{ marginLeft: '25px', paddingTop: '100px', color: '#fff' }}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h3"
                                sx={{
                                    fontFamily: 'Righteous, sans-serif',
                                    fontSize: '2.5rem',
                                    textAlign: 'left',
                                }}
                            >
                                {currentMovie?.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Noto Sans, sans-serif',
                                    textAlign: 'left',
                                    fontSize: '1rem',
                                }}
                            >
                                Language: {currentMovie?.original_language?.toUpperCase()} | Release Date: {currentMovie?.release_date}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', margin: '20px 25px' }}>
                            <IoIosInformationCircleOutline
                                onClick={() => handleIconClick('overview')}
                                style={{ cursor: 'pointer', fontSize: '28px' }}
                            />
                            <IoIosHeartEmpty
                                onClick={() => handleIconClick('like')}
                                style={{ cursor: 'pointer', fontSize: '28px' }}
                            />
                            <IoIosQuote
                                onClick={() => handleIconClick('personalNote')}
                                style={{ cursor: 'pointer', fontSize: '28px' }}
                            />
                        </Grid>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1" sx={{ fontFamily: 'Noto Sans, sans-serif', padding: '5px' }}>
                                {activeContent}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            )}

            {/* Section with Cards (original renderSection content) */}
            <div style={{ position: 'absolute', bottom: 0, width: '60%', padding: '10px 0', overflowX: 'auto', left: '40vw', whiteSpace: 'nowrap', zIndex: 1000 }}>
                {media[section]?.map((item, index) => {
                    const hasVideo = youtubeVideoMapping[item.title];
                    return (
                        <Card
                            key={index}
                            style={{
                                display: 'inline-block',
                                margin: '0 10px',
                                height: '300px',
                                width: hasVideo && hoveredMovie === item ? '500px' : '200px',
                                cursor: 'pointer',
                                transition: 'width 0.3s ease-in-out'
                            }}
                            onClick={() => handleCardClick(item)}
                            onMouseLeave={handleCardMouseLeave}
                        >
                            <CardActionArea>
                                {hasVideo && hoveredMovie === item ? (
                                    <iframe
                                        id={`player-${item.title}`}
                                        width="500px"
                                        height="300px"
                                        src={`http://www.youtube.com/embed/${youtubeVideoMapping[item.title]}?autoplay=1&controls=0&fs=0&rel=0&showinfo=0&start=10&modestbranding=0`}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title={item.title}
                                    />
                                ) : (
                                    <CardMedia component="img" height="300px" width="200px" image={item.poster_path} alt={item.title} />
                                )}
                                <CardContent>
                                    <Typography variant="subtitle1" style={{ color: '#fff' }}>
                                        {item.title}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    );
                })}
            </div>
        </div>
    );

    const renderSectionMobile = (section) => {
        if (!media[section]?.length) return null;

        const handleMobileNext = () => {
            setCurrentMovie((prevMovie) => {
                const currentIndex = media[section].findIndex(item => item.title === prevMovie.title);
                const nextIndex = currentIndex === media[section].length - 1 ? 0 : currentIndex + 1;
                return media[section][nextIndex];
            });
        };

        const handleMobilePrevious = () => {
            setCurrentMovie((prevMovie) => {
                const currentIndex = media[section].findIndex(item => item.title === prevMovie.title);
                const prevIndex = currentIndex === 0 ? media[section].length - 1 : currentIndex - 1;
                return media[section][prevIndex];
            });
        };

        const hasVideo = youtubeVideoMapping[currentMovie.title];

        return (
            <div>
                {/* Background Image Section */}
                <div
                    style={{
                        backgroundImage: `url(${currentMovie?.backdrop_path})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        width: '100vw',
                        height: '40vh',
                        position: 'fixed',
                        top: '10vh',
                        left: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: -1,
                        transition: 'opacity 2s ease-in-out',
                    }}
                />

                <div
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        width: '100%',
                        height: '60vh',
                        padding: '10px 0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        background: 'linear-gradient(to top, rgba(0, 0, 0, 1) 50vh, rgba(0, 0, 0, 0.5) 55vh, rgba(0, 0, 0, 0) 60vh)',
                        zIndex: 1000,
                    }}
                >
                    <IconButton onClick={handleMobilePrevious} sx={{ color: '#fff', fontSize: '24px' }}>
                        <ArrowBackIosIcon />
                    </IconButton>

                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            padding: '0 10px',
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                fontFamily: 'Righteous, sans-serif',
                                fontSize: '1.5rem',
                                color: '#fff',
                            }}
                        >
                            {currentMovie.title}
                        </Typography>

                        <Typography
                            variant="body2"
                            sx={{
                                fontFamily: 'Noto Sans, sans-serif',
                                fontSize: '0.9rem',
                                color: '#ccc',
                                margin: '5px 0',
                            }}
                        >
                            Language: {currentMovie.original_language.toUpperCase()} | Release: {currentMovie.release_date}
                        </Typography>

                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px', margin: '10px 0' }}>
                            <IoIosInformationCircleOutline
                                onClick={() => handleIconClick('overview')}
                                style={{ cursor: 'pointer', fontSize: '24px', color: '#fff' }}
                            />
                            <IoIosHeartEmpty
                                onClick={() => handleIconClick('like')}
                                style={{ cursor: 'pointer', fontSize: '24px', color: '#fff' }}
                            />
                            <IoIosQuote
                                onClick={() => handleIconClick('personalNote')}
                                style={{ cursor: 'pointer', fontSize: '24px', color: '#fff' }}
                            />
                        </Box>

                        <Box
                            sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                padding: '10px',
                                borderRadius: '10px',
                                maxHeight: '140px',
                                overflowY: 'auto',
                            }}
                        >
                            <Typography variant="body2" sx={{ fontFamily: 'Noto Sans, sans-serif', color: '#fff' }}>
                                {activeContent}
                            </Typography>
                        </Box>
                    </Box>

                    <IconButton onClick={handleMobileNext} sx={{ color: '#fff', fontSize: '24px' }}>
                        <ArrowForwardIosIcon />
                    </IconButton>
                </div>
            </div>
        );
    };


    const renderBooks = () => {
        if (!currentBook) return null;

        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    color: '#fff',
                    textAlign: 'center',
                    position: 'relative',
                    overflow: 'hidden'
                }}
            >
                <IconButton
                    sx={{ color: '#fff', position: 'absolute', left: '5%' }}
                    onClick={handlePrevious}
                >
                    <ArrowBackIosIcon />
                </IconButton>

                <Box
                    component="img"
                    src={currentBook.poster_path}
                    alt={`${currentBook.title} cover`}
                    sx={{
                        position: 'absolute',
                        top: '10vh',
                        right: isXsScreen ? 'auto' : '18vw', // Keep right for desktop, auto for mobile
                        left: isXsScreen ? '50%' : 'auto',  // Center horizontally on mobile, auto for desktop
                        transform: isXsScreen ? 'translateX(-50%)' : 'none', // Only center on mobile
                        aspectRatio: 3 / 4.5,
                        objectFit: 'cover',
                        height: isXsScreen ? '300px' : '375px',
                        width: isXsScreen ? '200px' : '250px',
                        boxShadow: `
                        0px 4px 8px rgba(0, 0, 0, 0.25),
                        0px 8px 16px rgba(0, 0, 0, 0.5),
                        0px 12px 24px rgba(0, 0, 0, 1)
                    `,
                        borderRadius: '10px',
                        zIndex: 10,
                    }}
                />

                <Box
                    sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        padding: '20px',
                        borderRadius: '25px',
                        top: isXsScreen ? '10vh' : 'auto',
                        width: isXsScreen ? '70vw' : '70vw',
                        height: isXsScreen ? '70vh' : '70vh',
                        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.5)',
                        overflowY: 'auto',
                        position: 'relative',
                        overflow: 'hidden'
                    }}
                >
                    <Grid container spacing={2} md={8} xs={12} sx={{ alignItems: 'flex-start', textAlign: 'left', position: 'relative', zIndex: 1, top: isXsScreen ? '15vh' : 'auto', }}>
                        <Grid item xs={12} sx={{ height: '110px' }}>
                            <Typography variant="h4" sx={{ fontFamily: 'Righteous, sans-serif', margin: isXsScreen ? '20px 25px 5px 25px' : '20px 25px', fontSize: isXsScreen ? '1.2rem' : 'auto' }}>
                                {currentBook.title}
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={12} sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', margin: isXsScreen ? '0px 25px 5px 25px' : '20px 25px', padding: isXsScreen ? '16px 16px 16px 0px' : '20px 25px' }}>
                            <IoIosInformationCircleOutline
                                onClick={() => handleIconClick('overview')}
                                style={{ cursor: 'pointer', fontSize: isXsScreen ? '1.2rem' : '28px' }}
                            />
                            <IoIosHeartEmpty
                                onClick={() => handleIconClick('like')}
                                style={{ cursor: 'pointer', fontSize: isXsScreen ? '1.2rem' : '28px' }}
                            />
                            <IoIosQuote
                                onClick={() => handleIconClick('personalNote')}
                                style={{ cursor: 'pointer', fontSize: isXsScreen ? '1.2rem' : '28px' }}
                            />
                        </Grid>

                        <Grid item xs={12} sx={{ margin: '0px 25px 0px 25px' }}>
                            {!isXsScreen ? (
                                // Desktop view rendering
                                <Typography variant="body1" sx={{ fontFamily: 'Noto Sans, sans-serif' }}>
                                    {activeContent}
                                </Typography>
                            ) : (
                                // Mobile view rendering
                                <Box
                                    sx={{
                                        padding: '10px',
                                        width: '100%',
                                        backgroundColor: '#0000',
                                        borderRadius: '8px',
                                        maxHeight: '30vh', // Set a max height to enable scrolling
                                        overflow: 'auto', // Enable scrolling when content exceeds maxHeight
                                    }}
                                >
                                    <Typography variant="body2" sx={{ fontFamily: 'Noto Sans, sans-serif' }}>
                                        {activeContent}
                                    </Typography>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Box>

                <IconButton
                    sx={{ color: '#fff', position: 'absolute', right: '5%' }}
                    onClick={handleNext}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            </div>
        );
    };


    const renderExperience = () => {
        const handleNext = () => {
            setCurrentUrlIndex((prevIndex) => (prevIndex + 1) % creditedUrls.length);
        };

        const handlePrevious = () => {
            setCurrentUrlIndex((prevIndex) => (prevIndex - 1 + creditedUrls.length) % creditedUrls.length);
        };

        if (metaLoading) {
            return <div>Loading Experience...</div>;
        }

        if (metaError || !metaData) {
            return <div>Error loading experience data or no data available.</div>;
        }

        return (
            <div style={{ padding: '20px', maxWidth: isXsScreen ? '70vw' : '50vw', maxHeight: isXsScreen ? '20vw' : '30vw', margin: '0 auto', fontFamily: 'Georgia, serif', position: 'relative', top: '10vh' }}>
                {/* Navigation Buttons */}
                <IconButton onClick={handlePrevious} style={{ position: 'absolute', top: '50%', left: '-50px', transform: 'translateY(-50%)', zIndex: 1000 }}>
                    <ArrowBackIosIcon />
                </IconButton>
                <IconButton onClick={handleNext} style={{ position: 'absolute', top: '50%', right: '-50px', transform: 'translateY(-50%)', zIndex: 1000 }}>
                    <ArrowForwardIosIcon />
                </IconButton>

                {/* Newspaper-like Content */}
                <div style={{ borderBottom: '2px solid #333', marginBottom: '20px' }}>
                    <Typography variant="body1" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        {metaData.title}
                    </Typography>
                </div>
                <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                    {metaData.image && (
                        <img src={metaData.image} alt="Front Page News" style={{ width: '100%', height: 'auto', maxHeight: '400px', objectFit: 'cover' }} />
                    )}
                </div>
                <Typography variant="body1" style={{ textAlign: 'justify' }}>
                    {metaData.description}
                </Typography>

                {/* Clickable Chip */}
                <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
                    <ClickableChip label="" url={creditedUrls[currentUrlIndex]} favicon={metaData.favicon} />
                </Box>
            </div>
        );
    };


    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Box sx={{ borderBottom: 1, borderColor: "transparent", bgcolor: 'transparent', zIndex: 1000, position: 'fixed', left: isXsScreen ? 0 : '25px', top: '10px', width: isXsScreen ? '100%' : 'auto' }}>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="media tabs"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: 'white',
                            color: 'white',
                        }
                    }}
                    variant={isXsScreen ? "fullWidth" : "standard"}
                    sx={{
                        '.MuiTab-root': {
                            color: 'rgba(255, 255, 255, 0.7)',
                            '&:hover': {
                                color: 'white',
                                opacity: 1,
                            },
                            minWidth: isXsScreen ? 'auto' : '160px',
                            padding: isXsScreen ? '10px 5px' : '10px 20px',
                        },
                        '.Mui-selected': {
                            color: 'white',
                            textShadow: '0px 0px 10px white',
                        },
                        display: 'flex',
                        justifyContent: 'space-around',
                    }}
                >
                    <Tab
                        label={isXsScreen ? null : "Home"}
                        icon={isXsScreen ? <HomeIcon /> : null}
                    />
                    <Tab
                        label={isXsScreen ? null : "Movies"}
                        icon={isXsScreen ? <MovieIcon /> : null}
                    />
                    <Tab
                        label={isXsScreen ? null : "TV Shows"}
                        icon={isXsScreen ? <TvIcon /> : null}
                    />
                    <Tab
                        label={isXsScreen ? null : "Documentaries"}
                        icon={isXsScreen ? <TheatersIcon /> : null}
                    />
                    <Tab
                        label={isXsScreen ? null : "Books"}
                        icon={isXsScreen ? <BookIcon /> : null}
                    />
                    <Tab
                        label={isXsScreen ? null : "Experience"}
                        icon={isXsScreen ? <MoreHorizIcon /> : null}
                    />
                </Tabs>
            </Box>

            {selectedTab === TAB_INDEXES.HOME && (
                <div>
                    {renderHome()}
                </div>
            )}

            {selectedTab === TAB_INDEXES.MOVIES && (
                <div>
                    {isXsScreen ? renderSectionMobile('movie') : renderSection('movie')}
                </div>
            )}
            {selectedTab === TAB_INDEXES.TV_SHOWS && (
                <div>
                    {isXsScreen ? renderSectionMobile('series') : renderSection('series')}
                </div>
            )}
            {selectedTab === TAB_INDEXES.DOCUMENTARY && (
                <div>
                    {isXsScreen ? renderSectionMobile('documentary') : renderSection('documentary')}
                </div>
            )}


            {selectedTab === TAB_INDEXES.BOOKS && (
                <div>
                    {renderBooks()}
                </div>
            )}

            {selectedTab === TAB_INDEXES.EXPERIENCE && (
                <div>
                    {renderExperience()}
                </div>
            )}

        </div>
    );
};

export default PublicViewTest;
