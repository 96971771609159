import HomeIcon from '@mui/icons-material/Home';
import { useAxios } from '../contexts/AxiosContext';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import logger from "../logger";
import { Grid, IconButton, Fade, Tabs, Tab, Box } from "@mui/material";
import { useTheme } from '../contexts/ThemeContext';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import LanguagesCard from './ProfileComponents/LanguagesCard';
import UserProfileCard from './ProfileComponents/UserProfileCard';
import MovieCarouselCard from './ProfileComponents/MovieCarouselCard';
import SeriesCarouselCard from './ProfileComponents/SeriesCarouselCard';
import DocCarouselCard from './ProfileComponents/DocCarouselCard';
import PeopleAndOthersCard from './ProfileComponents/PeopleAndOthersCard';
import fetchProfileHelper from '../helperFunctions/fetchProfileHelper'

const UserProfile = () => {
    const navigate = useNavigate();
    const { theme, toggleTheme } = useTheme();
    const { userId } = useParams();
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fadeIn, setFadeIn] = useState(false);
    const [showTabs, setShowTabs] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const axios = useAxios();

    useEffect(() => {
        setLoading(true);
        const fetchProfile = async () => {
            try {
                const profileData = await fetchProfileHelper(axios, userId); // Pass Axios instance
                setProfile(profileData);
                setFadeIn(true);  // Trigger fade-in effect when data is loaded
            } catch (error) {
                navigate(`/`);
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();

    }, [userId, navigate]);

    // Function to generate random delay
    const getRandomDelay = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleEditClick = () => {
        navigate(`/edit-profile/${userId}`);
    };

    const handleMouseMove = (event) => {
        const windowHeight = window.innerHeight;
        if (windowHeight - event.clientY < 50) {
            setShowTabs(true);
        } else {
            setShowTabs(false);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowLeft') {
            setTabIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        } else if (event.key === 'ArrowRight') {
            setTabIndex((prevIndex) => (prevIndex < 3 ? prevIndex + 1 : prevIndex));
        }
    };

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!profile) {
        return null;
    }

    return (
        <div onMouseMove={handleMouseMove}>
            <Grid container spacing={2} justifyContent="left" alignItems="left" sx={{ height: '100vh', padding: 2 }}>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        zIndex: 1000,
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.text.primary,
                    }}
                    onClick={toggleTheme}
                >
                    {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
                <IconButton onClick={() => navigate('/')}  sx={{
                        position: 'absolute',
                        top: 16,
                        right: 20,
                        zIndex: 1000,
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.text.primary,
                    }}>
                    <HomeIcon />
                </IconButton>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                    <Grid container direction="column" spacing={2}>
                        <Fade in={fadeIn} timeout={1000} style={{ transitionDelay: `${getRandomDelay(0, 500)}ms` }}>
                            <Grid item>
                                <UserProfileCard profile={profile} handleEditClick={handleEditClick} />
                            </Grid>
                        </Fade>
                        <Fade in={fadeIn} timeout={1000} style={{ transitionDelay: `${getRandomDelay(500, 1000)}ms` }}>
                            <Grid item>
                                <LanguagesCard /> {/* Use the new component */}
                            </Grid>
                        </Fade>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={9}>
                    <Fade in={fadeIn} timeout={1000} style={{ transitionDelay: `${getRandomDelay(1000, 1500)}ms` }}>
                        <Grid item>
                            {tabIndex === 0 && (<MovieCarouselCard userId={userId} />)}
                            {tabIndex === 1 && (<SeriesCarouselCard userId={userId} />)}
                            {tabIndex === 2 && (<DocCarouselCard userId={userId} />)}
                            {tabIndex === 3 && (<PeopleAndOthersCard userId={userId} />)}
                        </Grid>
                    </Fade>
                </Grid>
            </Grid>
            {showTabs && (
                <Box sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: 3,
                    zIndex: 1100,
                }}>
                    <Tabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="Movies" />
                        <Tab label="Series" />
                        <Tab label="Documentary" />
                        <Tab label="People" />
                    </Tabs>
                </Box>
            )}
        </div>
    );
};

export default UserProfile;
