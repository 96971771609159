import { FormControl, InputLabel, Select, MenuItem, Typography, Slider, Box, IconButton } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { useTheme } from '../contexts/ThemeContext';

function SocialGraph() {
    const svgRef = useRef(null);
    const [connectionLevel, setConnectionLevel] = useState(1);
    const [data, setData] = useState({ nodes: [], links: [] });
    const [selectedNode, setSelectedNode] = useState(null);
    const [selectedOccupation, setSelectedOccupation] = useState("");
    const { theme, toggleTheme } = useTheme();

    useEffect(() => {
        setData(generateData());
    }, []);

    useEffect(() => {
        drawGraph();
    }, [connectionLevel, data, selectedNode, selectedOccupation]);

    const generateData = () => {
        const occupations = ["Director", "Producer", "Studio", "Writer", "Actor"];
        const nodes = Array.from({ length: 50 }, (_, i) => ({
            id: `Person ${i + 1}`,
            occupation: occupations[Math.floor(Math.random() * occupations.length)]
        }));
        const links = [];

        nodes.forEach((node, index) => {
            const numConnections = Math.floor(Math.random() * 4) + 1; // 1 to 4 connections
            for (let i = 0; i < numConnections; i++) {
                const targetIndex = Math.floor(Math.random() * nodes.length);
                if (index !== targetIndex) {
                    links.push({ source: nodes[index], target: nodes[targetIndex] }); // Use node references directly
                }
            }
        });

        return { nodes, links };
    };

    const drawGraph = () => {
        const svg = d3.select(svgRef.current);
        svg.selectAll("*").remove();  // Clear the svg canvas on redraw

        const simulation = d3.forceSimulation(data.nodes)
            .force("link", d3.forceLink(data.links).id(d => d.id).distance(50))
            .force("charge", d3.forceManyBody().strength(-400))
            .force("center", d3.forceCenter(400, 300));

        const link = svg.selectAll(".link")
            .data(data.links)
            .enter().append("line")
            .attr("stroke-width", 1)
            .attr("stroke", "#999")
            .attr("opacity", 0.6);

        const node = svg.selectAll(".node")
            .data(data.nodes)
            .enter().append("circle")
            .attr("r", 5)
            .attr("fill", node => node.occupation === selectedOccupation ? 'yellow' : '#69b3a2')
            .on("click", handleClick)
            .on("mouseover", handleMouseOver)
            .on("mouseout", handleMouseOut)
            .call(d3.drag()
                .on("start", event => dragstarted(event, simulation))
                .on("drag", event => dragged(event, simulation))
                .on("end", event => dragended(event, simulation)));

        node.append("title")
            .text(d => `${d.id} - ${d.occupation}`);

        simulation.on("tick", () => {
            link.attr("x1", d => d.source.x)
                .attr("y1", d => d.source.y)
                .attr("x2", d => d.target.x)
                .attr("y2", d => d.target.y);

            node.attr("cx", d => d.x)
                .attr("cy", d => d.y);
        });

        if (selectedNode) {
            highlightNodes(selectedNode);
        }
    };

    function handleClick(event, d) {
        setSelectedNode(d);
    }

    function handleMouseOver(event, d) {
        d3.select("#tooltip")
            .style("left", event.pageX + 10 + "px")
            .style("top", event.pageY + 10 + "px")
            .text(`${d.id} - ${d.occupation}`)
            .classed("hidden", false);
    }

    function handleMouseOut(event, d) {
        d3.select("#tooltip")
            .classed("hidden", true);
    }

    function dragstarted(event, simulation) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        event.subject.fx = event.subject.x;
        event.subject.fy = event.subject.y;
    }

    function dragged(event, simulation) {
        event.subject.fx = event.x;
        event.subject.fy = event.y;
    }

    function dragended(event, simulation) {
        if (!event.active) simulation.alphaTarget(0);
        event.subject.fx = null;
        event.subject.fy = null;
    }

    const highlightNodes = (selectedNode) => {
        const nodesToHighlight = new Set();
        const linksToHighlight = new Set();

        const queue = [{ node: selectedNode, level: 0 }];
        while (queue.length > 0) {
            const { node, level } = queue.shift();
            if (level > connectionLevel) continue;

            nodesToHighlight.add(node.id);
            data.links.forEach(link => {
                if (link.source.id === node.id) {
                    linksToHighlight.add(link);
                    if (!nodesToHighlight.has(link.target.id)) {
                        queue.push({ node: link.target, level: level + 1 });
                    }
                } else if (link.target.id === node.id) {
                    linksToHighlight.add(link);
                    if (!nodesToHighlight.has(link.source.id)) {
                        queue.push({ node: link.source, level: level + 1 });
                    }
                }
            });
        }

        d3.selectAll('circle')
          .attr('fill', node => nodesToHighlight.has(node.id) ? '#c18c8c' : (node.occupation === selectedOccupation ? 'yellow' : '#69b3a2'));

        d3.selectAll('line')
          .attr('stroke', link => linksToHighlight.has(link) ? '#c18c8c' : '#999')
          .attr('stroke-width', link => linksToHighlight.has(link) ? 2 : 1)
          .attr('opacity', link => linksToHighlight.has(link) ? 1 : 0.6);
    };

    return (
        <div>
            <Box sx={{
                display: "flex", 
                flexDirection: "row",
                gap: 3,         // adds spacing of 3 between child components
                m: 2            // adds a margin of 10px around the Box
            }}>                
                <Typography id="connection-level-label" gutterBottom>
                    Connection Level: {connectionLevel}
                </Typography>
                <Slider
                    value={connectionLevel}
                    onChange={(e, newValue) => setConnectionLevel(newValue)}
                    aria-labelledby="connection-level-label"
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={1}
                    max={3}
                />
                <FormControl fullWidth>
                    <InputLabel id="occupation-select-label">Select Occupation</InputLabel>
                    <Select
                        labelId="occupation-select-label"
                        id="occupationSelect"
                        value={selectedOccupation}
                        onChange={(e) => setSelectedOccupation(e.target.value)}
                        label="Occupation"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="Director">Director</MenuItem>
                        <MenuItem value="Producer">Producer</MenuItem>
                        <MenuItem value="Studio">Studio</MenuItem>
                        <MenuItem value="Writer">Writer</MenuItem>
                        <MenuItem value="Actor">Actor</MenuItem>
                    </Select>
                </FormControl>
                <IconButton onClick={toggleTheme}>
            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
            </Box>
            <svg ref={svgRef} width="800" height="600" marginLeft="20vh" marginTop="20px"></svg>
            <Box id="tooltip" className="hidden" style={{ position: 'absolute', backgroundColor: 'white', padding: '5px', border: '1px solid black', borderRadius: '5px' }}></Box>
            
        </div>
    );
}

export default SocialGraph;
