import Fab from '@mui/material/Fab'; // Import the Fab component
import React, { useState, useRef } from 'react';
import {
  ToggleButton,
  IconButton,
  Tooltip,
  ToggleButtonGroup,
  FormControl,
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  Slider,
  Grid,
  LinearProgress,
  InputLabel,
  Drawer,
  Typography,
  useMediaQuery,
  Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment'
import SettingsIcon from '@mui/icons-material/Settings';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import config from './Ideogram_config.json'; // Ensure this path is correct
import { Quill as GlobalQuill } from 'react-quill';
import loglines from './loglines.json'; // Adjust the path as necessary
import { useAxios } from '../../../contexts/AxiosContext';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from 'file-saver'; // You need to install 'file-saver' using `npm install file-saver`
import { useLogin } from '../../../contexts/LoginContext';
import { SiTurbo } from "react-icons/si";
import { GiCagedBall } from "react-icons/gi";
import { BsEraser } from "react-icons/bs";
import { MdOutlineTitle } from "react-icons/md";
import { MdOutlineSubtitles } from "react-icons/md";
import { MdMovieFilter } from "react-icons/md";
import { CgNotes } from "react-icons/cg";
import { RiMovie2Line } from "react-icons/ri";

const Inline = GlobalQuill.import('blots/inline');

class TagBlot extends Inline {
  static create(value) {
    let node = super.create();
    node.setAttribute('data-tag', value);
    node.style.display = 'inline-block';
    node.style.backgroundColor = '#e0e0e0';
    node.style.color = '#333';
    node.style.padding = '2px 5px';
    node.style.borderRadius = '15px';
    node.style.margin = '2px';
    node.style.fontSize = '0.9em';
    node.textContent = `[${value}]`; // Display the tag as [tagname]
    return node;
  }

  static formats(node) {
    return node.getAttribute('data-tag');
  }
}

TagBlot.blotName = 'tag';
TagBlot.tagName = 'span'; // The HTML tag to use for the blot
GlobalQuill.register(TagBlot);

function IdeogramAPIPlayground({ template }) {
  const [loading, setLoading] = useState(false);
  const [imageGenerated, setImageGenerated] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(config.defaultValues.aspectRatio);
  const [model, setModel] = useState(config.defaultValues.model);
  const [magicPrompt, setMagicPrompt] = useState(config.defaultValues.magicPrompt);
  const [negativePrompt, setNegativePrompt] = useState(config.defaultValues.negativePrompt);
  const [seed, setSeed] = useState(config.defaultValues.seed);
  const [prompt, setPrompt] = useState(template || `Create a captivating Movie poster with the below details<br>
    Title:     [Title]<br>
    Logline:     [Longline]<br>
    Genre:     [Genre]<br>
    Format:     [Format]<br>
    Other Remarks:     [Other_remarks]`);
  const quillRef = useRef(null);
  const [title, setTitle] = useState("");
  const [genre, setGenre] = useState("");
  const [format, setFormat] = useState("");
  const [longline, setLongline] = useState("");
  const [otherRemarks, setOtherRemarks] = useState("");
  const [selectedStory, setSelectedStory] = useState(null);
  const axios = useAxios()
  const [progress, setProgress] = useState(0);
  const [statusMessage, setStatusMessage] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [magicResPrompt, SetMagicResPrompt] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [responseTime, setResponseTime] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { userId, username } = useLogin();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [generatedImages, setGeneratedImages] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageWeight, setImageWeight] = useState(50); // Default image weight set to 50

  const handleImageClick = (imageData) => {
    setSelectedImage(imageData);
    setOpenDialog(true);
  };


  const handleSubmit = async () => {
    setLoading(true);
    setProgress(0);
    setStatusMessage("Processing request...");

    // Simulate a progress bar that fills up to 90% over 13 seconds
    let progressInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev < 90) return prev + 2;
        clearInterval(progressInterval);
        return prev;
      });
    }, 290);

    // Replace tags in the original prompt with corresponding values
    let processedPrompt = prompt.replaceAll('[Title]', ` ${title.trim()} `)
      .replaceAll('[Longline]', ` ${longline.trim()} `)
      .replaceAll('[Genre]', ` ${genre.trim()} `)
      .replaceAll('[Format]', ` ${format.trim()} `)
      .replaceAll('[Other_remarks]', ` ${otherRemarks.trim()} `);

    // Strip out any HTML tags
    let plainTextPrompt = stripHtmlTags(processedPrompt);

    const requestBody = {
      prompt: plainTextPrompt,
      title,
      logLine: longline,
      genre,
      format,
      other_remarks: otherRemarks,
      userId: userId || config.defaultValues.userId,
      userName: username || "tester",
      model,
      aspect_ratio: aspectRatio,
      magic_prompt_option: magicPrompt,
      negative_prompt: negativePrompt,
      template: prompt,
    };

    // Log the request body
    console.log('Request Body:', requestBody);

    try {
      const startTime = Date.now(); // Capture start time

      const response = await axios.post('/poster/generate-poster-v2', requestBody);
      const endTime = Date.now(); // Capture end time
      const duration = endTime - startTime;
      setResponseTime(`Response Time: ${duration / 1000}s`); // Set response time

      if (response.data && response.data.fileUrl) {
        setProgress(100);
        setStatusMessage("Image generated successfully!");

        setImageUrl(response.data.fileUrl);
        console.log(response.data.responsePayload.data[0].prompt)
        SetMagicResPrompt(response.data.responsePayload.data[0].prompt)
        setImageData(response.data.imageData);
        const newImage = { imageData: response.data.imageData, prompt: plainTextPrompt };
        setGeneratedImages((prevImages) => [...prevImages, newImage]);

      } else {
        throw new Error(response.data.error || 'Failed to generate image');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatusMessage('An error occurred while generating the image.');
    } finally {
      setLoading(false);
    }
  };

  const handleRemixSubmit = async () => {
    setLoading(true);
    setStatusMessage("Processing remix request...");

    // Replace tags in the original prompt with corresponding values
    let processedPrompt = prompt.replaceAll('[Title]', ` ${title.trim()} `)
      .replaceAll('[Longline]', ` ${longline.trim()} `)
      .replaceAll('[Genre]', ` ${genre.trim()} `)
      .replaceAll('[Format]', ` ${format.trim()} `)
      .replaceAll('[Other_remarks]', ` ${otherRemarks.trim()} `);

    // Strip out any HTML tags
    let plainTextPrompt = stripHtmlTags(processedPrompt);

    const requestBody = {
      prompt: plainTextPrompt,
      title,
      logLine: longline,
      genre,
      format,
      other_remarks: otherRemarks,
      userId: userId || config.defaultValues.userId,
      userName: username || "tester",
      model,
      aspect_ratio: aspectRatio,
      magic_prompt_option: magicPrompt,
      negative_prompt: negativePrompt,
      template: prompt, 
      imageData: selectedImage, // Use the selected image for remix
      image_weight: imageWeight, // Convert weight to a value between 0 and 1
      seed,
    };

    try {
      const response = await axios.post('/poster/remix-image', requestBody);

      if (response.data && response.data.fileUrl) {
        setGeneratedImages((prevImages) => [
          ...prevImages,
          { imageData: response.data.imageData, prompt: requestBody.prompt }
        ]);
        setStatusMessage("Remix generated successfully!");
      } else {
        setStatusMessage("Remix failed");
      }
    } catch (error) {
      console.error("Error remixing image:", error);
      setStatusMessage("An error occurred while remixing the image.");
    } finally {
      setLoading(false);
    }
  };


  const handleQuillChange = (content) => {
    setPrompt(content);
  };

  const stripHtmlTags = (htmlString) => {
    return htmlString.replace(/<\/?[^>]+(>|$)/g, "");
  };

  const handleStorySelect = (event) => {
    const story = loglines.find(s => s.name === event.target.value);
    if (story) {
      setTitle(story.name);
      setGenre(story.genre);
      setFormat(story.format);
      setLongline(story.logLine);
      setOtherRemarks("");
      setSelectedStory(story.name);
    }
  };

  const handleClearStory = () => {
    setTitle('');
    setGenre('');
    setFormat('');
    setLongline('');
    setOtherRemarks('');
    setSelectedStory(null);
  };

  const handleTagClick = (tag) => {
    const quillEditor = quillRef.current.getEditor();
    const cursorPosition = quillEditor.getSelection()?.index;
    const tagWithSpaces = `    [${tag}]    `; // Four spaces before and after the tag

    if (cursorPosition !== null && cursorPosition !== undefined) {
      quillEditor.insertText(cursorPosition, tagWithSpaces);
    } else {
      quillEditor.focus();
      quillEditor.insertText(quillEditor.getLength() - 1, tagWithSpaces);
    }
  };

  return (
    <Box
      p={2}
      bgcolor="background.default"
      color="text.primary"
      sx={{
        borderRadius: 2,
        boxShadow: 3,
        maxWidth: '100%',
        margin: 'auto',
      }}
    >
      <Grid container spacing={2} direction="column" alignItems="center">
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
              <IconButton
                onClick={() => setDrawerOpen(true)}
                color="inherit"
                sx={{ display: isMobile ? 'block' : 'none' }}
              >
                <SettingsIcon />
              </IconButton>
              {!isMobile && (
                <Typography variant="h4" align="center" gutterBottom>
                  Ideogram API Playground
                </Typography>
              )}

            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          {imageData && (
            <Grid item xs={12}>
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  overflowX: 'auto', // Allow horizontal scrolling
                  borderRadius: 5,
                  paddingBottom: 2, // Add some padding to separate the thumbnails from the scroll bar
                  whiteSpace: 'nowrap', // Prevent the thumbnails from wrapping
                }}
              >
                <Grid container spacing={2} wrap="nowrap" sx={{ display: 'inline-flex' }}>
                  {generatedImages.map((image, index) => (
                    <Grid item key={index} sx={{ flexShrink: 0 }}> {/* Prevent shrinking */}
                      <img
                        src={`data:image/jpeg;base64,${image.imageData}`}
                        alt="Generated"
                        style={{ width: '100px', height: '100px', borderRadius: 5, objectFit: 'contain', cursor: 'pointer' }} // Set fixed thumbnail size
                        onClick={() => handleImageClick(image.imageData)} // Add click event handler
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={1} >
          <Grid item xs={6} md={6}>
            <Grid container >
              <Grid item xs={12} md={12}>
                <TextField
                  label="Title"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  label="Genre"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={genre}
                  onChange={(e) => setGenre(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  label="Format"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={format}
                  onChange={(e) => setFormat(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6} md={6}>
            <TextField
              label="Logline"
              multiline
              rows={8} // Adjusted rows to match the height
              variant="outlined"
              fullWidth
              margin="normal"
              value={longline}
              onChange={(e) => setLongline(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" align="left">
              Prompt Builder
            </Typography>
          </Grid>
          <Grid item xs={4.5}>
            <ReactQuill
              ref={quillRef}
              value={prompt}
              onChange={handleQuillChange}
              modules={{ toolbar: false }}
              style={{ height: '300px', marginBottom: '20px' }}
            />
          </Grid>
          <Grid item xs={3}>
            <Box display="flex" gap={1} mb={2}>
              {Object.keys(config.tagColors).map((tag) => {
                let IconComponent;
                switch (tag) {
                  case 'Title':
                    IconComponent = MdOutlineTitle;
                    break;
                  case 'Longline':
                    IconComponent = MdOutlineSubtitles;
                    break;
                  case 'Genre':
                    IconComponent = MdMovieFilter;
                    break;
                  case 'Other_remarks':
                    IconComponent = CgNotes;
                    break;
                  case 'Format':
                    IconComponent = RiMovie2Line;
                    break;
                  default:
                    IconComponent = MdOutlineTitle; // default icon
                }
                return (
                  <Tooltip title={tag} key={tag}>
                    <IconButton
                      onClick={() => handleTagClick(tag)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <IconComponent />
                    </IconButton>
                  </Tooltip>
                );
              })}
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setPrompt('')}
              >
                Clear
              </Button>
            </Box>
            <Box marginBottom={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
                disabled={loading}
                sx={{ borderRadius: 2 }}
              >
                Generate Image
              </Button>
            </Box>
            {selectedImage && (
              <Box marginTop={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={handleRemixSubmit}
                  disabled={loading}
                >
                  Generate with Remix
                </Button>
              </Box>
            )}

            <Box>
              <Typography variant="h6">Generate Progress</Typography>
              <LinearProgress variant="determinate" value={progress} sx={{ height: 10, borderRadius: 5 }} />
              <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                {statusMessage}
              </Typography>
            </Box>
            <Typography variant="subtitle1" align="right">
              {responseTime}
            </Typography>
          </Grid>
          <Grid item xs={4.5}>
            <TextField
              label="Magic Prompt"
              variant="outlined"
              disabled
              multiline
              rows={5}
              fullWidth
              value={magicResPrompt}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => navigator.clipboard.writeText(magicResPrompt)}
                      edge="end"
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>Image Preview</DialogTitle>
        <DialogContent>
          <img
            src={`data:image/jpeg;base64,${selectedImage}`}
            alt="Preview"
            style={{ width: '100%', borderRadius: 5 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => saveAs(`data:image/jpeg;base64,${selectedImage}`, 'generated-poster.jpg')}
          >
            Download
          </Button>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Fab
        onClick={() => setDrawerOpen(true)}
        color="primary"
        sx={{
          position: 'fixed',
          top: 16,
          right: 16,
          zIndex: 1000, // Ensures the button is above other elements
        }}
      >
        <SettingsIcon />
      </Fab>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: {
            width: isMobile ? '100%' : 450, // Adjust width based on mobile/desktop
            pl: "20px",
            display: 'flex', // Makes the drawer content a flex container
            flexDirection: 'column', // Stack items vertically
            alignItems: 'center', // Align items in the center horizontally
          },
        }}
      >

        <Grid item xs={8} container spacing={2} style={{ alignItems: "center" }}>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <InputLabel id="demo-simple-select-label">Select Story Template</InputLabel>
              <Grid container spacing={1}>
                <Grid item xs={9}>
                  <Select
                    id="demo-simple-select-label"
                    label="Select Story Template"
                    value={selectedStory || ''}
                    onChange={handleStorySelect}
                    fullWidth
                  >
                    <MenuItem value="" disabled>Select a story</MenuItem>
                    {loglines.map((story) => (
                      <MenuItem key={story.name} value={story.name}>
                        {story.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={3} display="flex" alignItems="center">
                  {selectedStory && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleClearStory}
                      fullWidth
                    >
                      Clear
                    </Button>
                  )}
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Box
            display="flex"
            flexDirection="column"
            // width="60%" // Reducing the size by 40%
            margin="auto"
            gap={1} // Adds spacing between the elements
          >
            <Grid item xs={4}>
              <FormControl fullWidth margin="normal">
                <Typography sx={{ paddingBottom: 1 }}>Aspect Ratio</Typography>
                <ToggleButtonGroup
                  value={aspectRatio}
                  exclusive
                  onChange={(e, newAspectRatio) => setAspectRatio(newAspectRatio)}
                  sx={{
                    button: { padding: '6px 16px', fontSize: '0.8rem', borderRadius: 1, width: '100px' },
                  }}
                >
                  {Object.entries(config.aspectRatios).map(([value, label]) => (
                    <ToggleButton key={value} value={value}>
                      {label}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="normal">
                <Typography sx={{ paddingBottom: 1 }}>Model</Typography>
                <ToggleButtonGroup
                  value={model}
                  exclusive
                  onChange={(e, newModel) => setModel(newModel)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'left',
                    button: { padding: '10px', width: '100px' },
                  }}
                >
                  <ToggleButton value="V_1">
                    <GiCagedBall />
                  </ToggleButton>
                  <ToggleButton value="V_1_TURBO">
                    <SiTurbo />
                  </ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="normal">
                <Typography sx={{ paddingBottom: 1 }}>Magic Prompt</Typography>
                <ToggleButtonGroup
                  value={magicPrompt}
                  exclusive
                  onChange={(e, newMagicPrompt) => setMagicPrompt(newMagicPrompt)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'left',
                    button: { padding: '6px 16px', fontSize: '0.8rem', borderRadius: 1, width: '100px' }
                  }}
                >
                  {config.magicPromptOptions.map((option) => (
                    <ToggleButton key={option} value={option}>{option}</ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </FormControl>
            </Grid>
          </Box>
          {selectedImage && (
            <Box my={2}>
              <Typography variant="body1">Image Weight ({imageWeight})</Typography>
              <Slider
                value={imageWeight}
                onChange={(e, newValue) => setImageWeight(newValue)}
                aria-labelledby="image-weight-slider"
                min={0}
                max={100}
              />
            </Box>
          )}

          <Grid item xs={4.5} md={12}>
            <TextField
              label="Negative Prompt"
              variant="outlined"
              multiline
              rows={10}
              fullWidth
              margin="normal"
              value={negativePrompt}
              onChange={(e) => setNegativePrompt(e.target.value)}
              placeholder="blur, pixelation, distortion"
            />
          </Grid>
        </Grid>
      </Drawer>

    </Box>
  );
}

export default IdeogramAPIPlayground;
