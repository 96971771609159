// styles.js
import React from 'react';
import { styled } from '@mui/system';
import { Tabs, Tab, Typography } from '@mui/material';

export const StyledTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
        display: 'none',
    },
}));

export const StyledTab = styled(
    React.forwardRef(({ isMobile, ...other }, ref) => <Tab ref={ref} {...other} />)
  )(({ theme, isMobile }) => ({
    borderRadius: '15px',
    margin: theme.spacing(0.5),
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: isMobile ? '0.75rem' : '0.875rem',
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
    color: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }));
  

export const TabLabel = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const ProgressLabel = styled(Typography)`
    color: ${({ completed }) => (completed ? '97ffba' : 'inherit')};
    font-weight: ${({ completed }) => (completed ? 'normal' : 'light')};
`;
