import React, { useEffect, useRef } from 'react';
import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useLoadScript } from '@react-google-maps/api';

const libraries = ['places'];

function LocationField() {
    const { register, setValue, formState: { errors } } = useFormContext();
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "AIzaSyDXFIGqLqv0CKY7cU6m5-iUtBGiqSKpsPo",
        libraries,
    });
    const autoCompleteRef = useRef();

    useEffect(() => {
        if (isLoaded && autoCompleteRef.current) {
            const autocomplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current);
            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                if (place.formatted_address) {
                    setValue("userDetails.location.value", place.formatted_address);
                }
            });
        }
    }, [isLoaded, setValue]);

    return (
        <TextField
            fullWidth
            label="Location"
            margin="normal"
            inputRef={autoCompleteRef}
            {...register('userDetails.location.value')}
            error={!!errors.userDetails?.location?.value}
            helperText={errors.userDetails?.location?.value ? errors.userDetails.location.value.message : ''}
        />
    );
}

export default LocationField;
