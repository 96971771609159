"use client";
import React, { useEffect, useState } from "react";
import { useAxios } from '../contexts/AxiosContext';
import { useTheme } from "../contexts/ThemeContext";
import { useLogin } from "../contexts/LoginContext";
import { useParams, useNavigate } from 'react-router-dom';
import logger from "../logger";
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Card,
  IconButton,
  Typography,
  Tooltip,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Button,
  Collapse,
  Grid,
  useMediaQuery,
} from "@mui/material";
import {
  Home as HomeIcon,
  Person as PersonIcon,
  ExitToApp as ExitToAppIcon,
  Brightness4 as Brightness4Icon,
  Brightness7 as Brightness7Icon,
  Work as WorkIcon,
  Book as BookIcon,
  Email as EmailIcon,
  Notifications as NotificationsIcon,
  Translate as TranslateIcon,
  AcUnit as AcUnitIcon,
} from '@mui/icons-material';
import { formatDistanceToNow } from 'date-fns';
import LanguagesCard from './ProfileComponents/LanguagesCard';
import PublicShare from "../utils/PublicShare";
import fetchProfileHelper from "../helperFunctions/fetchProfileHelper";
import UserPublicViews from "./UserPublicViews";

export function WelcomeUserPage() {
  const { theme, toggleTheme } = useTheme();
  const api = useAxios();
  const { isLoggedIn, userId, logout, fetchUserProfile } = useLogin();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [reachOuts, setReachOuts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showNotifications, setShowNotifications] = useState(true);
  const [showLanguages, setShowLanguages] = useState(false);
  const axios = useAxios();
  const [media, setMedia] = useState({ movie: [], series: [], documentary: [], director: [], game: [], book: [], writer: [] });
  const bgColor = theme.palette.background.default;
  const bgPaper = theme.palette.background.paper;
  const textColor = theme.palette.text.primary;
  const primaryColor = theme.palette.primary.main;
  const secondaryColor = theme.palette.secondary.main;
  const iconColor = textColor;
  const [selectedPoster, setSelectedPoster] = useState(null); // State for selected poster
  const [selectedWorkDetails, setSelectedWorkDetails] = useState(null); // State for selected work details
  const [selectedMedia, setSelectedMedia] = useState(null);

  const mediaTypes = {
    ownWorks: profile?.ownWorks?.portfolio || [],
    movie: media.movie,
    series: media.series,
    documentary: media.documentary,
    game: media.game,
    director: media.director,
    book: media.book,
  };

  

  const placeholders = [
    "https://craftypixels.com/placeholder-image/100x100/000000/ffffff",
    "https://craftypixels.com/placeholder-image/100x100/000000/ffffff",
    "https://craftypixels.com/placeholder-image/100x100/000000/ffffff",
    "https://craftypixels.com/placeholder-image/100x100/000000/ffffff",
    "https://craftypixels.com/placeholder-image/100x100/000000/ffffff",
    "https://craftypixels.com/placeholder-image/100x100/000000/ffffff",
    "https://craftypixels.com/placeholder-image/100x100/000000/ffffff",
    "https://craftypixels.com/placeholder-image/100x100/000000/ffffff",
  ];

  const [isNewUser, setIsNewUser] = useState(() => {
    const userDataJson = localStorage.getItem('userData');
    return userDataJson ? JSON.parse(userDataJson).isNewUser : '';
  });
  const [pubProfileLink, setPubProfileLink] = useState(() => {
    const userDataJson = localStorage.getItem('userData');
    return userDataJson ? JSON.parse(userDataJson).pubProfileLink : '';
  });

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  

  useEffect(() => {
    setLoading(true);
    const fetchProfile = async () => {
      try {
        const response = await api.get(`/public/profile/${pubProfileLink}`);
        if (response.data) {
          setProfile(response.data);
          console.log(response.data);
          logger(`Profile data fetched successfully: ${JSON.stringify(response.data)}`, 'debug', ['env:production', 'issue:profile-fetch', 'component:PublicView'], 'soozh-beta-frontend');

          // Set the first item in the ownWorks array as the selected work by default
          if (response.data.ownWorks && response.data.ownWorks.portfolio.length > 0) {
            setSelectedPoster(response.data.ownWorks.portfolio[0].poster);
            setSelectedWorkDetails(response.data.ownWorks.portfolio[0]);
          }
        } else {
          logger('Profile data is null, navigating to edit profile', 'warn', ['env:production', 'issue:profile-fetch', 'component:PublicView'], 'soozh-beta-frontend');
        }
      } catch (error) {
        logger(`Error fetching profile: ${error.message}`, 'error', ['env:production', 'issue:profile-fetch', 'component:PublicView'], 'soozh-beta-frontend');
        console.error('Error fetching profile:', error);
      }
      try {
        const response = await axios.get(`/mediaquery/${userId}/media_v2`);
        if (response.data) {
          console.log(response.data)
          setMedia(response.data || {});
        }
      } catch (err) {
        console.log(err.message);
      }
      setLoading(false);
    };

    fetchProfile();

    const fetchReachOuts = async () => {
      try {
        const response = await axios.get(`/profile/reachouts/${userId}`);
        setReachOuts(response.data);
      } catch (error) {
        console.error('Error fetching reach outs:', error);
      }
    };
    fetchReachOuts();
  }, [userId, navigate, axios]);

  useEffect(() => {
    const getUserProfile = async () => {
      if (userId) {
        const profileData = await fetchUserProfile(userId);
        setProfile(profileData);
        setLoading(false);
      }
    };
    getUserProfile();
  }, [userId, fetchUserProfile]);

  const handleEditClick = () => {
    navigate(`/edit-profile/${userId}`);
  };

  const handleNotificationsClick = () => {
    setShowNotifications((prev) => !prev);
    setShowLanguages(false);
  };

  const handleLanguagesClick = () => {
    setShowLanguages((prev) => !prev);
    setShowNotifications(false);
  };

  const handleAddButtonClick = (mediaType) => {
    const routes = {
      ownWorks: '/buildprofile',
      movie: '/buildprofile',
      series: '/buildprofile',
      documentary: '/buildprofile',
      game: '/buildprofile',
      director: '/buildprofile',
      book: '/buildprofile',
    };

    const redirectSections = {
      ownWorks: 'addStory',
      movie: 'addMovie',
      series: 'addSeries',
      documentary: 'addDocumentary',
      game: 'addGame',
      director: 'addStoryOfMyLife',
      book: 'addBook',
    };

    const route = routes[mediaType];
    const redirectSection = redirectSections[mediaType];

    navigate(route, { state: { redirectSection } });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={`relative w-full h-screen flex flex-col ${isMobile ? '' : 'md:flex-row'} gap-0 p-4`} style={{ backgroundColor: bgPaper }}>
      {/* User Content Box */}
      <div className={`relative p-4 rounded-lg`} style={{
        flex: '1 1 auto',
        height: "calc(100vh - 32px)",
        backgroundColor: bgColor,
        borderRadius: isMobile ? '42px 42px 0px 0px' : '42px 42px 0px 42px',
        padding: '32px',
        display: 'flex',
        flexDirection: 'column',
      }}>
        {profile && profile.userDetails?.fullName?.value ? (
          <>
            <Box display="flex" alignItems="flex-start" gap={4}>
              <Typography variant="h3" sx={{ fontWeight: "bold", color: textColor, whiteSpace: 'pre-line' }}>
                Welcome,{"\n"}{profile.userDetails.fullName.value}
              </Typography>
            </Box>

            <Grid container spacing={2} sx={{
              marginTop: 2,
              flexGrow: 1,
              height: isMobile ? "auto" : "calc(100vh - 400px)", // Adjust height for mobile
              overflow: 'hidden', // Ensure that any overflowing content is hidden
            }}>
              <Grid item xs={12} sm={6} md={4} sx={{ height: '100%', overflow: isMobile ? 'visible' : 'auto' }}>
                <Box sx={{
                  height: '100%',
                  overflow: isMobile ? 'visible' : 'auto', // Adjust overflow for mobile
                  borderRadius: '10px',
                  animation: 'slideUp 0.5s ease-out', // Add the slide-up animation
                }}>
                  {Object.keys(mediaTypes).map((mediaType, index) => (
                    <Card key={index} sx={{
                      height: '150px',
                      width: '100%',
                      marginBottom: 2,
                      backgroundColor: bgColor,
                      color: textColor,
                      padding: 2,
                      borderRadius: '32px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'left',
                      animation: 'slideUp 0.5s ease-out',
                    }}>
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 1,
                        height: '100%',
                        borderRadius: '20px',
                        overflowX: 'auto',
                        padding: '0px 10px 0px 0px'
                      }}>
                        <Box component="img" src={placeholders[index]} sx={{
                          height: '100%',
                          width: '100px',
                          objectFit: 'cover',
                          borderRadius: '20px',
                          backgroundColor: bgColor
                        }} />
                        {(Array.isArray(mediaTypes[mediaType]) ? mediaTypes[mediaType] : []).map((item, i) => (
                          <Box
                            key={i}
                            component="img"
                            src={item.poster_path || item.poster}
                            alt={`Poster ${i + 1}`}
                            sx={{
                              width: '100px',
                              height: '120px',
                              objectFit: 'cover',
                              borderRadius: '20px',
                              backgroundColor: bgColor,
                              cursor: 'pointer'
                            }}
                            onClick={() => setSelectedMedia(item)}
                          />
                        ))}
                        {/* Add Button Card */}
                        <Box sx={{
                          height: '100%',
                          width: '100px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: bgColor,
                          color: textColor,
                          borderRadius: '20px',
                          cursor: 'pointer'
                        }} onClick={() => handleAddButtonClick(mediaType)}>
                          <Typography variant="body2" sx={{ textAlign: 'center' }}>
                            Add {mediaType.charAt(0).toUpperCase() + mediaType.slice(1)}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  ))}
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={8}>
                <Box sx={{
                  position: 'relative',
                  backgroundColor: "transparent",
                  color: textColor,
                  padding: 2,
                  borderRadius: '32px',
                  height: '100%', // Fixing the height to prevent resizing
                  overflow: 'hidden',
                }}>
                  {selectedMedia ? (
                    <>
                      <Box sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        background: `linear-gradient(to right, rgba(0, 0, 0, 0.6) 90%, rgba(0, 0, 0, 0) 100%), url(${selectedMedia.screenshot_path || selectedMedia.poster_path || selectedMedia.poster})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'right',
                        backgroundRepeat: 'no-repeat',
                        filter: 'grayscale(100%)',
                        opacity: 0.35,
                      }} />
                      <Box sx={{
                        position: 'relative',
                        zIndex: 1,
                        padding: 2,
                        borderRadius: '20px',
                        width: '75%',
                        height: '100%', // Ensure the text block takes the full height
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center', // Center the text vertically
                        overflow: 'hidden',
                        background: 'linear-gradient(to top, transparent, rgba(0, 0, 0, 0.2) 50%, transparent)', // Apply the fade-out effect
                      }}>
                        <Typography variant="h3" sx={{ fontWeight: 'bold', zIndex: 3 }}>
                          {selectedMedia.name || selectedMedia.title}
                        </Typography>
                        <Typography variant="body1" sx={{ zIndex: 3 }}>
                          {selectedMedia.logLine || selectedMedia.overview}
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: 2, zIndex: 3 }}>
                          {selectedMedia.genre && `Genre: ${selectedMedia.genre}`}
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: 2, zIndex: 3 }}>
                          {selectedMedia.status && `Status: ${selectedMedia.status}`}
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: 2, zIndex: 3 }}>
                          {selectedMedia.release_date && `Release Date: ${new Date(selectedMedia.release_date).toLocaleDateString()}`}
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: 2, zIndex: 3 }}>
                          {selectedMedia.personalNote && `Personal Note: ${selectedMedia.personalNote}`}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <Typography variant="body1" sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                      Click a poster to see the details.
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (<Typography variant="h4" sx={{ fontWeight: "bold", color: textColor, whiteSpace: 'pre-line' }}>
          Profile not found, Contact Admin</Typography>)
        }
      </div>
      {/* <Box sx={{ width: '100%', maxWidth: '600px', margin: '0 auto', padding: 2, boxShadow: 3, borderRadius: 2 }}>
        <UserPublicViews pubProfileLink={pubProfileLink} />
      </Box> */}
      {/* New Right Section */}
      <div className={`relative flex flex-col ${isMobile ? '' : 'w-1/4'}`} style={{ height: "calc(100vh - 32px)" }}>
        {/* New Top Box */}
        <div className="relative p-4 rounded-lg hoverEffect" style={{
          backgroundColor: bgColor,
          height: '10%',
          borderRadius: '0px 0px 0px 0px',
          padding: '0px',
          transition: 'height 0.3s ease-in-out',
        }}>
          <div className="relative p-4 rounded-lg" style={{
            backgroundColor: bgPaper,
            height: '100%',
            borderRadius: isMobile ? '0px 0px 0px 0px' : '0px 0px 0px 42px',
            display: 'flex',
            justifyContent: 'space-between',
            paddingRight: '32px'
          }}>
            <IconButton onClick={handleNotificationsClick} sx={{
              color: iconColor,
              fontSize: { xs: "20px", sm: "42px", md: "28px", lg: "32px" },
            }}>
              <NotificationsIcon />
            </IconButton>
            {/* <IconButton onClick={handleLanguagesClick} sx={{
              color: iconColor,
              fontSize: { xs: "20px", sm: "42px", md: "28px", lg: "32px" },
            }}>
              <TranslateIcon />
            </IconButton> */}
            <IconButton onClick={toggleTheme} sx={{
              color: iconColor,
              fontSize: { xs: "20px", sm: "42px", md: "28px", lg: "32px" },
            }}>
              {theme.palette.mode === "dark" ? (
                <Brightness7Icon />
              ) : (
                <Brightness4Icon />
              )}
            </IconButton>
            <IconButton onClick={() => navigate(`/buildprofile`)} sx={{
              color: iconColor,
              fontSize: { xs: "20px", sm: "42px", md: "28px", lg: "32px" },
            }}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => navigate(`/profile/${pubProfileLink}`)} sx={{
              color: iconColor,
              fontSize: { xs: "20px", sm: "42px", md: "28px", lg: "32px" },
            }}>
              <PersonIcon />
            </IconButton>
            <IconButton onClick={logout} sx={{
              color: iconColor,
              fontSize: { xs: "20px", sm: "42px", md: "28px", lg: "32px" },
            }}>
              <ExitToAppIcon />
            </IconButton>
            <PublicShare pubProfileLink={pubProfileLink} />
          </div>
        </div>

        <style jsx>{`
          @keyframes slideUp {
            from {
              transform: translateY(100%);
              opacity: 0;
            }
            to {
              transform: translateY(0);
              opacity: 1;
            }
          }

          .hoverEffect:hover {
            height: 12%;
          }
        `}</style>

        {/* New Right Box */}
        <div className="relative p-4 rounded-lg" style={{
          backgroundColor: bgColor,
          flex: '1 1 auto',
          borderRadius: isMobile ? '0px 0px 42px 42px' : '0px 42px 42px 0px',
          overflow: 'auto'
        }}>
          {showNotifications && (
            <List>
              {reachOuts.map((reachOut) => (
                <React.Fragment key={reachOut._id.$oid}>
                  <ListItem alignItems="flex-start">
                    <ListItemIcon>
                      {reachOut.option === "Hire the Writer" ? (
                        <WorkIcon sx={{ color: primaryColor }} />
                      ) : (
                        <BookIcon sx={{ color: secondaryColor }} />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <>
                          {reachOut.name}
                          <Tooltip title={reachOut.email}>
                            <EmailIcon fontSize="small" sx={{
                              marginLeft: 1,
                              marginBottom: 0.5,
                              alignItems: "center",
                            }} />
                          </Tooltip>
                        </>
                      }
                      secondary={
                        <>
                          <Typography component="span" variant="body2" sx={{
                            display: "block",
                            color: textColor,
                            fontStyle: "italic",
                          }}>
                            "{reachOut.message}"
                          </Typography>
                          <Typography component="span" variant="body2" sx={{ display: "block", color: textColor }}>
                            <Tooltip title={reachOut.email}>
                              {formatDistanceToNow(new Date(reachOut.timestamp), {
                                addSuffix: true,
                              })}
                            </Tooltip>
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
              ))}
            </List>
          )}
          {showLanguages && (
            <LanguagesCard />
          )}
        </div>
      </div>
    </div>
  );
}
