import React from "react";
import { SparklesCore } from "../components/ui/sparkles";
import '../index.css';

export function Test() {
    return (
        <div>
            <div>
                <SparklesCore
                    id="tsparticlesfullpage"
                    background="transparent"
                    minSize={0.6}
                    maxSize={1.4}
                    particleDensity={100}
                    className="w-full h-full"
                    particleColor="#FFFFFF"
                />
            </div>
        </div>
    );
}
