import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { jwtDecode } from 'jwt-decode';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

const isTokenValid = () => {
    const token = localStorage.getItem('token');
    if (token) {
        const decoded = jwtDecode(token);
        return decoded.exp * 1000 > Date.now();
    }
    return false;
};

const getPreferredThemeMode = () => {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return 'dark';
    }
    return 'light';
};

export const ThemeProvider = ({ children }) => {
    const [mode, setMode] = useState(() => {
        const savedMode = isTokenValid() ? localStorage.getItem('themeMode') : null;
        return savedMode || getPreferredThemeMode();
    });

    useEffect(() => {
        if (isTokenValid()) {
            localStorage.setItem('themeMode', mode);
        }
    }, [mode]);

    const toggleTheme = () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    };

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: mode,
                    ...(mode === 'light'
                        ? {
                            primary: {
                                main: '#1976d2', // Blue
                            },
                            secondary: {
                                main: '#ff4081', // Pink
                            },
                            background: {
                                default: '#ffffff', // White
                                paper: '#f5f5f5', // Light Grey
                            },
                            text: {
                                primary: '#000000', // Black
                                secondary: '#555555', // Dark Grey
                            },
                        }
                        : {
                            primary: {
                                main: '#4a97d6', // Light Blue
                            },
                            secondary: {
                                main: '#f48fb1', // Light Pink
                            },
                            background: {
                                default: '#121212', // Dark Grey
                                paper: '#1e1e1e', // Slightly Lighter Dark Grey
                            },
                            text: {
                                primary: '#ffffff', // White
                                secondary: '#bbbbbb', // Light Grey
                            },
                        }),
                },
            }),
        [mode]
    );

    return (
        <ThemeContext.Provider value={{ toggleTheme, theme }}>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </MuiThemeProvider>
        </ThemeContext.Provider>
    );
};
