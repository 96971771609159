"use client";
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useLogin } from '../contexts/LoginContext';
import { useTheme } from '../contexts/ThemeContext';
import axios from 'axios';
import { Box, Typography, IconButton, Snackbar, Alert, useMediaQuery, List, ListItem, ListItemText } from "@mui/material";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { BackgroundBeams } from "./ui/background-beams";
import { FlipWords } from './ui/flip-words';
import { Signing } from './Signing';
import { cn } from '../utils/cn';
import { WelcomeUserPage } from './WelcomeUserPage'; // Import the WelcomeUserPage component
import { useAxios } from '../contexts/AxiosContext';

const loginSchema = z.object({
    email: z.string().email(),
    password: z.string().min(6, { message: "Password must be at least 6 characters long" })
});

const signUpSchema = z.object({
    name: z.string().min(1, { message: "Name is required" }),
    email: z.string().email({ message: "Invalid email address" }),
    password: z.string().min(6, { message: "Password must be at least 6 characters long" })
});

const forgotPasswordSchema = z.object({
    email: z.string().email({ message: "Invalid email address" })
});

const LandingPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [errors, setErrors] = useState({});
    const [isSignUp, setIsSignUp] = useState(false);
    const [isForgotPassword, setIsForgotPassword] = useState(false);
    const { isLoggedIn, login, error, logout, userId, username } = useLogin();
    const navigate = useNavigate();
    const { theme, toggleTheme } = useTheme();
    const bgColor = theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper;
    const textColor = theme.palette.text.primary;
    const words = ["interesting", "bold", "beautiful", "modern"];
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const [alert, setAlert] = useState({ message: '', severity: '' });
    const axios = useAxios()

    useEffect(() => {
        if (error) {
            setAlert({ message: '', severity: '' });
            setTimeout(() => {
                setAlert({ message: error, severity: 'error' });
            }, 100);
        }
    }, [error]);

    const handleLogin = async (event) => {
        event.preventDefault();
        setErrors({});

        const validation = loginSchema.safeParse({ email, password });

        if (!validation.success) {
            const newErrors = {};
            validation.error.errors.forEach((err) => {
                newErrors[err.path[0]] = err.message;
            });
            setErrors(newErrors);
            setAlert({ message: '', severity: '' });
            setTimeout(() => {
                setAlert({ message: 'Invalid login details', severity: 'error' });
            }, 100);
            return;
        }

        const loginSuccess = await login(email, password);
        if (loginSuccess) {
            navigate('/welcome');
        }
    };

    const handleSignUp = async (event) => {
        event.preventDefault();
        setErrors({});

        const validation = signUpSchema.safeParse({ name, email, password });

        if (!validation.success) {
            const newErrors = {};
            validation.error.errors.forEach((err) => {
                newErrors[err.path[0]] = err.message;
            });
            setErrors(newErrors);
            setAlert({ message: '', severity: '' });
            setTimeout(() => {
                setAlert({ message: 'Please correct the errors in the form', severity: 'error' });
            }, 100);
            return;
        }

        try {
            const res = await axios.post(`/auth/register`, { name, email, password });
            if (res.status === 200) {
                setAlert({ message: '', severity: '' });
                setTimeout(() => {
                    setAlert({ message: 'Registration successful! Please check your email to verify your account.', severity: 'success' });
                }, 100);
                setName('');
                setEmail('');
                setPassword('');
                setIsSignUp(false);
                navigate('/');
            }
        } catch (err) {
            setAlert({ message: '', severity: '' });
            setTimeout(() => {
                setAlert({ message: err.response?.data?.msg || 'Error occurred during registration', severity: 'error' });
            }, 100);
        }
    };

    const handleForgotPassword = async (event) => {
        event.preventDefault();
        setErrors({});

        const validation = forgotPasswordSchema.safeParse({ email });

        if (!validation.success) {
            const newErrors = {};
            validation.error.errors.forEach((err) => {
                newErrors[err.path[0]] = err.message;
            });
            setErrors(newErrors);
            setAlert({ message: '', severity: '' });
            setTimeout(() => {
                setAlert({ message: 'Invalid email address', severity: 'error' });
            }, 100);
            return;
        }

        try {
            const res = await axios.post(`/auth/forgot-password`, { email });
            if (res.status === 200) {
                setAlert({ message: '', severity: '' });
                setTimeout(() => {
                    setAlert({ message: 'Password reset link sent! Please check your email.', severity: 'success' });
                }, 100);
                setEmail('');
                setIsForgotPassword(false);
            }
        } catch (err) {
            setAlert({ message: '', severity: '' });
            setTimeout(() => {
                setAlert({ message: err.response?.data?.msg || 'Error occurred while requesting password reset', severity: 'error' });
            }, 100);
        }
    };

    if (isLoggedIn) {
        return <WelcomeUserPage />;
    }

    return (
        <div style={{ height: '100vh', width: '100%' }}>
            <IconButton
                sx={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    zIndex: 1000, // Ensure the button is above other elements
                    backgroundColor: theme.palette.background.paper, // Ensure it contrasts with the background
                    color: theme.palette.text.primary,
                }}
                onClick={toggleTheme}
            >
                {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
            {isMdUp && (
                <>
                    <BackgroundBeams />
                    <Box
                        sx={{
                            position: 'absolute',
                            top: { xs: '20%', md: '40%' },
                            left: { xs: '50%', md: '30%' },
                            transform: { xs: 'translate(-50%, -20%)', md: 'translate(-50%, -30%)' },
                            backgroundColor: 'transparent',
                            padding: 2,
                            borderRadius: 1,
                            textAlign: { xs: 'left', md: 'left' },
                        }}
                    >
                        <Typography
                            variant="h2"
                            sx={{
                                fontWeight: 'normal',
                                color: textColor,
                                display: 'flex',
                                alignItems: 'center',
                                fontFamily: 'Noto Sans, sans-serif',
                                justifyContent: { xs: 'center', md: 'flex-start' }
                            }}
                        >
                            Write&nbsp;
                            <Box component="span" sx={{ display: 'inline-block', fontFamily: 'Limelight, cursive' }}>
                                <FlipWords words={words} />
                            </Box>
                        </Typography>
                        <Typography
                            variant="h4"
                            sx={{
                                mt: 2,
                                color: textColor,
                                fontFamily: 'Noto Sans, sans-serif',
                            }}
                        >
                            <Box
                                sx={{
                                    mt: 2,
                                    color: textColor,
                                    fontFamily: 'Noto Sans, sans-serif',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: textColor,
                                        fontFamily: 'Noto Sans, sans-serif',
                                    }}
                                >
                                    End to End profile and IP management for WRITERS
                                </Typography>
                                <List>
                                    <ListItem>
                                        <ListItemText primary="Create Your Perfect Writer Profile in Under 5 Minutes - Absolutely Free" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Generate AI-Powered Posters for Your Stories at No Cost" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Share Everything with a Single Link" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Attract Interest Towards Your Stories" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Showcase Your Work Experience" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Turn Your Writing into a Profession" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Exclusive Agent Representation" />
                                    </ListItem>
                                </List>
                            </Box>
                        </Typography>
                    </Box>
                </>
            )}


            <Box>

            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    top: { xs: '50%', md: '50%' },
                    left: { xs: '50%', md: '75%' },
                    transform: { xs: 'translate(-50%, -50%)', md: 'translate(-50%, -50%)' },
                    width: { xs: '90%', sm: '70%', md: 'auto' },
                    backgroundColor: bgColor,
                    color: textColor,
                    borderRadius: 2,
                    boxShadow: theme.palette.mode === 'dark' ? '0 4px 20px rgba(255, 255, 255, 0.1)' : '0 4px 20px rgba(0, 0, 0, 0.1)',
                    padding: 3,
                    transition: 'all 0.3s ease-in-out',
                    minHeight: 'fit-content',
                    maxHeight: '80vh',
                    overflow: 'auto',
                }}
            >
                <Signing
                    handleSubmit={isSignUp ? handleSignUp : isForgotPassword ? handleForgotPassword : handleLogin}
                    isSignUp={isSignUp}
                    isForgotPassword={isForgotPassword}
                    email={email}
                    setEmail={setEmail}
                    password={password}
                    setPassword={setPassword}
                    name={name}
                    setName={setName}
                    errors={errors}
                    toggleSignUp={() => setIsSignUp(!isSignUp)}
                    toggleForgotPassword={() => setIsForgotPassword(!isForgotPassword)}
                />
            </Box>
            <Snackbar
                open={Boolean(alert.message)}
                autoHideDuration={6000}
                onClose={() => setAlert({ message: '', severity: '' })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={() => setAlert({ message: '', severity: '' })} severity={alert.severity}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default LandingPage;
