import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FormControl, InputLabel, Input, Button, Typography, Box } from '@mui/material';
import { useAxios } from '../contexts/AxiosContext';
import { useTheme } from '../contexts/ThemeContext';
import logger from '../logger';

const ResetPassword = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const { theme } = useTheme();
    const axios = useAxios()
    logger(`User is resetting the Password:${token}` , 'info', ['env:production', 'issue:password-reset'], 'soozh-beta-frontend');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        try {
            const response = await axios.post(`/auth/reset-password/${token}`, { password });
            setMessage(response.data.msg);
            logger(`Password resetted:${token}` , 'info', ['env:production', 'issue:password-reset'], 'soozh-beta-frontend');
            setTimeout(() => navigate('/login'), 3000); // Redirect to login after 3 seconds
        } catch (err) {
            try{
            logger(`Error While resetting password for :${token} with ${JSON.stringify(err)}` , 'info', ['env:production', 'issue:password-reset'], 'soozh-beta-frontend');
            }catch(err){
                  console.log('error while stringify the error log')
                }
            setError(err.response?.data?.msg || 'Error resetting password');
        }
    };

    return (
        <Box
            sx={{
                position: 'relative',
                color: theme.palette.text.primary,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                textAlign: 'center',
                p: 4,
                overflowY: 'hidden'
            }}
        >
            <div
                className="background"
                style={{
                    backgroundImage: 'url(/Cinema_BG.jpg)',
                    opacity: theme.palette.mode === 'dark' ? 1 : 1
                }}
            />
            <div
                className="background"
                style={{
                    backgroundImage: 'url(/Cinema_BG_dark.jpg)',
                    opacity: theme.palette.mode === 'dark' ? 1 : 0
                }}
            />
            <Box
                className="center-box"
                sx={{
                    zIndex: 1,
                    textAlign: 'center',
                    p: 4,
                    borderRadius: 2,
                    maxWidth: '500px',
                    width: '100%',
                    transform: 'translateY(-50px)'
                }}
            >
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                >
                    <Typography variant="h4" sx={{ fontFamily: 'Limelight' }}>Reset Password</Typography>
                    <FormControl error={!!error}>
                        <InputLabel>New Password</InputLabel>
                        <Input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            fullWidth
                            required
                        />
                        {error && <Typography variant="body2" color="error">{error}</Typography>}
                    </FormControl>
                    <Button type="submit" variant="contained">
                        Reset Password
                    </Button>
                    {message && <Typography color="success" mt={2}>{message}</Typography>}
                </Box>
            </Box>
        </Box>
    );
};

export default ResetPassword;
