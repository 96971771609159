import React, { useEffect, useState } from "react";
import { useAxios } from "../../contexts/AxiosContext"; 
import { useLogin } from "../../contexts/LoginContext";

const ProfileViewLogger = ({ pubProfileLink, children }) => {
    const [simpleViewRecorded, setSimpleViewRecorded] = useState(false);
    const [interactionRecorded, setInteractionRecorded] = useState(false);
    const [timerStarted, setTimerStarted] = useState(false);
    const [interactionOccurred, setInteractionOccurred] = useState(false);
    const api = useAxios();
    const { isLoggedIn, userId } = useLogin();
    const [pubProfileLinkCurrent, setPubProfileLinkCurrent] = useState(() => {
        const userDataJson = localStorage.getItem('userData');
        return userDataJson ? JSON.parse(userDataJson).pubProfileLink : '';
      });

    useEffect(() => {
        if (!simpleViewRecorded && pubProfileLink !== pubProfileLinkCurrent) {
            recordView("simple");
            setSimpleViewRecorded(true);
        }
    }, [simpleViewRecorded]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimerStarted(true);
        }, 30000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (timerStarted && interactionOccurred && !interactionRecorded && pubProfileLink !== pubProfileLinkCurrent) {
            recordView("interaction");
            setInteractionRecorded(true);
        }
    }, [timerStarted, interactionOccurred]);

    const handleInteraction = () => {
        if (interactionRecorded) return;

        setInteractionOccurred(true);
    };

    const recordView = async (viewType = "simple") => {
        try {
            const ipAddress = await fetchIpAddress();
            const geoData = await fetchGeoLocation(ipAddress);

            const viewData = {
                pubProfileLink,
                ipAddress,
                geoData,
                userId: isLoggedIn ? userId : null,
                viewType,
                timestamp: new Date().toISOString(),
            };

            await api.post('/public/logProfileView', viewData);
        } catch (error) {
            console.error("Error recording view:", error);
        }
    };

    const fetchIpAddress = async () => {
        try {
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();
            return data.ip;
        } catch (error) {
            console.error("Error fetching IP address:", error);
            return null;
        }
    };

    const fetchGeoLocation = async (ip) => {
        try {
            const response = await fetch(`https://ipinfo.io/${ip}/geo`);
            const data = await response.json();
            const { city, region, country, loc } = data;
            const [latitude, longitude] = loc.split(',');

            return {
                city,
                region,
                country,
                latitude,
                longitude,
            };
        } catch (error) {
            console.error("Error fetching geo-location:", error);
            return {
                city: null,
                region: null,
                country: null,
                latitude: null,
                longitude: null,
            };
        }
    };

    return (
        <div onClick={handleInteraction}>
            {children}
        </div>
    );
};

export default ProfileViewLogger;
